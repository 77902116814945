import { Box, Typography } from "@mui/material";
import ReviewCard from "./ReviewCard";
import LoadingReview from "./LoadingReview";
import { useContext } from "react";
import { ReviewsContext } from "../../Context/ReviewsContext";

const ReviewsTab = () => {
  const { loading, reviews } = useContext(ReviewsContext);

  return (
    <Box sx={{ padding: "0px !important" }}>
      {loading ? (
        <LoadingReview />
      ) : (
        <>
          <Typography
            variant="h3"
            sx={{ marginTop: "30px !important", fontWeight: "600" }}
          >
            Host Reviews
          </Typography>
          {reviews?.reviews?.map((review, index) => (
            <ReviewCard key={index} review={review} />
          ))}
        </>
      )}
    </Box>
  );
};

export default ReviewsTab;
