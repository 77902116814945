import { APIService } from "./APIService";

export const BeachGearService = {
    lists,
    adminBeachGears,
    lists,
    typeList,
    serviceList,
    beachGearCategoriesList,
    beachGearTypeList,
    beachGearNameList,
    hostBySlug,
    beachGearsByHost,
    typeListsByHost
};

function lists(data) {
    return APIService.post('api/beachgear-lists', data);
}
function adminBeachGears() {
    return APIService.get('api/admin-beachgears');
}
function typeList(data){
    return APIService.get('api/type-lists'); 
}
function serviceList(type="0", search=''){
    var search = search!='' ? '&search='+search : '';
    return APIService.get(`api/service-lists?type=${type}${search}`);
}
function beachGearCategoriesList(is_home = 0){
    return APIService.get('api/beachgear-categories-lists?is_home='+is_home);
}
function beachGearTypeList(categoryid){
    return APIService.get('api/beachgear-type-lists?categoryid='+categoryid);
}
function beachGearNameList(categoryid, typeid){
    return APIService.get('api/beachgear-name-lists?categoryid='+categoryid+'&typeid='+typeid);
}
function hostBySlug(slug){
    return APIService.get('api/host-by-slug/'+slug);
}
function beachGearsByHost(slug, type="all", search){
    let searchdata = search!='' ? '&search='+search : '';
    return APIService.get('api/beachgears-by-host/'+slug+'?type='+type+searchdata);
}
function typeListsByHost(slug){ 
    return APIService.get('api/type-lists-by-host/'+slug);
}

