const HostingInsights = (data) => {
  const titleData = {
    title: "Hosting insights and rewards",
    description:
      "Learn about best hosting practices, and get access to exclusive hosting perks.",
  };

  const optionsData = [
    {
      label: "Recognition and achievements",
      description:
        "Get recognized for reaching hosting milestones and Superhost status.",
      field: "recognition",
      options: data["recognition"],
    },
    {
      label: "Insights and tips",
      description:
        "Reach your hosting goals with data-backed tips and insights.",
      field: "insights",
      options: data["insights"],
    },
    {
      label: "Pricing trends and suggestions",
      description: "Optimize your price with data-backed tips and insights.",
      field: "pricing",
      options: data["pricing"],
    },
    {
      label: "Hosting perks",
      description:
        "Take advantage of Beachify perks like discounts on partner products and special promotions.",
      field: "perks",
      options: data["perks"],
    },
  ];

  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};
export default HostingInsights;
