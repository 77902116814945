import React from 'react';
import {
  Box,
  FormGroup,
  Stack,
  Typography,
  Switch
} from '@mui/material';
import styled from '@emotion/styled';
import InputMask from 'react-input-mask';
   
const TimeInput = ({id, placeholder, name, value, onChange, onBlur, onClick, className, style, sx, disabled, idSwitch, checkedSwitch, onChangeSwitch}) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  
  const beforeMaskedStateChange = ({nextState}) => {
    let { value, selection } = nextState;
    
    var firstChar = value[0];
    var secondChar = value[1];

    if(firstChar > 1){
      value = value.split('')
      value[0] = 0;
      value[1] = firstChar;
      value = value.join('');

      selection.start = parseInt(selection.start)+1;
      selection.end = parseInt(selection.end)+1;
    }

    if(firstChar == 1 && secondChar > 2){
      value = value.split('')
      value[1] = 0;
      value = value.join('');
    }

    return {...nextState, value, selection};
  }

  return (
      <>
        <Box style={{position: 'relative'}}>
          <InputMask
            beforeMaskedStateChange={beforeMaskedStateChange}
            mask={[
              /[0-9]/,
              /[0-9]/,
              ':',
              /[0-5]/,
              /[0-9]/,
              ' ',
              /[Aa|Pp]/,
              'M'
            ]}
            maskChar="_"
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            className={className}
            style={style}
            sx={sx}
            disabled={disabled}
          />
          <FormGroup style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '24px', display: 'none'}} id={idSwitch}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>AM</Typography>
                <AntSwitch 
                  onMouseDown={(e) => { e.preventDefault(); }}
                  checked={checkedSwitch}
                  onChange={onChangeSwitch} 
                />
              <Typography>PM</Typography>
            </Stack>
          </FormGroup>
        </Box>
      </>
  )
}

export default TimeInput;