import React from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import styled from '@emotion/styled';
   
const SliderInput = ({min, max, defaultValue, marks, onChangeCommitted}) => {
  const AirbnbSlider = styled(Slider)(({ theme }) => ({
      color: '#FF4110',
      height: 3,
      padding: '13px 0',
      '& .MuiSlider-markLabel':{
        '@media (min-width: 0) and (max-width:  991.98px)': { 
            transform: 'none',
        },
      },
      '& span:nth-child(6)':{
        '@media (min-width: 0) and (max-width: 991.98px)': { 
            left: 'unset !important',
            right: '0',
        },
      },
      '& .MuiSlider-mark':{
        height:0,
      },
      '& .MuiSlider-thumb': {
          height: 27,
          width: 27,
          backgroundColor: '#fff',
          border: '1px solid #fff',
          '&:hover': {
              boxShadow: '0 0 0 8px rgb(255 65 16 / 20%)',
          },
          '&.Mui-active': {
              boxShadow: '0 0 0 8px rgb(255 65 16 / 20%)',
          },
          '& .airbnb-bar': {
              height: 9,
              width: 1,
              backgroundColor: '#FF4110',
              marginLeft: 1,
              marginRight: 1,
          }, 
      },
      '& .MuiSlider-track': {
          height: 3,
      },
      '& .MuiSlider-rail': {
          color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
          opacity: theme.palette.mode === 'dark' ? undefined : 1,
          height: 3,
      },
  }));

    function AirbnbThumbComponent(props) {
        const { children, ...other } = props;
        return (
            <SliderThumb {...other}>
                {children}
                <span className="airbnb-bar" />
                <span className="airbnb-bar" />
                <span className="airbnb-bar" />
            </SliderThumb>
        );
    }

    return (
      <>
        <AirbnbSlider
          slots={{ thumb: AirbnbThumbComponent }}
          getAriaLabel={() => 'Minimum distance'}
          valueLabelDisplay="auto"
          disableSwap
          className="mx-4 mt-2"
          min={min}
          max={max}
          defaultValue={defaultValue}
          marks={marks}
          onChangeCommitted={onChangeCommitted} 
        />
      </>
    )
}

export default SliderInput;