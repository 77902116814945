import { Container, Typography } from "@mui/material";
import BulletList from "../../Components/Common/BulletList";

const listContent = [
  <Typography>
    <strong>Lorem ipsum </strong>dolor sit amet, consectetur adipiscing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
    ex ea commodo consequat.
  </Typography>,
  <Typography>
    <strong>Lorem ipsum </strong>dolor sit amet, consectetur adipiscing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
    ex ea commodo consequat.
  </Typography>,
  <Typography>
    <strong>Lorem ipsum </strong>dolor sit amet, consectetur adipiscing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
    ex ea commodo consequat.
  </Typography>,
];
const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Typography>Legal Terms</Typography>
      <Typography sx={{ fontWeight: "500" }} component="h2">
        Privacy Policy
      </Typography>

      <Typography sx={{ marginTop: 3, marginBottom: 1 }}>
        Last Updated: January 25, 2023
      </Typography>
      <Typography sx={{ marginTop: 1 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Typography>
      <Typography sx={{ marginTop: 3, fontWeight: "600" }} component="h4">
        1. DEFINITIONS
      </Typography>
      <Typography sx={{ marginTop: 1 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Typography>
      <Typography sx={{ marginTop: 3, fontWeight: "600" }} component="h4">
        2. PERSONAL INFORMATION WE COLLECT
      </Typography>
      <Typography sx={{ marginTop: 1 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Typography>
      <Typography sx={{ marginTop: 3, fontWeight: "600" }} component="h4">
        2.1 Information Needed to Use the Beachify Platform.
      </Typography>
      <Typography sx={{ marginTop: 1 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </Typography>
      <BulletList listContent={listContent} />
    </Container>
  );
};

export default PrivacyPolicy;
