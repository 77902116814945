import { useState } from "react";
import { useEffect } from "react";
import MakeToast from "../../Helpers/MakeToast";
import CustomTabs from "../Common/CustomTabs/CustomTabs";
import { TaxContext } from "../../Context/TaxContext";
import TaxDocuments from "./Tabs/TaxDocuments";
import TaxPayers from "./Tabs/TaxPayers";

const NotificationsTabs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const getTaxesData = () => {
    // NotificationSettingsService.getNotificationsSettings()
    //   .then((response) => {
    //     setData(response.data.data);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     MakeToast("error", error);
    //   });
  };
  useEffect(() => {
    setLoading(true);
    getTaxesData();
  }, []);
  const tabsContent = [
    {
      label: "Taxpayers",
      content: <TaxPayers />,
    },
    { label: "Tax documents", content: <TaxDocuments /> },
  ];
  return (
    <TaxContext.Provider
      value={{
        loading: loading,
        data: data,
        getTaxesData: getTaxesData,
      }}
    >
      <CustomTabs
        tabs={tabsContent}
        tabName="tax-tab"
        panelName="tax-tabpanel"
      />
    </TaxContext.Provider>
  );
};
export default NotificationsTabs;
