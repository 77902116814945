let defaultState = {
    openAuthModal: false,
}

let commonReducers = (state = defaultState, action) => {
    switch (action.type) {
        case 'OPEN_AUTH_MODAL': {
            let newState = { ...state };
            newState = {
                openAuthModal: true
            }
            return newState;
        }
        case 'REVERT_DATA': {
            let newState = { ...state };
            newState = {
                openAuthModal: false
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}

export default commonReducers