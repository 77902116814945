import { Button, Link, Typography,Box } from "@mui/material";
import TaxModal from "../TaxModal";
import { useState } from "react";

const TaxPayers = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
    <Box className="become-host-step-form">
    <Box className="small-heading">
      <Typography  sx={{ mb: 1 }} component="h2" className="main-heading">
        Taxpayer Information
      </Typography>
      </Box>
      </Box>
      <Typography variant="h4">
        Tax info is required in most countries where the taxpayer is a resident,
        Host or Co-Host, or both.{" "}
        <Link
          sx={{
            display: "initial !important",
            fontWeight: "500",
            fontSize: 17,
          }}
          className="link-update"
          href="#"
        >
          Learn more
        </Link>
      </Typography>
      <Button
        size="small"
        type="button"
        sx={{
          backgroundColor: "black !important",
          mt: 4,
          borderRadius: 16,
          border: "1px solid black !important",
        }}
        className="btn-primary submit-btn "
        onClick={() => setShowModal(true)}
      >
        Add tax info
      </Button>
      <TaxModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default TaxPayers;
