import { Typography, Grid } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import { NotificationSettingsContext } from "../../Context/NotificationSettingsContext";
import MakeToast from "../../Helpers/MakeToast";
import { NotificationSettingsService } from "../../Services/NotificationSettingsService";
import CustomSwitch from "../Common/CustomSwitch";
const optionsLabels = {
  email: "Email",
  sms: "SMS",
  phone: "Phone calls",
  browser: "Browser notifications",
};
//TODO: Validate push notifications from browser
const pushNotifications = false;
const SwitchOption = (props) => {
  const { keyName, value, field } = props;
  const { getNotificationsData } = useContext(NotificationSettingsContext);

  const [checked, setChecked] = useState(value);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setLoading(true);
    NotificationSettingsService.updateNotificationsSettings({
      field_name: `${field}_${keyName}`,
      value: !checked,
    })
      .then((response) => {
        setLoading(false);
        getNotificationsData();
        setChecked(!checked);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };

  return (
    <Grid sx={{ my: 3 }} container>
      <Grid xs={10} sx={{ alignSelf: "center" }} item>
        <Typography component="h4">{optionsLabels[keyName]}</Typography>
        {keyName == "browser" && !pushNotifications && (
          <Typography sx={{ fontWeight: "light" }} component="h4">
            Push notifications are off. To enable this feature, turn on
            notifications
          </Typography>
        )}
      </Grid>
      <Grid xs={2} sx={{ textAlign: "end" }} item>
        <CustomSwitch
          disabled={loading}
          checked={checked}
          onChange={handleChange}
          sx={{ marginTop: 2 }}
        />
      </Grid>
    </Grid>
  );
};

export default SwitchOption;
