import { Box, ButtonBase, Typography } from "@mui/material";
import { useState } from "react";
import EditModal from "./EditModal";
import BaseModal from "../../Components/Modal/BaseModal";
import images from "../../assets/js/images";

const labels = {
  email: "Email",
  sms: "SMS",
  phone: "Phone",
  browser: "Browser",
};
const settingsLabel = (options) => {
  let text = "On: ";
  let optionsLabel = [];
  for (const [key, value] of Object.entries(options)) {
    if (value == 1) optionsLabel.push(labels[key]);
  }
  if (optionsLabel.length > 0) {
    optionsLabel.forEach((optionLabel, index) => {
      text +=
        index == optionsLabel.length - 1 ? optionLabel : optionLabel + ", ";
    });
    return text;
  }
  return "Off";
};
const EditOption = (props) => {
  const { label = "", options, comingSoon = false } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography component="h4">{label}</Typography>
      <Typography sx={{ fontWeight: "light" }}>
        {settingsLabel(options)}
      </Typography>
      <ButtonBase
        sx={{ fontSize: 16, marginTop: 1 }}
        className="black-underline-link"
        variant="text"
        onClick={() => handleOpen()}
      >
        Edit
      </ButtonBase>
      {comingSoon ? (
        <BaseModal open={open} onClose={handleClose}>
          <Box sx={{ textAlign: "center" }}>
            <img src={images.logo} />
            <Typography sx={{ pt: 1 }} component="h3">
              {label} coming soon!
            </Typography>
          </Box>
        </BaseModal>
      ) : (
        <EditModal {...props} open={open} onClose={handleClose} />
      )}
    </Box>
  );
};

export default EditOption;
