import React, { useState } from "react";
import images from '../../assets/js/images';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Typography,
  Box,
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem, 
  Button, 
  CircularProgress
} from '@mui/material';
import { useFormik } from "formik";
import * as yup from 'yup';

import RadioInput from "../Inputs/RadioInput";
import AppHelper from '../../Helpers/AppHelper';
import MakeToast from "../../Helpers/MakeToast";

import { HostService } from "../../Services/HostService";

const DeliveryPickup  = ({hostData, getHostProfile}) => {
    const [loader, setLoader] = useState(false);
    const totaldays = AppHelper.lists().days;
    const totalhours = AppHelper.lists().hours;
    const yesno = AppHelper.lists().yesno;

   const validationSchema = yup.object({
        miles_allowed: yup
            .number()
            .required('Miles allowed is required')
            .typeError("Miles allowed must be a number"),
        per_mile_pricing: yup
            .number()
            .required('Charge per mile is required')
            .typeError("Charge per mile must be a number"),
        scheduled_booking_day: yup.string().when('is_scheduled_booking', {
            is: (is_scheduled_booking) => is_scheduled_booking=='1', 
            then: yup.string().required('Scheduled booking is required'),
            otherwise: yup.string().nullable(true)
        }),
        scheduled_booking_hour: yup.string().when('is_scheduled_booking', {
            is: (is_scheduled_booking) => is_scheduled_booking=='1', 
            then: yup.string().required('Scheduled booking is required'),
            otherwise: yup.string().nullable(true)
        }),
    });

    const formik = useFormik({
        initialValues: {
            delivery_pickup_service: hostData.delivery_pickup_service,
            is_scheduled_booking: hostData.is_scheduled_booking,
            scheduled_booking_day: hostData.scheduled_booking ? (hostData.scheduled_booking.day!=null ? hostData.scheduled_booking.day : '') : '',
            scheduled_booking_hour: hostData.scheduled_booking ? (hostData.scheduled_booking.hour!=null ? hostData.scheduled_booking.hour : '') : '',
            miles_allowed: hostData.miles_allowed,
            per_mile_pricing: hostData.per_mile_pricing,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoader(true);
            
            var extras = {
                'scheduled_booking': {
                    'day': values.scheduled_booking_day,
                    'hour': values.scheduled_booking_hour
                }
            }
        
            Object.assign(values, extras);
    
	        delete values.scheduled_booking_day;
	        delete values.scheduled_booking_hour;

            HostService.hostProfileUpdate(values).then((resp) => {
                getHostProfile();
                MakeToast("success", resp);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            });
        },
      });
    return(
        <>  
            <form onSubmit={formik.handleSubmit} className="become-host-step-form">
                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading">
                            Do you offer delivery & pickup services for items rented, such as beach gear?
                        </Typography>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Box className="radio-check-button">
                            <FormControl>
                                <RadioInput 
                                customArr={{data : yesno, name : 'name', id : 'id'}}
                                name="delivery_pickup_service"
                                value={formik.values.delivery_pickup_service}
                                onChange={formik.handleChange}
                                defaultValue="0"
                                sx={{
                                    fontSize: '16px',
                                    paddingRight: '30px',
                                }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                        <Typography variant="h6" component="h2" className="main-heading">
                            Do you require a minimum amount of notice from customers who want to book with delivery & pickup services added?
                        </Typography>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Box className="radio-check-button">
                            <FormControl>
                                <RadioInput 
                                customArr={{data : yesno, name : 'name', id : 'id'}}
                                name="is_scheduled_booking"
                                value={formik.values.is_scheduled_booking}
                                onChange={formik.handleChange}
                                defaultValue="0"
                                sx={{
                                    fontSize: '16px',
                                    paddingRight: '30px',
                                }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    {formik.values.is_scheduled_booking=='1' && 
                    <>
                    <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                        <Typography variant="h6" component="h2" className="main-heading">
                            How much notice do you require for bookings scheduled with delivery/pickup services?
                        </Typography>
                    </Grid>
                    <Box className="total-hours">
                        <Box className="opening-hours">
                            <Box className="hours-show"
                                style={{
                                border: `1px solid #${formik.touched.scheduled_booking_day && Boolean(formik.errors.scheduled_booking_day) ||
                                    formik.touched.scheduled_booking_hour && Boolean(formik.errors.scheduled_booking_hour) ? 'd83e3f' : 'EEEEEE'}`,
                                justifyContent: 'space-evenly'
                                }}
                            >
                                <div>
                                <FormControl sx={{
                                    m: 1,
                                    borderRight: 'none',
                                    width : '95px'
                                }} >
                                    <Select
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        color: '#09060F'
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    name="scheduled_booking_day"
                                    value={formik.values.scheduled_booking_day}
                                    onChange={formik.handleChange}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Days</em>
                                    </MenuItem>
                                    {totaldays.map((name) => {
                                        return (
                                        <MenuItem value={name} key={name} >{name}</MenuItem>);
                                    })}
                                    </Select>
                                </FormControl>
                                </div>
                                <div className="border-none-class">
                                <FormControl sx={{
                                    m: 1,
                                    borderRight: 'none'
                                }} >
                                    <Select
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        color: '#09060F',
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    name="scheduled_booking_hour"
                                    value={formik.values.scheduled_booking_hour}
                                    onChange={formik.handleChange}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Hours</em>
                                    </MenuItem>
                                    {totalhours.map((name) => {
                                        return (
                                        <MenuItem value={name} key={name} >{name}</MenuItem>);
                                    })}
                                    </Select>
                                </FormControl>
                                </div>
                            </Box>
                            {formik.touched.scheduled_booking_day && Boolean(formik.errors.scheduled_booking_day) ?
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                style={{
                                    paddingLeft: 15,
                                    color: 'red',
                                }}
                                id="input-with-icon-textfield-helper-text"
                                >
                                {formik.errors.scheduled_booking_day}
                                </p>
                                : formik.touched.scheduled_booking_hour && Boolean(formik.errors.scheduled_booking_hour) ?
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                    style={{
                                    paddingLeft: 15,
                                    color: 'red',
                                    }}
                                    id="input-with-icon-textfield-helper-text"
                                >
                                    {formik.errors.scheduled_booking_hour}
                                </p>
                                :
                                ''
                            }
                        </Box>
                    </Box>
                    </>
                    }
                    <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block">
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                        sx={{
                            '@media (max-width:769px)': {
                            marginBottom: '0 !important',
                            },
                            '@media (max-width:577px)': {

                            },
                        }}>
                        Pricing setup
                        </Typography>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="small-heading">
                        How many miles will your company travel for delivery & pickup services? beachify may not allow customers that require these services to find hosts, or book delivery/pickup services with hosts for locations that are outside of the hosts’ designated service area.
                        </Typography>
                    </Grid>
                    <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12}>
                        <FormControl sx={{ width: '100%' }} className="input-style "
                        style={{
                            border: `1px solid #${formik.touched.miles_allowed && Boolean(formik.errors.miles_allowed) ? 'd83e3f' : 'EEEEEE'}`
                        }}
                        >
                        <TextField className="outline-input" placeholder="Miles allowed"
                            id="input-with-icon-textfield"
                            name="miles_allowed"
                            value={formik.values.miles_allowed}
                            onChange={formik.handleChange}
                            sx={{
                            paddingLeft: '30px',
                            '.MuiInputBase-root': {
                                '&:before': {
                                borderBottom: '0px solid',
                                },
                                '&:after': {
                                borderBottom: '0px solid'
                                },
                                '&:hover': {
                                '&:before': {
                                    borderBottom: '0px !important',
                                },
                                },
                            },
                            '@media (max-width:577px)': {
                                paddingLeft: '15px'
                            },
                            }}
                            variant="standard"
                        />
                        </FormControl>
                        {formik.touched.miles_allowed && Boolean(formik.errors.miles_allowed) &&
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                            style={{
                            paddingLeft: 15,
                            color: 'red',
                            }}
                            id="input-with-icon-textfield-helper-text"
                        >
                            {formik.errors.miles_allowed}
                        </p>
                        }
                    </Grid>
                    <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="small-heading">
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="sub-heading">
                        How much do you charge per mile for delivery & pickup services?
                        </Typography>
                    </Grid>
                    <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12}>
                        <Box>
                        <FormControl sx={{ width: '100%' }} className="input-style"
                            style={{
                            border: `1px solid #${formik.touched.per_mile_pricing && Boolean(formik.errors.per_mile_pricing) ? 'd83e3f' : 'EEEEEE'}`
                            }}
                        >
                            <TextField className="outline-input" placeholder="Charge per mile"
                            id="input-with-icon-textfield"
                            name="per_mile_pricing"
                            value={formik.values.per_mile_pricing}
                            onChange={formik.handleChange}
                            sx={{
                                paddingLeft: '30px',
                                '.MuiInputBase-root': {
                                '&:before': {
                                    borderBottom: '0px solid',
                                },
                                '&:after': {
                                    borderBottom: '0px solid'
                                },
                                '&:hover': {
                                    '&:before': {
                                    borderBottom: '0px !important',
                                    },
                                },
                                },
                                '@media (max-width:577px)': {
                                paddingLeft: '15px'
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start" className="input-image"
                                    sx={{
                                    paddingRight: '5px',

                                    }} >
                                    <img src={images.priceIcon} alt="price" />

                                </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            />
                        </FormControl>
                        {formik.touched.per_mile_pricing && Boolean(formik.errors.per_mile_pricing) &&
                            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                            style={{
                                paddingLeft: 15,
                                color: 'red',
                            }}
                            id="input-with-icon-textfield-helper-text"
                            >
                            {formik.errors.per_mile_pricing}
                            </p>
                        }
                        </Box>
                    </Grid>
                </Grid>
                <Box className="modal-filter-btn">
                    <Button className="filter-button rounded-button"
                        type="submit"
                        sx={{
                        marginTop: '15px !important',
                        color: '#ffffff',
                        textTransform: 'capitalize',
                        }} >
                            {loader ? <CircularProgress color="inherit" /> : 'Save' }
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default DeliveryPickup;
