import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const GoogleAutoCompleteInput = React.memo(({ placeholder, name, value, onChange, className, sx, disabled, InputProps, setGoogleAutocompleteData, variant, onFocus, onBlur }) => {
  const handlePlaceSelected = useCallback((place) => {
    const address = place.formatted_address;

    const googleData = { address };

    setGoogleAutocompleteData({});
    setTimeout(() => {
      setGoogleAutocompleteData(googleData);
    }, 500);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        googleData.lat = latLng.lat;
        googleData.long = latLng.lng;

        setGoogleAutocompleteData({});
        setTimeout(() => {
          setGoogleAutocompleteData(googleData);
        }, 500);
      })
      .catch(error => console.error('Error', error));
  }, [setGoogleAutocompleteData]);

  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: 'us' },
    },
    onPlaceSelected: handlePlaceSelected,
  });

  return (
    <TextField
      inputRef={ref}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      sx={sx}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={InputProps}
      variant={variant || 'outlined'}
      disabled={disabled}
    />
  );
});

export default GoogleAutoCompleteInput;
