import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import FormField from "../FormField";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  BusinessInfoContext,
  BusinessPhoneContext,
} from "../../../Context/BusinessInfoContext";
import { useContext, useEffect, useState } from "react";
import OtpPhone from "../Otp/Phone";
import { BusinessInfoService } from "../../../Services/BusinessInfoService";
import MakeToast from "../../../Helpers/MakeToast";

const PhoneNumber = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  let informationValidationSchema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone is required")
      .typeError("Phone must be a valid number")
      .matches(phoneRegExp, 'Phone number is not valid')
  });
  
  const [openOtp, setOtpOpen] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const { business } = useContext(BusinessInfoContext);

  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { phone: business?.phone ?? "" },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  const formSubmit = (values) => {
    BusinessInfoService.generateOtp({
      value: values.phone,
      field_name: "mobile_no",
    })
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
        MakeToast("success", response);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    editEnable == false && formik.setFieldValue("phone", business?.phone ?? "");
  }, [editEnable]);
  return (
    <BusinessPhoneContext.Provider value={values.phone}>
      {openOtp && (
        <OtpPhone
          setShow={setEditEnable}
          openOtp={openOtp}
          setOtpOpen={setOtpOpen}
          handleClose={() => setOtpOpen(false)}
          phone={values.phone}
        />
      )}
      <FormField
        label="Phone Number"
        description="Please enter your company phone number below."
        text={business?.phone}
        editEnable={editEnable}
        setEditEnable={setEditEnable}
      >
        <FormControl
          sx={{ width: "100%", mt: 2 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Phone Number"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.phone && touched.phone && errors.phone && (
          <FormHelperText className="field-helper-text error">
            {errors.phone && touched.phone && errors.phone}
          </FormHelperText>
        )}

        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          disabled={formik.isSubmitting}
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Save"
          )}
        </Button>
      </FormField>
    </BusinessPhoneContext.Provider>
  );
};

export default PhoneNumber;
