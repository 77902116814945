// import React from 'react';
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
// ----favorite page start---//
import ListItemIcon from '@mui/material/ListItemIcon';
// ----favorite page end---//

import ServiceHeader from "../../Components/Service/ServiceHeader";
import ServiceSidebar from "../../Components/Service/ServiceSidebar";
import HostContent from "../../Components/Favorites/HostContent";

import {
    Paper,
    Container,
} from '@mui/material';
import BeachGear from "../../Components/Home/BeachGear";
import images from "../../assets/js/images";
import Grid from '@mui/material/Unstable_Grid2';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
// ----card-----//

const imagesArr = [
    {
        url: '',
        // background:color('light-fourth'),
        title: 'Add photo',
        width: '206px',
        height: '168px',
        backgroundPosition: 'cover',
    },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

//-card end---//
const Favorites = () => {

    // -------------favorite page start------//
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>

        {/*-------------------- Favourite Host start---------------*/}
            <section className="service-listing section-padding ">
                <Container>
                    <ServiceHeader images={images}/>
                    <Grid container>
                        <ServiceSidebar images={images} />
                        <HostContent images={images} />
                    </Grid>
                </Container>
            </section>
        </>
    )
}

export default Favorites;