import { Box, Link, Typography } from "@mui/material";

const Help = () => {
  return (
    <Box sx={{ mt: 6 }}>
      <Box className="become-host-step-form">
      <Box className="small-heading">
      <Typography sx={{ mb: 1 }} component="h2" className="main-heading">
        Need help?
      </Typography>
      </Box>
      </Box>
      <Typography variant="h4">
        Get answers to questions about taxes in our{" "}
        <Link
          sx={{
            display: "initial !important",
            fontWeight: "500",
            fontSize: 17,
          }}
          className="link-update"
          href="#"
        >
          Help Center
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default Help;
