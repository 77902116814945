import { Card, CardContent, Hidden } from "@mui/material";
import InfoDescription from "./InfoDescription";

const InfoRightCard = () => {
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="info-right-card">
        <CardContent sx={{ paddingTop: 0 }}>
          <InfoDescription
            title="Why isn’t my info shown here?"
            description="We’re hiding some account details to protect your identity."
            icon="vpn_lock"
          />
          <InfoDescription
            title="Which details can be edited?"
            description="Details Beachify uses to verify your identity can’t be changed. Contact info and some personal details can be edited, but we may ask you verify your identity the next time you book or create a listing."
            icon="lock"
          />
          <InfoDescription
            title="What info is shared with others?"
            description="Beachify only releases contact information for Hosts and guests after a reservation is confirmed."
            icon="visibility"
            noBorder
          />
        </CardContent>
      </Card>
    </Hidden>
  );
};
export default InfoRightCard;
