import { combineReducers } from 'redux';

import commonReducers from './CommonReducers';
import filterReducers from './FilterReducers';
import userReducers from './UserReducers';
import cartReducers from './CartReducers';
import calculationReducers from './CalculationReducers';

let reducers = combineReducers({
    userReducers: userReducers,
    filterReducers: filterReducers,
    commonReducers: commonReducers,
    cart: cartReducers,
    calculationReducers:calculationReducers
});

const rootReducer = (state, action) => {
    return reducers(state, action)
}

export default rootReducer;