import React, { useCallback } from "react";
import { Box, FormControl, TextField, InputAdornment, Button } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";

import AppHelper from "../../Helpers/AppHelper";
import QuestionMarkPopover from "../Common/QuestionMarkPopover";
import BreadCrumb from "../Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import MakeToast from "../../Helpers/MakeToast";
import Grid from '@mui/material/Unstable_Grid2';
import debounce from 'lodash.debounce';

import SelectInput from "../../Components/Inputs/SelectInput";

const ServiceHeader = ({ images, activeServiceCategories, setActiveServiceCategories, getServiceList, beachgearcategorieslist }) => {
    const location = useLocation();
    const { user } = useSelector((state) => state.userReducers.data);
    const debounceFn = debounce((category, value) => {
        getServiceList(category, value);
    }, 1000);
    const [cpyBtn, setCpyBtn] = React.useState(null);
    const cpyBtnContent = "Give this link to your website manager to create a “Book now with Beachify!“ button on your website and social media’s to make it easy for customers to book your services.";

    return (
        <>

            <Grid item xs={12}>
                <div className="service-top-header">
                    <BreadCrumb data={[["Services"]]} />
                    <FormControl sx={{ width: '324px', border: '0 !important' }} className="input-style reduce-bottom-space search-listing-space">
                        <TextField placeholder="Search" id="searchServiceCategories" className="outline-input input-text-style"
                            onChange={(e) => debounceFn(activeServiceCategories[0], e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={images.searchIcon} alt="" />
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                '.MuiInputBase-root': {
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#eee',
                                    },
                                    '&.Mui-focused': {
                                        ' .MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #eee',
                                        }

                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#eee',
                                    }

                                },
                            }}
                        />
                    </FormControl>
                </div>
            </Grid>
            {/* <Grid xs={12} sm={12} md={3} lg={2} className='web-view-all'></Grid> */}
            <Grid xs={12} sm={12} md={12} lg={12} className='web-view-all'>
                <div className="main-heading-block">
                    <Box className="service-heading">
                        <h2 className="main-heading">
                            {location.pathname.includes("services") ? (activeServiceCategories[1] ? activeServiceCategories[1] : "") : "Favourites"}
                        </h2>
                    </Box>
                    <div className="service-header-block">
                        {
                            location.pathname.includes("services") &&
                            <>
                                <div style={{ display: "flex" }}>
                                    <Button
                                        type="button"
                                        fullHeight
                                        disableElevation
                                        variant="contained"
                                        className="border-radius-16 btn-primary text-case contact-us-btn"
                                        onClick={async () => {
                                            if ("clipboard" in navigator) {
                                                await navigator.clipboard.writeText(window.origin + "/host/" + user.host_slug);
                                            } else {
                                                document.execCommand("copy", true, window.origin + "/host/" + user.host_slug);
                                            }
                                            MakeToast('success', 'Store Link Copied');
                                        }}
                                    >
                                        Copy store link
                                    </Button>
                                    <QuestionMarkPopover
                                        anchorPopOver={cpyBtn}
                                        setAnchorPopOver={setCpyBtn}
                                        content={cpyBtnContent}
                                        spanStyle={{ paddingTop: '5px' }}
                                    />
                                </div>
                                <div className="fill-plus-icon">
                                    <RouterLink
                                        className="view-all-btn"
                                        to="/service/add"
                                    >
                                        <img src={images.plusfillicon} alt="" />
                                        Add new service
                                    </RouterLink>
                                </div>
                                <Grid xs={12} sm={12} md={4} lg={4}>
                                    <FormControl variant="standard" className="input-style outlined-input select-style textfield-rouded-md" sx={{
                                        width: '100%',
                                        borderRadius: '18px',
                                        height: '48px',
                                        paddingLeft:'10px',
                                        width:'230px'
                                    }}
                                    >
                                        <SelectInput
                                            customObj={beachgearcategorieslist}
                                            name="category_id"
                                            onChange={(e) => {
                                                console.log(e.target)
                                                setActiveServiceCategories([e.target.value, e.target.value==0 ? 'Services' : beachgearcategorieslist[e.target.value]])
                                                getServiceList(e.target.value, document.getElementById('searchServiceCategories').value);
                                            }}
                                            renderValue={(selected) => {
                                                return beachgearcategorieslist[activeServiceCategories[0]];
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        }
                    </div>
                </div>
                <div className="mobile-bottom-btn">
                    <div className="fill-plus-icon btn-primary mobile-link-btn">
                        <RouterLink
                            className="view-all-btn light-text"
                            to="/service/add"
                        >
                            <img src={images.plusfillicon} alt="" />
                            Add new service
                        </RouterLink>
                    </div>
                </div>
            </Grid>

        </>
    );
}

export default ServiceHeader;
