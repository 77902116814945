import { Box, Typography } from "@mui/material";

const FormTitle = () => {
  return (
    <Box sx={{ borderBottom: "1px solid #ebebeb" }}>
      <Typography sx={{ fontWeight: "500", mb: 1 }} component="h3">
        Fill out tax info
      </Typography>
      <Typography sx={{ mb: 4 }} component="h4">
        Make sure all details are entered accurately before you sign and submit.
      </Typography>
    </Box>
  );
};

export default FormTitle;
