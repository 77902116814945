import { Box, Container, Typography } from "@mui/material";
import CentralCard from "../../Components/Referral/CentralCard";
import BaseModal from "../../Components/Modal/BaseModal";
import images from "../../assets/js/images";
import { useHistory } from "react-router-dom";

const Referral = () => {
  const navigate = useHistory();
  const handleClose = () => navigate.push("/account-settings");
  return (
    <>
      <Container sx={{ marginTop: 3 }}>
        <Typography sx={{ fontWeight: "600" }} component="h1">
          Guest referrals
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CentralCard />
        </Box>
      </Container>
      <BaseModal open="true" onClose={handleClose}>
        <Box sx={{ textAlign: "center" }}>
          <img src={images.logo} />
          <Typography sx={{ pt: 1 }} component="h3">
            Referral programs coming soon!
          </Typography>
        </Box>
      </BaseModal>
    </>
  );
};

export default Referral;
