import { Card, CardContent, Hidden, Link, Typography } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
const PaymentsCard = () => {
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="info-right-card">
        <CardContent sx={{ pt: 4 }}>
          <PaymentsIcon sx={{ fontSize: 40 }} />
          <Typography sx={{ fontWeight: "600", my: 2 }} component="h4">
            Make all payments through Beachify
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            Always pay and communicate through Beachify to ensure you're
            protected under our{" "}
            <Link
              sx={{
                display: "initial !important",
                fontWeight: "500",
                fontSize: 14,
              }}
              className="link-update"
              href="#"
            >
              Terms of Service, Payments Terms of Service
            </Link>
            , cancellation, and other safeguards.{" "}
            <Link
              sx={{
                display: "initial !important",
                fontWeight: "500",
                fontSize: 14,
              }}
              className="link-update"
              href="#"
            >
              Learn more
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Hidden>
  );
};
export default PaymentsCard;
