import TabPanel from "../Common/CustomTabs/TabPanel";
import { Box } from "@mui/material";
import { StyledTab, StyledTabs } from "../Common/CustomTabs/StyledTabs";
import { useContext } from "react";
import { TabsContext } from "../../Context/PaymentsContext";

function tabProps(props) {
  const { index, panelName, tabName } = props;

  return {
    id: `${tabName}-${index}`,
    "aria-controls": `${panelName}-${index}`,
  };
}

const Tabs = () => {
  const tabName = "simple-tab";
  const panelName = "simple-tabpanel";
  const { tabIndex, setTabIndex, tabsContent } = useContext(TabsContext);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={tabIndex} onChange={handleChange}>
          {tabsContent.map((tab, index) => (
            <StyledTab
              key={index}
              label={tab.label}
              {...tabProps(index, panelName, tabName)}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabsContent.map((tab, index) => (
        <TabPanel
          key={index}
          panelName={panelName}
          tabName={tabName}
          value={tabIndex}
          index={index}
        >
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
