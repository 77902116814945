import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Taxpayer or business name is required")
    .typeError("Taxpayer or business name must be a valid value"),
  disregarded_entity_name: yup
    .string()
    .typeError("Disregarded entity name must be a valid value"),
  classification: yup
    .string()
    .required("Tax classification is required")
    .typeError("Tax classification must be a valid value"),
  type_id: yup
    .string()
    .required("Tax ID type is required")
    .typeError("Tax ID type must be a valid value"),
  id_number: yup
    .string()
    .required("ID is required")
    .typeError("ID must be a valid value"),
  address: yup.object().shape({
    country: yup
      .string()
      .required("Country is required")
      .typeError("Country must be a valid value"),
    street: yup
      .string()
      .required("Street is required")
      .typeError("Street must be a valid value"),
    apt_suite: yup.string().typeError("Apt, suite, etc. must be a valid value"),
    city: yup
      .string()
      .required("City is required")
      .typeError("City must be a valid value"),
    state: yup
      .string()
      .required("State is required")
      .typeError("State must be a valid value"),
    zip: yup
      .string()
      .required("ZIP code is required")
      .typeError("ZIP code must be a valid value"),
  }),
  delivery_type: yup
    .string()
    .required("Delivery type is required")
    .typeError("Delivery type must be a valid value"),
  signature: yup
    .string()
    .required("Signature is required")
    .typeError("Signature must be a valid value"),
});

export default ValidationSchema;
