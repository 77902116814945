import { APIService } from "./APIService";

export const PrivacyAndSharingSevice = {
  updateSwitch,
  dataRequestGenerateOtp,
  dataRequestVerifyOtp,
  deleteUser,
};

function updateSwitch(data) {
  return APIService.post("api/account_settings/update_sharing_switches", data);
}
function dataRequestGenerateOtp(data) {
  return APIService.post(
    "api/account_settings/data_request/generate_otp",
    data
  );
}
function dataRequestVerifyOtp(data) {
  return APIService.post("api/account_settings/data_request/verify_otp", data);
}
function deleteUser(data) {
  return APIService.post("api/account_settings/delete_user", data);
}
