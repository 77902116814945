import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  cheking_savings: yup.string().required("Please select an option"),
  holder_name: yup
    .string()
    .required("Holder name is required")
    .typeError("Holder name must be a valid value"),
  routing_number: yup
    .string()
    .min(9, "Routing number must be exactly 9 digits")
    .max(9, "Routing number must be exactly 9 digits")
    .required("Routing number is required")
    .typeError("Routing number must be a valid value"),
  account_number: yup
    .string()
    .required("Account number is required")
    .typeError("Account number must be a valid value"),
  confirm_account_number: yup
    .string()
    .required("Account number confirmation is required")
    .oneOf([yup.ref("account_number"), null], "Account numbers must match"),
});

export default ValidationSchema;
