import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  zip: yup
    .string()
    .required("ZIP code is required")
    .typeError("ZIP code must be a valid value"),
  cardholder_details: yup.object().shape({
    first_name: yup
      .string()
      .required("First Name is required")
      .typeError("First Name must be a valid value"),
    last_name: yup
      .string()
      .required("Last name is required")
      .typeError("Last name must be a valid value"),
    date_of_birth: yup
      .string()
      .required("Date of birth is required")
      .typeError("Date of birth must be a valid value"),
    country: yup
      .string()
      .required("Country is required")
      .typeError("Country must be a valid value"),
  }),
  card_type: yup
    .string()
    .required("Card type is required")
    .typeError("Card type must be a valid value"),
  accept_terms: yup.bool().oneOf([true], "You need to accept the fee terms"),
});

export default ValidationSchema;
