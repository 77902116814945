import { Container, Link, Typography } from "@mui/material";
import ReviewsTabs from "./ReviewsTabs";
import { ReviewsContext } from "../../Context/ReviewsContext";
import { ReviewsService } from "../../Services/ReviewsService";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const HostReviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  async function getReviews() {
    await ReviewsService.hostReviews()
      .then((response) => setReviews(response.data.data))
      .catch((error) => MakeToast("error", error));
    setLoading(false);
  }

  useEffect(() => {
    getReviews();
  }, []);
  const navigate = useHistory();

  return (
    <ReviewsContext.Provider
      value={{ loading: loading, reviews: reviews, setReviews: setReviews }}
    >
      <Container sx={{ py: 5 }}>
        <BreadCrumb data={[["Profile", "goback"], ["Reviews"]]} />
        <Typography
          sx={{ fontWeight: "500", marginTop: "20px !important" }}
          component="h1"
        >
          Host Reviews
        </Typography>
        <ReviewsTabs />
      </Container>
    </ReviewsContext.Provider>
  );
};

export default HostReviews;
