import { useContext } from "react";
import { FormContext } from "../../../../../Context/TaxContext";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
const DisregardedEntityName = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Disregarded entity name (optional)
      </Typography>
      <TextField
        placeholder="Disregarded entity name (optional)"
        name="disregarded_entity_name"
        value={formik.values.disregarded_entity_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.disregarded_entity_name &&
        formik.touched.disregarded_entity_name && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.disregarded_entity_name}
          </FormHelperText>
        )}
      <Typography sx={{ color: "#9b9a9a" }}>
        You only need to fill this out if you have a DBA name, trade name or
        disregarded entity name.
      </Typography>
    </Box>
  );
};

export default DisregardedEntityName;
