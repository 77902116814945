import React from 'react'
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import FavoriteButton from '../../BeachGear/FavoriteButton'
import images from '../../../assets/js/images'
import ShareModal from "../../../Components/Modal/ShareModal";

function HostProfileCard({ hostData, navigate, handleOpenSupport, hostLoader, user }) {
    const [openShare, setOpenShare] = React.useState(false);

    return (
        <>
        <Box className='service-listing-bg bg-light-fourth border-radius-30'>
            <Box className='horizontal-service-list-card'>
                <Box className='service-logo'>
                    <img src={hostData?.profile_image ? hostData?.profile_image : images.smallogo} alt="" />
                </Box>
                <Box class="horizontal-service-listing">
                    <div className="main-heading-block">
                        {
                            hostLoader
                                ?
                                <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5, marginBottom: 15 }} />
                                :
                                <>
                                    <h2 className="main-heading" sx={{ marginBottom: '0 !important', marginTop: '0 !important' }}>
                                        {hostData.company_name}
                                    </h2>
                                    <Box style={{ margin: "0" }}>
                                        {user.role_id == "3" ? (
                                            <FavoriteButton
                                                elementData={hostData}
                                                isFavorite={hostData.is_favorite}
                                                isHost={true}
                                            />
                                        ) : <></>}
                                        <img src={images.UploadIcon2} alt="upload2" className='upload-right-space' onClick={() => { setOpenShare(true); }}/>
                                    </Box>
                                </>
                        }
                    </div>
                    <List sx={{ p: 0, display: 'flex', flexWrap: 'wrap', width: '100%' }} className="feature-listing listing-visible">
                        <ListItem>
                            <ListItemIcon className="feature-listing-icon">
                                <img src={images.locationGreyIcon} alt="location" className='img-color' />
                            </ListItemIcon>
                            {
                                hostLoader
                                    ?
                                    <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5 }} />
                                    :
                                    <ListItemText className="feature-list-name black-text" primary={hostData.billing_address} />
                            }
                        </ListItem>

                        <ListItem >
                            <ListItemIcon className="feature-listing-icon">
                                <img src={images.distanceIcon} alt="distance" className="img-color" />
                            </ListItemIcon>
                            {
                                hostLoader
                                    ?
                                    <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5 }} />
                                    :
                                    <ListItemText className="feature-list-name black-text" primary={hostData.distance + "m from you"} />
                            }
                        </ListItem>

                        {
                            hostData.instant_booking_available
                                ?
                                <ListItem >
                                    <ListItemIcon className="feature-listing-icon">
                                        <img src={images.roundedbookingIcon} alt="booking" className="img-color" />
                                    </ListItemIcon>
                                    {
                                        hostLoader
                                            ?
                                            <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5 }} />
                                            :
                                            <ListItemText className="feature-list-name black-text" primary="Instant booking" />
                                    }
                                </ListItem>
                                :
                                <></>
                        }

                        {
                            hostData.delivery_pickup_service
                                ?
                                <ListItem >
                                    <ListItemIcon className="feature-listing-icon">
                                        <img src={images.deliveryIcon} alt="delivery" className='img-color' />
                                    </ListItemIcon>
                                    {
                                        hostLoader
                                            ?
                                            <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5 }} />
                                            :
                                            <ListItemText className="feature-list-name black-text" primary="Delivery & Pickup" />
                                    }
                                </ListItem>
                                :
                                <></>
                        }
                    </List>
                    {
                        hostLoader
                        ?
                        <Skeleton variant="rounded" width={150} height={30} style={{ marginTop: "10px", borderRadius : "15px" }} />
                        :
                        <div className="main-heading-block" style={{ marginTop: "10px" }}>
                            <Button
                                type="button"
                                fullHeight
                                disableElevation
                                variant="contained"
                                className="border-radius-16 btn-primary text-case contact-us-btn"
                                onClick={() => navigate.push("/profile/" + hostData.user_id)}
                            >
                                View Host Profile
                            </Button>
                        </div>
                    }
                </Box>
            </Box>
            <Box className='card-btn-2'>
                {
                hostLoader
                ?
                <Skeleton variant="rounded" width={150} height={30} style={{ marginTop: "10px", borderRadius : "15px" }} />
                :
                <Button
                    type="submit"
                    fullHeight
                    disableElevation
                    variant="contained"
                    className="border-radius-16 btn-primary text-case contact-us-btn"
                    onClick={handleOpenSupport}
                >
                    Contact
                </Button>
                }
            </Box>
        </Box>
        {
            openShare
            ?
            <ShareModal openShare={openShare} setOpenShare={setOpenShare} title={hostData.company_name} image={hostData?.profile_image ? hostData?.profile_image : images.smallogo} url={window.location.href}/>
            :
            <></>
        }
        </>
    )
}

export default HostProfileCard