import { useContext } from "react";
import { Box } from "@mui/material";
import TabOption from "../TabOption";
import TabLoading from "../TabLoading";
//Data from host options
import HostsTravelTips from "../OffersAndUpdates/Hosts/TravelTips";
import HostsUpdates from "../OffersAndUpdates/Hosts/Updates";
import HostsHostingInsights from "../OffersAndUpdates/Hosts/HostingInsights";
import HostsHostingUpdates from "../OffersAndUpdates/Hosts/HostingUpdates";
//Data from renters options
import RenterTravelTips from "../OffersAndUpdates/Renters/TravelTips";
import RenterUpdates from "../OffersAndUpdates/Renters/Updates";
import { NotificationSettingsContext } from "../../../Context/NotificationSettingsContext";
import { useSelector } from "react-redux";

const OffersAndUpdates = () => {
  const { user } = useSelector((state) => state.userReducers.data);
  const { data, loading } = useContext(NotificationSettingsContext);
  let TravelTipsData = [];
  let UpdatesData = [];
  let HostingInsights = [];
  let HostingUpdates = [];
  if (user.role_id == 2) {
    TravelTipsData = HostsTravelTips(data);
    UpdatesData = HostsUpdates(data);
    HostingInsights = HostsHostingInsights(data);
    HostingUpdates = HostsHostingUpdates(data);
  } else {
    TravelTipsData = RenterTravelTips(data);
    UpdatesData = RenterUpdates(data);
  }
  return (
    <>
      {loading ? (
        <TabLoading />
      ) : user.role_id == 2 ? (
        <>
          <Box className="gray-border-bottom">
            <TabOption {...HostingInsights} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...HostingUpdates} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...TravelTipsData} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...UpdatesData} />
          </Box>
        </>
      ) : (
        <>
          <Box className="gray-border-bottom">
            <TabOption {...TravelTipsData} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...UpdatesData} />
          </Box>
        </>
      )}
    </>
  );
};

export default OffersAndUpdates;
