import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FullLayout from "./Pages/Layout/FullLayout";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import configureStore from './Redux/store';
import { Provider as ReduxProvider } from 'react-redux'

import AppRouter from './Router/Router';
import GeoHelper from "./Helpers/GeoHelper";
import SiteLoader from "./Components/Common/SiteLoader";

const store = configureStore();

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    window.addEventListener("load", setLoaderHandle);
    setLoaderHandle();
    return () => {
      window.removeEventListener("load", setLoaderHandle);
    };
  }, []);

  const setLoaderHandle = () => {
    const time = setTimeout(() => setIsLoading(false), 1300);
  }


  GeoHelper.setGeolocationCoordinates();
  const theme = createTheme({
    typography: {
      fontFamily: `'Jost', sans-serif`,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    components: {

      // // Name of the component
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            '&.MuiContainer-maxWidthLg': {
              // maxWidth: 1280,
            },
          },
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: `'Jost', sans-serif`,
          },
        },
      },

    },
  });

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        {isLoading ? (<SiteLoader />) : (
          <FullLayout>
            <AppRouter />
          </FullLayout>
        )}
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
