import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  Link,
  TextareaAutosize,
  TextField,
  Typography,
  Switch,
  // Grid,
} from "@mui/material";
import { useContext } from "react";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useFormik } from "formik";
import { useState } from "react";
import LanguagesModal from "./LanguagesModal";
import LanguageList from "./LanguageList";
import { ProfileService } from "../../../Services/ProfileService";
import MakeToast from "../../../Helpers/MakeToast";
import ValidationSchema from "./Form/ValidationSchema";
import InitialValues from "./Form/InitialValues";
import GoogleAutoCompleteInput from "../../../Components/Inputs/GoogleAutoCompleteInput";
import { useEffect } from "react";
import images from "../../../assets/js/images";
import OperationHours from "./Form/OperationHours";
import ModalPopup from "../../Modal/ModalPopup";
import { useDispatch } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';

const EditProfileForm = ({ setShowEditProfile }) => {
  const [googleAutocompleteData, setGoogleAutocompleteData] = useState({});
  useEffect(() => {
    if (googleAutocompleteData.address && googleAutocompleteData.address != "")
      formik.setFieldValue(
        "rental_location_address",
        googleAutocompleteData.address
      );
    if (googleAutocompleteData.lat && googleAutocompleteData.lat != "")
      formik.setFieldValue("lat", googleAutocompleteData.lat);
    if (googleAutocompleteData.long && googleAutocompleteData.long != "")
      formik.setFieldValue("long", googleAutocompleteData.long);
  }, [googleAutocompleteData]);

  const { profile, languages, getProfile } = useContext(ProfileContext);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const submitChanges = (values) => {
    let checkedLanguages = languages.userLanguages.filter(
      (language) => language.checked
    );
    let languagesId = checkedLanguages.map((a) => a.code);

    ProfileService.hostStore({
      ...values,
      languages: languagesId,
    })
      .then((response) => {
          delete formik.values.open_close_checkbox;
          dispatch({
            type: "LOGIN_START",
            payload: response?.data,
          });
          setShowEditProfile(false);
          MakeToast("success", response);
          getProfile();
          formik.setSubmitting(false);
      })
      .catch((error) => {
        MakeToast("error", error);
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues(profile),
    enableReinitialize: true,
    onSubmit: submitChanges,
  });

  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <>
      <Box sx={{ marginTop: 5 }}>
        <Typography component="h4" sx={{ marginBottom: 1 }}>
          Company name
        </Typography>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
          style={{
            border: `1px solid #${
              formik.touched.company_name && Boolean(formik.errors.company_name)
                ? "d83e3f"
                : "EEEEEE"
            }`,
          }}
        >
          <TextField
            id="company_name"
            value={values.company_name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input"
            sx={{
              paddingLeft: "30px",
              ".MuiInputBase-root": {
                "&:before": {
                  borderBottom: "0px solid",
                },
                "&:after": {
                  borderBottom: "0px solid",
                },
                "&:hover": {
                  "&:before": {
                    borderBottom: "0px !important",
                  },
                },
              },
              "@media (max-width:577px)": {
                paddingLeft: "15px",
              },
            }}
            variant="standard"
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.company_name && touched.company_name}
        </FormHelperText>
        <Typography component="h4" sx={{ marginBottom: 1, marginTop: 3 }}>
          Company overview/biography
        </Typography>
        <TextareaAutosize
          id="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          className="input-style small-radius"
          style={{
            width: "100%",
            height: "110px",
            border: `1px solid #${
              formik.touched.description && Boolean(formik.errors.description)
                ? "d83e3f"
                : "EEEEEE"
            }`,
          }}
        />
        <FormHelperText className="field-helper-text error">
          {errors.description && touched.description}
        </FormHelperText>

        <Typography component="h4" sx={{ marginBottom: 1, marginTop: 3 }}>
          General Location
        </Typography>
        <Box>
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
            style={{
              border: `1px solid #${
                formik.touched.rental_location_address &&
                Boolean(formik.errors.rental_location_address)
                  ? "d83e3f"
                  : "EEEEEE"
              }`,
            }}
          >
            <GoogleAutoCompleteInput
              placeholder="Location"
              name="rental_location_address"
              value={formik.values.rental_location_address}
              onChange={formik.handleChange}
              setGoogleAutocompleteData={setGoogleAutocompleteData}
              className="outline-input"
              sx={{
                paddingLeft: "30px",
                ".MuiInputBase-root": {
                  "&:before": {
                    borderBottom: "0px solid",
                  },
                  "&:after": {
                    borderBottom: "0px solid",
                  },
                  "&:hover": {
                    "&:before": {
                      borderBottom: "0px !important",
                    },
                  },
                },
                "@media (max-width:577px)": {
                  paddingLeft: "15px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className="input-image"
                    sx={{
                      paddingRight: "5px",
                      "@media (max-width:577px)": {
                        paddingRight: "10px",
                      },
                    }}
                  >
                    <img src={images.locationIcon} alt="location" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      // paddingRight: '30px',
                      "@media (max-width:1023.98px)": {
                        paddingRight: "0px",
                      },
                    }}
                  >
                    <Link
                      href="#"
                      underline="none"
                      sx={{
                        fontSize: "14px",
                        paddingRight: "30px",
                        color: "#B1B1B1",
                        "&:hover": {
                          color: "#B1B1B1",
                        },
                        "@media (max-width:577px)": {
                          paddingRight: "15px",
                        },
                        "@media (max-width:767.98px)": {
                          paddingRight: "10px",
                        },
                        "@media (max-width:1023.98px)": {
                          paddingRight: "10px",
                        },
                      }}
                    >
                      Open map
                    </Link>
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
          {formik.touched.rental_location_address &&
            Boolean(formik.errors.rental_location_address) && (
              <p
                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                style={{
                  paddingLeft: 15,
                  color: "red",
                }}
                id="input-with-icon-textfield-helper-text"
              >
                {formik.errors.rental_location_address}
              </p>
            )}
        </Box>
        <Typography component="h4" sx={{ marginBottom: 1, marginTop: 5 }}>
          Languages Spoken
        </Typography>
        <LanguageList />
        <Link onClick={() => setOpenLanguage(true)} className="link-update">
          Add more
        </Link>
        <Typography component="h4" sx={{ marginBottom: 2, marginTop: 5 }}>
          Operation Hours
        </Typography>
        <OperationHours formik={formik} />
        <Grid container>
          <Grid xs={12} item className="operation-padding">
           <Box className="edit-profile-btn">
            <Link
              onClick={() => setShowEditProfile(false)}
              className="link-update"
            >
              Cancel
            </Link>
            <Button
              sx={{ my: 1.5 }}
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              disabled={formik.isSubmitting}
              onClick={(e) => formik.handleSubmit()}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Save"
              )}
            </Button>
            </Box>
          </Grid>
          {/* <Grid sx={{ textAlign: "end" }} xs={6} item>
            <Button
              sx={{ my: 1.5 }}
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              disabled={formik.isSubmitting}
              onClick={(e) => formik.handleSubmit()}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Save"
              )}
            </Button>
          </Grid> */}
        </Grid>
      </Box>
      <LanguagesModal
        open={openLanguage}
        onClose={() => setOpenLanguage(false)}
      />
      {openPopup && (
        <ModalPopup
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          content={
            <>
              <Box className="modalheader" sx={{ marginBottom: 0 }}>
                <Box className="modal-close-btn">
                  <Button
                    size="small"
                    onClick={() => {
                      setOpenPopup(false);
                    }}
                  >
                    {" "}
                    <img src={images.closeIcon} alt="" />
                  </Button>
                </Box>
              </Box>
              <Box className="modal-body message-modal">
                <Typography component="h4">
                  Your specified operation hours conflict with currently
                  scheduled reservations. Be sure to reach out to your customers
                  to reschedule or cancel reservations that conflict with these
                  times of return to complete this action
                </Typography>
              </Box>
            </>
          }
        />
      )}
    </>
  );
};

export default EditProfileForm;
