import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import FormField from "../../BusinessInfo/FormField";
import { useState } from "react";
import { ProfileContext } from "../../../Context/ProfileContext";
import { PersonalInformationService } from "../../../Services/PersonalInformationService";
import Otp from "./Otp";
import { useFormik } from "formik";
import MakeToast from "../../../Helpers/MakeToast";
const Phone = ({ initialValue }) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let informationValidationSchema = yup.object().shape({
    mobile_no: yup
      .string()
      .required("Mobile number is required")
      .typeError("Mobile number must be a number")
      .matches(phoneRegExp, "Mobile number is not valid"),
  });
  const [openOtp, setOtpOpen] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { mobile_no: initialValue ?? "" },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleBlur = formik.handleBlur;
  const handleChange = formik.handleChange;
  const formSubmit = (values) => {
    PersonalInformationService.generateOtp({
      value: values.mobile_no,
      type: "mobile_no",
    })
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
        MakeToast("success", response);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  return (
    <ProfileContext.Provider value={formik}>
      {openOtp && (
        <Otp
          openOtp={openOtp}
          handleClose={() => setOtpOpen(false)}
          handleCloseEdit={() => setEditEnable(false)}
          userFieldData={values.mobile_no}
          type="mobile_no"
        />
      )}
      <FormField
        label="Phone Number"
        description="Please enter your phone number below."
        value={values.mobile_no}
        text={values.mobile_no}
        editEnable={editEnable}
        setEditEnable={setEditEnable}
        sx={{}}
      >
        <FormControl
          sx={{ width: "100%", mt: 2 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="+01 99999999999"
            name="mobile_no"
            value={values.mobile_no}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.mobile_no && touched.mobile_no && errors.mobile_no && (
          <FormHelperText className="field-helper-text error">
            {errors.mobile_no && touched.mobile_no && errors.mobile_no}
          </FormHelperText>
        )}

        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          disabled={formik.isSubmitting}
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Save"
          )}
        </Button>
      </FormField>
    </ProfileContext.Provider>
  );
};

export default Phone;
