import { APIService } from "./APIService";

export const NotificationSettingsService = {
  getNotificationsSettings,
  updateNotificationsSettings,
  updateMarketingEmails,
  updateMarketingSms,
};

function getNotificationsSettings() {
  return APIService.get("api/account_settings/notifications_settings");
}

function updateNotificationsSettings(data) {
  return APIService.post(
    "api/account_settings/notifications_settings/update",
    data
  );
}
function updateMarketingEmails(data) {
  return APIService.post(
    "api/account_settings/modify_email_notifications",
    data
  );
}
function updateMarketingSms(data) {
  return APIService.post("api/account_settings/modify_sms_notifications", data);
}
