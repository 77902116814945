import { Box, Typography } from "@mui/material";
import PendingCard from "../PendingCard";

const Pending = () => {
  return (
    <Box>
      <Typography sx={{ fontWeight: 600, mb: 1 }} component="h3">
        Home referrals
      </Typography>
      <PendingCard />
    </Box>
  );
};

export default Pending;
