import {
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  TextareaAutosize,
  TextField,
  Typography,
  // Grid,
  Box,
} from "@mui/material";
import { useContext } from "react";
import { ProfileContext } from "../../../Context/ProfileContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import LanguagesModal from "./LanguagesModal";
import LanguageList from "./LanguageList";
import { ProfileService } from "../../../Services/ProfileService";
import MakeToast from "../../../Helpers/MakeToast";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';

const EditProfileForm = ({ setShowEditProfile }) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) =>
      formik.setFieldValue("location", place.formatted_address),
  });
  const { profile, languages, getProfile } = useContext(ProfileContext);
  const [openLanguage, setOpenLanguage] = useState(false);
  let dataValidationSchema = yup.object().shape({
    about: yup.string().typeError("About description must be a text"),
    location: yup.string().typeError("Location must be a text"),
  });
  const dispatch = useDispatch();

  const submitChanges = (values) => {
    let checkedLanguages = languages.userLanguages.filter(
      (language) => language.checked
    );
    let languagesId = checkedLanguages.map((a) => a.code);
    ProfileService.store({
      ...values,
      languages: languagesId,
    })
      .then((response) => {
        dispatch({
          type: "LOGIN_START",
          payload: response?.data?.data,
        });
        setShowEditProfile(false);
        MakeToast("success", response);
        getProfile();
        formik.setSubmitting(false);
      })
      .catch((error) => {
        MakeToast("error", error);
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    validationSchema: dataValidationSchema,
    initialValues: {
      about: profile?.about ?? "",
      location: profile?.location ?? "",
    },
    enableReinitialize: true,
    onSubmit: submitChanges,
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <>
      <Grid sx={{ marginTop: 5 }} className="operation-padding">
        <Typography component="h4" sx={{ marginBottom: 1 }}>
          About
        </Typography>
        <TextareaAutosize
          id="about"
          value={values.about}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{
            width: "100%",
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: "18px",
            padding: "18px",
            height: "110px",
          }}
        />
        <FormHelperText className="field-helper-text error">
          {errors.about && touched.about}
        </FormHelperText>

        <Typography component="h4" sx={{ marginBottom: 1, marginTop: 5 }}>
          Location
        </Typography>
        <TextField
          id="location"
          inputRef={ref}
          value={values.location}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{ width: "100%" }}
          className="outline-input textfield-rouded-md  input-text-style location-padding"
        />
        <FormHelperText className="field-helper-text error">
          {errors.location && touched.location}
        </FormHelperText>
        <Typography component="h4" sx={{ marginBottom: 1, marginTop: 5 }}>
          Languages Spoken
        </Typography>
        <LanguageList />
        <Link onClick={() => setOpenLanguage(true)} className="link-update">
          Add more
        </Link>
        <Grid sx={{ marginTop: 5 }} container className="operation-padding">
          <Grid xs={12} sx={{pt:0,pb:0 }}>
            <Box className="edit-profile-btn">
            <Link
              onClick={() => setShowEditProfile(false)}
              className="link-update"
            >
              Cancel
            </Link>
            <Button
              sx={{ my: 1.5 }}
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              disabled={formik.isSubmitting}
              onClick={(e) => formik.handleSubmit()}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Save"
              )}
            </Button>
            </Box>
          </Grid>
         
          <Grid sx={{ textAlign: "end" }} xs={6} >
            {/* <Button
              sx={{ my: 1.5 }}
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              disabled={formik.isSubmitting}
              onClick={(e) => formik.handleSubmit()}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Save"
              )}
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      <LanguagesModal
        open={openLanguage}
        onClose={() => setOpenLanguage(false)}
      />
    </>
  );
};

export default EditProfileForm;
