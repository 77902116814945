import { Box } from "@mui/material";
import TaxPayersContent from "../TaxPayers/TaxPayers";
import Help from "../Help/Help";

const TaxPayers = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <TaxPayersContent />
      <Help />
    </Box>
  );
};

export default TaxPayers;
