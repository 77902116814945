import EditOption from "./EditOption";
import TitleDescription from "./TitleDescription";

const TabOption = (props) => {
  const { titleData, optionsData } = props;
  return (
    <>
      <TitleDescription {...titleData} />
      {optionsData.map((optionData, index) => (
        <EditOption key={index} {...optionData} />
      ))}
    </>
  );
};

export default TabOption;
