import { Link, Typography } from "@mui/material";
import BulletList from "../Common/BulletList";
const listContent = [
  <Typography component="p" className="inner-points">
    If you have a checkout (as a guest or a host) within the past 60 days, your
    deletion request can’t be processed until the 60-day claim period has
    elapsed.
  </Typography>,
  <Typography component="p" className="inner-points">
    Once your request is processed, your personal data will be deleted (except
    for certain information that we are legally required or permitted to retain,
    as outlined in our{" "}
    <Link
      className="black-underline-link"
      target="_blank"
      href="/privacy-policy"
    >
      Privacy Policy
    </Link>
    ).
  </Typography>,
  <Typography component="p" className="inner-points">
    If you want to use Beachify in the future, you’ll need to set up a new
    account.
  </Typography>,
  <Typography component="p" className="inner-points">
    If you have any future reservations as a host or guest, they must first be
    cancelled in accordance with the applicable host cancellation policy before
    submitting your deletion request. Cancellation fees may apply. More
    information about cancellations can be found on our{" "}
    <Link className="black-underline-link" href="#">
      Help Center
    </Link>
  </Typography>,
];

const DeleteList = () => {
  return <BulletList listContent={listContent} />;
};

export default DeleteList;
