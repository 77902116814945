import AuthHelper from "../../Helpers/AuthHelper";

let defaultState = {
  data: {
    isLoggedIn: AuthHelper.getToken() ? true : false,
    user: AuthHelper.getUserData() ? AuthHelper.getUserData() : [],
    token: AuthHelper.getToken(),
  },
};

let userReducers = (state = defaultState, action) => {
  switch (action.type) {
    case "LOGIN_START": {
      let newState = { ...state };
      AuthHelper.setToken(action.payload.data.access_token);
      newState.data = {
        isLoggedIn: true,
        user: AuthHelper.getUserData(),
        token: action.payload.data.token,
      };
      return newState;
    }
    case "LOGOUT": {
      let newState = { ...state };
      newState.data = {
        user: [],
        isLoggedIn: false,
      };
      AuthHelper.removeAuthData();
      return newState;
    }
    case "UPDATE_USER_DATA": {
      let newState = { ...state };
      newState.data.user = {
        ...newState.data.user,
        ...action.payload.data,
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default userReducers;
