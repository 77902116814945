import React from "react";

import $ from "jquery";
import moment from "moment";
import "../../Helpers/moment-custom-locale";
import "../../Helpers/daterangepickers";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const SingleDatePicker = ({ selector, date, setDate }) => {
    let parentSelector = selector.replace('#', '');
    parentSelector = parentSelector.replace('.', '');

    React.useEffect(() => {
        $(function () {
            var today = new Date();
            var maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
            var formattedMaxDate = maxDate.getDate() + '/' + (maxDate.getMonth() + 1) + '/' + maxDate.getFullYear();
            var options = {
                parentSelector: parentSelector,
                autoUpdateInput: true,
                autoApply: true,
                singleDatePicker: true,
                showCustomRangeLabel: false,
                maxDate: formattedMaxDate,
                ranges: false,
                showDropdowns: true,
                drops: "up",
                startDate: formattedMaxDate,
                linkedCalendars: false,
                alwaysShowCalendars: true
            };

            if (date != '' && date != null) Object.assign(options, { startDate: moment(new Date(date.replace(/-/g, "/"))) })

            $(selector).daterangepicker(options, function (start, end, label) {
                setDate(start.format("MM/DD/YYYY"))
            });
        });
    }, [date])
}

//export default React.memo(RangePicker);
export default SingleDatePicker;
