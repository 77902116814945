import React from "react";
import { Box, Button } from '@mui/material';
import images from "../../assets/js/images";
import ModalPopup from './ModalPopup';

const buttonStyle = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  color: '#fff',
  padding: '20px 0',
  border: '1px solid #EEEEEE',
  borderRadius: '50px',
  backgroundColor: '#FF4110',
  minWidth: '190px',
  '&:hover': {
    backgroundColor: '#FF4110'
  }
}

const SignupListModal = ({ isOpen, handleClose, handleOpenRegister, handleOpenHostRegister }) => {
  return (
    <>
      <ModalPopup
        className="modallogin"
        open={isOpen}
        onClose={handleClose}
        content={<>
          <Box className="modalheader" sx={{ 
            marginBottom:'20px'
            }}>
            <Box id="modal-modal-title" className="modal-heading" component="h2">
              Sign Up
            </Box>
            <Box className="modal-close-btn" onClick={handleClose}>
              <Button size="small" disableRipple > <img src={images.closeIcon} alt="" /></Button>
            </Box>
          </Box>
          <Box className="modal-body" sx={{ p:0 }}>
              <Box className="social-media-btn">
                <Button size="small" onClick={handleOpenRegister} sx={buttonStyle}>
                  Sign Up as a Customer
                </Button>
                <Button size="small" onClick={handleOpenHostRegister} sx={buttonStyle}>
                  Sign Up as a Host
                </Button>
              </Box>
          </Box>
        </>}
      />
    </>
  );
}

export default SignupListModal;
