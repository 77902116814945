import { APIService } from "./APIService";

export const LoginAndSecurityService = {
  emailVerifyOtp,
  emailGenerateOtp,
  phoneVerifyOtp,
  phoneGenerateOtp,
  deviceHistory,
  disconnectSocialAccount,
  connectSocialAccount,
  removeDeviceHistory,
  deactivateUser,
};

function emailGenerateOtp(data) {
  return APIService.post(
    "api/account_settings/update_email/generate_otp",
    data
  );
}

function emailVerifyOtp(data) {
  return APIService.post("api/account_settings/update_email/verify_otp", data);
}

function phoneGenerateOtp(data) {
  return APIService.post(
    "api/account_settings/update_phone/generate_otp",
    data
  );
}

function phoneVerifyOtp(data) {
  return APIService.post("api/account_settings/update_phone/verify_otp", data);
}

function deviceHistory() {
  return APIService.get("api/account_settings/device_history");
}

function removeDeviceHistory(data) {
  return APIService.post("api/account_settings/device_history/remove", data);
}

function connectSocialAccount(data) {
  return APIService.post("api/account_settings/social_accounts/connect", data);
}
function disconnectSocialAccount() {
  return APIService.post("api/account_settings/social_accounts/disconnect");
}

function deactivateUser() {
  return APIService.post("api/account_settings/account/deactivate");
}
