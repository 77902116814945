import { APIService } from "./APIService";

export const TipService = {
    hosts,
    renters
};

function hosts(data) {
    return APIService.post('api/tip/hosts', data);
}

function renters(data) {
    return APIService.post('api/tip/renters', data);
}