import { styled, Tab, Tabs } from "@mui/material";

const StyledTabs = styled((props) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "black",
  },
}));

const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  textTransform: "none",
  color: "rgba(0, 0, 0, 0.7) !important",
  "&.Mui-selected": {
    color: "black !important",
  },
}));

export { StyledTabs, StyledTab };
