import { useContext } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { FormContext, TaxFormContext } from "../../../../../Context/TaxContext";
import SelectInput from "../../../../Inputs/SelectInput";

const PermanentResidenceAddress = () => {
  const { formik } = useContext(FormContext);
  const { countries } = useContext(TaxFormContext);
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Permanent residence address
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        {countries?.length > 0 ? (
          <SelectInput
            customArr={{ data: countries, name: "name", id: "code" }}
            name="permanent_residence_address.country"
            value={formik.values.permanent_residence_address.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (formik.values.permanent_residence_address.country ==
                  undefined ||
                  formik.values.permanent_residence_address.country == "")
              ) {
                return <em>Country</em>;
              }

              let countryData = countries.find(
                (o) =>
                  o.code == formik.values.permanent_residence_address.country
              );
              return countryData ? countryData.name : "";
            }}
          />
        ) : (
          <SelectInput
            className="opacity-40"
            renderValue={(selected) => {
              return <em>Country</em>;
            }}
          />
        )}
      </FormControl>
      {formik.errors.permanent_residence_address?.country &&
        formik.touched.permanent_residence_address?.country && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.country}
          </FormHelperText>
        )}
      <TextField
        placeholder="Address line 1"
        name="permanent_residence_address.line_1"
        value={formik.values.permanent_residence_address.line_1}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "18px 18px 0px 0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="Address line 2 (if applicable)"
        name="permanent_residence_address.line_2"
        value={formik.values.permanent_residence_address.line_2}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="Address line 3 (if applicable)"
        name="permanent_residence_address.line_3"
        value={formik.values.permanent_residence_address.line_3}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="Address line 4 (if applicable)"
        name="permanent_residence_address.line_4"
        value={formik.values.permanent_residence_address.line_4}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="City"
        name="permanent_residence_address.city"
        value={formik.values.permanent_residence_address.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="State/territory"
        name="permanent_residence_address.state"
        value={formik.values.permanent_residence_address.state}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="ZIP code"
        name="permanent_residence_address.zip"
        value={formik.values.permanent_residence_address.zip}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input  input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px 0px 18px 18px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.permanent_residence_address?.line_1 &&
        formik.touched.permanent_residence_address?.line_1 && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.line_1}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.line_2 &&
        formik.touched.permanent_residence_address?.line_2 && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.line_2}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.line_3 &&
        formik.touched.permanent_residence_address?.line_3 && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.line_3}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.line_4 &&
        formik.touched.permanent_residence_address?.line_4 && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.line_4}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.city &&
        formik.touched.permanent_residence_address?.city && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.city}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.state &&
        formik.touched.permanent_residence_address?.state && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.state}
          </FormHelperText>
        )}
      {formik.errors.permanent_residence_address?.zip &&
        formik.touched.permanent_residence_address?.zip && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.permanent_residence_address?.zip}
          </FormHelperText>
        )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter an address for the taxpayer whose name you added above. Do not
        enter a P.O. box or in-care-of address.
      </Typography>
    </Box>
  );
};

export default PermanentResidenceAddress;
