import { Box, IconButton, Modal, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};
const closeButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 2,
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
  },
}));

const BaseModal = (props) => {
  const { onClose, children } = props;
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton onClick={onClose} sx={closeButtonStyle}>
          <CloseIcon sx={{ fontSize: 25, mb:1 }} />
        </IconButton>
        {children}
      </ResponsiveStyles>
    </Modal>
  );
};

export default BaseModal;
