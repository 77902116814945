import { Grid, Typography } from "@mui/material";

const ReferralListItem = ({ items }) => {
  return items.map((item, index) => (
    <Grid key={index} sx={{ marginBottom: 2 }} container>
      <Grid xs={6} item>
        <Typography sx={{ fontWeight: "light" }} component="h4">
          {item.label}
        </Typography>
      </Grid>
      <Grid xs={6} sx={{ textAlign: "right" }} item>
        <Typography sx={{ fontWeight: "600" }} component="h4">
          {item.quantity}
        </Typography>
      </Grid>
    </Grid>
  ));
};

export default ReferralListItem;
