import {
  Link as MaterialLink,
  Skeleton,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";

// import Grid from '@mui/material/Unstable_Grid2';
import StarIcon from "@mui/icons-material/Star";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import HomeIcon from "@mui/icons-material/Home";
import EditProfileForm from "../../../Components/Profile/Renter/EditProfileForm";
import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppHelper from '../../../Helpers/AppHelper';
import AuthHelper from "../../../Helpers/AuthHelper";
import { ProfileService } from "../../../Services/ProfileService";
import { LoginAndSecurityService } from "../../../Services/LoginAndSecurityService";
import MakeToast from "../../../Helpers/MakeToast";

const ProfileDataSection = (props) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducers.data);

  const [showEditProfile, setShowEditProfile] = useState(false);
  const { profile, languages, loading, setLanguages, isEditable } =
    useContext(ProfileContext);
  const [spokenLanguages, setSpokenLanguages] = useState([]);

  useEffect(() => {
    languagesApi();
  }, [showEditProfile, profile]);

  useEffect(() => {
    languagesApi();
  }, []);

  const languagesApi = () => {
    setLanguages({
      userLanguages: [...languages.availableLanguages],
      availableLanguages: [...languages.availableLanguages],
    });
    setSpokenLanguages(
      languages.availableLanguages
        .filter((language) => language.checked)
        .map((l) => l.name)
    );
  };

  const deleteAccount = () => {
    AppHelper.SAToast(
      'Are you sure you want to delete your account? This will erase all of your profile settings and data.',
      'notext',
      { title: 'delete-title-swal'}
    ).then((result) => {
        if (result.isConfirmed) {
          ProfileService.accountDelete().then(resp => {
            try {
              LoginAndSecurityService.removeDeviceHistory(AuthHelper.getToken());
            } catch (err) { }
        
            dispatch({
              type: "LOGOUT",
              payload: [],
            });

            navigate.push('/');
            MakeToast("success", "Account deleted successfully.");
          })
        }
    })
  }

  const AboutSection = () => {
    return (
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
          ml: "0px",
        }}
        spacing={1}
        container
        className="operation-padding"
      >
        {loading ? (
          <Skeleton sx={{ ml: 1, mt: 2 }} height={25} width={90} />
        ) : (
          <>
            {profile.about && (
              <>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "500", textAlign: "start" }}
                      component="h3"
                    >
                      About
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{ fontWeight: "350", textAlign: "start" }}
                  component="h4"
                >
                  {profile.about}
                </Typography>
              </>
            )}
            {profile.location && (
              <>
                <Grid
                  sx={{ alignItems: "end", marginTop: 4, marginBottom: 0 }}
                  spacing={1}
                  container
                >
                  <Grid item>
                    <HomeIcon style={{ fontSize: 20 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "400", textAlign: "start" }}
                      component="h4"
                    >
                      Lives in {profile.location}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {spokenLanguages?.length > 0 && (
              <Grid
                sx={{ alignItems: "end", marginBottom: 4 }}
                spacing={1}
                container
              >
                <Grid item>
                  <ChatBubbleIcon style={{ fontSize: 20 }} />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontWeight: "400", textAlign: "start" }}
                    component="h4"
                  >
                    Speaks {spokenLanguages.join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid sx={{ pl: "0px !important" }} item>
              <StarIcon style={{ fontSize: 25 }} />
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: "500", textAlign: "start" }}
                component="h3"
              >
                {profile.reviews} reviews
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
  return (
    <>
      {loading ? (
        <>
          <Skeleton height={40} width={200} />
          <Skeleton height={13} width={60} />
        </>
      ) : (
        <>
          <Box className="main-heading-block" sx={{ display: "unset" }}>
            <h1 className="main-heading">Hi, I’m {profile.user.name}</h1>
            <p>Joined in {profile.joined}</p>
          </Box>
        </>
      )}
      {isEditable && (
        <>
          {loading ? (
            <Skeleton sx={{ mt: 1 }} height={15} width={50} />
          ) : (
            <MaterialLink
              onClick={() => {
                setShowEditProfile(true);
              }}
              className={"link-update " + (showEditProfile && "disable-link")}
            >
              Edit profile
            </MaterialLink>
          )}

          {showEditProfile && (
            <EditProfileForm
              showEditProfile={showEditProfile}
              setShowEditProfile={setShowEditProfile}
            />
          )}
        </>
      )}

      {!showEditProfile && <AboutSection />}

      {isEditable && (
        <Link to="/users/reviews" className="link-update reviews-borders">
          Reviews by you
        </Link>
      )}

      {user.role_id=='3' && (
      <Button size="small" type="button" sx={{margin:"15px 0"}} className="btn-primary submit-btn rounded-button" onClick={(e) => { deleteAccount() }}>
          Delete Account
      </Button>
      )}
    </>
  );
};
export default ProfileDataSection;
