import { Button, Card, Divider, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import { useState } from "react";
import ImproveModal from "./ImproveModal";
import { useSelector } from "react-redux";
import PhoneModal from "./PhoneModal";
import { ModalContext } from "./Context/ModalContext";
const SecureAccountCard = () => {
  const [open, setOpen] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePhoneClose = () => setOpenPhone(false);
  const { user } = useSelector((state) => state.userReducers.data);
  //Check if the user has a verified email and mobile phone
  const isSecure = user?.email != null && user?.mobile_no != null;
  return (
    <ModalContext.Provider
      value={{
        openPhone: openPhone,
        setOpenPhone: setOpenPhone,
      }}
    >
      <Card className="privacy-info-card">
        <SecurityIcon sx={{ fontSize: 40 }} />
        {isSecure ? (
          <>
            <Typography sx={{ fontWeight: "600", my: 2 }} component="h4">
              Keeping your account secure
            </Typography>
            <Typography sx={{ marginTop: 2 }}>
              We regularly review accounts to make sure they’re secure as
              possible. We’ll also let you know if there’s more we can do to
              increase the security of your account.
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ fontWeight: "600", my: 2 }} component="h4">
              Let's make your account more secure
            </Typography>
            <Typography sx={{ fontWeight: "500" }} component="h4">
              Your account security: Low
            </Typography>
            <Typography sx={{ marginTop: 2 }}>
              We’re always working on ways to increase safety in our community.
              That’s why we look at every account to make sure it’s as secure as
              possible.
            </Typography>
            <Divider sx={{ width: 70, pt: 3 }} light />

            <Button
              sx={{ mt: 1.5 }}
              onClick={() => handleOpen()}
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
            >
              Improve
            </Button>
            <ImproveModal open={open} onClose={handleClose} />
            <PhoneModal open={openPhone} onClose={handlePhoneClose} />
          </>
        )}
      </Card>
    </ModalContext.Provider>
  );
};

export default SecureAccountCard;
