import React from 'react';
import {FormControl, FormControlLabel, Checkbox} from '@mui/material';
import styled from '@emotion/styled';
   
const CheckboxInput = ({label, name, value, checked, onChange, className, sx, style, inputtype, checkboxborder}) => {
    const BpIcon = styled("span")(({ theme }) => ({
        borderRadius: 3,
        width: 26,
        height: 26,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: checkboxborder ? checkboxborder : "#E5E4E4",
        "input:disabled ~ &": {
          boxShadow: "none",
          background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: "#FF4110",
        backgroundImage:
          "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
          display: "block",
          width: 22,
          height: 22,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
        "input:hover ~ &": {
          backgroundColor: "#FF4110",
        },
    });
    
    function BpCheckbox(props, index) {
        return (
            <Checkbox
            sx={{
              "&:hover": { bgcolor: "transparent" },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
            />
        );
    }
    
    return (
        <>
          {
            inputtype=='checkboxonly' 
            ?
            <BpCheckbox 
              name={name} 
              value={value} 
              checked={checked} 
              onChange={onChange}
              className={className}
            />
            :
            <FormControlLabel 
              control={<BpCheckbox />} 
              label={label} 
              name={name} 
              value={value} 
              checked={checked} 
              onChange={onChange}
              className={className}
              sx={sx}
              style={style} 
            />           
          }
        </>
    )
}

export default CheckboxInput;