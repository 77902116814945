import { Card, Hidden, Link, Typography } from "@mui/material";

const PrivacyInfoCard = () => {
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="privacy-info-card">
        <Typography sx={{ fontWeight: "500" }} component="h4">
          Committed to privacy
        </Typography>
        <Typography sx={{ marginTop: 2 }}>
          Beachify is committed to keeping your data protected. See details in
          our{" "}
          <Link
            className="black-underline-link"
            target="_blank"
            href="/privacy-policy"
          >
            Privacy Policy.
          </Link>
        </Typography>
      </Card>
    </Hidden>
  );
};
export default PrivacyInfoCard;
