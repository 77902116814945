import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";

const DataTab = () => {
  return (
    <Box>
      <Box className="become-host-step-form">
        <Box className="small-heading">
        <Typography sx={{ fontWeight: "500" }} component="h2" className="main-heading">
        Manage your account data
      </Typography>
        </Box>
      </Box>
      
      <Typography>You can delete your personal data from Beachify.</Typography>
      {/* <Link to="/account-settings/privacy-and-sharing/data">
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            marginTop: 5,
            paddingBottom: 0,
          }}
        >
          <Typography sx={{ color: "black", fontWeight: "400" }} component="h4">
            Request your personal data
          </Typography>
          <KeyboardArrowRightIcon sx={{ color: "black", fontSize: 25 }} />
        </Typography>
      </Link>
      <Typography sx={{ fontWeight: "200" }}>
        We’ll create a file for you to download your personal data.
      </Typography> */}
      <Link to="/account-settings/manage-your-data/deletion">
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            marginTop: 5,
            paddingBottom: 0,
          }}
        >
          <Typography sx={{ color: "black", fontWeight: "400" }} component="h4">
            Delete your account
          </Typography>
          <KeyboardArrowRightIcon sx={{ color: "black", fontSize: 25 }} />
        </Typography>{" "}
      </Link>
      <Typography sx={{ fontWeight: "200" }}>
        This will delete your account.
      </Typography>
    </Box>
  );
};

export default DataTab;
