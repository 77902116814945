import { Skeleton } from "@mui/material";

const TabLoading = () => {
  return (
    <>
      <Skeleton variant="rounded" mt={2} height={25} />
      <Skeleton variant="rounded" sx={{ mt: 1 }} height={15} />
      <Skeleton variant="rounded" sx={{ mt: 3 }} height={10} width={100} />
      <Skeleton variant="rounded" sx={{ mt: 1 }} height={7} width={100} />
      <Skeleton variant="rounded" sx={{ mt: 2 }} height={8} width={50} />
    </>
  );
};

export default TabLoading;
