import { Button, Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import DetailModal from "./DetailsModal";
import ReferralListItem from "./ListItem";
//TODO: Get this info from backend
const listItems = [
  { label: "Completed referrals", quantity: 0 },
  { label: "Sign ups", quantity: 0 },
];

const CentralCard = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Card
      className="account-card"
      sx={{ width: 500, maxHeight: 700, padding: 1 }}
    >
      <CardContent>
        <Typography sx={{ fontWeight: "600", marginBottom: 2 }} component="h4">
          Track your referrals
        </Typography>
        <ReferralListItem items={listItems} />
        <Button
          onClick={() => handleOpen()}
          sx={{ width: 1, marginTop: 1 }}
          className="outlined-black-btn"
        >
          Show more details
        </Button>
        <DetailModal open={open} onClose={handleClose} />
      </CardContent>
    </Card>
  );
};

export default CentralCard;
