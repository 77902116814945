import { Container } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import EditOptions from "../../Components/GlobalPreferences/EditOptions";
import InfoRightCard from "../../Components/GlobalPreferences/InfoRightCard";
import Grid from '@mui/material/Unstable_Grid2';

const GlobalPreferences = () => {
  return (
    <Container sx={{ py: 5, }}>
      <AccountSettingsTitle
        title="Global preferences"
        routeLabel="Global preferences"
      />
      <Grid container rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} >
        <Grid xs={12} md={8} item>
          <EditOptions />
        </Grid>
        <Grid md={4} item className="mobile-none">
          <InfoRightCard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default GlobalPreferences;
