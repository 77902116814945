import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Taxpayer or business name is required")
    .typeError("Taxpayer or business name must be a valid value"),
  country_citizenship: yup
    .string()
    .required("Country/region of citizenship is required")
    .typeError("Country/region of citizenship must be a valid value"),
  id_number: yup.string().typeError("US tax ID number must be a valid value"),
  foreign_id_number: yup
    .string()
    .typeError("Foreign tax ID number must be a valid value"),
  permanent_residence_address: yup.object().shape({
    country: yup
      .string()
      .required("Country is required")
      .typeError("Country must be a valid value"),
    line_1: yup
      .string()
      .required("Line 1 is required")
      .typeError("Line 1 must be a valid value"),
    line_2: yup.string().typeError("Line 2 must be a valid value"),
    line_3: yup.string().typeError("Line 3 must be a valid value"),
    line_4: yup.string().typeError("Line 4 must be a valid value"),
    city: yup.string().typeError("City/village must be a valid value"),
    state: yup.string().typeError("State must be a valid value"),
    zip: yup.string().typeError("ZIP code must be a valid value"),
  }),
  mail_address: yup.object().when("same_mail_address", {
    is: false,
    then: yup.object().shape({
      country: yup.string().required("Country is required"),
      street: yup.string().required("Country is required"),
      apt_suite: yup.string().nullable(true),
      city: yup.string().required("City is required"),
      state: yup.string().required("State is required"),
      zip: yup.string().required("ZIP code is required"),
    }),
    otherwise: yup.object().shape({
      country: yup.string().nullable(true),
      street: yup.string().nullable(true),
      apt_suite: yup.string().nullable(true),
      city: yup.string().nullable(true),
      state: yup.string().nullable(true),
      zip: yup.string().nullable(true),
    }),
  }),
  same_mail_address: yup
    .boolean()
    .required("Same mail address is required")
    .typeError("Same mail address must be a valid value"),
  signature: yup
    .string()
    .required("Signature is required")
    .typeError("Signature must be a valid value"),
});

export default ValidationSchema;
