import { Container } from "@mui/material";

import ProfileDataSection from "./ProfileDataSection";
import UserProfileCard from "./UserProfileCard";
import { useEffect } from "react";
import { ProfileService } from "../../../Services/ProfileService";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MakeToast from "../../../Helpers/MakeToast";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

const UserShow = () => {
  const params = useParams();
  const { user } = useSelector((state) => state.userReducers.data);
  const [profile, setProfile] = useState([]);
  const isEditable = params.id ? user.id == params.id : true;
  const [languages, setLanguages] = useState({
    userLanguages: [],
    availableLanguages: [],
  });
  const [profileId, setProfileId] = useState(params.id ? params.id : user.id);

  const [loading, setLoading] = useState(true);
  const getProfile = () => {
    setLoading(true);
    ProfileService.profile(profileId)
      .then((response) => {
        setProfile(response.data.data.profile);
        setLanguages({
          userLanguages: [...response.data.data.languages],
          availableLanguages: [...response.data.data.languages],
        });
        setLoading(false);
      })
      .catch((error) => {
        MakeToast("error", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    // if (user.id) {
    getProfile();
    // }
  }, []);
  return (
    <ProfileContext.Provider
      value={{
        profile: profile,
        setProfile: setProfile,
        languages: languages,
        setLanguages: setLanguages,
        loading: loading,
        getProfile: getProfile,
        isEditable: isEditable,
      }}
    >
      <Container maxWidth="lg" sx={{ py: 5 }} className="hsgdbhn">
        <BreadCrumb data={[["Profile"]]} />
        <Grid
          rowSpacing={{ xs: 1, sm: 2, md: 5 }}
          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
          container
          style={{marginTop:'5px'}}
        >
          <Grid xs={12} md={4} item>
            <UserProfileCard />
          </Grid>
          <Grid xs={12} md={8} className="edit-profile-padding">
            <ProfileDataSection />
          </Grid>
        </Grid>
      </Container>
    </ProfileContext.Provider>
  );
};
export default UserShow;
