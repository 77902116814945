import React from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import images from "../../assets/js/images";

const QuantityInput = ({ quantity, increment, decrement, boxwidth }) => {
  return (
    <>
      <Box style={{width : boxwidth ?? '83px'}}> 
        <ButtonGroup
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            sx={{
              border: 0,
              p: 0,
              minWidth: '24px !important',
              "&:hover": {
                border: 0,
                backgroundColor:
                  "transparent",
              },
            }}
            disableRipple
            aria-label="reduce"
            onClick={decrement}
          >
            <img src={images.minusIcon} alt="decrease" />
          </Button>
          <p className="qty-input">{quantity}</p>
          <Button
            sx={{
              border: 0,
              p: 0,
              minWidth: '24px !important',
              "&:hover": {
                border: 0,
                backgroundColor:
                  "transparent",
              },
            }}
            disableRipple
            aria-label="increase"
            onClick={increment}
          >
            <img src={images.plusIcon} alt="increase" />
          </Button>
        </ButtonGroup>
      </Box>
    </>
  )
}

export default QuantityInput;