const InitialValues = {
  zip: "",
  cardholder_details: {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    country: "",
  },
  card_type: "",
  accept_terms: false,
};
export default InitialValues;
