import React from 'react'
import ModalPopup from './ModalPopup';
import {Box, Button, FormControl, Typography, TextareaAutosize, FormHelperText, CircularProgress, Link } from '@mui/material';
import { Formik } from "formik";
import * as yup from 'yup';
import MakeToast from "../../Helpers/MakeToast";
import images from '../../assets/js/images';

import { BookingService } from "../../Services/BookingService";

function ContactCustomerModal(props) {
    const [customerDetail, setCustomerDetail] = React.useState({})
    const [showMobile, setShowMobile] = React.useState(false)
    const [showEmail, setShowEmail] = React.useState(false)
    const [showText, setShowText] = React.useState(false)

    const toggle = (type) => {
        if(type=='mobile'){
            setShowMobile(showMobile => !showMobile)
            setShowEmail(false);
            setShowText(false);
            newWindow(`tel:${customerDetail?.mobile}`);
        }else if(type=='email'){
            setShowEmail(showEmail => !showEmail);
            setShowMobile(false);
            setShowText(false);
            newWindow(`mailto:${customerDetail?.email}`)
        }else if(type=='text'){
            setShowText(showText => !showText);
            setShowMobile(false)
            setShowEmail(false);
            newWindow(`sms:${customerDetail?.mobile}`)
        }
    }

    const newWindow = (value) => {
        const windowRef = window.open(value, '_blank');
        windowRef.focus();
        windowRef.onfocus = function() {
            return;
        } 
    }

    React.useEffect(() => {
        BookingService.bookedCustomer(props.id).then((res) => {
            setCustomerDetail(res.data.data)
        });
    }, [props])

    let textValidationSchema = yup.object().shape({
        message: yup.string().required('Message is required')
    });


    return (
        <ModalPopup
            contentStyle={{ width: '550px', height: '328px' }}
            open={props.openContactCustomer}
            onClose={() => { props.setOpenContactCustomer(false) }}
            content={<>
                <Box className="modalheader" sx={{ marginBottom: 0 }}>
                    <Box className="modal-close-btn">
                        <Button size="small" onClick={() => { props.setOpenContactCustomer(false) }}> <img src={images.closeIcon} alt="" /></Button>
                    </Box>
                </Box>
                <Box className="modal-body message-modal">
                    <Typography component="h2">
                        Contact Customer
                    </Typography>
                    <Box  sx={{ display: "flex", alignItems: "center", justifyContent:'space-between',flexWrap:'wrap'}}>
                        <Link underline="none" 
                            component="button" 
                            className= { 
                                showMobile 
                                ? 
                                'cc-btn-width btn-primary border-radius-md' 
                                : 
                                'cc-btn-width outline-btn-white border-radius-md'} 
                            sx={{ textTransform: 'unset!important' }} 
                            onClick={() => toggle('mobile')} 
                        >
                            Call
                        </Link>
                        <Link underline="none" 
                            component="button" 
                            className= { 
                                showText 
                                ? 
                                'cc-btn-width btn-primary border-radius-md' 
                                : 
                                'cc-btn-width outline-btn-white border-radius-md'} 
                            sx={{ textTransform: 'unset!important' }} 
                            onClick={() => toggle('text')} 
                        >
                            Text
                        </Link>
                        <Link underline="none" 
                            component="button" 
                            className= { 
                                showEmail 
                                ? 
                                'cc-btn-width btn-primary border-radius-md' 
                                : 
                                'cc-btn-width outline-btn-white border-radius-md'} 
                            sx={{ textTransform: 'unset!important' }}
                            onClick={() => toggle('email')} 
                        >
                            Email
                        </Link>
                    </Box>
                </Box>
            </>}
        />
    )
}

export default ContactCustomerModal