import React from 'react'
import ModalPopup from './ModalPopup';
import { Box, Button, CircularProgress, FormHelperText, FormControl, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import images from '../../assets/js/images';
import MakeToast from '../../Helpers/MakeToast';
import RadioInput from '../../Components/Inputs/RadioInput';
import TimeInput from '../../Components/Inputs/TimeInput';
import { BookingService } from '../../Services/BookingService';

function OnTheWayModal(props) {
    const pagetype = props.pagetype;
    const onthewayoption = {'1' : 'I’m running on time', '2' : 'I’m running late'};

    const validationSchema = yup.object().shape({
        eta: yup.string()
        .test("eta", "ETA is required", (val, data) => {
            return data.parent.option=='1' ? true : (val != undefined && val.replace(/:| |_/g, "").length === 6 ? true : false);
        })
    });

    const formik = useFormik({
        initialValues: {
            id: '',
            option: '1',
            eta: '',
            eta_checkbox: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            var service;
            if(pagetype=='1') service = BookingService.pickupHostNotify(values);
            else if(pagetype=='2') service = BookingService.dropHostNotify(values);

            service.then((res) => {
                props.onEmitValue('');
                formik.setSubmitting(false);
                MakeToast("success", "Notified successfully");
                props.setOpenOnTheWay(false);
            });
        },
    });

    React.useEffect(() => {
        formik.setFieldValue('id', props.id);
    }, [props])

    return (
        <ModalPopup
            contentStyle={{ width: '550px', height: '328px' }}
            open={props.openOnTheWay}
            onClose={() => { props.setOpenOnTheWay(false) }}
            content={<>
                <Box className="modalheader" sx={{ marginBottom: 0 }}>
                    <Box className="modal-close-btn">
                        <Button size="small" onClick={() => { props.setOpenOnTheWay(false) }}> <img src={images.closeIcon} alt="" /></Button>
                    </Box>
                </Box>
                <Box className="modal-body message-modal">
                    <form onSubmit={formik.handleSubmit} className="on-way-modal">
                        <Typography component="h2">
                            On the way {pagetype=='1' ? ' - pickup' : ' - drop'}
                        </Typography>
                        <Box className="radio-check-button">
                            <FormControl sx={{ display: 'flex' }}>
                                <RadioInput
                                    customObj={onthewayoption}
                                    name="option"
                                    value={formik.values.option}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        fontSize: '16px',
                                        paddingRight: '30px',
                                    }}
                                />
                            </FormControl>
                        </Box>
                        {
                            formik.values.option=='2'
                            ?
                            <>
                                <Box className='time-field'>
                                    <FormControl sx={{
                                        width: '100%',
                                        '@media (max-width:769px)': {
                                            marginBottom: '15px',
                                            '@media (max-width:566px)': {
                                                marginBottom: '0px',
                                            }
                                        },
                                        // '@media (max-width:566px)': {
                                        //     width: '90% !important',
                                        // },
                                    }} className="input-style">
                                        <TimeInput
                                            id="etatext"
                                            placeholder="ETA"
                                            name="eta"
                                            value={formik.values.eta}
                                            onChange={(e) => {
                                                var text = e.target.value;
                                                var split = text.split(' ');

                                                formik.setFieldValue('eta', split[0] + ' ' + (split[1] == 'PM' || split[1] == 'pM' ? 'PM' : 'AM'));
                                                formik.setFieldValue('eta_checkbox', (split[1] == 'PM' || split[1] == 'pM') ? true : false);
                                            }}
                                            onBlur={(e) => {
                                                formik.handleChange(e);
                                                document.getElementById('etaampmbtn').style.display = "none";
                                            }}
                                            onClick={(e) => {
                                                if (e.target.value == '__:__ AM') e.target.setSelectionRange(0, 0);
                                                document.getElementById('etaampmbtn').style.display = "";
                                            }}
                                            className="etatime"
                                            idSwitch={"etaampmbtn"}
                                            checkedSwitch={formik.values.eta_checkbox}
                                            onChangeSwitch={(e) => {
                                                var text = formik.values.eta;
                                                var split = text.split(' ');

                                                formik.setFieldValue('eta', split[0] + ' ' + (e.target.checked ? 'PM' : 'AM'));
                                                formik.setFieldValue('eta_checkbox', e.target.checked ? true : false);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <FormHelperText className="field-helper-text error" style={{ marginBottom: "15px" }}>{formik?.errors?.eta}</FormHelperText>
                            </>
                            :
                            <></>
                        }
                        <Box sx={{ marginTop: '15px' }}>
                            <Button type="submit" disabled={formik.isSubmitting} className="btn-primary border-radius-md w-100" sx={{ padding: '15px 10px !important' }}>
                                {formik.isSubmitting ? <CircularProgress color="inherit" /> : "Notify host"}
                            </Button>
                        </Box>
                        
                    </form>
                </Box>
            </>}
        />
    )
}

export default OnTheWayModal