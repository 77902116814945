let defaultState = {
    isCalUpdated: false,
}

let calculationReducers = (state = defaultState, action) => {
    switch (action.type) {
        case 'CALCULATION_UPDATE': {
            let newState = { ...state };
            newState = {
                isCalUpdated: action.payload
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}

export default calculationReducers;
