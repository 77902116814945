import { useContext } from "react";
import { FormContext } from "../../../../../Context/TaxContext";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
const ForeignIDNumber = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Foreign tax ID number (optional)
      </Typography>
      <TextField
        placeholder="Foreign tax ID number (optional)"
        name="foreign_id_number"
        value={formik.values.foreign_id_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.foreign_id_number && formik.touched.foreign_id_number && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.foreign_id_number}
        </FormHelperText>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Number must correspond with the taxpayer name on this form. We keep this
        info private.
      </Typography>
    </Box>
  );
};

export default ForeignIDNumber;
