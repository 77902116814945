import React from 'react'
import ModalPopup from './ModalPopup';
import { Box, Button, CircularProgress, FormControl, FormHelperText, TextareaAutosize, Typography } from '@mui/material';
import SelectInput from '../Inputs/SelectInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AppHelper from '../../Helpers/AppHelper';
import { BookingService } from '../../Services/BookingService';
import images from '../../assets/js/images';
import MakeToast from '../../Helpers/MakeToast';

function DenyModal(props) {
    const denylist = AppHelper.lists().denylist;

    let cValidationSchema = yup.object().shape({
        cancellation_option: yup.string().nullable().required('Option is required')
    });

    const cFormik = useFormik({
        initialValues: {
            id: '',
            cancellation_option: '',
            cancellation_reason: ''
        },
        validationSchema: cValidationSchema,
        onSubmit: values => {
            BookingService.denyBooking(values).then((res) => {
                props.onEmitValue('');
                cFormik.setSubmitting(false);
                MakeToast("success", "Denied successfully");
                props.setOpenDeny(false);
            });
        },
    });

    React.useEffect(() => {
        cFormik.setFieldValue('id', props.id);
    }, [props])

    return (
        <ModalPopup
            contentStyle={{ width: '550px', height: '328px' }}
            open={props.openDeny}
            onClose={() => { props.setOpenDeny(false) }}
            content={<>
                <Box className="modalheader" sx={{ marginBottom: 0 }}>
                    <Box className="modal-close-btn">
                        <Button size="small" onClick={() => { props.setOpenDeny(false) }}> <img src={images.closeIcon} alt="" /></Button>
                    </Box>
                </Box>
                <Box className="modal-body message-modal">
                    <form onSubmit={cFormik.handleSubmit}>
                        <Typography component="h2">
                            Order Cancellation
                        </Typography>
                        <Typography component="h4">
                            Please list reason for cancellation
                        </Typography>
                        <Box
                            sx={{
                                '& > :not(style)': {
                                    width: '90%',
                                    margin: '15px 0 0',
                                    borderRadius: '18px !important'
                                },

                            }}
                        >
                            <FormControl variant="standard" className="input-style outlined-input select-style" sx={{
                                '@media (max-width:575px)': {
                                    width: '100% !important'
                                },
                                '.MuiInputBase-root': {
                                    paddingLeft: '20px',
                                },
                            }}>
                                <SelectInput
                                    customArr1={denylist}
                                    name="cancellation_option"
                                    value={cFormik.values.cancellation_option}
                                    onChange={cFormik.handleChange}
                                    renderValue={(selected) => {
                                        if (selected == undefined || selected == '') {
                                            return <em>Reason for cancellation</em>;
                                        }

                                        let cancellationdata = denylist.indexOf(cFormik.values.cancellation_option);
                                        return cancellationdata > -1 ? denylist[cancellationdata] : '';
                                    }}
                                />
                            </FormControl>
                            <FormHelperText className="field-helper-text error" style={{ width: "50%", margin: "5px 0 15px 25%" }}>{cFormik?.errors?.cancellation_option}</FormHelperText>
                        </Box>
                        {
                            cFormik.values.cancellation_option == 'Other'
                                ?
                                <Box sx={{ marginTop: '15px' }}>
                                    <TextareaAutosize
                                        name="cancellation_reason"
                                        value={cFormik.values.cancellation_reason}
                                        onChange={cFormik.handleChange}
                                        className="textfield-area input-style"
                                        minRows={3}
                                        placeholder="Reason"
                                        style={{
                                            width: '90%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Box>
                                :
                                <></>
                        }
                        <Button type="submit" disabled={cFormik.isSubmitting} className="btn-primary border-radius-md w-100" sx={{ width: '90%', padding: '15px 10px !important' }}>
                            {cFormik.isSubmitting ? <CircularProgress color="inherit" /> : "Cancel the order"}
                        </Button>
                    </form>
                </Box>
            </>}
        />
    )
}

export default DenyModal