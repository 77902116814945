import { FormControl, FormHelperText, TextField } from "@mui/material";
import * as yup from "yup";
import FormControlEdit from "../Hoc/FormControlEdit";
let informationValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .typeError("Name must be a text"),
});
const Name = ({ initialValue, formik }) => {
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <>
      <FormControl sx={{ width: "100%" }} className="input-style small-radius">
        <TextField
          placeholder="James Jackson"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className="outline-input textfield-rouded-md input-text-style small-radius"
          sx={{
            borderRadius: "18px !important",
            ".MuiInputBase-input": {
              paddingLeft: "30px",
            },

            ".MuiInputBase-root": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
              "&.Mui-focused": {
                " .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
            },
          }}
        />
      </FormControl>
      {errors.name && touched.name && errors.name && (
        <FormHelperText className="field-helper-text error">
          {errors.name && touched.name && errors.name}
        </FormHelperText>
      )}
    </>
  );
};

export default FormControlEdit(Name, {
  informationValidationSchema: informationValidationSchema,
  fieldName: "name",
  label: "Your Name",
  description: "Please enter your name below.",
});
