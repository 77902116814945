import { Box, ButtonBase, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MakeToast from "../../Helpers/MakeToast";
import { LoginAndSecurityService } from "../../Services/LoginAndSecurityService";
import ConfirmationModal from "../Common/ConfirmationModal";
const Account = () => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const confirmAction = () => {
    LoginAndSecurityService.deactivateUser()
      .then((res) => {
        setLoader(false);
        dispatch({
          type: "LOGOUT",
          payload: [],
        });
        MakeToast("success", res);
      })
      .catch((error) => {
        setLoader(false);
        MakeToast("error", error);
      });
  };
  return (
    <Box sx={{ mt: 5 }}>

      <Typography sx={{ fontWeight: "600", mb: 5 }} component="h3" className="main-heading">
        Account
      </Typography>
      <Grid sx={{ mt: 4 }} className="gray-border-bottom" container>
        <Grid xs={10} item>
          <Typography component="h4">Deactivate your account</Typography>
        </Grid>
        <Grid xs={2} sx={{ textAlign: "end" }} item>
          <ButtonBase
            sx={{ fontSize: 18, marginTop: 1, color: "#D93900" }}
            variant="text"
            onClick={() => setOpen(true)}
            disabled={loader}
          >
            Deactivate
          </ButtonBase>
        </Grid>
      </Grid>
      <ConfirmationModal
        title="Are you sure?"
        description="Your account will be deactivated and you will no longer be able to log in."
        setOpen={setOpen}
        open={open}
        confirmAction={confirmAction}
      />
    </Box>
  );
};

export default Account;
