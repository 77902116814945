import {
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import images from "../../../assets/js/images";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useContext, useEffect, useState } from "react";
import { ProfileService } from "../../../Services/ProfileService";
import MakeToast from "../../../Helpers/MakeToast";
import AppHelper from '../../../Helpers/AppHelper';
import { useDispatch, useSelector } from "react-redux";

const ConfirmedListItem = ({ title }) => {
  return (
    <ListItem sx={{ paddingLeft: 0 }}>
      <ListItemIcon sx={{ minWidth: 25 }}>
        <CheckIcon />
      </ListItemIcon>
      <ListItemText sx={{ fontSize: 16 }} primary={title} />
    </ListItem>
  );
};

const CompanyProfileCard = () => {
  const { profile, loading, isEditable, setProfile } =
    useContext(ProfileContext);
  const dispatch = useDispatch();
  const handleUploadPhoto = (event) => {
    if(AppHelper.checkFileFormat(event.target.files[0].type)){
      let image = event.target.files[0];
      let formData = new FormData();
      formData.append("profile_image", image);

      if (image)
        ProfileService.updateProfileImage(formData)
          .then((response) => {
            dispatch({
              type: "LOGIN_START",
              payload: response?.data,
            });
            let newProfile = { ...profile };
            newProfile.user.profile_image = response?.data?.data?.image ?? "";
            setProfile({ ...newProfile });
            MakeToast("success", response);
          })
          .catch((error) => {
            MakeToast("error", error);
          });
    }else{
      MakeToast("error", "Unsupported file format.");
    }
  };
  return (
    <Card className="user-info-card" sx={{p:0,paddingBottom:"0 !important"}}>
      <CardContent sx={{p:0,paddingBottom:"0 !important"}}>
        <img
          className="user-image"
          src={profile?.user?.profile_image ?? images.userDefault}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = images.userDefault;
          }}
        />
        {isEditable && (
          <div className="uploadPhotoWrapper">
            Update photo
            <input
              className="uploadPhotoInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleUploadPhoto(e)}
            />
          </div>
        )}

        {isEditable && (
          <div className="confirmed-section">
            {loading ? (
              <>
                <Skeleton height={40} width={100} />
                <Skeleton sx={{ mt: 1 }} height={30} />
              </>
            ) : (
              <>
                <Typography className="host-heading"
                  sx={{ fontWeight: "500", textAlign: "start" }}
                  component="h3"
                >
                  Company Name confirmed
                </Typography>
                <List dense={true}>
                  <ConfirmedListItem title="Email address" />
                  {profile?.phone_confirmed && (
                    <ConfirmedListItem title="Phone Number" />
                  )}
                </List>
              </>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
export default CompanyProfileCard;
