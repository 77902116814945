const InitialValues = {
  name: "",
  disregarded_entity_name: "",
  entity_type: "",
  country_region: "",
  type_id: "",
  id_number: "",
  foreign_id_number: "",
  permanent_residence_address: {
    country: "",
    line_1: "",
    line_2: "",
    line_3: "",
    line_4: "",
    city: "",
    state: "",
    zip: "",
  },
  us_business_address: {
    street: "",
    apt_suite: "",
    city: "",
    state: "",
    zip: "",
  },
  income: "",
  signature: "",
};
export default InitialValues;
