import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { FormContext } from "../../../Context/PaymentsContext";
import { useContext } from "react";

const RadioItem = (props) => {
  const { option } = props;
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == option.id;
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={option.id}
      control={<Radio />}
      label={
        <>
          <Typography
            sx={{ fontSize: 17, color: "black", fontWeight: "light" }}
          >
            {option.label}
          </Typography>
        </>
      }
      sx={{
        fontSize: 19,
        mx: "0px !important",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
};

const CardType = () => {
  const { formik } = useContext(FormContext);

  const types = [
    { id: "PERSONAL", label: "Personal" },
    { id: "BUSINESS", label: "Business" },
  ];
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Is this a personal or business card?
      </Typography>
      <RadioGroup
        name="card_type"
        value={formik.values.card_type}
        onChange={(event) =>
          formik.setFieldValue("card_type", event.target.value)
        }
      >
        {types.map((data, index) => (
          <RadioItem key={index} option={data} />
        ))}
      </RadioGroup>
      {formik.errors.card_type && formik.touched.card_type && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.card_type}
        </FormHelperText>
      )}

      <FormControlLabel
        control={
          <Checkbox
            name="accept_terms"
            sx={{ color: "black !important", fontSize: 25 }}
            checked={formik.values.accept_terms}
            onChange={(event) =>
              formik.setFieldValue("accept_terms", event.target.checked)
            }
          />
        }
        sx={{ fontSize: 18, borderTop: "solid 1px #ebebeb", pt: 3, mt: 3 }}
        label="I understand that a 1.5% fee will be deducted from each payout (maximum fee $15 USD, including tax if applicable)."
      />
      {formik.errors.accept_terms && formik.touched.accept_terms && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.accept_terms}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CardType;
