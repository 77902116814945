const logo = "/assets/images/logo.png";
const heroImage = "/assets/images/hero-banner.png";
const beachGearImage = "/assets/images/beach-gear-image.png";
const popularCategoriesImage = "/assets/images/popular-categories-image.png";
const smallogo = "/assets/images/user-default.png";
const calenderIcon1 = "/assets/images/calendar-1.png";
const car1 = "/assets/images/delivery-car-1.png";
const trip = "/assets/images/trip.png";
const beachumberlla = "/assets/images/beach-umberlla.png";
const itemImg = "/assets/images/item-img.png";
// All beaches
const allBeaches1 = "/assets/images/all-beaches1.png";
const allBeaches2 = "/assets/images/all-beaches2.png";
const allBeaches3 = "/assets/images/all-beaches3.png";
const allBeaches4 = "/assets/images/all-beaches4.png";
const allBeaches5 = "/assets/images/all-beaches5.png";

// Popular beach gear
const beachGear1 = "/assets/images/popular-beachgear1.png";
const beachGear2 = "/assets/images/popular-beachgear2.png";
const beachGear3 = "/assets/images/popular-beachgear3.png";
const beachGear4 = "/assets/images/popular-beachgear4.png";
const beachGear5 = "/assets/images/popular-beachgear5.png";

// Popular categories
const category1 = "/assets/images/popular-categories-image.png";
const category2 = "/assets/images/popular-categories2.png";
const category3 = "/assets/images/popular-categories3.png";
const category4 = "/assets/images/popular-categories4.png";
const category5 = "/assets/images/popular-categories5.png";
const category6 = "/assets/images/popular-categories6.png";

// Previously viewed
const prevViewed1 = "/assets/images/previously-viewed1.png";
const prevViewed2 = "/assets/images/previously-viewed2.png";
const prevViewed3 = "/assets/images/previously-viewed3.png";

// ---upload image--//
const uploadImage = "/assets/images/upload-image.png";
const uploadPdf = "/assets/images/upload-pdf.png";
//umberlla
const umberlla1 = "/assets/images/umberlla-1.png";
const umberlla2 = "/assets/images/umberlla-2.png";
const umberlla3 = "/assets/images/umberlla-3.png";
const umberlla4 = "/assets/images/umberlla-4.jpg";
const umberlla5 = "/assets/images/umberlla-5.png";


const fb = "/assets/images/fb.png";
const whatsapp = "/assets/images/whatsapp.png";

const cartIcon = "/assets/images/cart-icon.svg";
const notificationIcon = "/assets/images/notification-icon.svg";
const toggleIcon = "/assets/images/toggle-icon.svg";
const profileIcon = "/assets/images/profile-icon.svg";
const searchIcon = "/assets/images/search-icon.svg";
const locationIcon = "/assets/images/location-icon.svg";
const calendarIcon = "/assets/images/calendar-icon.svg";
const filterIcon = "/assets/images/filter-icon.svg";
const locationGreyIcon = "/assets/images/location-grey-icon.svg";
const distanceIcon = "/assets/images/distance-icon.svg";
const timeIcon = "/assets/images/time-icon.svg";
const deliveryIcon = "/assets/images/delivery-icon.svg";
const bookingDateIcon = "/assets/images/booking-date-icon.svg";
const arrowIcon = "/assets/images/Arrow-icon.svg";
const heartOutlinedIcon = "/assets/images/like-icon.svg";
const addIcon = "/assets/images/adding-icon.svg";
const leftChevronIcon = "/assets/images/left-chevron.svg";
const rightChevronIcon = "/assets/images/right-chevron.svg";
const facebookIcon = "/assets/images/facebook.svg";
const instagramIcon = "/assets/images/instagram.svg";
const twitterIcon = "/assets/images/twitter.svg";
const heartIcon = "/assets/images/like-filled-icon.svg";
const plusIcon = "/assets/images/plus.svg";
const minusIcon = "/assets/images/minus.svg";
const deleteIcon = "/assets/images/delete-icon.svg";
const rightArrowIcon = "/assets/images/right-arrow.svg";
const leftArrowIcon = "/assets/images/left-arrow.svg";
const headerprofileIcon = "/assets/images/headerprofileicon.svg";
const faqIcon = "/assets/images/faqicon.svg";
const supportIcon = "/assets/images/supporticon.svg";
const logoutIcon = "/assets/images/logout.svg";
const priceIcon = "/assets/images/dollar-sign.svg";
const closeIcon = "/assets/images/close-btn-icon.svg";
const mailIcon = "/assets/images/mail-icon.svg";
const phoneIcon = "/assets/images/phone-icon.svg";
const facebookIconone = "/assets/images/facebook-icon.svg";
const googleIcon = "/assets/images/google-icon.svg";
const contactIcon = "/assets/images/contact-icon.svg";
const datePicker = "/assets/images/date-picker.svg";
const backIcon = "/assets/images/back-arrow.svg";
const incorrectIcon = "/assets/images/incorrect-code.svg";
const downarrowIcon = "/assets/images/downarrowicon.svg";

const restingIcon = "/assets/images/resting.svg";
const plusfillicon = "/assets/images/fill-plus-icon.svg";
const plusminusicon = "/assets/images/fill-minus-icon.svg";
const downarrowicon = "/assets/images/down-arrow.svg";
const uploadicon = "/assets/images/uploadicon.svg";
const mastercardicon = "/assets/images/mastercard-icon.svg";
const amexicon = "/assets/images/amex.svg";
const cartesicon = "/assets/images/cartes.svg";
const diners = "/assets/images/diners.svg";
const jcb = "/assets/images/jcb.svg";
const unionpay = "/assets/images/unionpay.svg";
const visa = "/assets/images/visa.svg";
const discover = "/assets/images/discover.svg";
const UploadIcon2 = "/assets/images/uploadIcon-2.svg";
const addpeople = "/assets/images/addpeople-Icon.svg";
const blackoutlineheartIcon = "/assets/images/black-outline-heart-icon.svg";
const colordateIcon = "/assets/images/color-date.svg";
const down_arrowIcon = "/assets/images/down-arrow.svg";
const sidemenuIcon = "/assets/images/side-menu.svg";
const editIcon = "/assets/images/edit-icon.png";
const reviewstarIcon = "/assets/images/review-star.svg";
const protectcheck = "/assets/images/protect-check.svg";
const roundedbookingIcon = "/assets/images/rounded-booking.svg";
const likeblockoutlineIcon = "/assets/images/like_block-outline.svg";
const questionIcon = "/assets/images/question-icon.svg";
const userDefault = "/assets/images/user-default.png";
const colorprofileIcon = "/assets/images/color-profile-icon.svg";
const archive = "/assets/images/archive.svg";
const starIcon = "/assets/images/star-fill-icon.svg";
const shieldIcon = "/assets/images/shield-icon.svg";
const contactless = "/assets/images/contactless.svg";
const instantbooking = "/assets/images/instant-booking.svg";
const greycalendar = "/assets/images/grey-calandar.svg";
const ProfileGrey = "/assets/images/profile-grey.svg";
const ColortimeIcon = "/assets/images/color-time.svg";
const circlecloseIcon = "/assets/images/circleclose.svg";

const images = {
  // Images
  amexicon,
  cartesicon,
  diners,
  jcb,
  unionpay,
  visa,
  discover,
  logo,
  heroImage,
  beachGearImage,
  popularCategoriesImage,
  allBeaches1,
  allBeaches2,
  allBeaches3,
  allBeaches4,
  allBeaches5,
  beachGear1,
  beachGear2,
  beachGear3,
  beachGear4,
  beachGear5,
  category1,
  category2,
  category3,
  category4,
  category5,
  category6,
  prevViewed1,
  prevViewed2,
  prevViewed3,
  umberlla1,
  umberlla2,
  umberlla3,
  umberlla4,
  umberlla5,
  fb,
  whatsapp,
  uploadImage,
  uploadPdf,
  smallogo,

  // SVG
  cartIcon,
  notificationIcon,
  toggleIcon,
  profileIcon,
  searchIcon,
  locationIcon,
  calendarIcon,
  filterIcon,
  locationGreyIcon,
  distanceIcon,
  timeIcon,
  deliveryIcon,
  bookingDateIcon,
  arrowIcon,
  heartOutlinedIcon,
  addIcon,
  leftChevronIcon,
  rightChevronIcon,
  facebookIcon,
  instagramIcon,
  twitterIcon,
  heartIcon,
  plusIcon,
  minusIcon,
  deleteIcon,
  rightArrowIcon,
  leftArrowIcon,
  headerprofileIcon,
  faqIcon,
  supportIcon,
  logoutIcon,
  priceIcon,
  closeIcon,
  mailIcon,
  facebookIconone,
  googleIcon,
  contactIcon,
  datePicker,
  backIcon,
  incorrectIcon,
  phoneIcon,
  downarrowIcon,

  restingIcon,
  plusfillicon,
  plusminusicon,
  downarrowicon,
  uploadicon,
  mastercardicon,
  UploadIcon2,
  addpeople,
  blackoutlineheartIcon,
  down_arrowIcon,
  sidemenuIcon,
  editIcon,
  reviewstarIcon,
  protectcheck,
  roundedbookingIcon,
  likeblockoutlineIcon,
  colordateIcon,
  questionIcon,
  colorprofileIcon,
  calenderIcon1,
  car1,
  userDefault,
  archive,
  starIcon,
  shieldIcon,
  contactless,
  instantbooking,
  greycalendar,
  ProfileGrey,
  ColortimeIcon,
  circlecloseIcon,
  trip
};

export default images;
