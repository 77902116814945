import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import images from "../../assets/js/images";
import { PaymentMethodService } from "../../Services/PaymentMethodService";
import { useState } from "react";
import { useEffect } from "react";

const DataForm = () => {
  const paymentMethods = {
    mastercard: images.mastercardicon,
    cartes_bancaires: images.cartesicon,
    diners: images.diners,
    discover: images.discover,
    jcb: images.jcb,
    visa: images.visa,
    unionpay: images.unionpay,
    amex: images.amexicon,
  };
  const [paymentMethod, setPaymentMethod] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentMethodService.paymentMethods().then((response) => {
      setPaymentMethod(response.data.data);
      setLoading(false);
    });
  }, []);
  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Card holder
        </Typography>
        <Box className="small-service-heading ">
          {loading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: 4 }} height={45} />
          ) : (
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="No Data"
                className="outline-input textfield-rouded-md input-text-style small-radius"
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
                value={paymentMethod?.card_holder}
                InputProps={{ readOnly: true }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Credit card number
        </Typography>
        <Box className="small-service-heading ">
          {loading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: 4 }} height={45} />
          ) : (
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                type="text"
                className="outline-input textfield-rouded-md input-text-style small-radius"
                value={
                  paymentMethod?.id
                    ? "**** **** **** " + paymentMethod?.last_four
                    : "No Data"
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        "@media (max-width:1023.98px)": {
                          paddingRight: "0px",
                        },
                      }}
                    >
                      <Link
                        href="#"
                        sx={{
                          paddingRight: "6px",
                          color: "#B1B1B1",
                          "&:hover": {
                            color: "#B1B1B1",
                          },
                        }}
                      >
                        <img
                          src={
                            paymentMethod?.card?.brand
                              ? paymentMethods[paymentMethod?.card?.brand]
                              : paymentMethods["visa"]
                          }
                          alt="card"
                        />
                      </Link>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Expiration month and year
        </Typography>
        <Box className="small-service-heading ">
          {loading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: 4 }} height={45} />
          ) : (
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="No Data"
                value={paymentMethod?.expiration_date}
                InputProps={{ readOnly: true }}
                className="outline-input textfield-rouded-md input-text-style small-radius"
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          CVC
        </Typography>
        <Box className="small-service-heading ">
          {loading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: 4 }} height={45} />
          ) : (
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                type="text"
                InputProps={{ readOnly: true }}
                value={paymentMethod?.expiration_date ? "***" : "No Data"}
                className="outline-input textfield-rouded-md input-text-style small-radius"
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Billing Address
        </Typography>
        <Box className="small-service-heading ">
          {loading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: 4 }} height={45} />
          ) : (
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="No billing address data"
                className="outline-input textfield-rouded-md input-text-style small-radius"
                InputProps={{
                  sx: {
                    "input::placeholder": {
                      color: "#757575",
                    },
                  },
                }}
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
                value={paymentMethod?.billing_address}
                InputProps={{ readOnly: true }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>
    </>
  );
};
export default DataForm;
