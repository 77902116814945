import { useEffect, useState } from "react";
import { ReviewsService } from "../../Services/ReviewsService";
import { useHistory, useParams } from "react-router-dom";
import MakeToast from "../../Helpers/MakeToast";
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ReviewsContext } from "../../Context/ReviewsContext";
import LoadingReview from "../../Components/HostPublicProfile/LoadingReview";
import ReviewCard from "../../Components/HostPublicProfile/ReviewCard";
import CreateButton from "../../Components/HostPublicProfile/Create/Button";

const HostPublicReviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [hostProfile, setHostProfile] = useState([]);
  const params = useParams();
  const getBookings = () => {
    setBookings([]);
    ReviewsService.bookings().then((response) =>
      setBookings(response.data.data)
    );
  };
  const getReviews = async () => {
    await ReviewsService.hostReviews(params.id)
      .then((response) => {
        setReviews(response.data.data.reviews);
        setHostProfile(response.data.data.host_profile);
      })
      .catch((error) => MakeToast("error", error));
    setLoading(false);
  };
  useEffect(() => {
    getReviews();
  }, []);
  const navigate = useHistory();

  return (
    <ReviewsContext.Provider
      value={{
        loading: loading,
        reviews: reviews,
        getReviews: getReviews,
        getBookings: getBookings,
        bookings: bookings,
      }}
    >
      <Container sx={{ py: 5 }}>
        <BreadCrumb data={[["Host", `host/${hostProfile.slug}`], ["Reviews"]]} />
        <Typography
          sx={{ fontWeight: "500", marginTop: "20px !important" }}
          component="h1"
        >
          Reviews
        </Typography>
        <Box sx={{ padding: "0px !important", marginTop: "30px !important" }}>
          {loading ? (
            <LoadingReview />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h3" sx={{ fontWeight: "600" }}>
                  {hostProfile.company_name}'s Reviews
                </Typography>
                <CreateButton />
              </Box>

              {reviews.map((review, index) => (
                <ReviewCard key={index} review={review} />
              ))}
            </>
          )}
        </Box>
      </Container>
    </ReviewsContext.Provider>
  );
};

export default HostPublicReviews;
