import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import {
  FormContext,
  PayoutMethodContext,
} from "../../../Context/PaymentsContext";
import Form from "./Form";
import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFormik } from "formik";
import ValidationSchema from "./ValidationSchema";
import InitialValues from "./InitialValues";
import { PayoutMethodService } from "../../../Services/PayoutMethodService";
import MakeToast from "../../../Helpers/MakeToast";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const FastPay = (props) => {
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
  );
  return (
    <Elements stripe={stripePromise}>
      <FastPayChild {...props} />
    </Elements>
  );
};

const FastPayChild = () => {
  const { setPageIndex } = useContext(PayoutMethodContext);
  const navigate = useHistory();
  const { user } = useSelector((state) => state.userReducers.data);
  const elements = useElements();
  const stripe = useStripe();
  const handleSubmit = async (values) => {
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: user?.name,
        email: user?.email ?? null,
        phone: user?.phone ?? null,
      },
    });

    if (result.error) {
      formik.setSubmitting(false);
      MakeToast("error", result.error?.message);
      return;
    }
    await PayoutMethodService.store({
      paid_type: "FASTPAY",
      info: { ...values, stripe_payout_method_id: result.paymentMethod.id },
    })
      .then((response) => {
        MakeToast("success", response);
        navigate.push("/account-settings/payments-payouts");
      })
      .catch((error) => MakeToast("error", error));
    formik.setSubmitting(false);
  };
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  return (
    <FormContext.Provider
      value={{
        formik: formik,
      }}
    >
      <Container
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
          px: "20% !important",
          my: 9,
          py: 6,
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "500" }} component="h2">
          Add debit card info
        </Typography>
        <Typography sx={{ fontWeight: "light" }} component="h4">
          Most debit Visa and Mastercards will work, including reloadable cards.
          Learn more
        </Typography>
        <Form />
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "white",
          px: 3,
        }}
      >
        <Link
          sx={{ mt: "0px !important", fontSize: 17, fontWeight: "600" }}
          className="link-update"
          onClick={() => setPageIndex(0)}
        >
          Back
        </Link>
        <Button
          type="button"
          sx={{
            border: "1px solid black !important",
            backgroundColor: "black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          className="btn-primary submit-btn "
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </FormContext.Provider>
  );
};

export default FastPay;
