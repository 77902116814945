import React, { useState } from "react";
import { Badge } from "@mui/material";
import MakeToast from "../../Helpers/MakeToast";
import { useLocation } from "react-router-dom";

import images from "../../assets/js/images";
import { FavoriteService } from "../../Services/FavoriteService";
import { FavoritesContext } from "../../Context/FavoritesContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";

const FavoriteButton = (props) => {
  const {
    isHost,
    elementData,
    elementData: { id },
    isFavorite,
  } = props;
  const { pathname } = useLocation();
  const favoriteItemsUpdate = pathname.includes("favorites")
    ? useContext(FavoritesContext).getData
    : null;
  const updateCategoryQty = pathname.includes("favorites")
    ? useContext(FavoritesContext).updateCategoryQty
    : null;

  const [favoriteStatus, setFavoriteStatus] = useState(
    pathname.includes("favorites") ? true : isFavorite
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFavorites = (event) => {
    event.preventDefault();
    setIsLoading(true);
    let method = FavoriteService[isHost ? "addHost" : "addBeachGear"];
    if (favoriteStatus)
      method = FavoriteService[isHost ? "removeHost" : "removeBeachGear"];
    method(id)
      .then((response) => {
        setIsLoading(false);
        if (pathname.includes("favorites")) {
          favoriteItemsUpdate();
          updateCategoryQty(elementData);
        } else setFavoriteStatus(!favoriteStatus);
        MakeToast("success", response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({
            type: "OPEN_AUTH_MODAL",
          });
        }
        setIsLoading(false);
      });
  };

  return (
    <Badge
      onClick={(e) => handleFavorites(e)}
      className={
        "featuring-badge value-button cursor-pointer" +
        (isLoading && " opacity-40 pe-none")
      }
    >
      {favoriteStatus ? (
        <img src={images.heartIcon} alt="whislisted" class="fav-image"/>
      ) : (
        <img src={images.heartOutlinedIcon} alt="wishlist" class="fav-image"/>
      )}
    </Badge>
  );
};

export default FavoriteButton;
