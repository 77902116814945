import { APIService } from "./APIService";

export const ProfileService = {
  profile,
  hostProfile,
  store,
  hostStore,
  updateProfileImage,
  hostUpdateProfileImage,
  accountDelete,
  hostAccountDelete
};

function profile(id) {
  return APIService.get(`api/profile/${id}`);
}
function hostProfile(id) {
  return APIService.get(`api/host_profile/${id}`);
}
function store(data) {
  return APIService.post("api/profile/store", data);
}
function hostStore(data) {
  return APIService.post("api/host_profile/store", data);
}
function updateProfileImage(data) {
  return APIService.post("api/profile/update_profile_image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
function hostUpdateProfileImage(data) {
  return APIService.post("api/host_profile/update_profile_image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function accountDelete() {
  return APIService.post("api/profile/account_delete", {});
}

function hostAccountDelete() {
  return APIService.post("api/host_profile/account_delete", {});
}