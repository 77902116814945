const ActivityAndPolicies = (data) => {
  const titleData = {
    title: "Account activity and policies",
    description:
      "Confirm your booking and account activity, and learn about important Beachify policies.",
  };

  const optionsData = [
    {
      label: "Account activity",
      description:
        "Stay on top of your reservations, account activity, and legal info, like our Terms of Service.",
      field: "activity",
      options: data["activity"],
    },
    {
      label: "Listing activity",
      description: "Stay organized with notifications about all your bookings.",
      field: "listingactivity",
      options: data["listingactivity"],
    },
    {
      label: "Guest policies",
      description: "Keep up to date on important info about using Beachify.",
      field: "guestpolicies",
      options: data["guestpolicies"],
    },
    {
      label: "Host policies",
      description:
        "Get updates about changes to Beachify rules and policies, plus tax and regulatory updates.",
      field: "hostpolicies",
      options: data["hostpolicies"],
    },
  ];
  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};

export default ActivityAndPolicies;
