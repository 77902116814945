import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MakeToast from "../../Helpers/MakeToast";
import { LoginAndSecurityService } from "../../Services/LoginAndSecurityService";
import DeviceItem from "./DeviceItem";
import LoadingDeviceHistory from "./Loading/DeviceHistory";
const devicesData = [
  {
    so: "Windows 10.0",
    browser: "Chrome",
    isCurrent: true,
    location: "Oxford, Michigan",
    date: "February 6, 2023 at 10:24",
    isLogOut: false,
    isMobile: false,
    handleClick: () => {
      // TODO: Log out session
    },
  },
  {
    so: "Mobile Device",
    browser: "Beachify",
    isCurrent: false,
    location: "Oxford, Michigan",
    date: "February 6, 2023 at 10:24",
    isLogOut: true,
    isMobile: true,
    handleClick: () => {
      // TODO: Log out session
    },
  },
];

const DeviceHistory = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    LoginAndSecurityService.deviceHistory()
      .then((response) => {
        setDevices(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  }, []);
  return (
    <Box sx={{ mt: 5 }}>
  <Box className="become-host-step-form">
        <Box classname="small-heading">
        <Typography sx={{  mb: 3 }} component="h2" className="main-heading">
        Device history
      </Typography>
  </Box>
</Box>
      {loading ? (
        <LoadingDeviceHistory />
      ) : devices.length > 0 ? (
        devices.map((deviceData, index) => (
          <DeviceItem key={index} {...deviceData} />
        ))
      ) : (
        <Typography component="h4">No data found</Typography>
      )}
    </Box>
  );
};

export default DeviceHistory;
