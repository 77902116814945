import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";

const SignDate = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500", mb: 2 }} component="h4">
        Sign and date
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        Under penalties of perjury, I certify that:
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        1. The number shown on this form is my correct taxpayer identification
        number (or I am waiting for a number to be issued to me); and
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        2. I am not subject to backup withholding because: (a) I am exempt from
        backup withholding, or (b) I have not been notified by the Internal
        Revenue Service (IRS) that I am subject to backup withholding as a
        result of a failure to report all interest or dividends, or (c) the IRS
        has notified me that I am no longer subject to backup withholding; and
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        3. I am a US citizen or other US person; and
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        4. The FATCA code(s) entered on this form (if any) indicating that I am
        exempt from FATCA reporting is correct.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        The Internal Revenue Service does not require your consent to any
        provision of this document other than the certifications required to
        avoid backup withholding.
      </Typography>

      <TextField
        placeholder="Your signature"
        name="signature"
        value={formik.values.signature}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.signature && formik.touched.signature && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.signature}
        </FormHelperText>
      )}
      <TextField
        label="Date"
        value={new Date().toLocaleDateString("en-US")}
        disabled={true}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <Typography sx={{ color: "#9b9a9a" }}>
        Typing your name acts as your signature and certifies that you have
        capacity to sign for the taxpayer identified on this form.
      </Typography>
    </Box>
  );
};

export default SignDate;
