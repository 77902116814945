import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import MakeToast from "../../../Helpers/MakeToast";
import { LoginAndSecurityService } from "../../../Services/LoginAndSecurityService";
import OtpEmail from "../Otp/Email";
const Email = ({ setShowEmail }) => {
  const [openOtp, setOtpOpen] = useState(false);
  const [email, setEmail] = useState("");

  const formSubmit = (values, actions) => {
    setEmail(values.email);
    LoginAndSecurityService.emailGenerateOtp(values)
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
        actions.resetForm({ email: "", confirm_email: "" });
      })
      .catch((error) => {
        formik.setSubmitting(false);
        actions.resetForm({ email: "", confirm_email: "" });
        MakeToast("error", error);
      });
  };

  let informationValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    confirm_email: yup
      .string()
      .email("Please enter a valid email")
      .oneOf([yup.ref("email"), null], "Emails must match")
      .required("Confirm email is required"),
  });

  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { email: "", confirm_email: "" },
    enableReinitialize: true,
    onSubmit: formSubmit,
  });

  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      {openOtp && (
        <OtpEmail
          setShowEmail={setShowEmail}
          openOtp={openOtp}
          setOtpOpen={setOtpOpen}
          handleClose={() => setOtpOpen(false)}
          email={email}
        />
      )}
      <Box sx={{ mb: 2 }}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.email && touched.email && errors.email && (
          <FormHelperText className="field-helper-text error">
            {errors.email && touched.email && errors.email}
          </FormHelperText>
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Confirm email"
            name="confirm_email"
            value={values.confirm_email}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.confirm_email &&
          touched.confirm_email &&
          errors.confirm_email && (
            <FormHelperText className="field-helper-text error">
              {errors.confirm_email &&
                touched.confirm_email &&
                errors.confirm_email}
            </FormHelperText>
          )}
      </Box>
      <Button
        sx={{ my: 1.5 }}
        size="small"
        type="button"
        disableRipple
        disableElevation
        className="btn-primary submit-btn rounded-button"
        disabled={formik.isSubmitting}
        onClick={(e) => formik.handleSubmit()}
      >
        {formik.isSubmitting ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
};

export default Email;
