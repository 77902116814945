import { APIService } from "./APIService";
import deviceSessionData from "../Helpers/DeviceSession";
export const AuthenticationService = {
  login,
  verifyOtp,
  register,
  hostsRegister,
  resentEmail,
  loginWithSocial,
};

function login(data) {
  return APIService.post("api/login", data);
}

function register(data) {
  return APIService.post("api/register", data);
}

function hostsRegister(data) {
  return APIService.post("api/hosts/register", data);
}

function resentEmail(data) {
  return APIService.post("api/resend-email", data);
}

function verifyOtp(data) {
  let dataWithSession = {
    ...data,
    ...deviceSessionData,
  };
  return APIService.post("api/verify-otp", dataWithSession);
}

function loginWithSocial(data) {
  let dataWithSession = {
    ...data,
    ...deviceSessionData,
  };
  return APIService.post("api/login-with-social", dataWithSession);
}
