import { Box, Grid, Link, Rating, Typography } from "@mui/material";
import images from "../../assets/js/images";
import { useState } from "react";
import EditModal from "./Edit/Modal";
import { useSelector } from "react-redux";

const ReviewCard = ({ review }) => {
  const [showEdit, setShowEdit] = useState(false);
  const { user } = useSelector((state) => state.userReducers.data);

  return (
    <>
      <Box
        sx={{
          borderBottom: "solid 1px #EBEBEB !important",
        }}
      >
        <Grid
          sx={{
            paddingBottom: "15px",
            paddingTop: "15px",
          }}
          container
        >
          <Grid xs={12} md={1}>
            <img
              className="reviews-user-image"
              src={review?.author?.image ?? images.userDefault}
            />
          </Grid>
          <Grid xs={12} md={11}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{review?.author?.name}</Typography>
              <Typography sx={{ fontSize: 10, marginLeft: 1 }}>
                {review.date}
              </Typography>
            </Box>
            <Rating name="read-only" value={review?.score} readOnly />
            <Typography>{review.review}</Typography>
            {user.id == review.author.id && (
              <Link
                onClick={() => setShowEdit(true)}
                className="link-update"
                sx={{
                  fontSize: 14,
                  mt: "0px !important",
                }}
              >
                Edit
              </Link>
            )}
          </Grid>
        </Grid>
        {review?.answer && (
          <Grid
            sx={{
              borderBottom: "solid 1px #EBEBEB !important",
              paddingBottom: "15px",
              paddingTop: "15px",
              pl: 6,
            }}
            container
          >
            <Grid xs={12} md={1}>
              <img
                className="reviews-user-image"
                src={review?.host?.image ?? images.userDefault}
              />
            </Grid>
            <Grid xs={12} md={11}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{review?.host?.name}</Typography>
                <Typography sx={{ fontSize: 10, marginLeft: 1 }}>
                  {review?.answer?.date}
                </Typography>
              </Box>
              <Typography>{review?.answer?.answer}</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
      <EditModal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        review={review}
      />
    </>
  );
};
export default ReviewCard;
