import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import SelectInput from "../../Components/Inputs/SelectInput";
import MoreInfoModal from "../../Components/AddPayments/MoreInfoModal";
import images from "../../assets/js/images";
import { useContext } from "react";
import { PayoutMethodContext } from "../../Context/PaymentsContext";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentIcon from "@mui/icons-material/Payment";

const RadioItem = (props) => {
  const { option } = props;

  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == option.id;
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={option.id}
      control={<Radio />}
      labelPlacement="start"
      label={
        <Grid spacing={3} container>
          <Grid item>{option.icon}</Grid>
          <Grid item>
            <Typography
              sx={{ fontSize: 16, color: "black", fontWeight: "600" }}
            >
              {option.name}
            </Typography>
            {option.list.map((item) => (
              <>
                <Typography
                  sx={{ fontSize: 14, color: "black", fontWeight: "light" }}
                >
                  <strong>•</strong> {item}
                </Typography>
              </>
            ))}
          </Grid>
        </Grid>
      }
      sx={{
        justifyContent: "space-between !important",
        fontSize: 19,
        mx: "0px !important",
        px: 2,
        py: 2,
        my: 0.7,
        border: !checked ? "solid 2px #ebebeb" : "solid 2px #000",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          pl: 1,
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
};

const PaymentMethod = () => {
  const types = [
    {
      id: 1,
      name: "Fast Pay",
      icon: <PaymentIcon sx={{ fontSize: 10 }} />,
      list: [
        "Visa or Mastercard debit required",
        "30 minutes or less",
        "1.5% fee (maximum $15)",
      ],
    },
    {
      id: 2,
      name: "Bank account",
      icon: <AccountBalanceIcon sx={{ fontSize: 10 }} />,
      list: ["3-5 business days", "No fees"],
    },
  ];
  const history = useHistory();
  const { setPageIndex } = useContext(PayoutMethodContext);
  const [selectedType, setSelectedType] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Container
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
          px: "20% !important",
          my: 9,
          py: 6,
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "500" }} component="h2">
          Let's add a payout method
        </Typography>
        <Typography sx={{ fontWeight: "light" }} component="h4">
          To start, let us know where you'd like us to send your money.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontWeight: "500" }} component="h4">
            Billing Country / Region
          </Typography>
          <FormControl
            variant="standard"
            className="input-style outlined-input select-style textfield-rouded-md"
            sx={{
              width: "100%",
              borderRadius: "18px",
              mb: 1,
              mt: 1,
              pl: 4,
              mr: 4,
            }}
          >
            <SelectInput
              sx={{ pointerEvents: "none" }}
              className="opacity-40"
              renderValue={(selected) => {
                return <em>United States</em>;
              }}
            />
          </FormControl>
          <Typography sx={{ color: "#9b9a9a" }}>
            This is where you opened your financial account.
            <Link
              sx={{
                display: "initial !important",
                fontWeight: "500",
                marginLeft:"5px"
              }}
              className="link-update"
              onClick={() => setOpenModal(true)}
            >
              More info
            </Link>
          </Typography>
          <MoreInfoModal open={openModal} onClose={() => setOpenModal(false)} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontWeight: "500",textTransform:'capitalize' }} component="h4">
            How Would You Like To Get Paid?
          </Typography>
          <Typography sx={{ color: "#9b9a9a",textTransform:'capitalize' }}>
            Payouts will be sent in USD.
          </Typography>
          <RadioGroup
            value={selectedType}
            onChange={(event) => setSelectedType(event.target.value)}
          >
            {types.map((data, index) => (
              <RadioItem key={index} option={data} />
            ))}
          </RadioGroup>
          <Typography sx={{ color: "#9b9a9a" }}>
            Payouts take longer for some banks, and reviews could result in
            holds or delays. Learn more
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "white",
          px: 3,
        }}
      >
        <Button
          type="button"
          sx={{
            border: "1px solid black !important",
            backgroundColor: "black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          className="btn-primary submit-btn "
          disabled={!selectedType}
          onClick={() => setPageIndex(selectedType)}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

export default PaymentMethod;
