import { Box, Container, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PayoutMethodContext } from "../../Context/PaymentsContext";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import PaymentMethod from "./PayoutMethod";
import FastPay from "../../Components/AddPayments/Fastpay/FastPay";
import BankAccount from "../../Components/AddPayments/BankAccount/BankAccount";
import { CountryService } from "../../Services/CountryService";
import { useEffect } from "react";

const AddPayoutMethod = () => {
  useEffect(() => {
    getCountries();
  }, []);
  const history = useHistory();
  const [pageIndex, setPageIndex] = useState(0);
  const [countries, setCountries] = useState({});
  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <PayoutMethodContext.Provider
      value={{
        pageIndex: pageIndex,
        setPageIndex: setPageIndex,
        countries: countries,
      }}
    >
      <Container
        sx={{
          height: "100% !important",
          width: "100% !important",
          maxWidth: "100% !important",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: "1",
          padding: "0px !important",
          overflowY: "clip",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75px",
            backgroundColor: "white",
            borderBottom: "solid 1px #ebebeb",
            position: "fixed",
            width: "100%",
            top: 0,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
            onClick={() => {
              history.push("/account-settings/payments-payouts");
            }}
          >
            <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
          </IconButton>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Set up payouts
          </Typography>
        </Box>
        {pageIndex == 0 && <PaymentMethod />}
        {pageIndex == 1 && <FastPay />}
        {pageIndex == 2 && <BankAccount />}
      </Container>
    </PayoutMethodContext.Provider>
  );
};

export default AddPayoutMethod;
