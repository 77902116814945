import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";

const SignDate = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500", mb: 2 }} component="h4">
        Sign and date Form W-8BEN
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        Under penalties of perjury, I declare that I have examined the
        information on this form and to the best of my knowledge and believe it
        is true, correct, and complete. I further certify under penalties of
        perjury that:
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The person named on line 1 of this form is not a US person,
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The income to which this form relates is:
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        a. not effectively connected with the conduct of a trade or business in
        the United States,
        <br />
        b. effectively connected but is not subject to tax under an applicable
        income tax treaty, or
        <br />
        c. the partner's share of a partnership's effectively connected income,
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The person named on line 1 of this form is a resident of the treaty
        country listed on line 9 of the form (if any) within the meaning of the
        income tax treaty between the United States and that country, and
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • For broker transactions or barter exchanges, the beneficial owner is
        an exempt foreign person as defined in the instructions.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        Furthermore, I authorize this form to be provided to any withholding
        agent that has control, receipt, or custody of the income of which I am
        the beneficial owner or any withholding agent that can disburse or make
        payments of the income of which I am the beneficial owner. I agree that
        I will submit a new form within 30 days if any certification made on
        this form becomes incorrect.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        The Internal Revenue Service does not require your consent to any
        provisions of this document other than the certifications required to
        establish your status as a non-US individual and, if applicable, obtain
        a reduced rate of withholding.
      </Typography>

      <TextField
        placeholder="Your signature"
        name="signature"
        value={formik.values.signature}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.signature && formik.touched.signature && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.signature}
        </FormHelperText>
      )}
      <TextField
        label="Date"
        value={new Date().toLocaleDateString("en-US")}
        disabled={true}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <Typography sx={{ color: "#9b9a9a" }}>
        Typing your name acts as your signature and certifies that you have
        capacity to sign for the taxpayer identified on this form.
      </Typography>
    </Box>
  );
};

export default SignDate;
