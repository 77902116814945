import { useContext } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { FormContext, TaxFormContext } from "../../../../../Context/TaxContext";
import SelectInput from "../../../../Inputs/SelectInput";

const Address = () => {
  const { formik } = useContext(FormContext);
  const { countries } = useContext(TaxFormContext);
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Address
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        {countries?.length > 0 ? (
          <SelectInput
            customArr={{ data: countries, name: "name", id: "code" }}
            name="address.country"
            value={formik.values.address.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (formik.values.address.country == undefined ||
                  formik.values.address.country == "")
              ) {
                return <em>Country</em>;
              }

              let countryData = countries.find(
                (o) => o.code == formik.values.address.country
              );
              return countryData ? countryData.name : "";
            }}
          />
        ) : (
          <SelectInput
            className="opacity-40"
            renderValue={(selected) => {
              return <em>Country</em>;
            }}
          />
        )}
      </FormControl>
      {formik.errors.address?.country && formik.touched.address?.country && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.address?.country}
        </FormHelperText>
      )}
      <TextField
        placeholder="Street address"
        name="address.street"
        value={formik.values.address.street}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "18px 18px 0px 0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="Apt, suite, etc. (if applicable)"
        name="address.apt_suite"
        value={formik.values.address.apt_suite}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",

            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="City"
        name="address.city"
        value={formik.values.address.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="State/territory"
        name="address.state"
        value={formik.values.address.state}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="ZIP code"
        name="address.zip"
        value={formik.values.address.zip}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input  input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px 0px 18px 18px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter an address for the taxpayer or business identified on this form.
      </Typography>
      {formik.errors.address?.street && formik.touched.address?.street && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.address?.street}
        </FormHelperText>
      )}
      {formik.errors.address?.apt_suite &&
        formik.touched.address?.apt_suite && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.address?.apt_suite}
          </FormHelperText>
        )}
      {formik.errors.address?.city && formik.touched.address?.city && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.address?.city}
        </FormHelperText>
      )}
      {formik.errors.address?.state && formik.touched.address?.state && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.address?.state}
        </FormHelperText>
      )}
      {formik.errors.address?.zip && formik.touched.address?.zip && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.address?.zip}
        </FormHelperText>
      )}
    </Box>
  );
};

export default Address;
