import React, { useEffect, useState } from "react";

import {
    Box,
    Container,
    Link,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    ListItemIcon,
    Card,

} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import images from "../../assets/js/images";
import { useTheme } from '@mui/material/styles';
// ---slider-----//
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import BeachGear from "../../Components/Home/BeachGear";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const sliderimages = [
    {
        imgPath:
            'https://beachify.webwizardsusa.com/assets/images/popular-beachgear2.png',
    },
    {
        imgPath:
        'https://beachify.webwizardsusa.com/assets/images/popular-beachgear2.png',
    },
    {
        imgPath:
            'https://beachify.webwizardsusa.com/assets/images/popular-beachgear1.png',
    },
    {
        imgPath:
            'https://beachify.webwizardsusa.com/assets/images/popular-beachgear3.png',
    },
];






const BeachDetail = () => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };








    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const [popularBGDatas, setPopularBGDatas] = useState({});
    const [popularCategories, setPopularCategories] = useState([]);
    const [nearByBeaches, setNearByBeaches] = useState({});
    const [previousRented, setPreviouslyRented] = useState({});
    const [previousViewed, setPreviouslyViewed] = useState({});

    useEffect(() => {
        getHomeData();
    }, []);

    const getHomeData = () => {
        setPopularBGDatas({
            title: 'Popular beach gear',
            viewAllLink: '#',
            popularGears: [
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: false,
                    featureImg: '',
                    cardAction: {
                        title: 'Umbrella',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        dateTime: 'Mon - Fri, 8am - 7pm',
                        pickupDrop: 'Drop-off',
                        bookignType: 'Contactless booking',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Surfboard',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        dateTime: 'Mon - Fri, 8am - 7pm',
                        pickupDrop: 'Drop-off',
                        bookignType: 'Contactless booking',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: false,
                    featureImg: '',
                    cardAction: {
                        title: 'Chair',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        dateTime: 'Mon - Fri, 8am - 7pm',
                        pickupDrop: 'Drop-off',
                        bookignType: 'Contactless booking',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear4,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear1,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Umbrella',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        dateTime: 'Mon - Fri, 8am - 7pm',
                        pickupDrop: 'Drop-off',
                        bookignType: 'Contactless booking',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: false,
                    featureImg: '',
                    cardAction: {
                        title: 'Chair',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        dateTime: 'Mon - Fri, 8am - 7pm',
                        pickupDrop: 'Drop-off',
                        bookignType: 'Contactless booking',
                        amount: '10'
                    }
                },
            ]
        });
        setPopularCategories([
            { img: images.category1, title: 'Badminton' },
            { img: images.category2, title: 'Beach Chair' },
            { img: images.category3, title: 'Umbrella' },
            { img: images.category4, title: 'Kayak (1 Person)' },
            { img: images.category5, title: 'Boogie board' },
            { img: images.category1, title: 'Life Vest (Child)' },
            { img: images.category2, title: 'Beach Chair' },
            { img: images.category3, title: 'Umbrella' },
            { img: images.category4, title: 'Kayak (1 Person)' },
            { img: images.category5, title: 'Boogie board' },
            { img: images.category1, title: 'Life Vest (Child)' },
            { img: images.category2, title: 'Badminton' },
        ]);
        setNearByBeaches({
            title: 'All beaches nearby',
            viewAllLink: '#',
            beaches: [
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches5,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches4,
                        },
                    ],
                    title: 'Virginia Key',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches4,
                        },
                    ],
                    title: 'Dewey Beach',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches1,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches4,
                        },
                    ],
                    title: 'Rehoboth Beach',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches1,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches4,
                        },
                    ],
                    title: 'Virginia Beach',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches4,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches1,
                        },
                    ],
                    title: 'Cape May',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.allBeaches5,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.allBeaches2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.allBeaches3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.allBeaches4,
                        },
                    ],
                    title: 'Virginia Key',
                    freePlaces: 432,
                    distance: '176m from you',
                },
            ]
        });
        setPreviouslyRented({
            title: 'Previously rented',
            viewAllLink: '#',
            previousRents: [
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: false,
                    featureImg: '',
                    cardAction: {
                        title: 'Umbrella',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: true,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Chair',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear1,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Beach chair',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear4,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear1,
                        },
                    ],
                    isInstanceBooking: true,
                    isContactlessBooking: false,
                    featureImg: '',
                    cardAction: {
                        title: 'Umbrella',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear5,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Surfboard',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.beachGear4,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.beachGear2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.beachGear3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.beachGear4,
                        },
                    ],
                    isInstanceBooking: false,
                    isContactlessBooking: true,
                    featureImg: '',
                    cardAction: {
                        title: 'Chair',
                        location: 'Virginia Key',
                        distance: '176m from you',
                        amount: '10'
                    }
                },
            ]
        });
        setPreviouslyViewed({
            title: 'Previously viewed',
            viewAllLink: '#',
            beaches: [
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed1,
                        },
                    ],
                    title: 'Hermosa Beach',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed1,
                        },
                    ],
                    title: 'Pismo Beach',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed2,
                        },
                    ],
                    title: 'Chair',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed2,
                        },
                    ],
                    title: 'Umbrella',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed1,
                        },
                    ],
                    title: 'Surfboard',
                    freePlaces: 432,
                    distance: '176m from you',
                },
                {
                    imagesData: [
                        {
                            label: 'San Francisco – Oakland Bay Bridge, United States',
                            imgPath: images.prevViewed3,
                        },
                        {
                            label: 'Bird',
                            imgPath: images.prevViewed2,
                        },
                        {
                            label: 'Bali, Indonesia',
                            imgPath: images.prevViewed1,
                        },
                        {
                            label: 'Goč, Serbia',
                            imgPath: images.prevViewed2,
                        },
                    ],
                    title: 'Virginia Key',
                    freePlaces: 432,
                    distance: '176m from you',
                },
            ]
        });
    }

    return (
        <>
            <section className="service-listing beach-detail section-padding section-border inner-section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* <Grid item xs={12}>
                            <div className="service-top-header">
                                <div className="left-back-arrow ">
                                    <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        className=" tertiary-text"
                                        color="inherit"
                                    >
                                        <img src={images.backIcon} alt=""
                                        />
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </Grid> */}

                        <Grid xs={12} className='main-heading-bottom' sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom:'0 !important',
                        }}>
                            <div className="main-heading-block beach-detail-heading">
                                <h2 className="main-heading">Virginia Key
                                </h2>
                                <List className='feature-listing'>
                                    <ListItem >
                                        <ListItemIcon className="feature-listing-icon">
                                            <img src={images.distanceIcon} alt="distance" />
                                        </ListItemIcon>
                                        <ListItemText
                                            className="feature-list-name" primary="176m from you"
                                        />
                                    </ListItem>

                                </List>

                            </div>
                            <Box className='uplad-icon-two-img'>
                                <Link href="#">
                                    <img src={images.UploadIcon2} alt="upload2" />
                                </Link>

                            </Box>



                        </Grid>
                        <Grid xs={12}>
                            <Card className="border-0 shadow-none vertical-card card-info beach-detail-slider">
                                <div className="card-media-item bg-white border-radius-12">
                                    <div className="card-media-block">
                                        <Box sx={{ flexGrow: 1 }} className="card-slider">
                                            <AutoPlaySwipeableViews
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {sliderimages.map((step, index) => (
                                                    <div key={step.label}>
                                                        {Math.abs(activeStep - index) <= 2 ? (
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    display: 'block',
                                                                    overflow: 'hidden',
                                                                    width: '100%',
                                                                    objectFit: 'cover',
                                                                }}
                                                                src={step.imgPath}
                                                                alt={step.label}
                                                                className="border-radius-12 card-image"
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </AutoPlaySwipeableViews>
                                            <MobileStepper
                                                steps={maxSteps}
                                                position="static"
                                                activeStep={activeStep}
                                                className="stepper-dots"
                                                nextButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleNext}
                                                        disabled={activeStep === maxSteps - 1}
                                                        className="prev-btn"
                                                    >
                                                        {theme.direction === 'rtl' ? (
                                                            <img src={images.rightChevronIcon} alt="arrow" />
                                                        ) : (
                                                            <img src={images.rightChevronIcon} alt="arrow" />
                                                        )}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" className="next-btn">
                                                        {theme.direction === 'rtl' ? (
                                                            <img src={images.leftChevronIcon} alt="arrow" />
                                                        ) : (
                                                            <img src={images.leftChevronIcon} alt="arrow" />
                                                        )}
                                                    </Button>
                                                }
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                        <Grid xs={12} className='beach-detail-bottom-space'>
                            <div className="main-heading-block beach-detail-heading">
                                <h2 className="main-heading">Beach Gear
                                </h2>
                            </div>

                            <div className="grid-5">
                                {popularBGDatas?.popularGears?.length && popularBGDatas.popularGears.map(gData => <BeachGear beachgear={gData} />)}
                            </div>

                        </Grid>
                        <Grid xs={12} className='show-more'>
                            <Button
                                size="small"
                                className="grey-btn"
                            >
                                Show more
                                <img src={images.downarrowicon} alt="downarrow" />
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="about-beach section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid xs={12} sm={8} md={8} lg={9}>
                            <Box className="right-space">
                                <div className="main-heading-block beach-detail-heading">
                                    <h2 className="main-heading">About beach
                                    </h2>
                                </div>

                                {/* ---- use classname 'text-truncate-7line'truncation  &use classname 'text-truncate-7line-hidden' truncation ---- */}
                                <Typography variant="h6" component="p" className="text-truncate-7line text-truncate-7line-hidden">
                                    What is now Virginia Key was the southern end of a barrier island that extended from the New River inlet in Fort Lauderdale to just north of Key Biscayne. Early accounts by Spanish explorers indicated the existence of one or more inlets somewhere on the long spit of land enclosing the northern end of Biscayne Bay, but such inlets open and close over time. At the beginning of the 19th century, there was no inlet through the barrier island between the New River Inlet and Bear Cut, at the northern end of Key Biscayne. Hurricanes in 1835 and 1838 opened a new inlet, Narrows Cut (now known as Norris Cut), separating Virginia  Key from what is now Fisher Island at the south end of Miami Beach. The island was named by Frederick H. Gerdes of the United States Coast Survey in 1849. He noted that the island north of Key Biscayne had no name, and had not existed as an island until 'Narrows Cut' had broken through "ten or twelve" years before (i.e., the hurricane of 1835 or 1838). He described Virginia Key as three miles (5 km) long and one mile (1.6 km) wide (later, as five miles (8 km) long and one-and-a-half miles wide), with a fine Atlantic beach, but mostly covered with mangroves.[4]
                                </Typography>
                                <Link href='#'>
                                    Read more
                                </Link>
                            </Box>

                        </Grid>
                        <Grid xs={12} sm={4} md={4} lg={3}>
                            <Box className="small-box beach-detail-heading">
                                <Typography variant="h6" component="h2">
                                    Virginia Key
                                </Typography>
                                <List className='feature-listing'>
                                    <ListItem >
                                        <ListItemIcon className="feature-listing-icon">
                                            <img src={images.distanceIcon} alt="distance" />
                                        </ListItemIcon>
                                        <ListItemText
                                            className="feature-list-name" primary="176m from you"
                                        />
                                    </ListItem>

                                    <ListItem >
                                        <ListItemIcon className="feature-listing-icon">
                                            <img src={images.addpeople} alt="people" />
                                        </ListItemIcon>
                                        <ListItemText
                                            className="feature-list-name" primary="432 free places"
                                        />
                                    </ListItem>

                                </List>
                                <Box className="box-contact-btn">
                                    <Button
                                        size="small"
                                        className="btn-primary border-radius-md contact-btn"
                                    >
                                        Contacts

                                    </Button>
                                    <Link href="#" >
                                        <img src={images.blackoutlineheartIcon} alt="outline" />
                                    </Link>

                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </>
    )
}

export default BeachDetail;
