import {
  Box,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#000" : "#308fe8",
  },
}));

const PendingCard = () => {
  return (
    <Card className="outlined-card">
      <CardContent sx={{ padding: 0, paddingBottom: "0px !important" }}>
        <Box sx={{ padding: 2 }}>
          <Typography fontWeight="bold" component="h4">
            example@gmail.com
          </Typography>
          <Typography component="h4">
            $20 USD after their first qualifying stay invite sent
          </Typography>
          <BorderLinearProgress
            sx={{ mt: 2 }}
            variant="determinate"
            value={25}
          />
        </Box>
        <CardActions
          sx={{ borderTop: "solid 1px #DDDDDD", padding: 2, alignItems: "end" }}
        >
          <EmojiObjectsOutlinedIcon sx={{ fontSize: 30, marginRight: 1 }} />
          <Typography fontWeight="light" component="h4">
            Waiting for them to signup for Beachify
          </Typography>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default PendingCard;
