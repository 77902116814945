import { useContext } from "react";
import {
  Box,
  FormHelperText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";

const SpecifyIncome = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3, borderBottom: "1px solid #ebebeb", pb: 6 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Specify income
      </Typography>
      <TextareaAutosize
        name="income"
        value={formik.values.income}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="input-style small-radius"
        style={{
          width: "100%",
          height: "70px",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.income && formik.touched.income && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.income}
        </FormHelperText>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        List each item of income that is received from the payer effectively
        connected with the conduct of a US trade or business. Ex: “Rental
        income”
      </Typography>
    </Box>
  );
};

export default SpecifyIncome;
