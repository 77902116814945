import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { CountryService } from "../../Services/CountryService";
import * as yup from "yup";
import SelectInput from "../Inputs/SelectInput";
import DeleteConfirmModal from "./DeleteConfirmModal";
import { useDispatch } from "react-redux";
import MakeToast from "../../Helpers/MakeToast";
import { PrivacyAndSharingSevice } from "../../Services/PrivacyAndSharingSevice";
const reasonsOptions = [
  "I'm not satisfied with my experience on Beachify",
  "I'm not confident about how Beachify treats my private data",
  "I want to delete a duplicate account",
  "I don't use Beachify enough",
  "Other",
];

const DeleteForm = () => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  let dataValidationSchema = yup.object().shape({
    country_id: yup
      .number()
      .required("Country is required")
      .typeError("Country must be a number"),
    reason: yup.string().nullable().typeError("Reason must be a text"),
  });
  const formik = useFormik({
    validationSchema: dataValidationSchema,
    initialValues: { country_id: "", reason: "" },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };

  const confirmAction = () => {
    formik.setSubmitting(true);
    PrivacyAndSharingSevice.deleteUser(values)
      .then((response) => {
        formik.setSubmitting(false);
        dispatch({
          type: "LOGOUT",
          payload: [],
        });
        MakeToast("success", response);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  const formSubmit = () => {
    formik.setSubmitting(false);
    setModalOpen(true);
  };
  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography sx={{ fontWeight: "500", marginBottom: 1 }} component="h4">
        Where do you reside?
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        {countries?.length > 0 ? (
          <SelectInput
            customArr={{ data: countries, name: "name", id: "id" }}
            name="country_id"
            value={values.country_id}
            onChange={handleChange}
            onBlur={handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (values.country_id == undefined || values.country_id == "")
              ) {
                return <em>Country</em>;
              }

              let countryData = countries.find(
                (o) => o.id == values.country_id
              );
              return countryData ? countryData.name : "";
            }}
          />
        ) : (
          <SelectInput
            className="opacity-40 pe-none"
            renderValue={(selected) => {
              return <em>Country/Region</em>;
            }}
          />
        )}
      </FormControl>
      <FormHelperText className="field-helper-text error">
        {errors.country_id && touched.country_id && errors.country_id}
      </FormHelperText>

      <Typography
        sx={{ fontWeight: "500", marginBottom: 1, marginTop: 2 }}
        component="h4"
      >
        Why are you deleting your account?
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        <SelectInput
          customArr1={reasonsOptions}
          name="reason"
          value={values.reason}
          onChange={handleChange}
          onBlur={handleBlur}
          renderValue={(selected) => {
            if (
              (selected == undefined || selected == "") &&
              (values.reason == undefined || values.reason == "")
            ) {
              return <em>Select reason (Optional)</em>;
            }

            let reasonData = reasonsOptions.find(
              (reason) => reason == values.reason
            );
            return reasonData ? reasonData : "";
          }}
        />
      </FormControl>
      <FormHelperText className="field-helper-text error">
        {errors.reason && touched.reason && errors.reason}
      </FormHelperText>
      <Grid
        sx={{
          borderTop: "solid 1px #EBEBEB",
          paddingTop: 2,
          paddingBottom: 4,
          marginTop: 5,
          alignItems: "center",
        }}
        container
      >
        <Grid xs={6} item>
          <Link
            className="black-underline-link"
            to="/account-settings/privacy-and-sharing"
          >
            Back
          </Link>
        </Grid>
        <Grid xs={6} sx={{ textAlign: "right" }} item>
          <Button
            size="small"
            type="button"
            disableRipple
            disableElevation
            className="btn-primary submit-btn rounded-button"
            onClick={(e) => formik.handleSubmit()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
      <DeleteConfirmModal
        open={modalOpen}
        loading={formik.isSubmitting}
        setLoading={formik.setSubmitting}
        setOpen={setModalOpen}
        confirmAction={confirmAction}
      />
    </Box>
  );
};

export default DeleteForm;
