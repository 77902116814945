import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useContext } from "react";
import { cloneDeep } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  outline: "none",
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
    borderRadius: 0,
    "& .listResponsive": {
      maxHeight: "80% !important",
    },
  },
}));

const LanguagesModal = (props) => {
  const { onClose } = props;
  const { languages, setLanguages } = useContext(ProfileContext);
  const checkHandler = (event, index) => {
    let newSelectedLanguages = cloneDeep(languages);
    newSelectedLanguages.userLanguages[index].checked = event.target.checked;
    let newUserLanguages = [...newSelectedLanguages.userLanguages];
    let newAvailableLanguages = [...languages.availableLanguages];
    newUserLanguages.sort();
    newAvailableLanguages.sort();
    setLanguages({
      userLanguages: newUserLanguages,
      availableLanguages: newAvailableLanguages,
    });
  };
  const CheckListItem = ({ language, index }) => {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={language.checked}
              sx={{
                "& .MuiSvgIcon-root": {
                  borderRadius: 40,
                  fontSize: 28,
                  color: "black",
                },

                paddingBottom: "0px !important",
                paddingTop: "0px !important",
              }}
            />
          }
          label={language.name}
          sx={{
            fontSize: 18,
            "& .MuiFormControlLabel-label": {
              fontWeight: "lighter",
            },
          }}
          onChange={(e) => checkHandler(e, index)}
        />
      </FormGroup>
    );
  };
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton
          sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
        </IconButton>
        <Box
          sx={{
            textAlign: "center",
            height: "10%",
            borderBottom: "solid 1px #ebebeb",
            p: 2.5,
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Languages I speak
          </Typography>
        </Box>

        <Box
          sx={{
            p: 3,
            maxHeight: "58rem",
            overflowX: "auto",
          }}
          className="listResponsive"
        >
          <Typography sx={{ fontSize: 15, mb: 1 }}>
            We have many international travelers who appreciate hosts who can
            speak their language.
          </Typography>
          {languages.userLanguages.map((language, index) => (
            <CheckListItem key={index} index={index} language={language} />
          ))}
        </Box>
        <Box
          sx={{
            height: "10%",
            borderTop: "solid 1px #ebebeb",
            p: 2.5,
          }}
        >
          <Button
            size="small"
            type="button"
            sx={{ backgroundColor: "black !important" }}
            className="btn-primary submit-btn rounded-button"
            onClick={onClose}
          >
            Done
          </Button>
        </Box>
      </ResponsiveStyles>
    </Modal>
  );
};

export default LanguagesModal;
