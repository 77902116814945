import React from 'react';
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Button,
    InputAdornment,
    Typography,
    FormControl,
    FormGroup
} from '@mui/material';
import { useDispatch } from "react-redux";

import images from '../../assets/js/images';
import AppHelper from "../../Helpers/AppHelper";
import QuestionMarkPopover from '../Common/QuestionMarkPopover';
import SelectInput from "../Inputs/SelectInput";
import CheckboxInput from "../Inputs/CheckboxInput";
import SwitchInput from '../Inputs/SwitchInput';
import SliderInput from '../Inputs/SliderInput';
import ModalPopup from './ModalPopup';

const FilterModal = ({ isOpen, setLoginOpen }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useHistory();
    const handleClose = () => setLoginOpen(false);

    const pricefilter = { 'asc': 'High to Low', 'desc': 'Low to High' };

    const [ibInfo, setIBInfo] = React.useState(null);
    const ibContent = "Listings you can book without waiting for Host approval.";

    const [priceSort, setPriceSort] = React.useState('');
    const [rangeValues, setRangeValues] = React.useState(30);
    const [instantBookingValues, setInstantBookingValues] = React.useState(0);
    const [deliveryPickupValues, setDeliveryPickupValues] = React.useState(0);

    React.useEffect(() => {
        var searchData = sessionStorage.getItem('searchItems') != null ? JSON.parse(sessionStorage.getItem('searchItems')).data : {};
        setPriceSort(searchData.price_sort);
        setRangeValues(searchData.host_proximity)
        setInstantBookingValues(searchData.is_instant_booking)
        setDeliveryPickupValues(searchData.is_delivery_pickup)
    }, [isOpen]);

    const saveSearch = (e) => {
        if (location.pathname == '/search-result') {
            handleClose(e)
            navigate.push('/search-result?search=' + AppHelper.searchRandomNumber());
            return;
        }

        navigate.push('/search-result');
    }

    return (
        <>
            <ModalPopup
                contentStyle={{ width: '750px', width1: '680px' }}
                className="modallogin"
                open={isOpen}
                onClose={handleClose}
                content={<>
                    <Box className="modalheader">
                        <Box id="modal-modal-title" className="modal-heading" component="h2" sx={{
                            marginBottom: '0',
                        }}>
                            Filters
                        </Box>
                        <Box className="modal-close-btn" onClick={() => {
                            setLoginOpen(false);
                        }}>
                            <Button size="small" disableRipple > <img src={images.closeIcon} alt="" /></Button>
                        </Box>
                    </Box>
                    <Box className="modal-body text-start" sx={{ padding: '0' }}>
                        <Box>
                            <Typography id="modal-modal-title" variant="h3" component="h2" className="modal-sub-title" sx={{
                                fontWeight: "500",
                                textAlign:'start'
                            }}>
                                Price Filter
                            </Typography>
                        </Box>
                        <Box autoComplete="off" sx={{ mx: 'auto' }}>
                            <Box
                                sx={{
                                    '& > :not(style)': { width: 300, },
                                    '@media (max-width: 575.98px)': { 
                                        width:'100% !important',
                                    }
                                }}
                                noValidate
                                autoComplete="off"
                                className='d-flex bottom-space gap-3 mb-4'
                            >
                                <FormControl variant="standard" className="input-style outlined-input select-style">
                                    <SelectInput
                                        customObj={pricefilter}
                                        startAdornment={
                                            <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 1.5, pr: 1 }}>
                                                <img src={images.priceIcon} alt="price" />
                                            </InputAdornment>
                                        }
                                        value={priceSort}
                                        renderValue={(data) => {
                                            if ((data == undefined || data == '') && (priceSort == undefined || priceSort == '')) {
                                                return <em>Filter By Price</em>;
                                            }

                                            return pricefilter[priceSort];
                                        }}
                                        onChange={(e) => {
                                            setPriceSort(e.target.value);

                                            dispatch({
                                                type: 'SET_PRICE_FILTER',
                                                payload: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <hr />
                        <Box sx={{ width: '100%' }}>
                            <FormGroup>
                                <Typography id="modal-modal-title" variant="h6" component="h2" className="modal-sub-title mt-" sx={{ display:'flex',alignItems:'center' }}>
                                    Booking Options
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={8}>
                                        <Typography className='mt-3'sx={{ textAlign:'start' }}>Host Proximity</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SliderInput
                                            min={10}
                                            max={70}
                                            defaultValue={rangeValues}
                                            marks={[
                                                {
                                                    value: 10,
                                                    label: '10 miles',
                                                },
                                                {
                                                    value: 70,
                                                    label: '70 miles',
                                                },
                                            ]}
                                            onChangeCommitted={(e, newValue) => {
                                                setRangeValues(newValue);

                                                dispatch({
                                                    type: 'SET_HOST_PROXIMITY',
                                                    payload: newValue
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6} sm={8} md={6} lg={8} sx={{ display:'flex',alignItems:'center' }}>
                                        <div className='mt-4'>
                                            <span className='p-relative'>
                                                Instant Booking
                                                <QuestionMarkPopover
                                                    anchorPopOver={ibInfo}
                                                    setAnchorPopOver={setIBInfo}
                                                    content={ibContent}
                                                    spanClassName='p-absolute'
                                                    iconStyle={{ fontSize: '22px' }}
                                                />
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid className='mt-4 modal-popup-button' item xs={6} sm={4} md={6} lg={4}  sx={{ 
                                         textAlign:'end',
                                            '@media (min-width: 576px) and (max-width: 767.98px)': { 
                                                     textAlign:'end',
                                            }
                                         }}>
                                        
                                        <SwitchInput className="filter-modal-popup-button"
                                    //    style={{ display:'flex',alignItems:'center' }}
                                            checked={instantBookingValues == 1 ? true : false}
                                            onChange={(e) => {
                                                setInstantBookingValues((e.target.checked) ? 1 : 0)
                                                dispatch({
                                                    type: 'SET_INSTANT_BOOKING',
                                                    payload: (e.target.checked) ? 1 : 0,
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6} sm={8} md={6} lg={8} sx={{ display:'flex',alignItems:'center' }}>
                                        <Typography className='mt-3'>Delivery & Pickup</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={6} lg={4} className="modal-popup-button"  sx={{ 
                                             textAlign:'end',
                                            '@media (min-width: 576px) and (max-width: 767.98px)': { 
                                                     textAlign:'end',
                                            }
                                         }}>
                                        <SwitchInput
                                            checked={deliveryPickupValues == 1 ? true : false}
                                            onChange={(e) => {
                                                setDeliveryPickupValues((e.target.checked) ? 1 : 0)
                                                dispatch({
                                                    type: 'SET_DELIVERY_PICKUP',
                                                    payload: (e.target.checked) ? 1 : 0,
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Box>
                        <hr />
                        <Box className="modalfooter filter-btn">
                            <div className='rental-search-form modal-filter-btn'>
                                <RouterLink
                                    variant="contained"
                                    className="rounded-button filter-button"
                                    href="javascript:void(0);"
                                    onClick={saveSearch}
                                >
                                    <img src={images.filterIcon} alt="filter" />
                                    <span>Filter</span>
                                </RouterLink>
                            </div>
                        </Box>
                    </Box>
                </>}
            />
        </>
    )
}

export default FilterModal;