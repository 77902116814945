import { useContext } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FormContext, TaxFormContext } from "../../../../../Context/TaxContext";
import styled from "@emotion/styled";

const USBusinessAddress = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(5),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
  }));
  const { formik } = useContext(FormContext);
  const { countries } = useContext(TaxFormContext);
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        US Business address
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        <Select
          input={<BootstrapInput />}
          sx={{ borderBottom: "none !important" }}
          value="US"
          disabled={true}
        >
          <MenuItem value="US">United States</MenuItem>
        </Select>
      </FormControl>
      <TextField
        placeholder="Street address"
        name="us_business_address.street"
        value={formik.values.us_business_address.street}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "18px 18px 0px 0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="Apt, suite, etc. (if applicable)"
        name="us_business_address.apt_suite"
        value={formik.values.us_business_address.apt_suite}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",

            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="City"
        name="us_business_address.city"
        value={formik.values.us_business_address.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="State/territory"
        name="us_business_address.state"
        value={formik.values.us_business_address.state}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input input-text-style"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <TextField
        placeholder="ZIP code"
        name="us_business_address.zip"
        value={formik.values.us_business_address.zip}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input  input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            borderRadius: "0px 0px 18px 18px !important",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.us_business_address?.street &&
        formik.touched.us_business_address?.street && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.us_business_address?.street}
          </FormHelperText>
        )}
      {formik.errors.us_business_address?.apt_suite &&
        formik.touched.us_business_address?.apt_suite && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.us_business_address?.apt_suite}
          </FormHelperText>
        )}
      {formik.errors.us_business_address?.city &&
        formik.touched.us_business_address?.city && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.us_business_address?.city}
          </FormHelperText>
        )}
      {formik.errors.us_business_address?.state &&
        formik.touched.us_business_address?.state && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.us_business_address?.state}
          </FormHelperText>
        )}
      {formik.errors.us_business_address?.zip &&
        formik.touched.us_business_address?.zip && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.us_business_address?.zip}
          </FormHelperText>
        )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter the US address where the individual or entity earns income.
        Usually this is the address of the primary US listing. Do not enter a
        P.O. box or in-care-of address.
      </Typography>
    </Box>
  );
};

export default USBusinessAddress;
