import { Box, Typography } from "@mui/material";
import images from "../../assets/js/images";
import Grid from '@mui/material/Unstable_Grid2';

const ReviewCard = ({ review, userField }) => {
  return (
    <Grid
      sx={{
        borderBottom: "solid 1px #EBEBEB !important",
        paddingBottom: "15px",
        paddingTop: "15px",
      }}
      container
    >
      <Grid xs={12} md={1}>
        <img className="reviews-user-image" src={images.userDefault} />
      </Grid>
      <Grid xs={12} md={11}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
          <Typography>{review[userField].name}</Typography>
          <Typography sx={{ fontSize: 10, marginLeft: 1 }}>
            {review.date}
          </Typography>
        </Box>
        <Typography>{review.review}</Typography>
      </Grid>
    </Grid>
  );
};
export default ReviewCard;
