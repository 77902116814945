import CustomTabs from "../../Components/Common/CustomTabs/CustomTabs";
import ReviewsAboutYouTab from "./ReviewsAboutYouTab";
import ReviewsByYouTab from "./ReviewsByYouTab";

const ReviewsTabs = () => {
  const tabsContent = [
    { label: "Reviews by you", content: <ReviewsByYouTab /> },
  ];
  return (
    <CustomTabs
      tabs={tabsContent}
      tabName="reviews-tab"
      panelName="reviews-tabpanel"
      sx={{ marginTop: 5 }}
    />
  );
};

export default ReviewsTabs;
