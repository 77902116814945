import React, { useEffect, useState } from 'react'
import { Box, Drawer, Button, Typography, ButtonGroup, Card, MobileStepper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import images from "../../assets/js/images";
import AppHelper from "../../Helpers/AppHelper";
import RadioInput from "../Inputs/RadioInput";
import QuantityInput from "../Inputs/QuantityInput";

import { CartService } from "../../Services/CartService";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CartDrawer = ({ beachGear, startDate, endDate, cartItem, openDrawer, setOpenDrawer, cartAction, setCartAction }) => {
    const [priceData, setPriceData] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [selectedPriceData, setSelectedPriceData] = useState('');

    const anchorkey = 'right';
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const cartSubmit = () => {
        var cartdata = {};

        if (cartItem?.cart_id) {
            CartService.update(cartdata, cartItem?.cart_id).then(resp => {
                setState({ ...state, [anchorkey]: false });
                setOpenDrawer(false);
                setCartAction(cartAction + 1);
            })
        } else {
            CartService.add(cartdata).then(resp => {
                setState({ ...state, [anchorkey]: false });
                setOpenDrawer(false);
                setCartAction(cartAction + 1);
            })
        }

    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const [state, setState] = React.useState({ right: false });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenDrawer(open)
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        setState({ ...state, [anchorkey]: openDrawer });

        if (openDrawer) {
            var pricedatas = AppHelper.sortedPrice(beachGear.price);

            if (cartItem?.quantity) setQuantity(cartItem?.quantity);
            else setQuantity(1);

            if (cartItem?.price_id) setSelectedPriceData(cartItem?.price_id);
            else if (pricedatas[0]) setSelectedPriceData(pricedatas[0].id);

            setPriceData(pricedatas);
        }
    }, [openDrawer]);

    const list = (anchor) => (
        <Box className="right-drawer"
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 480,
                '@media (max-width:769px)': {
                    width: 350,

                },
                '@media (max-width:577px)': {
                    width: '300px',
                    borderRadius: '16px',
                },
            }}
            role="presentation"
        >
            <Card className="border-0 shadow-none vertical-card card-info drawer-img drawer-image">
                <Button size="small" disableRipple
                    className='close-btn'
                    onClick={toggleDrawer(anchor, false)}>
                    <img src={images.closeIcon} alt="" />
                </Button>
                <div className="card-media-item bg-white border-radius-12">

                    <div className="card-media-block">
                        <Box sx={{ flexGrow: 1 }} className="card-slider">
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {beachGear.imagesData?.length && beachGear.imagesData.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    display: 'block',
                                                    overflow: 'hidden',
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                src={step.imgPath}
                                                alt={step.label}
                                                className="border-radius-12 card-image"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                className="stepper-dots"
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                        className="prev-btn"
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <img src={images.rightChevronIcon} alt="arrow" />
                                        ) : (
                                            <img src={images.rightChevronIcon} alt="arrow" />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" className="next-btn">
                                        {theme.direction === 'rtl' ? (
                                            <img src={images.leftChevronIcon} alt="arrow" />
                                        ) : (
                                            <img src={images.leftChevronIcon} alt="arrow" />
                                        )}
                                    </Button>
                                }
                            />
                        </Box>
                    </div>
                </div>
            </Card>
            <Box className='drawer-inner'>
                <Box className="inner-main-heading">
                    <Typography variant="h6" component="h2" className='about-inner-heading'>{beachGear.title}</Typography>
                    <Box className="vertical-card-divider"></Box>
                    <span className='tertiary-text description-text'>Description</span>

                    <Typography variant="h6" component="p" className='drawer-content'>{beachGear.description}</Typography>

                    <Box class="section_pricelist">
                        {priceData && priceData.length
                            ?
                            <RadioInput
                                customArr={{ data: priceData, name: 'name', id: 'id' }}
                                name="price"
                                value={selectedPriceData}
                                onChange={(e) => {
                                    setSelectedPriceData(e.target.value)
                                }}
                                sx={{
                                    fontSize: '16px',
                                    paddingRight: '30px',
                                    width: '100%'
                                }}
                            />
                            :
                            <></>
                        }
                    </Box>

                    <Box className="filter-menu-footer">
                        <Typography variant="h6" component="h2">Total <span className='primary-text drawer-total-amount'>{AppHelper.currency()}{priceData.find(o => o.id == selectedPriceData)?.price}</span></Typography>
                        <QuantityInput
                            boxwidth='110px'
                            decrement={() => {
                                if (quantity >= 2) {
                                    setQuantity(quantity - 1);
                                }
                            }}
                            quantity={quantity}
                            increment={() => {
                                if (quantity >= 1) {
                                    setQuantity(quantity + 1);
                                }
                            }}
                        />
                        <Button
                            type="submit"
                            fullHeight
                            disableElevation
                            variant="contained"
                            className="border-radius-md btn-primary save-btn"
                            onClick={cartSubmit}
                        >
                            Add this item
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            <div key={anchorkey}>
                <Drawer
                    anchor={anchorkey}
                    open={state[anchorkey]}
                    onClose={toggleDrawer(anchorkey, false)}
                >
                    {list(anchorkey)}
                </Drawer>
            </div>
        </>
    )
}

export default CartDrawer;
