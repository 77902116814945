import React from "react";
import { Container } from "@mui/system";

import CustomTabs from "../../Components/Common/CustomTabs/CustomTabs";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import InstantBooking from "../../Components/BookingPreferences/InstantBooking";
import DeliveryPickup from "../../Components/BookingPreferences/DeliveryPickup";
import CancellationPolicy from "../../Components/BookingPreferences/CancellationPolicy";
import AwayMode from "../../Components/BookingPreferences/AwayMode";

import { HostService } from "../../Services/HostService";

const BookingPreferences = () => {
  const [hostData, setHostData] = React.useState({});
  React.useEffect(() => {
      getHostProfile();
  }, []);

  const getHostProfile = () => {
    HostService.hostProfile().then((resp) => {
      setHostData(resp.data.data)
    });
  };
  
  const tabsContent = [
    {label: "Instant booking", content: <InstantBooking hostData={hostData} getHostProfile={getHostProfile} />},
    {label: "Delivery & pickup", content: <DeliveryPickup hostData={hostData} getHostProfile={getHostProfile} /> },
    {label: "Cancellation policy", content: <CancellationPolicy hostData={hostData} getHostProfile={getHostProfile} /> },
    {label: "Away mode", content: <AwayMode hostData={hostData} getHostProfile={getHostProfile} /> },
  ];

  return (
    <Container sx={{ py: 5, }}>
        <AccountSettingsTitle
        title="Bookings"
        routeLabel="Bookings"
        />
        <CustomTabs
        tabs={tabsContent}
        tabName="privacysharing-tab"
        panelName="privacysharing-tabpanel"
        />
    </Container>
  );
};

export default BookingPreferences;