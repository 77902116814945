import React from "react";
import {Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import images from "../../assets/js/images";
import ServiceSidebar from "../../Components/Service/ServiceSidebar";
import ServiceHeader from "../../Components/Service/ServiceHeader";
import ServiceContent from "../../Components/Service/ServiceContent";

import { BeachGearService } from "../../Services/BeachGearService";

const ServiceList = () => {
    const [serveList, setServeList] = React.useState();
    const [loader, setLoader] = React.useState(false);
    const [activeServiceCategories, setActiveServiceCategories] = React.useState(['0', 'Services']);

    const [beachgearcategorieslist, setBeachGearCategoriesList] = React.useState([]);
    const getBeachGearCategoriesList = () => {
        BeachGearService.beachGearCategoriesList().then(res => {
            setBeachGearCategoriesList(Object.assign({'0' : 'All'}, res.data.data));
        }).catch(error => {
        });
    };
    
    React.useEffect(() => {
        setLoader(true);
        getBeachGearCategoriesList();
        BeachGearService.serviceList().then(res => {
            setLoader(false);
            setServeList(res.data.data);
        })
    }, []);

    const getServiceList = (type, search = '') => {
        setLoader(true);
        BeachGearService.serviceList(type, search).then(res => {
            setLoader(false);
            setServeList(res.data.data);
        })
    }

    return (
        <>
            <section className="service-listing section-padding ">
                <Container>
                    <Grid container>
                        <ServiceHeader getServiceList={getServiceList} images={images} activeServiceCategories={activeServiceCategories} setActiveServiceCategories={setActiveServiceCategories} beachgearcategorieslist={beachgearcategorieslist}/>
                    </Grid>
                    <Grid container>
                        <ServiceContent pageType="serviceList" serveList={serveList} images={images} loader={loader} />
                    </Grid>
                </Container>
            </section>
        </>
    )
}

export default ServiceList;