import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import SelectInput from "../../../Components/Inputs/SelectInput";
import images from "../../../assets/js/images";
import CardHolderDetails from "./CardHolderDetails";
import StripeFields from "./StripeFields";
import CardType from "./CardType";
import { FormContext } from "../../../Context/PaymentsContext";
import { useContext } from "react";
const Form = () => {
  const elements = useElements();
  const stripe = useStripe();
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 2 }}>
      <img style={{ marginRight: "5px" }} src={images.mastercardicon} />
      <img src={images.visa} />
      <StripeFields />
      <Typography
        sx={{ fontWeight: "500", mt: "16px !important" }}
        component="h4"
      >
        ZIP code
      </Typography>
      <Box className="small-service-heading ">
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Ex. 94013"
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {formik.errors.zip && formik.touched.zip && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.zip}
          </FormHelperText>
        )}
      </Box>
      <Typography
        sx={{ fontWeight: "500", mt: "16px !important" }}
        component="h4"
      >
        Billing country/region
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          pl: 4,
          mr: 4,
        }}
      >
        <SelectInput
          sx={{ pointerEvents: "none" }}
          className="opacity-40"
          renderValue={(selected) => {
            return <em>United States</em>;
          }}
        />
      </FormControl>

      <CardHolderDetails />
      <CardType />
    </Box>
  );
};

export default Form;
