import React , {useCallback, useState}from 'react'
import { useParams, useHistory, Link as RouterLink } from "react-router-dom"
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import {
    Box,
    Tooltip,
    Typography,
    Container,
    FormControl,
    TextField,
    InputAdornment,
    TextareaAutosize,
    Badge,
    Button,
    CircularProgress,
    FormHelperText,
    InputLabel,
    Link,
} from '@mui/material';
import { useFormik } from "formik";
import * as yup from 'yup';

import images from "../../assets/js/images";
import MakeToast from '../../Helpers/MakeToast';
import AppHelper from '../../Helpers/AppHelper';
import QuestionMarkPopover from '../../Components/Common/QuestionMarkPopover';
import ModalPopup from '../../Components/Modal/ModalPopup';
import SelectInput from "../../Components/Inputs/SelectInput";
import RadioInput from '../../Components/Inputs/RadioInput';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BaseModal from '../../Components/Modal/BaseModal';

import { CommonService } from "../../Services/CommonService";
import { BeachGearService } from "../../Services/BeachGearService";
import { BeachGearHostService } from "../../Services/BeachGearHostService";
import { BookingService } from "../../Services/BookingService";

function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

function comparePriceSets(prevPriceSets, nextPriceSets) {
    if (prevPriceSets?.length !== nextPriceSets?.length) {
      return false;
    }
  
    for (let i = 0; i < prevPriceSets?.length; i++) {
      const prevFrom = prevPriceSets[i].from;
      const prevTo = prevPriceSets[i].to;
      const nextFrom = nextPriceSets[i].from;
      const nextTo = nextPriceSets[i].to;
  
      if (prevFrom !== nextFrom || prevTo !== nextTo) {
        return false;
      }
    }
  
    return true;
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
};

const ServiceForm = () => {
    const params = useParams();
    const navigate = useHistory();
    const typeofcharges = AppHelper.lists().typeofcharges;
    const yesno = AppHelper.lists().yesno;
    const pricetypes = AppHelper.lists().pricetypes;
    const minutes = AppHelper.lists().minutes;
    const hours = AppHelper.lists().hours1;
    const days = AppHelper.lists().days1;

    const [publishunpublish, setPublishUnpublish] = React.useState('0');
    const [dropdownDays, setDropdownDays] = React.useState(days);
    const [coverimagepreview, setCoverImagePreview] = React.useState('')
    const [imagepreview, setImagePreview] = React.useState([]);
    const [focusedListFields, setFocusedListFields] = React.useState([]);
    const [focusedSaleFields, setFocusedSaleFields] = React.useState([]);

    const [fValid, setFValid] = React.useState(false);

    const [openModal, setOpenModal] = useState(false);

    const sleep = (ms) =>{
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleTextFieldFocus = useCallback((index, setState, state) => {
        const updatedFocusedFields = [...state];
        updatedFocusedFields[index] = true;
        setState(updatedFocusedFields);
    }, []);
    
    const handleTextFieldBlur = useCallback((index, setState, state) => {
        const updatedFocusedFields = [...state];
        updatedFocusedFields[index] = false;
        setState(updatedFocusedFields);
    }, []);
    
    let serviceValidationSchema = yup.object().shape({
        category_id: yup.number().nullable().required('Category is required'),
        beachgeartype_id: yup.number().nullable().when([], {
            is: (category_id) => category_id == 1,
            then: yup.number().nullable().required('Type is required'),
            otherwise: yup.number().nullable(),
        }),
        beachgear_id: yup.number().nullable().required('Sub Type is required'),
        name: yup.string().nullable().required('Name is required'),
        quantity: yup.number().nullable().when('category_id', {
            is: (category_id) => (fValid && category_id == 1),
            then: yup.number().nullable().required('Quantity is required').typeError("Quantity must be a number").min(1, "Quantity must be greater than or equal to 1"),
            otherwise: yup.number().nullable().notRequired().typeError("Quantity must be a number").min(1, "Quantity must be greater than or equal to 1")
        }),
        description: yup.string().nullable().when([], {
            is: () => fValid,
            then: yup.string().nullable().required('Description is required'),
            otherwise: yup.string().nullable().notRequired()
        }),
        list_price: yup.number().nullable().when('type_of_charge', {
            is: (type_of_charge) => type_of_charge == '1',
            then: yup.number().nullable().when([], {
                is: () => fValid,
                then: yup.number().nullable().required('List Price is required').typeError("List Price must be a number").min(1, "List Price must be greater than or equal to 1"),
                otherwise: yup.number().nullable().notRequired().typeError("List Price must be a number").min(1, "List Price must be greater than or equal to 1")
            }),
            otherwise: yup.number().nullable().notRequired().typeError("List Price must be a number").min(1, "List Price must be greater than or equal to 1")
        }),
        deal_price: yup.number().nullable().when('type_of_charge', {
            is: (type_of_charge) => type_of_charge == '1',
            then: yup.number().nullable().when([], {
                is: () => fValid,
                then: yup.number().nullable().required('Sale Price is required').typeError("Sale Price must be a number").min(1, "Sale Price must be greater than or equal to 1").test("saleprice", "Sale price must be lower than list price", (val, parent) => { return val < parent.parent.list_price; }),
                otherwise: yup.number().nullable().notRequired().typeError("Sale Price must be a number").min(1, "Sale Price must be greater than or equal to 1")
            }),
            otherwise: yup.number().nullable().notRequired().typeError("Sale Price must be a number").min(1, "Sale Price must be greater than or equal to 1")
        }),
        price_sets: yup.array().when('type_of_charge', {
            is: (type_of_charge) => type_of_charge == '2',
            then: yup.array().of(
                yup.object().shape({
                    type: yup.string().nullable().when([], {
                        is: () => fValid,
                        then: yup.string().nullable().required("Type is required"),
                        otherwise: yup.string().nullable().notRequired()
                    }),
                    option: yup.string().nullable().when('type', {
                        is: (type) => (type != "Days" && fValid),
                        then: yup.string().nullable().required("Option is required"),
                        otherwise: yup.string().nullable().notRequired()
                    }),
                    from: yup.string().nullable().when('type', {
                        is: (type) => (type == "Days" && fValid),
                        then: yup.string().nullable().required("From is required"),
                        otherwise: yup.string().nullable().notRequired()
                    }),
                    to: yup.string().nullable().when('type', {
                        is: (type) => (type == "Days" && fValid),
                        then: yup.string().nullable().required("To is required").test("to_min", "To value must be greater than from value", (val, parent) => { return val > parseInt(parent.parent.from); }),
                        otherwise: yup.string().nullable().notRequired()
                    }),
                    list_price: yup.number().nullable().when('type', {
                        is: (type) => (type != "Days" && fValid),
                        then: yup.number().nullable().required('List Price is required').typeError("List Price must be a number").min(1, "List Price must be greater than or equal to 1"),
                        otherwise: yup.number().nullable().notRequired(),
                    }),
                    deal_price: yup.number().nullable().when([], {
                        is: () => fValid,
                        then: yup.number().nullable().when('type', {
                            is: (type) => (type == "Days"),
                            then: yup.number().nullable().required('Price is required').typeError("Price must be a number").min(1, "Price must be greater than or equal to 1").test("saleprice", "price must be lower than list price", (val, parent) => { return parent.parent.type != 'Days' ? val < parent.parent.list_price : true; }),
                            otherwise: yup.number().nullable().required('Sale Price is required').typeError("Sale Price must be a number").min(1, "Sale Price must be greater than or equal to 1").test("saleprice", "Sale price must be lower than list price", (val, parent) => { return parent.parent.type != 'Days' ? val < parent.parent.list_price : true; })
                        }),
                        otherwise: yup.number().nullable().when('type', {
                            is: (type) => (type == "Days"),
                            then: yup.number().nullable().notRequired().min(1, "Price must be greater than or equal to 1"),
                            otherwise: yup.number().nullable().notRequired().min(1, "Sale Price must be greater than or equal to 1")
                        })
                    })
                })
            ),
            otherwise: ''
        }),
        security_deposit_require: yup.number().nullable().when([], {
            is: () => fValid,
            then: yup.number().nullable().required('Security Deposit Require is required'),
            otherwise: yup.number().nullable().notRequired()
        }),
        security_deposit_price: yup.number().nullable().when('security_deposit_require', {
            is: (security_deposit_require) => security_deposit_require == 1,
            then: yup.number().nullable().when([], {
                is: () => fValid,
                then: yup.number().nullable().required('Security Deposit Price is required').typeError("Security Deposit Price must be a number").min(1, "Security Deposit Price must be greater than or equal to 1"),
                otherwise: yup.number().nullable().notRequired().typeError("Security Deposit Price must be a number").min(1, "Security Deposit Price must be greater than or equal to 1")
            }),
            otherwise: yup.number().nullable().notRequired().typeError("Security Deposit Price must be a number").min(1, "Security Deposit Price must be greater than or equal to 1")
        }),
        is_slot_based: yup.number().nullable().when([], {
            is: () => fValid,
            then: yup.number().nullable().required('Reservation requirement is required'),
            otherwise: yup.number().nullable().notRequired()
        }),
        coverimage: yup.mixed().nullable().when([], {
            is: () => fValid,
            then: yup.mixed().nullable().required('Cover Image is required'),
            otherwise: yup.mixed().nullable().notRequired()
        })
    });

    const [beachgearcategorieslist, setBeachGearCategoriesList] = React.useState([]);
    const getBeachGearCategoriesList = () => {
        BeachGearService.beachGearCategoriesList().then(res => {
            setBeachGearCategoriesList(res.data.data);
        }).catch(error => {
        });
    };

    const [beachgeartypelist, setBeachGearTypeList] = React.useState([]);
    const getBeachGearTypeList = (categoryid) => {
        if (categoryid != '') {
            BeachGearService.beachGearTypeList(categoryid).then(res => {
                setBeachGearTypeList(res.data.data);
            }).catch(error => {
            });
        }
    };

    const [beachgearnamelist, setBeachGearNameList] = React.useState([]);
    const getBeachGearNameList = (categoryid, typeid) => {
        if (categoryid != '') {
            if (categoryid == 2 || categoryid == 3) {
                typeid = 'all';
            }
            BeachGearService.beachGearNameList(categoryid, typeid).then(res => {
                setBeachGearNameList(res.data.data);
            }).catch(error => {
            });
        }
    };

    const [serviceStatus, setServiceStatus] = React.useState('')
    const [bookingStatus, setBookingStatus] = React.useState(null);
    const [inprogresStatus, setInprogresStatus] = React.useState(false);
    const [pendingRequestStatus, setPendingRequestStatus] = React.useState(false);
    const [instantBookingStatus, setInstantBookingStatus] = React.useState(false);
    const [upcomingStatus, setUpcomingStatus] = React.useState(false);
    const [bookingID, setBookingID] = React.useState(null)
    const [formdata, setFormData] = React.useState([])
    var priceSetObject = { id: '', type: '', option: '', list_price: null, deal_price: null };
    React.useEffect(() => {
        getBeachGearCategoriesList();

        if (params.id != null) {
            BeachGearHostService.show(params.id).then(response => {
                var formdatas = response.data.data;

                setServiceStatus(formdatas.display_status);
                setBookingStatus(formdatas.booking_status);
                setInprogresStatus(formdatas.inprogress_status);
                setPendingRequestStatus(formdatas.pending_request_status);
                setInstantBookingStatus(formdatas.is_instant_booking);
                setUpcomingStatus(formdatas.upcoming_status);
                setBookingID(formdatas.booking_id);

                getBeachGearTypeList(formdatas.beach_gear.category_id);
                let typeId;
                if (formdatas.beach_gear.category_id == 2 || formdatas.beach_gear.category_id == 3) {
                    typeId = 'all';
                } else {
                    typeId = formdatas.beach_gear.beachgeartype_id;
                }
                getBeachGearNameList(formdatas.beach_gear.category_id, typeId)

                setFormData({
                    category_id: formdatas.beach_gear.category_id,
                    beachgeartype_id: formdatas.beach_gear.beachgeartype_id,
                    beachgear_id: formdatas.beach_gear.id,
                    name: formdatas.name,
                    quantity: formdatas.quantity,
                    description: formdatas.description,
                    type_of_charge: formdatas.type_of_charge == null ? '' : formdatas.type_of_charge,
                    list_price: formdatas.list_price,
                    deal_price: formdatas.deal_price,
                    security_deposit_require: formdatas.security_deposit_require == null ? '' : formdatas.security_deposit_require,
                    security_deposit_price: formdatas.security_deposit_price,
                    is_slot_based: formdatas.is_slot_based,
                    price_sets: formdatas.price_sets == null ? [priceSetObject] : formdatas.price_sets,
                    coverimage: Object.values(formdatas.coverimage)[0] ? Object.values(formdatas.coverimage)[0] : '',
                    image: Object.values(formdatas.images),
                    is_published: formdatas.is_published,
                    is_featured: formdatas.is_featured,
                })

                if (Object.values(formdatas.coverimage)[0]) setCoverImagePreview(Object.values(formdatas.coverimage)[0])
                if (Object.values(formdatas.images)) setImagePreview(Object.values(formdatas.images))
            }).catch(error => {
            });
        } else {
            setFormData({
                category_id: null,
                beachgeartype_id: null,
                beachgear_id: null,
                name: '',
                quantity: null,
                description: '',
                type_of_charge: '',
                list_price: null,
                deal_price: null,
                security_deposit_require: null,
                security_deposit_price: null,
                is_slot_based: null,
                price_sets: [priceSetObject],
                coverimage: '',
                image: [],
                is_published: 0,
                is_featured: ''
            })
        }
    }, []);

    const formik = useFormik({
        validationSchema: serviceValidationSchema,
        initialValues: formdata,
        enableReinitialize: true,
        onSubmit: (values) => formSubmit(values)
    });

    React.useEffect(() => {
        let categoryvalue = formik.values.category_id;
        if (categoryvalue == 1 || categoryvalue == 2) {
            formik.setFieldValue("type_of_charge", "2");
        } else if (categoryvalue == 3) {
            formik.setFieldValue("type_of_charge", "1");
        }
    }, [formik.values.category_id]);

    const [priceSets,setPriceSets] = React.useState(formik.values.price_sets);
    const priceSetsRef = React.useRef(priceSets);

    React.useEffect(() => {
        priceSetsRef.current = priceSets;
    }, [priceSets]);

    React.useEffect(() => {
        setPriceSets(formik.values.price_sets);
    }, [formik.values.price_sets]);

    const prevPriceSetsRef = React.useRef(formik.values.price_sets);


    React.useEffect(() => {
        if (!comparePriceSets(prevPriceSetsRef.current, formik.values.price_sets)) {
            formik.validateForm().then(() => {
                let price_set = formik.values.price_sets;
                const overlappingRanges = [];
                
                for (let i = 0; i < price_set.length; i++) {
                    const currentRange = price_set[i];
                    const currentFrom = parseInt(currentRange.from, 10);
                    const currentTo = parseInt(currentRange.to, 10);
                
                    for (let j = 0; j < price_set.length; j++) {
                        if (i !== j) {
                            const otherRange = price_set[j];
                            const otherFrom = parseInt(otherRange.from, 10);
                            const otherTo = parseInt(otherRange.to, 10);
                            if (currentFrom >= otherFrom && currentFrom <= otherTo) {
                                overlappingRanges.push({ index: i, field: 'from' });
                                break;
                            }
                    
                            if (currentTo >= otherFrom && currentTo <= otherTo) {
                                overlappingRanges.push({ index: i, field: 'to' });
                                break;
                            }
                        }
                    }
                }
            
                overlappingRanges.forEach((item) => {
                    const fieldName = `price_sets[${item.index}].${item.field}`;
                    formik.setFieldError(fieldName, "This day was already used");
                });
            });
        }
        prevPriceSetsRef.current = formik.values?.price_sets;
    }, [formik.values.price_sets]);

    const values = formik.values;
    const errors = formik.errors;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const handleBlur = formik.handleBlur;
    const setFieldValue = formik.setFieldValue;

    const formSubmit = (values) => {
        values.is_published = publishunpublish;

        var services = '';
        if (params.id != null) services = BeachGearHostService.update(values, params.id);
        else services = BeachGearHostService.create(values);

        services.then(response => {
            formik.setSubmitting(false);
            navigate.push('/services');
            MakeToast("success", "Service submitted successfully.");
        }).catch(error => {
            formik.setSubmitting(false);
            MakeToast("error", "Couldn't submit the service.");
        });
    }
   

    const [lspInfo, setLSPInfo] = React.useState(null);
    const lspContent = "Your “list” price is the price customers will see with a slash through it, under which they will see your “sale” price. <br />Your sale price doesn’t always have to be lower than the price you normally charge.<br /> Displaying a “sale” price helps make your listing look more attractive to customers, and can help achieve more bookings.";

    const [hiInfo, setHiInfo] = React.useState(null);
    const hiContent = "Hiding the item allows this item to remain in your services while not being viewable by customers. This is most commonly done in instances such as if an item is down for maintenance, being replaced, or if a service is temporarily unavailable.";

    const [openHDItem, setOpenHDItem] = React.useState(false);
    const [openHDItemType, setOpenHDItemType] = React.useState('');
    const hdItem = (type) => {
        if (type == 1) {
            if (inprogresStatus == true) {
                setOpenHDItemType(4)
                setOpenHDItem(true)
            } else if (upcomingStatus == true) {
                setOpenHDItemType(3);
                setOpenHDItem(true)
            } else if (pendingRequestStatus == true) {
                if (instantBookingStatus == 1) {
                    setOpenHDItemType(2);
                    setOpenHDItem(true);
                } else {
                    AppHelper.SAToast(
                        'It looks like you have a reservation request for this item.',
                        'Are you sure you want to hide this item? If so, your reservation request will automatically be denied'
                    ).then((result) => {
                        if (result.isConfirmed) {
                            BookingService.denyBooking({ id: params.id }).then((res) => { });
                            values.is_published = values.is_published == '1' ? '0' : '1';
                            BeachGearHostService.changePublish({ publish: values.is_published }, params.id).then(resp => {
                                itemtype = '1';
                            })
                        }
                    })
                }
            } else {
                AppHelper.SAToast().then((result) => {
                    if (result.isConfirmed) {
                        values.is_published = values.is_published == '1' ? '0' : '1';
                        BeachGearHostService.changePublish({ publish: values.is_published }, params.id).then(resp => {
                        })
                        setOpenHDItemType(1);
                        setOpenHDItem(true)
                    }
                })
            }
        } else if (type == 2) {
            if (inprogresStatus == true) {
                setOpenHDItemType(4);
                setOpenHDItem(true)
            } else if (upcomingStatus == true) {
                setOpenHDItemType(3);
                setOpenHDItem(true)
            } else if (pendingRequestStatus == true) {
                if (instantBookingStatus == 1) {
                    setOpenHDItemType(2);
                    setOpenHDItem(true);
                } else {
                    AppHelper.SAToast(
                        'It looks like you have a reservation request for this item.',
                        'Are you sure you want to delete this item without approving the request? If so, your reservation request will automatically be denied'
                    ).then((result) => {
                        if (result.isConfirmed) {
                            BookingService.denyBooking({ id: bookingID }).then((res) => { });
                            BeachGearHostService.del(params.id).then(resp => {
                                navigate.push('/services');
                            })
                        }
                    })
                }
            } else {
                AppHelper.SAToast().then((result) => {
                    if (result.isConfirmed) {
                        BeachGearHostService.del(params.id).then(resp => {
                            MakeToast('success', 'Service is deleted successfully', 5000);
                            navigate.push('/services');
                        })
                    }
                })
            }
        }

    }

    return (
        <>
            <section className='service-listing service-listing-form section-padding'>
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <BreadCrumb data={[['Services', '/services'], [params.id == null ? 'Create' : 'Update']]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="main-heading-block">
                                <Box className="service-heading">
                                    <h2 className="main-heading">New beach gear</h2>
                                </Box>
                            </Box>
                        </Grid>
                        <form id="serviceform" onSubmit={formik.handleSubmit} encType="multipart/form-data" className="service-form">
                            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12} sx={{ marginBottom: '0 !important' }}>
                                    <h2 className="main-heading secondary-text">Type of service</h2>
                                </Grid>
                                <Grid xs={12} sm={12} md={4} lg={4} sx={{ paddingBottom: '0 !important' }}>
                                    <Typography variant="h4" sx={{ marginBottom: '5px !important' }}>
                                        What category does your service best fall under?
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={12} md={8} lg={8} sx={{ paddingBottom: '0 !important' }}></Grid>
                                <Grid xs={12} sm={12} md={4} lg={4}>
                                    <FormControl variant="standard" className="input-style outlined-input select-style textfield-rouded-md" sx={{
                                        width: '100%',
                                        borderRadius: '18px',
                                        height: '48px'
                                    }}
                                    >
                                        <SelectInput
                                            customObj={beachgearcategorieslist}
                                            name="category_id"
                                            value={values.category_id}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                var categoryvalue = e.target.value;
                                                handleChange(e);
                                                setFieldValue("beachgeartype_id", "");
                                                setFieldValue("beachgear_id", "");
                                                getBeachGearTypeList(categoryvalue)

                                                if (categoryvalue == 1 || categoryvalue == 2) {
                                                    setFieldValue("type_of_charge", "2");
                                                    setFieldValue("list_price", null);
                                                    setFieldValue("deal_price", null);
                                                } else {
                                                    setFieldValue("type_of_charge", "1");
                                                    setFieldValue("price_sets", [priceSetObject]);
                                                }

                                                if (categoryvalue == 2 || categoryvalue == 3) {
                                                    getBeachGearNameList(categoryvalue, '')
                                                } else {
                                                    setBeachGearNameList([]);
                                                }
                                            }}
                                            renderValue={(selected) => {
                                                if ((selected == undefined || selected == '') && (values.category_id == undefined || values.category_id == '')) {
                                                    return <em>Category</em>;
                                                }
                                                return beachgearcategorieslist[values.category_id];
                                            }}
                                            className={errors.category_id && touched.category_id ? "input-style error" : ""}
                                            sx={{ pl: 1.5, pr: 1 }}
                                        />
                                    </FormControl>
                                    <FormHelperText className="field-helper-text error">{errors.category_id && touched.category_id && errors.category_id}</FormHelperText>
                                </Grid>
                                {(!values.category_id || values.category_id == 1) ?
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <FormControl variant="standard" className="input-style outlined-input select-style textfield-rouded-md" sx={{
                                            width: '100%',
                                            borderRadius: '18px',
                                            height: '48px'
                                        }}
                                        >
                                            <SelectInput
                                                customArr={{ data: beachgeartypelist, name: 'name', id: 'id' }}
                                                name="beachgeartype_id"
                                                value={values.beachgeartype_id}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("beachgear_id", "");
                                                    getBeachGearNameList(values.category_id, e.target.value)
                                                }}
                                                renderValue={(selected) => {
                                                    if ((selected == undefined || selected == '') && (values.beachgeartype_id == undefined || values.beachgeartype_id == '')) {
                                                        return <em>Beach Gear Type</em>;
                                                    }

                                                    let bgldata = beachgeartypelist.find(o => o.id == values.beachgeartype_id);
                                                    return bgldata ? bgldata.name : '';
                                                }}
                                                className={errors.beachgeartype_id && touched.beachgeartype_id ? "input-style error" : ""}
                                                sx={{ pl: 1.5, pr: 1 }}
                                            />
                                        </FormControl>
                                        <FormHelperText className="field-helper-text error">{errors.beachgeartype_id && touched.beachgeartype_id && errors.beachgeartype_id}</FormHelperText>
                                    </Grid>
                                    :
                                    <></>
                                }
                                <Grid xs={12} sm={12} md={4} lg={4}>
                                    <FormControl variant="standard" className="input-style outlined-input select-style textfield-rouded-md" sx={{
                                        width: '100%',
                                        borderRadius: '18px',
                                        height: '48px'
                                    }}
                                    >
                                        <SelectInput
                                            customArr={{ data: beachgearnamelist, name: 'name', id: 'id' }}
                                            name="beachgear_id"
                                            value={values.beachgear_id}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            renderValue={(selected) => {
                                                if ((selected == undefined || selected == '') && (values.beachgear_id == undefined || values.beachgear_id == '')) {
                                                    return <em>Type</em>;
                                                }

                                                let bgndata = beachgearnamelist.find(o => o.id == values.beachgear_id);
                                                return bgndata ? bgndata.name : '';
                                            }}
                                            className={errors.beachgear_id && touched.beachgear_id ? "input-style error" : ""}
                                            sx={{ pl: 1.5, pr: 1 }}
                                        />
                                    </FormControl>
                                    <FormHelperText className="field-helper-text error">{errors.beachgear_id && touched.beachgear_id && errors.beachgear_id}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} >
                                    <Box className="small-service-heading ">
                                        <Typography variant="h4" mb={1}>
                                            Enter the exact name that you call your item/service or package sale on your website or on your advertisements.
                                            This will be the title of your item/service that is shown to customers when they're searching on Beachify
                                        </Typography>
                                        <FormControl sx={{ width: '100%', }} className="input-style border-0"
                                        >
                                            <TextField placeholder="Name" className="outline-input textfield-rouded-md input-text-style "
                                                error={errors.name && touched.name ? true : false}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                sx={{

                                                    '.MuiInputBase-input': {
                                                        paddingLeft: '30px',
                                                    },

                                                    '.MuiInputBase-root': {
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                        },
                                                        '&.Mui-focused': {
                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #eee',
                                                            }
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                        }
                                                    },
                                                }}
                                                inputProps={{ maxLength: 46 }}
                                            />
                                        </FormControl>
                                        <FormHelperText className="field-helper-text error">{errors.name && touched.name && errors.name}</FormHelperText>
                                    </Box>
                                </Grid>
                                {(!values.category_id || values.category_id == 1) ?
                                <Grid item xs={12} sm={12} md={8} lg={8} >
                                    <Box className="small-service-heading ">
                                        <Typography variant="h4" mb={1}>
                                            How many of this item do you currently have for rent?
                                        </Typography>
                                        <FormControl sx={{ width: '100%', }} className="input-style border-0"
                                        >
                                            <TextField placeholder="Quantity" className="outline-input textfield-rouded-md  input-text-style"
                                                error={errors.quantity && touched.quantity ? true : false}
                                                name="quantity"
                                                value={values.quantity}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                sx={{

                                                    '.MuiInputBase-input': {
                                                        paddingLeft: '30px',
                                                    },

                                                    '.MuiInputBase-root': {
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                        },
                                                        '&.Mui-focused': {
                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #eee',
                                                            }
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                        }
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                        <FormHelperText className="field-helper-text error">{errors.quantity && touched.quantity && errors.quantity}</FormHelperText>
                                    </Box>
                                </Grid>
                                :<></>}
                                <Grid item xs={12} sm={12} md={8} lg={8} >
                                    <Box className="small-service-heading " sx={{ marginBottom: 0 }}>
                                        <Typography variant="h4" mb={1}>
                                            Enter a detailed description of your item or service
                                        </Typography>
                                        <Box sx={{
                                            color: '#09060F',
                                        }}>
                                            <TextareaAutosize className={"order-radius-md textfield-area outline-input input-style " + (errors.description && touched.description ? "error" : "")}
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                Inputaria-label="minimum height"
                                                minRows={3}
                                                placeholder="Description"
                                                style={{
                                                    width: '100%',
                                                    borderColor: '#E5E4E4',
                                                    borderRadius: '18px',
                                                    padding: '18px',
                                                    height: '160px',
                                                }}
                                            />
                                        </Box>
                                        <FormHelperText className="field-helper-text error">{errors.description && touched.description && errors.description}</FormHelperText>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={12} >
                                    <Box className=''>
                                        <h2 className="main-heading">Pricing</h2>
                                    </Box>
                                </Grid>
                                {values.type_of_charge != '' &&
                                    <Grid xs={12} sm={12} md={12} lg={12}>
                                        <Box className="radio-check-button" sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
                                            <FormControl>
                                                <RadioInput
                                                    customObj={typeofcharges}
                                                    name="type_of_charge"
                                                    value={values.type_of_charge}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    radiohidden="1"
                                                    sx={{
                                                        fontSize: '16px',
                                                        paddingRight: '30px',
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                }
                                {values.type_of_charge == '1' &&
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={4} >
                                            <Box className="small-service-heading" sx={{
                                                borderBottom: '1px dotted #F8F8FA',
                                            }}>
                                                <FormControl sx={{
                                                    width: '100%',

                                                }} className="input-style border-0"
                                                >
                                                    <TextField placeholder=""
                                                        error={errors.list_price && touched.list_price ? true : false}
                                                        className="outline-input textfield-rouded-md input-text-style"
                                                        name="list_price"
                                                        value={values.list_price}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" className="input-image"
                                                                    sx={{
                                                                        paddingLeft: '0 !important',
                                                                        paddingRight: '5px',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <img src={images.priceIcon} alt="dollar"
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <span
                                                                        style={{ color: 'black', margin: '2px 3px 0 0' }}
                                                                    >
                                                                        List Price
                                                                    </span>
                                                                    <QuestionMarkPopover
                                                                        anchorPopOver={lspInfo}
                                                                        setAnchorPopOver={setLSPInfo}
                                                                        content={lspContent}
                                                                        spanStyle={{ paddingTop: '5px' }}
                                                                    />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{
                                                            '.MuiInputBase-input': {
                                                                paddingLeft: '0px',
                                                            },
                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }

                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{errors.list_price && touched.list_price && errors.list_price}</FormHelperText>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} >
                                            <Box className="small-service-heading" sx={{
                                                borderBottom: '1px dotted #F8F8FA',
                                            }}>
                                                <FormControl sx={{
                                                    width: '100%',
                                                }} className="input-style border-0"
                                                >
                                                    <TextField placeholder=""
                                                        error={errors.deal_price && touched.deal_price ? true : false}
                                                        className="outline-input textfield-rouded-md input-text-style"
                                                        name="deal_price"
                                                        value={values.deal_price}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" className="input-image"
                                                                    sx={{
                                                                        paddingLeft: '0 !important',
                                                                        paddingRight: '5px',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <img src={images.priceIcon} alt="dollar" />

                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <span
                                                                        style={{ color: 'black', margin: '2px 3px 0 0' }}
                                                                    >
                                                                        Sale Price
                                                                    </span>
                                                                    <QuestionMarkPopover
                                                                        anchorPopOver={lspInfo}
                                                                        setAnchorPopOver={setLSPInfo}
                                                                        content={lspContent}
                                                                        spanStyle={{ paddingTop: '5px' }}
                                                                    />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{
                                                            '.MuiInputBase-input': {
                                                                paddingLeft: '0px',
                                                            },
                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }

                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{errors.deal_price && touched.deal_price && errors.deal_price}</FormHelperText>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} ></Grid>
                                    </>
                                }
                                {values.type_of_charge == '2' && values.price_sets.map((result, index) => {
                                    return (
                                        <Grid container key={index} xs={12} sx={{
                                            '@media (max-width:577px)': {
                                                width: '100%',
                                            },
                                            '@media (max-width:767.98px)': {
                                                width: '100%',
                                            },
                                            '@media (max-width:1023.98px)': {
                                                width: '100%',
                                            },
                                        }}>
                                            {index == 0 && <Grid xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: '0' }}>
                                                <Typography variant="h4" mb={1}>
                                                    Add pricing tiers for your customers
                                                </Typography>
                                            </Grid>}
                                            <Grid xs={12} sm={6} md={4} lg={2}>
                                                <FormControl variant="standard"
                                                    className={"input-style outlined-input select-style textfield-rouded-md input-style " + ((Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined' && errors.price_sets[index]?.type) ? "error" : "")} sx={{
                                                        width: '100%', marginRight: 2,
                                                        borderRadius: '18px',
                                                        paddingLeft: '30px',
                                                        height: '48px',
                                                    }}
                                                >
                                                    <SelectInput
                                                        customArr1={pricetypes}
                                                        name={`price_sets.${index}.type`}
                                                        value={values.price_sets[index].type}
                                                        onChange={(e) => { 
                                                            setFieldValue(`price_sets.${index}.option`, '');
                                                            
                                                            if(e.target.value=='Days'){
                                                                setFieldValue(`price_sets.${index}.list_price`, '');
                                                            }

                                                            handleChange(e); 
                                                        }}
                                                        onBlur={handleBlur}
                                                        renderValue={(selected) => {
                                                            if ((selected == undefined || selected == '') && (values.price_sets[index].type == undefined || values.price_sets[index].type == '')) {
                                                                return <em>Type</em>;
                                                            }

                                                            let typedata = pricetypes.indexOf(values.price_sets[index].type);
                                                            return typedata > -1 ? pricetypes[typedata] : '';
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index]?.type : false}</FormHelperText>
                                            </Grid>
                                            <Grid xs={12} sm={6} md={4} lg={3}>
                                                <FormControl variant="standard"
                                                    className={"input-style outlined-input select-style textfield-rouded-md input-style " + ((Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined' && (errors.price_sets[index]?.option || errors.price_sets[index]?.from)) ? "error" : "")} sx={{
                                                        width: '100%', marginRight: 2,
                                                        borderRadius: '18px',
                                                        paddingLeft: '15px',
                                                        height: '48px',
                                                    }}
                                                    fullWidth
                                                >
                                                    <SelectInput
                                                        customArr1={values.price_sets[index].type == 'Minutes' ? minutes : (values.price_sets[index].type == 'Hours' ? hours : (values.price_sets[index].type == 'Days' ? days : []))}
                                                        name={values.price_sets[index].type == 'Days' ? `price_sets.${index}.from` : `price_sets.${index}.option`}
                                                        value={values.price_sets[index].type == 'Days' ? values.price_sets[index]?.from : values.price_sets[index]?.option}
                                                        onChange={(e) => { 
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        renderValue={(selected) => {
                                                            if ((selected == undefined || selected == '') && ((values.price_sets[index].type == 'Days' ? values.price_sets[index]?.from : values.price_sets[index]?.option) == undefined || (values.price_sets[index].type == 'Days' ? values.price_sets[index]?.from : values.price_sets[index]?.option == ''))) {
                                                                return <em>{values.price_sets[index].type == 'Days' ? "From" : "Option"}</em>;
                                                            }

                                                            let optionlist = values.price_sets[index].type == 'Minutes' ? minutes : (values.price_sets[index].type == 'Hours' ? hours : (values.price_sets[index].type == 'Days' ? days : []));
                                                            let optiondata = optionlist.length ? optionlist.indexOf(values.price_sets[index].type == 'Days' ? values.price_sets[index]?.from : values.price_sets[index]?.option) : [];
                                                            return optiondata > -1 ? optionlist[optiondata] : '';
                                                        }}
                                                        selectdisabled={values.price_sets[index].type=='' ? true : false}
                                                        startAdornment={
                                                            <InputAdornment position="start" className="input-image" underline="none" style={{ fontWeight: 'bold', paddingRight: '15px', marginRight: '7px', height: '40px',  marginTop: '-3px' }}>
                                                                From
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index]?.option : false}</FormHelperText>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index]?.from : false}</FormHelperText>
                                            </Grid>
                                            {values.price_sets[index].type == 'Days' ? 
                                            <>
                                            <Grid xs={12} sm={6} md={4} lg={3}>
                                                <FormControl variant="standard"
                                                    className={"input-style outlined-input select-style textfield-rouded-md input-style " + ((Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined' && (errors.price_sets[index]?.option || errors.price_sets[index]?.to)) ? "error" : "")} sx={{
                                                        width: '100%', marginRight: 2,
                                                        borderRadius: '18px',
                                                        paddingLeft: '15px',
                                                        height: '48px',
                                                    }}
                                                >
                                                    <SelectInput
                                                        customArr1={values.price_sets[index].type == 'Minutes' ? minutes : (values.price_sets[index].type == 'Hours' ? hours : (values.price_sets[index].type == 'Days' ? days : []))}
                                                        name={values.price_sets[index].type == 'Days' ? `price_sets.${index}.to` : `price_sets.${index}.option`}
                                                        value={values.price_sets[index].type == 'Days' ? values.price_sets[index]?.to : values.price_sets[index]?.option}
                                                        onChange={(e) => { 
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        renderValue={(selected) => {
                                                            if ((selected == undefined || selected == '') && ((values.price_sets[index].type == 'Days' ? values.price_sets[index]?.to : values.price_sets[index]?.option) == undefined || (values.price_sets[index].type == 'Days' ? values.price_sets[index]?.to : values.price_sets[index]?.option == ''))) {
                                                                return <em>To</em>;
                                                            }
                                                            let optionlist = values.price_sets[index].type == 'Minutes' ? minutes : (values.price_sets[index].type == 'Hours' ? hours : (values.price_sets[index].type == 'Days' ? days : []));
                                                            let optiondata = optionlist.length ? optionlist.indexOf(values.price_sets[index].type == 'Days' ? values.price_sets[index]?.to : values.price_sets[index]?.option) : [];
                                                            return optiondata > -1 ? optionlist[optiondata] : '';
                                                        }}
                                                        selectdisabled={values.price_sets[index].type == '' || !values.price_sets[index].from ? true : false}
                                                        startAdornment={
                                                            <InputAdornment position="start" className="input-image" underline="none" style={{ fontWeight: 'bold', paddingRight: '15px', marginRight: '7px', height: '40px',  marginTop: '-3px' }}>
                                                                To
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index]?.option : false}</FormHelperText>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index]?.to : false}</FormHelperText>
                                            </Grid>
                                            </> :
                                                <></>
                                            }

                                            {values.price_sets[index].type != 'Days' ?
                                            <Grid xs={12} sm={5} md={2} lg={3}>
                                                <FormControl sx={{
                                                    width: '100%'
                                                }} className="input-style border-0"
                                                >
                                                    <TextField placeholder=""
                                                        error={(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined' && errors.price_sets[index]?.list_price) ? true : false}
                                                        className="outline-input textfield-rouded-md input-text-style"
                                                        name={`price_sets.${index}.list_price`}
                                                        value={values.price_sets[index].list_price}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <img src={images.priceIcon} alt="dollar" />

                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <span
                                                                        style={{ color: 'black', margin: '2px 3px 0 0'}}
                                                                    >
                                                                        List Price
                                                                    </span>
                                                                    <QuestionMarkPopover
                                                                        anchorPopOver={lspInfo}
                                                                        setAnchorPopOver={setLSPInfo}
                                                                        content={lspContent}
                                                                        spanStyle={{ paddingTop: '5px' }}
                                                                    />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{

                                                            '.MuiInputBase-input': {
                                                                paddingLeft: '0px',
                                                            },

                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }

                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }

                                                            },
                                                        }}
                                                        disabled={values.price_sets[index].type=='' ? true : false}
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index].list_price : false}</FormHelperText>
                                            </Grid>
                                            :
                                            <></>
                                            }
                                            <Grid xs={10} sm={6} md={2} lg={3}>
                                                <FormControl sx={{
                                                    width: '100%'
                                                }} className="input-style border-0"
                                                >
                                                    <TextField placeholder=""
                                                        error={(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined' && errors.price_sets[index]?.deal_price) ? true : false}
                                                        className="outline-input textfield-rouded-md input-text-style"
                                                        name={`price_sets.${index}.deal_price`}
                                                        value={values.price_sets[index].deal_price}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <img src={images.priceIcon} alt="dollar" />

                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end" className="input-image"
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        paddingLeft: '0 !important',
                                                                        '@media (max-width:577px)': {
                                                                            paddingRight: '10px',
                                                                        },
                                                                    }} >
                                                                    <span
                                                                        style={{ color: 'black', margin: '2px 3px 0 0'}}
                                                                    >
                                                                        {values.price_sets[index].type != 'Days' ? 'Sale Price' : 'Price'}
                                                                    </span>
                                                                    <QuestionMarkPopover
                                                                        anchorPopOver={lspInfo}
                                                                        setAnchorPopOver={setLSPInfo}
                                                                        content={lspContent}
                                                                        spanStyle={{ paddingTop: '5px' }}
                                                                    />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{

                                                            '.MuiInputBase-input': {
                                                                paddingLeft: '0px',
                                                            },

                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }

                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }

                                                            },
                                                        }}
                                                        disabled={values.price_sets[index].type=='' ? true : false}
                                                    />
                                                </FormControl>
                                                <FormHelperText className="field-helper-text error">{(Object.keys(errors).length > 0 && errors?.price_sets && typeof errors?.price_sets[index] !== 'undefined') ? errors.price_sets[index].deal_price : false}</FormHelperText>
                                            </Grid>
                                            <Grid xs={2} sm={1} md={8} lg={1} sx={{ margin: 'auto' }}>
                                                {index != 0 &&
                                                    <Button className='plus-img'
                                                        onClick={() => {
                                                            values.price_sets.splice(index, 1)
                                                            setFieldValue('price_sets', values.price_sets)
                                                        }}
                                                        sx={{
                                                            '&::hover': {
                                                                backgroundColor: 'transparent',
                                                            }
                                                        }}>
                                                        <img src={images.plusminusicon} alt="minus" />
                                                    </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                {(values.type_of_charge == '2') &&
                                    <Grid xs={12}>
                                        <Button size="small" type="button" className="btn-primary submit-btn rounded-button"
                                            onClick={() => {
                                                let length = values.price_sets.length - 1;
                                                var previousValue = parseInt(values.price_sets[length].to);
                                                if(previousValue){
                                                    priceSetObject = { id: '', type: 'Days', from: (previousValue+1).toString(), list_price: null, deal_price: null };
                                                }
                                                values.price_sets.push(priceSetObject)
                                                setFieldValue('price_sets', formik.values.price_sets)
                                            }}
                                            sx={{
                                                '@media (max-width: 575.98px)': {
                                                    width: '100%',
                                                },
                                            }}>
                                            {values.price_sets[values.price_sets.length - 1].type == "Days" ? "Add price tier" : "Add another rate" }
                                        </Button>
                                    </Grid>
                                }
                                <Grid xs={12} sm={12} md={12} lg={12}>
                                    <Box className="radio-check-button">
                                        <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                                            Do you require a security deposit for this item?
                                        </Typography>
                                        <FormControl>
                                            <RadioInput
                                                customArr={{ data: yesno, name: 'name', id: 'id' }}
                                                name="security_deposit_require"
                                                value={values.security_deposit_require}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("security_deposit_price", null);
                                                }}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    setFieldValue("security_deposit_price", null);
                                                }}
                                                sx={{
                                                    fontSize: '16px',
                                                    paddingRight: '30px',
                                                }}
                                            />
                                        </FormControl>
                                        <FormHelperText className="field-helper-text error">{errors.security_deposit_require && touched.security_deposit_require && errors.security_deposit_require}</FormHelperText>
                                    </Box>
                                </Grid>
                                {values.security_deposit_require == '1' &&
                                    <Grid item xs={12} sm={12} md={8} lg={8} >
                                        <Box className="small-service-heading ">
                                            <FormControl sx={{ width: '100%', }} className="input-style border-0"
                                            >
                                                <TextField placeholder="Security Deposit Price"
                                                    error={errors.security_deposit_price && touched.security_deposit_price ? true : false}
                                                    className="outline-input textfield-rouded-md input-text-style"
                                                    name="security_deposit_price"
                                                    value={values.security_deposit_price}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    sx={{

                                                        '.MuiInputBase-input': {
                                                            paddingLeft: '30px',
                                                        },

                                                        '.MuiInputBase-root': {
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#eee',
                                                            },
                                                            '&.Mui-focused': {
                                                                ' .MuiOutlinedInput-notchedOutline': {
                                                                    border: '1px solid #eee',
                                                                }

                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#eee',
                                                            }

                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                            <FormHelperText className="field-helper-text error">{errors.security_deposit_price && touched.security_deposit_price && errors.security_deposit_price}</FormHelperText>
                                        </Box>
                                    </Grid>
                                }

                                <Grid item xs={12} >
                                    <Box className='small-service-heading'>
                                        <h2 className=" main-heading">Reservation requirement</h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12}>
                                    <Box className="radio-check-button " sx={{ marginBottom: '20px' }}>
                                        <Typography variant="h4" mb={1}>
                                            Does your company require specific reservation slot start & end times for this service?
                                        </Typography>
                                        <FormControl>
                                            <RadioInput
                                                customArr={{ data: yesno, name: 'name', id: 'id' }}
                                                name="is_slot_based"
                                                value={values.is_slot_based}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                sx={{
                                                    fontSize: '16px',
                                                    paddingRight: '30px',
                                                }}
                                            />
                                        </FormControl>
                                        <FormHelperText className="field-helper-text error">{errors.is_slot_based && touched.is_slot_based && errors.is_slot_based}</FormHelperText>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className="small-service-heading">
                                    <h2 className="main-heading">Photo gallery
                                    </h2>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12}>
                                    <Box className='uplaod-image-block'>
                                        <div>
                                            <Box className={'upload-image ' + (coverimagepreview != '' ? 'imagebgpreview' : '')}
                                                style={{ backgroundImage: `url(${coverimagepreview})`, 'backgroundRepeat': 'no-repeat', 'backgroundSize': 'cover' }}
                                                onClick={() => {
                                                    document.getElementById("coverImageFile").click()
                                                }}
                                            >
                                                <img src={images.uploadicon} alt="upload" />
                                                <Typography id="modal-modal-title" variant="h6" component="h2" className="upload-text">
                                                    Upload an image
                                                </Typography>
                                                <div className="featuring-block">
                                                    <Badge className="featuring-badge order-badge">
                                                        Cover Photo
                                                    </Badge>
                                                </div>
                                            </Box>
                                            <FormHelperText className="field-helper-text error">{errors.coverimage && touched.coverimage && errors.coverimage}</FormHelperText>
                                            <p style={{ color: 'red' }}>(Image size : 750 x 500)</p>
                                        </div>

                                        {imagepreview.map((result, index) => (
                                            <div className="upload-src-image">
                                                <img src={result} />
                                                <i className="image-src-close"
                                                    onClick={() => {
                                                        imagepreview.splice(index, 1)
                                                        CommonService.removeMedia({ url: result }).then((res) => { })
                                                        setFieldValue("image", imagepreview);
                                                        setImagePreview(imagepreview)
                                                    }}
                                                >x</i>
                                            </div>
                                        ))}
                                        <div>
                                            <Box className='upload-image'
                                                onClick={() => {
                                                    document.getElementById("imageFile").click()
                                                }}
                                            >
                                                <img src={images.uploadicon} alt="upload" />
                                                <Typography id="modal-modal-title" variant="h6" component="h2" className="upload-text">
                                                    Upload an image
                                                </Typography>
                                            </Box>
                                        </div>
                                        <input accept="image/*" type="file" name="coverimage" id="coverImageFile" style={{ display: "none" }}
                                            onChange={async (event) => {
                                                if (AppHelper.checkFileFormat(event.currentTarget.files[0].type)) {
                                                    const file = event.currentTarget.files[0];
                                                    if(await AppHelper.checkFileDimensions(file, 750, 500)){
                                                       const base64 = await AppHelper.convertToBase64(file);
                                                        setFieldValue("coverimage", base64);
                                                        setCoverImagePreview(base64)
                                                    }else{
                                                        setOpenModal(true)
                                                        // MakeToast("error", "Make sure your image is resized to 750 x 500 before trying again.");
                                                    }
                                                } else {
                                                    MakeToast("error", "Unsupported file format.");
                                                }
                                            }}
                                        />
                                        <input accept="image/*" type="file" name="image" id="imageFile" multiple style={{ display: "none" }}
                                            onChange={async (event) => {
                                                var imagepreviewbase64 = values.image;
                                                var filess = event.target.files.length;
                                                for (var i = 0; i < filess; i++) {
                                                    const file = event.target.files[i];
                                                    if (AppHelper.checkFileFormat(file.type)) {
                                                        if(await AppHelper.checkFileDimensions(file, 750, 500)){
                                                            const base64 = await AppHelper.convertToBase64(file);
                                                            imagepreviewbase64.push(base64);
                                                        }else{
                                                            setOpenModal(true)
                                                        }
                                                    } else {
                                                        MakeToast("error", "Unsupported file format.");
                                                    }
                                                }

                                                setFieldValue("image", imagepreviewbase64);
                                                setImagePreview(imagepreviewbase64)
                                            }}
                                        />
                                    </Box>
                                    <Grid xs={12} sm={12} md={12} lg={12}>
                                        <Box noValidate autoComplete="off" className='submit-btn-end p-relative'>
                                            <Button size="small" type="button" className="btn-primary submit-btn rounded-button" disabled={formik.isSubmitting}
                                                onClick={(e) => {
                                                    setPublishUnpublish((values.is_published == 1 ? '1' : '0'))
                                                    values.is_published == 1 ? setFValid(true) : setFValid(false)
                                                    setTimeout(() => { formik.handleSubmit(); }, 500);
                                                }}
                                            >
                                                {formik.isSubmitting ? <CircularProgress color="inherit" /> : "Save"}
                                            </Button>
                                            {
                                                values.is_published == 0
                                                    ?
                                                    <Button size="small" type="button" className="btn-primary submit-btn rounded-button" disabled={formik.isSubmitting}
                                                        onClick={(e) => {
                                                            setPublishUnpublish('1')
                                                            setFValid(true)
                                                            setTimeout(() => { formik.handleSubmit(); }, 500);
                                                        }}
                                                    >
                                                        {formik.isSubmitting ? <CircularProgress color="inherit" /> : "Save & Publish"}
                                                    </Button>
                                                    :
                                                    <></>
                                            }
                                            {
                                                values.is_published == 1 && params.id != null
                                                    ?
                                                    <>
                                                        <Button size="small" type="button" className="btn-primary submit-btn rounded-button"
                                                            onClick={(e) => {
                                                                AppHelper.SAToast().then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        var featuredstatus = values.is_featured == '1' ? '0' : '1';
                                                                        BeachGearHostService.isFeaturedService({ is_featured: featuredstatus }, params.id).then((res) => {
                                                                            setFieldValue("is_featured", featuredstatus);
                                                                            MakeToast("success", "Service feature is changed successfully.");
                                                                        })
                                                                            .catch((error) => { });
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {values.is_featured == '1' ? 'Remove this from featured service' : 'Make this my featured service'}
                                                        </Button>
                                                        <Button size="small" type="button" className="btn-tertiary submit-btn rounded-button" onClick={(e) => { hdItem('2') }}
                                                        >
                                                            Delete Item
                                                        </Button>
                                                        <Button size="small" type="button" className="btn-secondary-outline submit-btn rounded-button"
                                                            disabled={!values.is_published}
                                                            onClick={(e) => { hdItem('1') }}
                                                        >
                                                            Hide Item
                                                        </Button>
                                                        <QuestionMarkPopover
                                                            className="submit-btn-icon"
                                                            anchorPopOver={hiInfo}
                                                            setAnchorPopOver={setHiInfo}
                                                            content={hiContent}
                                                        // spanStyle={{ position : 'absolute', top : '6px'}}
                                                        />
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Container>
                {openHDItem &&
                    <ModalPopup
                        open={openHDItem}
                        onClose={() => { setOpenHDItem(false) }}
                        content={<>
                            <Box className="modalheader" sx={{ marginBottom: 0 }}>
                                <Box className="modal-close-btn">
                                    <Button size="small" onClick={() => { setOpenHDItem(false) }}> <img src={images.closeIcon} alt="" /></Button>
                                </Box>
                            </Box>
                            <Box className="modal-body message-modal">
                                <Typography component="h4">
                                    {openHDItemType == '1' && "Item is now hidden from new potential customers"}
                                    {openHDItemType == '2' &&
                                        <>
                                            It looks like you have a pending reservation with this item. Are you sure you want to hide this item? If so, you’ll need to cancel the reservation first. Visit the
                                            <RouterLink to="/bookings?status=pending"> Pending </RouterLink>
                                            section of the bookings tab to cancel the reservation, then revisit this page.
                                        </>
                                    }
                                    {openHDItemType == '3' &&
                                        <>
                                            It looks like you have an upcoming reservation with this item. Are you sure you want to pause this item? If so, you’ll need to cancel the reservation first. Visit the
                                            <RouterLink to="/bookings?status=upcoming"> Upcoming </RouterLink>
                                            section of the bookings tab to cancel the reservation, then revisit this page.
                                        </>
                                    }
                                    {openHDItemType == '4' && "It looks like this item is currently out with a customer. Wait until the reservation has completed to perform this action"}
                                </Typography>
                            </Box>
                        </>}
                    />}
            </section>
            <BaseModal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ pt: 1 }} component="h4">
                        Make sure your image is resized to 750 x 500. Use the Image Size app listed below to resize your image:
                        <Typography  sx={{ fontSize: 16, marginTop:2 }}>Download for IOS:</Typography>
                        <a href="https://apps.apple.com/us/app/image-size/id670766542" target="_blank"
                         style={{ fontSize: '16px' }}
                        >
                            Image Size
                        </a>
                        <Typography sx={{ fontSize: 16, marginTop:2 }}>Download for Android:</Typography>
                        <a href="https://play.google.com/store/search?q=Image+Size&c=apps&hl=en_US&gl=US&pli=1" target="_blank"
                        style={{ fontSize: '16px' }}
                        >Image Size -Android</a>
                    </Typography>
                </Box>
            </BaseModal>
        </>
    )
}

export default React.memo(ServiceForm);
