import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Link,
  Rating,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import images from "../../assets/js/images";
import { useState, useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ReviewsService } from "../../Services/ReviewsService";
import MakeToast from "../../Helpers/MakeToast";
import { ReviewsContext } from "../../Context/ReviewsContext";

const ReviewCard = ({ review }) => {
  const { setReviews } = useContext(ReviewsContext);
  const [showReply, setShowReply] = useState(false);
  const submitChanges = async (values) => {
    const methodName = review?.answer ? "updateAnswer" : "answer";
    await ReviewsService[methodName](values)
      .then((response) => {
        setShowReply(false);
        setReviews(response.data.data);
        MakeToast("success", response);
      })
      .catch((error) => MakeToast("error", error));
    formik.setSubmitting(false);
  };
  const ValidationSchema = yup.object().shape({
    reply: yup.string("Enter your reply").required("Reply is required"),
  });
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: {
      id: review?.answer ? review?.answer.id : review?.id,
      reply: review?.answer?.answer ?? "",
    },
    enableReinitialize: true,
    onSubmit: submitChanges,
  });
  const values = formik.values;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <Box
      sx={{
        borderBottom: "solid 1px #EBEBEB !important",
      }}
    >
      <Grid
        sx={{
          paddingBottom: "15px",
          paddingTop: "15px",
        }}
        container
      >
        <Grid xs={12} md={1}>
          <img
            className="reviews-user-image"
            src={review?.author?.image ?? images.userDefault}
          />
        </Grid>
        <Grid xs={12} md={11}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{review?.author?.name}</Typography>
            <Typography sx={{ fontSize: 10, marginLeft: 1 }}>
              {review.date}
            </Typography>
          </Box>
          <Rating name="read-only" value={review?.score} readOnly />
          <Typography>{review.review}</Typography>
          {!showReply ? (
            !review?.answer && (
              <Link
                onClick={() => setShowReply(true)}
                className="link-update"
                sx={{
                  fontSize: 14,
                  mt: "0px !important",
                }}
              >
                Reply
              </Link>
            )
          ) : (
            <Box sx={{ mt: 1 }}>
              <TextareaAutosize
                id="reply"
                value={values.reply}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input-style small-radius"
                style={{
                  width: "100%",
                  height: "110px",
                  border: `1px solid #${
                    formik.touched.reply && Boolean(formik.errors.reply)
                      ? "d83e3f"
                      : "EEEEEE"
                  }`,
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Link
                  onClick={() => {
                    setShowReply(false);
                    formik.resetForm();
                  }}
                  className="link-update"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Cancel
                </Link>
                <Button
                  size="small"
                  type="button"
                  disableRipple
                  disableElevation
                  className="btn-primary submit-btn rounded-button"
                  style={{ marginTop: "15px" }}
                  disabled={formik.isSubmitting}
                  onClick={(e) => formik.handleSubmit()}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      {review?.answer && !showReply && (
        <Grid
          sx={{
            borderBottom: "solid 1px #EBEBEB !important",
            paddingBottom: "15px",
            paddingTop: "15px",
            pl: 6,
          }}
          container
        >
          <Grid xs={12} md={1}>
            <img
              className="reviews-user-image"
              src={review?.host?.image ?? images.userDefault}
            />
          </Grid>
          <Grid xs={12} md={11}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{review?.host?.name}</Typography>
              <Typography sx={{ fontSize: 10, marginLeft: 1 }}>
                {review?.answer?.date}
              </Typography>
            </Box>
            <Typography>{review?.answer?.answer}</Typography>
            {review?.answer?.updated == 1 ? (
              <Link
                className="link-update"
                onClick={() =>
                  MakeToast("error", "Reply can only be updated one time")
                }
                sx={{
                  fontSize: 14,
                  mt: "0px !important",
                  opacity: "60%",
                }}
              >
                Edit
              </Link>
            ) : (
              <Link
                onClick={() => setShowReply(true)}
                className="link-update"
                sx={{
                  fontSize: 14,
                  mt: "0px !important",
                }}
              >
                Edit
              </Link>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default ReviewCard;
