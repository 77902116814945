import { APIService } from "./APIService";

export const TutorialService = {
    hosts,
    renters
};

function hosts() {
    return APIService.post('api/tutorials/hosts');
}

function renters() {
    return APIService.post('api/tutorials/renters');
}