import React from "react";
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
} from '@mui/material';

const Category = (props) => {

    return (
        <>
            <div>
                <Card className="border-0 bg-transparent shadow-none category-card card-info">
                    <CardActionArea disableRipple>
                        <CardMedia
                            component="img"
                            height="140"
                            image={props.img}
                            alt="green iguana"
                            className="border-radius-12 card-image"
                        />
                        <CardContent sx={{ p: 0 }}>
                            <Typography variant="h5" component="div" className="card-title text-truncate">
                                {props.title}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>
    )
}

export default Category;
