const InitialValues = {
  name: "",
  disregarded_entity_name: "",
  classification: "",
  type_id: "",
  id_number: "",
  address: {
    country: "",
    street: "",
    apt_suite: "",
    city: "",
    state: "",
    zip: "",
  },
  delivery_type: "",
  signature: "",
};
export default InitialValues;
