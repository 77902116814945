import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const BulletList = ({ listContent }) => {
  return (
    <List>
      {listContent.map((content, index) => (
        <ListItem key={index} sx={{ paddingLeft: 0, alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: 20 }}>
            <CircleIcon
              sx={{
                fontSize: 7,
                color: "black",
                minWidth: "10px !important",
              }}
            />
          </ListItemIcon>
          {content}
        </ListItem>
      ))}
    </List>
  );
};

export default BulletList;
