import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import AuthCode from "react-auth-code-input";
import { useDispatch } from "react-redux";
import MakeToast from "../../../Helpers/MakeToast";
import images from "../../../assets/js/images";
import { PersonalInformationService } from "../../../Services/PersonalInformationService";
import ScreenLoader from "../../Common/ScreenLoader";
import ModalPopup from "../../Modal/ModalPopup";

const Otp = ({
  openOtp,
  handleClose,
  userFieldData,
  type,
  handleCloseEdit,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [seconds, setSeconds] = useState(60);
  const [loader, setLoader] = useState(false);
  const handleOnChange = (res) => {
    setResult(res);
  };

  useEffect(() => {
    let interval = null;
    if (openOtp) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevTime) => prevTime - 1);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [openOtp, seconds]);

  const handleResent = () => {
    setLoader(true);
    PersonalInformationService.generateOtp({
      value: userFieldData,
      type: type,
    })
      .then((response) => {
        MakeToast("success", response);
        setSeconds(60);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleGoBack = () => {
    handleClose();
  };

  useEffect(() => {
    if (result?.length === 6) {
      setLoader(true);
      PersonalInformationService.verifyOtp(
        { otp: result, value: userFieldData, type: type },
        type
      )
        .then((response) => {
          handleClose();
          handleCloseEdit();
          setLoader(false);
          dispatch({
            type: "LOGIN_START",
            payload: response?.data,
          });
          MakeToast("success", response);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  }, [result]);

  return (
    <>
      <ScreenLoader open={loader} />
      <ModalPopup
        className="modalotp"
        open={openOtp}
        onClose={handleClose}
        content={
          <>
            <Box className="modalheader">
              <Box className="modal-heading-back" onClick={handleGoBack}>
                <Button
                  className="modal-back-btn border-radius-12"
                  sx={{ p: 0 }}
                >
                  <img src={images.backIcon} alt="" />
                </Button>
                <Box
                  id="modal-modal-title"
                  className="modal-heading"
                  component="h2"
                >
                  Enter your verification code
                </Box>
              </Box>
            </Box>
            <Box className="modal-body">
              <Typography component="p" className="sub-text secondary-text">
                {" "}
                The code was sent to{" "}
                <Link href="#" className="secondary-text">
                  {userFieldData}
                </Link>
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <Box component="div" className="otp-input">
                  <AuthCode onChange={handleOnChange} />
                </Box>
              </Box>
              {seconds > 0 ? (
                <Typography
                  component="p"
                  sx={{ mb: 0 }}
                  className="sub-text otp-code-msg"
                >
                  Resend code after
                  <Link href="#" className="primary-text">
                    {seconds} seconds
                  </Link>
                </Typography>
              ) : (
                <Link href="#" className="primary-text" onClick={handleResent}>
                  Resend Code
                </Link>
              )}
            </Box>
          </>
        }
      />
    </>
  );
};

export default Otp;
