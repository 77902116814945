import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../../../../../Context/TaxContext";

const RadioItem = (props) => {
  const { option } = props;

  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == option.id;
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={option.id}
      control={<Radio />}
      label={
        <>
          <Typography sx={{ fontSize: 16, color: "black", fontWeight: "600" }}>
            {option.id}
          </Typography>
          <Typography
            sx={{ fontSize: 14, color: "black", fontWeight: "light" }}
          >
            {option.description}
          </Typography>
        </>
      }
      sx={{
        fontSize: 19,
        mx: "0px !important",
        px: "3px !important",
        py: 2,
        my: 0.7,
        border: !checked ? "solid 2px #ebebeb" : "solid 2px #000",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          pl: 1,
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
};
const IDNumber = () => {
  const { formik } = useContext(FormContext);

  const types = [
    { id: "SSN", description: "Social Security Number" },
    { id: "EIN", description: "Employer Identification Number" },
  ];
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Tax ID number
      </Typography>
      <Typography sx={{ color: "#9b9a9a" }}>
        Individuals should provide a SSN. Registered businesses should add their
        EIN.
      </Typography>
      <RadioGroup
        name="type_id"
        value={formik.values.type_id}
        onChange={(event) =>
          formik.setFieldValue("type_id", event.target.value)
        }
      >
        {types.map((data, index) => (
          <RadioItem key={index} option={data} />
        ))}
      </RadioGroup>
      {formik.errors.type_id && formik.touched.type_id && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.type_id}
        </FormHelperText>
      )}
      {formik.values.type_id != "" && (
        <>
          <TextField
            placeholder={formik.values.type_id}
            name="id_number"
            value={formik.values.id_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              width: "100%",
              my: 1,
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9b9a9a",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #9b9a9a",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9b9a9a",
                },
              },
            }}
          />{" "}
          {formik.errors.id_number && formik.touched.id_number && (
            <FormHelperText className="field-helper-text error">
              {formik.errors.id_number}
            </FormHelperText>
          )}
        </>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Type and number must correspond with the taxpayer name on this form. We
        keep this info private.
      </Typography>
    </Box>
  );
};
export default IDNumber;
