import React from 'react';
import { Link as RouterLink } from "react-router-dom"

import {
    Container,
    Link,
    List, 
    ListItem,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import images from "../../assets/js/images";

const Footer = () => {
    return (
        <>
            <footer className="footer-container">
                <div className="primary-footer">
                    <Container>
                        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid xs={6} sm={4} md={4} lg={4}>
                                <h6 className="footer-heading">Company</h6>
                                <List className="footer-nav-list">
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >About</Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Jobs</Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none">Partnerships</Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Newsroom</Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Investor Relations</Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Advertising</Link>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid xs={6} sm={5} md={4} lg={4}>
                                <h6 className="footer-heading">Policies</h6>
                                <List className="footer-nav-list">
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Privacy policy </Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" > Terms of use </Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" > Accessibility </Link>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Link href="#" underline="none" >Your privacy choices</Link>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid xs={6} sm={3} md={4} lg={4}>
                                <h6 className="footer-heading">Help</h6>
                                <List className="footer-nav-list">
                                    <ListItem disablePadding>
                                        <RouterLink to="/help?tab=0" underline="none" > FAQ’s</RouterLink>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <RouterLink to="/help?tab=1" underline="none" >Support </RouterLink>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <RouterLink to="/help?tab=2" underline="none" > Tips for success </RouterLink>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <RouterLink to="/help?tab=3" underline="none" > Tutorials </RouterLink>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="secondary-footer">
                    <Container>
                        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid xs={6} sm={6} md={7} lg={8} className="terms-col">
                                <List className="footer-nav-inline-list" sx={{ p: 0 }}>
                                      <ListItem disablePadding>
                                      <Link
                                    href="#"
                                    underline="none"
                                >
                                    © 2022 Beachify
                                </Link>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid xs={6} sm={6} md={5} lg={4} className="socialize-col">
                                <Link
                                    href="#"
                                    underline="none"
                                >
                                    <img src={images.facebookIcon} alt="facebook" />
                                </Link>
                                <Link
                                    href="#"
                                    underline="none"
                                >
                                    <img src={images.instagramIcon} alt="instagram" />
                                </Link>
                                <Link
                                    href="#"
                                    underline="none"
                                >
                                    <img src={images.twitterIcon} alt="twitter" />
                                </Link>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default Footer;
