import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import {
  FormContext,
  PayoutMethodContext,
} from "../../../Context/PaymentsContext";
import MakeToast from "../../../Helpers/MakeToast";
import { PayoutMethodService } from "../../../Services/PayoutMethodService";
import { useFormik } from "formik";
import ValidationSchema from "./ValidationSchema";
import InitialValues from "./InitialValues";
import { useHistory } from "react-router-dom";
import Form from "./Form";

const BankAccount = () => {
  const { setPageIndex } = useContext(PayoutMethodContext);
  const navigate = useHistory();
  const handleSubmit = async (values) => {
    PayoutMethodService.store({
      paid_type: "BANKACCOUNT",
      info: values,
    })
      .then((response) => {
        MakeToast("success", response);
        navigate.push("/account-settings/payments-payouts");
      })
      .catch((error) => MakeToast("error", error));
    formik.setSubmitting(false);
  };
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  return (
    <FormContext.Provider
      value={{
        formik: formik,
      }}
    >
      <Container
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
          px: "20% !important",
          my: 9,
          py: 6,
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "500" }} component="h2">
          Add bank account info
        </Typography>
        <Form />
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "white",
          px: 3,
        }}
      >
        <Link
          sx={{ mt: "0px !important", fontSize: 17, fontWeight: "600" }}
          className="link-update"
          onClick={() => setPageIndex(0)}
        >
          Back
        </Link>
        <Button
          type="button"
          sx={{
            border: "1px solid black !important",
            backgroundColor: "black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          className="btn-primary submit-btn "
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </FormContext.Provider>
  );
};

export default BankAccount;
