import { Box, Container, Typography } from "@mui/material";
import NotificationsTabs from "../../Components/Notifications/Tabs";
import PhoneCard from "../../Components/Notifications/PhoneCard";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import CheckboxInput from "../../Components/Inputs/CheckboxInput";
import { useState } from "react";
import { NotificationSettingsService } from "../../Services/NotificationSettingsService";
import MakeToast from "../../Helpers/MakeToast";
import Grid from '@mui/material/Unstable_Grid2';

const Notifications = () => {
  const [unsubscribe, setUnsubcribe] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChangeMarketingEmails = (e) => {
    NotificationSettingsService.updateMarketingEmails({
      value: !unsubscribe,
    })
      .then((response) => {
        setUnsubcribe(!unsubscribe);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  return (
    <Container sx={{ py: 5 }}>
      <AccountSettingsTitle title="Notifications" routeLabel="Notifications" />
      <Grid sx={{ paddingTop: 5 }}rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12} md={8} item>
          <NotificationsTabs />
        </Grid>
        <Grid md={4} item className="mobile-none">
          <PhoneCard />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
        <CheckboxInput
          disabled={loading}
          inputtype="checkboxonly"
          checked={unsubscribe}
          onChange={onChangeMarketingEmails}
        />
        <Typography
          sx={{ ml: 1, opacity: loading ? "50%" : "100%" }}
          component="h4"
        >
          Unsubcribe from all marketing emails
        </Typography>
      </Box>
    </Container>
  );
};
export default Notifications;
