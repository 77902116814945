import { Box, Button, Chip, Grid, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { PayoutMethodService } from "../../../Services/PayoutMethodService";
import PayoutMethod from "../PayoutMethod";
import { useEffect } from "react";

const Payouts = () => {
  const navigate = useHistory();
  const [method, setMethod] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPayoutMethods = () => {
    PayoutMethodService.get()
      .then((response) => {
        setLoading(false);
        setMethod(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    getPayoutMethods();
  }, []);
  return (
    <Box>
      <Box className="become-host-step-form">        
      <Box className="small-heading">
              <Typography component="h2"  className="main-heading">
                How you’ll get paid
              </Typography>
        </Box>
      </Box>

      <Typography sx={{ fontWeight: "light", fontSize: 18 }}>
        Add at least one payout method so we know where to send your money.
      </Typography>
      {loading ? (
        <Grid spacing={2} sx={{ mt: 2 }} container>
          <Grid item>
            <Skeleton width={60} height={70} />
          </Grid>
          <Grid item>
            <Skeleton width={100} height={30} />
            <Skeleton width={200} height={30} />
          </Grid>
        </Grid>
      ) : (
        <PayoutMethod method={method} />
      )}

      <Button
        size="small"
        type="button"
        sx={{
          backgroundColor: "black !important",
          mt: 2,
          mb: 7,
          borderRadius: 16,
          border: "1px solid black !important",
        }}
        className="btn-primary submit-btn "
        onClick={() =>
          navigate.push("/account-settings/payments-payouts/create")
        }
      >
        Set up payouts
      </Button>
    </Box>
  );
};

export default Payouts;
