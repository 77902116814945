import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";

const TextEditor = ({ formEditor, name, value }) => {
  const editorRef = useRef(null);

  return (
    <Editor
      apiKey="0fjjn4envtsqryvp7broyrdafw6zysnip54pyqdwu11dhz3f"
      onInit={(evt, editor) => (editorRef.current = editor)}
      onChange={() => { formEditor(name, editorRef.current.getContent()) }}
      initialValue={''}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
};

export default TextEditor;
