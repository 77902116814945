import { Box, Typography } from "@mui/material";
import ConnectOption from "./ConnectOption";

const socialOptions = [
  {
    title: "Facebook",
    name: "facebook",
  },
  {
    title: "Google",
    name: "google",
  },
];

const SocialAccounts = () => {
  return (
    <Box sx={{ mt: 5 }}>
<Box className="become-host-step-form">
  <Box className="small-heading">
  <Typography sx={{ mb: 3 }} component="h2" className="main-heading">
        Social Accounts
      </Typography>
  </Box>
</Box>
      {socialOptions.map((socialOption) => (
        <ConnectOption {...socialOption} />
      ))}
    </Box>
  );
};

export default SocialAccounts;
