import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  outline: "none",
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "auto !important",
    width: "100% !important",
    // maxWidth: "100% !important",
    borderRadius: 10,
    "& .listResponsive": {
      maxHeight: "80% !important",
    },
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto !important",
    width: "90% !important",
    // maxWidth: "100% !important",
    borderRadius: 10,
    "& .listResponsive": {
      maxHeight: "80% !important",
    },
  },
}));

const TaxModal = (props) => {
  const history = useHistory();
  const { onClose } = props;
  const [locationLink, setLocationLink] = useState(null);
  const locations = [
    { name: "United States", link: "/account-settings/taxes/create/us" },
  ];

  const LocationItem = ({ location }) => {
    return (
      <FormControlLabel
        value={location.link}
        control={<Radio />}
        label={location.name}
        sx={{
          fontSize: 19,
          mx: "0px !important",
          py: "3px !important",
          borderBottom: "solid 1px #ebebeb",
          "& .MuiSvgIcon-root": {
            fontSize: 26,
            color: "black",
          },
          "& .MuiFormControlLabel-label": {
            pl: 1,
            lineHeight: "initial",
            fontWeight: "100",
          },
        }}
      />
    );
  };
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton
          sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
        </IconButton>
        <Box
          sx={{
            textAlign: "center",
            // height: "10%",
            borderBottom: "solid 1px #ebebeb",
            p: 2.5,
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Add tax info
          </Typography>
        </Box>
        <Box
          sx={{
            p: 3,
            maxHeight: "58rem",
            overflowX: "auto",
          }}
          className="listResponsive"
        >
          <Typography sx={{ fontSize: 15, mb: 1 }}>
            To start, choose the location where the taxpayer is a resident,
            Host, or Co-Host.
          </Typography>
          <RadioGroup
            name="location-radio-buttons-group"
            value={locationLink}
            onChange={(event) => setLocationLink(event.target.value)}
          >
            {locations.map((location, index) => (
              <LocationItem key={index} location={location} />
            ))}
          </RadioGroup>
        </Box>
        <Box
          sx={{
            // height: "10%",
            borderTop: "solid 1px #ebebeb",
            p: 2,
            px: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link className="link-update" onClick={onClose}>
            Cancel
          </Link>
          <Button
            size="small"
            type="button"
            sx={{
              backgroundColor: "black !important",
              border: "1px solid black !important",
            }}
            className="btn-primary submit-btn rounded-button"
            onClick={() => {
              history.push(locationLink);
            }}
            disabled={!locationLink ? true : false}
          >
            Continue
          </Button>
        </Box>
      </ResponsiveStyles>
    </Modal>
  );
};

export default TaxModal;
