import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import { Box, Tabs, Tab, Button, Link, FormControl, Input, InputAdornment, Container, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import parse from 'html-react-parser'
import { useSelector } from "react-redux";
import { FaqService } from "../../Services/FaqService";

import images from "../../assets/js/images";
import Support from '../../Components/Support/Support';


const Faq = () => {
    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

    const [faq, setFaq] = React.useState([]);
    const [value, setValue] = React.useState('00');
    const handleChange = (event, newValue) => {
        setValue(newValue);

        setTimeout(() => {
            document.querySelectorAll(".contactus").forEach((el) =>
                el.addEventListener("click", (e) => {
                    setOpenSupport(true)
                })
            );
        }, 1000);
    };

    const getSearch = (value) => {
        if (isLoggedIn && user.role_id == "2") {
            getHostFaq(value);
        }else{
            getRenterFaq(value);
        }
    }
    
    const getRenterFaq = (search = '') => {
        setFaq([]);
        setValue('00');
        FaqService.renters({ search: search }).then(response => {
            setFaq(response.data.data);
        }).catch(error => {

        });
    }

    const getHostFaq = (search = '') => {
        setFaq([]);
        setValue('00');

        FaqService.hosts({ search: search }).then(response => {
            setFaq(response.data.data);
        }).catch(error => {

        });
    }
    
    // window resize starts here
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = React.useState(getWindowSize());
    React.useEffect(() => {
        if (isLoggedIn && user.role_id == "2") {
            getHostFaq();
        }else{
            getRenterFaq();
        }

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    // window resize ends here

    // Tab starts here
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Box className="faq-answer">
                            <h3>{children.question}</h3>
                            {parse(children.answer)}
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    // Tab ends here

    // Accordion starts here
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<img src={images.plusfillicon} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper': {
            flexShrink: '0',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(45deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    const [expanded, setExpanded] = React.useState('panel0');
    const handleChange1 = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [openSupport, setOpenSupport] = React.useState(false);
    // Accordion ends here

    return (
        <>
            <Support setOpen={setOpenSupport} open={openSupport} />
            <section className="faq-section ">
                {/* <Container> */}
                    <Grid container>
                        <Grid xs={12} sx={{ py: 0 }}>
                            <Box sx={{
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "20px",
                                '@media (max-width:991px)': {
                                    display: "flex",
                                    marginBottom: "10px",
                                },
                            }}>
                                <Box className="main-heading-block" sx={{ justifyContent: "start", }}>
                                    <RouterLink to="/">
                                        <Button
                                            className="modal-back-btn"
                                            sx={{ p: 0 }}>
                                            <img src={images.backIcon} alt="" />
                                        </Button>
                                    </RouterLink>
                                    <h2 className="main-heading m-0">FAQs</h2>
                                </Box>
                                <Box sx={{
                                    display: "none",
                                    marginLeft: "auto",
                                    '@media (max-width:991px)': {
                                        display: "flex",
                                    },
                                }}>
                                    <IconButton aria-label="faq-search" component="label">
                                        <img src={images.searchIcon} alt="search" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        {windowSize.innerWidth <= 991 ?
                            <Box className="faq-accordion w-100">
                                {Object.keys(faq).map((r, i) => (
                                    faq[r].map((result, index) => (
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel' + (i+''+index)} onChange={handleChange1('panel' + (i+''+index))} className="question-block" >
                                        <AccordionSummary aria-controls={'panel' + (i+''+index) + 'd-content'} id={'panel' + (i+''+index) + 'd-header'} className="faq-question bg-white" sx={{ flexDirection: "row" }}>
                                            {result.question}
                                        </AccordionSummary>
                                        <AccordionDetails className="border-0 p-0 faq-answer">
                                            <p>{parse(result.answer)}</p>
                                        </AccordionDetails>
                                    </Accordion>
                                ))))}
                                {/* <div className="mobile-bottom-btn"> */}
                                    <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        className="mobile-link-btn btn-primary"
                                        color="inherit"
                                        onClick={() => setOpenSupport(true)}
                                    >
                                        Contact Us
                                    </Link>
                                {/* </div> */}
                            </Box>
                            :
                            <Box className="vertical-tab-block"
                                sx={{ flexGrow: 1, display: 'flex' }}
                            >
                                <Box className="tab-block border-radius-16">
                                    <FormControl variant="standard" className="input-style outlined-input" underline="none">
                                        <Input
                                            id="input-with-icon-adornment "
                                            placeholder="Search"
                                            underline="none"
                                            disableUnderline={true}
                                            aria-controls={open ? 'search-bear-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            startAdornment={
                                                <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                    <img src={images.searchIcon} alt="search" />
                                                </InputAdornment>
                                            }
                                            onKeyUp={(e) => { getSearch(e.target.value) }}
                                        />
                                    </FormControl>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        className="question-block"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        style={{maxHeight: '600px', overflow: 'auto'}}
                                    >
                                        {
                                            Object.keys(faq).map((r, i) => (
                                                faq[r].map((result, index) => (  
                                                    <Tab className="faq-question" value={(i+''+index)} label={parse((r!='' && index==0 ? '<p className="faq-subheading">'+r+'</p>' : '')+result.question)} {...a11yProps((i+''+index))} />
                                            ))))
                                        }
                                    </Tabs>
                                    <Box className="tab-support-button">
                                        <Link underline="none" component="button" className="btn-primary border-radius-md w-100" sx={{ textTransform: 'capitalize', marginBottom: '0px !important', padding: '15px 10px !important' }} onClick={() => setOpenSupport(true)} >
                                            Contact Us
                                        </Link>
                                    </Box>
                                </Box>
                                {Object.keys(faq).map((r, i) => (
                                    faq[r].map((result, index) => (
                                    <TabPanel className="faq-value-block border-radius-16" value={value} index={(i+''+index)}>
                                        {result}
                                    </TabPanel>
                                ))))}
                            </Box>
                        }
                    </Grid>
                {/* </Container> */}
            </section>
        </>
    )
}

export default Faq;
