import { ProfileContext } from "../../../Context/ProfileContext";
import { useContext, useState, useEffect } from "react";
import { Chip, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { cloneDeep } from "lodash";
const LanguageList = () => {
  const { languages, setLanguages } = useContext(ProfileContext);
  const [languagesList, setLanguagesList] = useState(
    languages.userLanguages.filter((language) => language.checked)
  );
  useEffect(
    () =>
      setLanguagesList(
        languages.userLanguages.filter((language) => language.checked)
      ),
    [languages]
  );
  const handleDelete = (language) => {
    let newLanguagesData = cloneDeep(languages.userLanguages);
    let index = newLanguagesData.findIndex(
      (languageData) => languageData.id == language.id
    );
    if (index != -1) {
      newLanguagesData[index].checked = false;
      setLanguages({
        userLanguages: [...newLanguagesData],
        availableLanguages: [...languages.availableLanguages],
      });
    }
  };
  return (
    <Stack direction="row" spacing={1}>
      {(languagesList ?? []).map((language) => (
        <Chip
          sx={{
            fontSize: 16,
            height: 45,
            px: 1,
            borderRadius: 50,
          }}
          key={language.id}
          label={language.name}
          variant="outlined"
          deleteIcon={<CloseIcon />}
          onDelete={() => handleDelete(language)}
        />
      ))}
    </Stack>
  );
};

export default LanguageList;
