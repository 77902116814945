import { FormControl, FormHelperText, TextField, Grid } from "@mui/material";
import * as yup from "yup";
import SelectInput from "../../Inputs/SelectInput";
import FormControlEdit from "../Hoc/FormControlEdit";
import { usePlacesWidget } from "react-google-autocomplete";
import { useState } from "react";
import getAddressComponentData from "../../../Helpers/LocationComponenHelper";

let informationValidationSchema = yup.object().shape({
  country_id: yup
    .number()
    .required("Country is required")
    .typeError("Country must be a text"),
  address_number: yup
    .string()
    .nullable()
    .typeError("Address Number must be a text"),
  street: yup
    .string()
    .required("Street is required")
    .typeError("Street must be a text"),
  city: yup
    .string()
    .required("City is required")
    .typeError("City must be a text"),
  state: yup
    .string()
    .required("State is required")
    .typeError("State must be a text"),
  zip_code: yup
    .string()
    .required("ZIP Code is required")
    .typeError("ZIP Code must be a text"),
});
const Address = ({ formik, countries }) => {
  const [countryCode, setCountryCode] = useState();
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["address"],
      fields: ["address_components", "geometry"],
      componentRestrictions: {
        country: countryCode ? [countryCode] : [],
      },
    },
    onPlaceSelected: (place) => {
      let placeData = getAddressComponentData(place.address_components);
      if (placeData.street_address)
        formik.setFieldValue("street", placeData.street_address);
      if (placeData.postal_code)
        formik.setFieldValue("zip_code", placeData.postal_code);
      if (placeData.state) formik.setFieldValue("state", placeData.state);
      if (placeData.city) formik.setFieldValue("city", placeData.city);
      if (placeData.country) {
        let countryData = countries.find((o) => o.code == placeData.country);
        formik.setFieldValue("country_id", countryData.id);
      }
    },
  });
  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControl
          variant="standard"
          className="input-style outlined-input select-style textfield-rouded-md"
          sx={{
            width: "100%",
            borderRadius: "18px",
            mb: 1,
            pl: 4,
            mr: 4,
          }}
        >
          {countries?.length > 0 ? (
            <SelectInput
              customArr={{ data: countries, name: "name", id: "id" }}
              name="country_id"
              value={values.country_id}
              onChange={(e) => {
                handleChange(e);
                let countryData = countries.find((o) => o.id == e.target.value);
                setCountryCode(countryData.code || "");
              }}
              onBlur={handleBlur}
              renderValue={(selected) => {
                if (
                  (selected == undefined || selected == "") &&
                  (values.country_id == undefined || values.country_id == "")
                ) {
                  return <em>Country</em>;
                }

                let countryData = countries.find(
                  (o) => o.id == values.country_id
                );
                return countryData ? countryData.name : "";
              }}
            />
          ) : (
            <SelectInput
              className="opacity-40"
              renderValue={(selected) => {
                return <em>Country</em>;
              }}
            />
          )}
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.country_id && touched.country_id && errors.country_id}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          sx={{ width: "100%", mb: 1 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Street Address"
            inputRef={ref}
            name="street"
            value={values.street}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.street && touched.street && errors.street}
        </FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          sx={{ width: "100%", mb: 1, pr: 1 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Apt, suite (optional)"
            name="address_number"
            value={values.address_number}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.address_number &&
            touched.address_number &&
            errors.address_number}
        </FormHelperText>
      </Grid>

      <Grid item xs={6}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="State"
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.state && touched.state && errors.state}
        </FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          sx={{ width: "100%", pr: 1 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="ZIP Code"
            name="zip_code"
            value={values.zip_code}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.zip_code && touched.zip_code && errors.zip_code}
        </FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.city && touched.city && errors.city}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default FormControlEdit(Address, {
  informationValidationSchema: informationValidationSchema,
  fieldName: "address",
  label: "Address",
  description: "Please enter your information below.",
});
