import React from "react";
import { Box, Modal } from '@mui/material';

const ModalPopup = ({ open, onClose, content, className, contentStyle }) => {
    const modalWrapper = {
        overflow: "auto",
        maxHeight: "100vh",
        display: "flex",
    };
    
    const modalBlock = {
        position: "relative",
        zIndex: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        width: "100%",
    }
    
    const modalContentStyle = {
        position: "relative",
        width: contentStyle!=null && contentStyle.width ? contentStyle.width : 500,
        minHeight: contentStyle!=null && contentStyle.minHeight ? contentStyle.minHeight : 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: contentStyle!=null && contentStyle.padding ? contentStyle.padding : '35px',
        borderRadius: '32px',
        margin: '20px',
        '@media (max-width:769px)': {
            width: contentStyle!=null && contentStyle.width1 ? contentStyle.width1 : 440,
            padding: contentStyle!=null && contentStyle.padding1 ? contentStyle.padding1 : '30px',
            borderRadius: '16px',
        },
        '@media (max-width:577px)': {
            width: contentStyle!=null && contentStyle.width2 ? contentStyle.width2 : '95%',
            padding: contentStyle!=null && contentStyle.padding2 ? contentStyle.padding2 : '25px 20px',
            borderRadius: '16px',
        },
    };

    return (
        <>
            <Modal 
                disableEnforceFocus
                className={className}
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={modalWrapper}
            >
                <Box sx={modalBlock}>
                    <Box sx={modalContentStyle}>
                        {content}
                    </Box>
                </Box>
            </Modal >
        </>
    );

};

export default ModalPopup;
