import React from 'react';
import { InputBase, Select, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import parse from 'html-react-parser'

const SelectInput = ({ customObj, customArr, customArr1, name, value, onBlur, onChange, renderValue, className, sx, startAdornment, selectdisabled, disabled, multiple }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 150,
            },
        },
    };

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            // marginTop: theme.spacing(5),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),

        },
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }));

    return (
        <>
            <Select
                displayEmpty
                labelId="demo-customized-select-label"
                MenuProps={MenuProps}
                input={<BootstrapInput />}
                id="demo-customized-select"
                className={"customized-select " + className}
                startAdornment={startAdornment}
                renderValue={renderValue}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                value={value}
                sx={sx}
                multiple={multiple}
                disabled={selectdisabled ? true : false}
            >
                {
                    (customObj != '' && customObj != undefined) &&
                    Object.keys(customObj).map((i) => (
                        <MenuItem
                            key={i}
                            value={i}
                            disabled={disabled != '' && disabled != undefined && disabled.findIndex(o => { return o == i }) > -1 && i != value ? true : null}
                        >
                            {customObj[i]}
                        </MenuItem>
                    ))
                }

                {
                    (customArr != '' && customArr != undefined) &&
                    customArr.data.map((result, index) => (
                        <MenuItem
                            key={index}
                            value={result[customArr.id]}
                        >
                            {parse(result[customArr.name])}
                        </MenuItem>
                    ))
                }

                {
                    (customArr1 != '' && customArr1 != undefined) &&
                    Object.keys(customArr1).map((i) => (
                        <MenuItem
                            key={i}
                            value={customArr1[i]}
                            disabled={disabled != '' && disabled != undefined && disabled.findIndex(o => { return o == customArr1[i] }) > -1 && customArr1[i] != value ? true : null}
                        >
                            {customArr1[i]}
                        </MenuItem>
                    ))
                }
            </Select>
        </>
    )
}

export default SelectInput;