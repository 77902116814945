import { Container } from "@mui/material";
import SettingsTabs from "../../Components/PrivacyAndSharing/SettingsTabs";
import PrivacyInfoCard from "../../Components/PrivacyAndSharing/PrivacyInfoCard";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import Grid from '@mui/material/Unstable_Grid2';

const PrivacyAndSharing = () => {
  return (
    <Container sx={{ py: 5,}}>
      <AccountSettingsTitle
        title="Privacy and sharing"
        routeLabel="Privacy & sharing"
      />
      <Grid sx={{ paddingTop: 5 }} rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12} md={8} item>
          <SettingsTabs />
        </Grid>
        <Grid md={4} item>
          <PrivacyInfoCard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyAndSharing;
