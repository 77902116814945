import { Box, Typography } from "@mui/material";
import ReviewCard from "./ReviewCard";
import LoadingReview from "./LoadingReview";
import { useContext } from "react";
import { ReviewsContext } from "../../Context/ReviewsContext";

const exampleReviews = [
  {
    name: "Bart Cassidy",
    date: "April 2021",
    comment: "This is an example review on bechify",
  },
  {
    name: "Example user",
    date: "November 2022",
    comment: "This is other example review on bechify",
  },
];

const ReviewsByYouTab = () => {
  const { loading, reviews } = useContext(ReviewsContext);

  return (
    <Box sx={{ padding: "0px !important" }}>
      {loading ? (
        <LoadingReview />
      ) : (
        <>
          {/* <Typography variant="h3" sx={{ fontWeight: "600" }}>
            Reviews to write
          </Typography>
          {exampleReviews.map((review, index) => (
            <ReviewCard key={index} {...review} />
          ))} */}
          <Typography
            variant="h3"
            sx={{ marginTop: "30px !important", fontWeight: "600" }}
          >
            Past reviews you’ve written
          </Typography>

          {reviews.byYou.map((review, index) => (
            <ReviewCard key={index} review={review} userField="reviewed" />
          ))}
        </>
      )}
    </Box>
  );
};

export default ReviewsByYouTab;
