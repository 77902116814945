import { useContext } from "react";
import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { FormContext, TaxFormContext } from "../../../../../Context/TaxContext";
import SelectInput from "../../../../Inputs/SelectInput";

const CountryRegion = () => {
  const { formik } = useContext(FormContext);
  const { countries } = useContext(TaxFormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Country/region of incorporation or residence
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
          mt: 1,
        }}
      >
        {countries?.length > 0 ? (
          <SelectInput
            customArr={{ data: countries, name: "name", id: "id" }}
            name="country_region"
            value={formik.values.country_region}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (formik.values.country_region == undefined ||
                  formik.values.country_region == "")
              ) {
                return <em>Country/region of incorporation or residence</em>;
              }

              let optionData = countries.find(
                (o) => o.id == formik.values.country_region
              );
              return optionData ? optionData.name : "";
            }}
          />
        ) : (
          <SelectInput
            className="opacity-40"
            renderValue={(selected) => {
              return <em>Country</em>;
            }}
          />
        )}
      </FormControl>
      {formik.errors.country_region && formik.touched.country_region && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.country_region}
        </FormHelperText>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        For individuals, select the country or region of residence for tax
        purposes. All other entity types, select the country or region under
        whose laws the entity is organized or incorporated.
      </Typography>
    </Box>
  );
};

export default CountryRegion;
