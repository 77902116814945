import { useState } from "react";
import UnitedStates from "./PayersLocations/UnitedStates/UnitedStates";
import { Box, Container, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TaxFormContext } from "../../Context/TaxContext";
import { useHistory } from "react-router-dom";
import W9 from "../../Components/Taxes/TaxesLocations/UnitedStates/W9/W9";
import W8ECI from "../../Components/Taxes/TaxesLocations/UnitedStates/W9ECI/W8ECI";
import W8BEN from "../../Components/Taxes/TaxesLocations/UnitedStates/W8BEN/W8BEN";
import { CountryService } from "../../Services/CountryService";
import { useEffect } from "react";
const TaxInfo = () => {
  useEffect(() => {
    getCountries();
  }, []);
  const history = useHistory();
  const [pageIndex, setPageIndex] = useState(0);
  const [countries, setCountries] = useState({});
  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <TaxFormContext.Provider
      value={{
        setPageIndex: setPageIndex,
        countries: countries,
      }}
    >
      <Container
        sx={{
          height: "100% !important",
          width: "100% !important",
          maxWidth: "100% !important",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: "1",
          padding: "0px !important",
          overflowY: "clip",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75px",
            backgroundColor: "white",
            borderBottom: "solid 1px #ebebeb",
            position: "fixed",
            width: "100%",
            top: 0,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
            onClick={() => {
              history.push("/account-settings/taxes");
            }}
          >
            <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
          </IconButton>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Add tax info
          </Typography>
        </Box>
        {pageIndex == 0 && <UnitedStates />}
        {pageIndex == 1 && <W9 />}
        {pageIndex == 2 && <W8ECI />}
        {pageIndex == 3 && <W8BEN />}
      </Container>
    </TaxFormContext.Provider>
  );
};

export default TaxInfo;
