import { Container, Link, Typography } from "@mui/material";
import ReviewsTabs from "./ReviewsTabs";
import { ReviewsContext } from "../../Context/ReviewsContext";
import { ReviewsService } from "../../Services/ReviewsService";
import MakeToast from "../../Helpers/MakeToast";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const UsersReviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState({
    byYou: [],
    aboutYou: [],
  });

  useEffect(() => {
    async function getReviews() {
      let byYou = [];
      let aboutYou = [];
      await ReviewsService.byYou()
        .then((response) => (byYou = response.data.data))
        .catch((error) => MakeToast("error", error));
      await ReviewsService.aboutYou()
        .then((response) => (aboutYou = response.data.data))
        .catch((error) => MakeToast("error", error));
      setReviews({
        byYou: byYou,
        aboutYou: aboutYou,
      });
      setLoading(false);
    }
    getReviews();
  }, []);
  const navigate = useHistory();

  return (
    <ReviewsContext.Provider value={{ loading: loading, reviews: reviews }}>
      <Container sx={{ py: 5 }}>
        <BreadCrumb data={[["Profile", "goback"], ["Reviews"]]} />
        <Typography
          sx={{ fontWeight: "500", marginTop: "20px !important" }}
          component="h1"
        >
          Reviews by you
        </Typography>
        <ReviewsTabs />
      </Container>
    </ReviewsContext.Provider>
  );
};

export default UsersReviews;
