import { Container, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DataForm from "../../Components/PrivacyAndSharing/DataForm";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';

const PrivacyAndSharingData = () => {
  return (
    <Container sx={{ marginTop: 2,  }}>
      <Grid container>
        <Grid xs={12} md={6} item>
          <Link to="/account-settings/privacy-and-sharing">
            <ArrowBackIosIcon sx={{ color: "black" }} />
          </Link>
          <Typography
            sx={{ fontWeight: "500", paddingTop: 2, paddingBottom: 3 }}
            variant="h2"
          >
            Request your personal data
          </Typography>
          <Typography sx={{ fontWeight: "light" }} variant="h4">
            Before we get you a copy of your data, we’ll just need you to answer
            a few questions.
          </Typography>
          <DataForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyAndSharingData;
