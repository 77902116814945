import React from 'react';
import { useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Link,
    List,
    ListItemText,
    Paper,
    Typography,
    CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from "react-redux";

import images from "../../assets/js/images";
import MakeToast from '../../Helpers/MakeToast';
import AppHelper from '../../Helpers/AppHelper';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ServicePayment from '../../Components/Service/ServicePayment';
import QuantityInput from "../../Components/Inputs/QuantityInput";
import ExistingCard from '../../Components/Stripe/ExistingCard';

import { BookingService } from '../../Services/BookingService';
import { CartService } from "../../Services/CartService";
import { Link as RouterLink } from "react-router-dom";
import ScreenLoader from '../../Components/Common/ScreenLoader';

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const currency = AppHelper.currency();

    const [cartlist, setCartList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const getCartList = () => {
        setLoader(true);
        AppHelper.cartLists(setCartList)
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    };

    const cartDeleteAll = () => {
        AppHelper.cartDeleteByUserAction(getCartList, dispatch)
    };

    const { isLoggedIn } = useSelector((state) => state.userReducers.data);
    React.useEffect(() => {
        if (isLoggedIn) {
            getCartList();
        }
    }, []);

    const updates = useSelector((state) => state.cart);
    React.useEffect(() => {
        if (isLoggedIn) {
            getCartList();
        }
    }, [updates]);

    const [openPayment, setOpenPayment] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState('')

    const formik = useFormik({
        initialValues: {},
        onSubmit: values => {
            formik.setSubmitting(true);
            values.payment_method = cartlist.payment_method.stripe_payment_method_id;
            confirmBooking(values)
        },
    });

    const handleSubmitPayment = () => {
        if (cartlist.payment_method == null) {
            setOpenPayment(true);
        } else {
            formik.submitForm();
        }
    }

    const { values } = formik;

    const handlePaymentMethod = (value) => {
        setOpenPayment(false);
        formik.setSubmitting(true);
        setPaymentMethod(value.id);
        values.payment_method = value.id;
        confirmBooking(values)
    }

    const confirmBooking = (values) => {
        BookingService.confirm(values).then(res => {
            formik.setSubmitting(false);
            MakeToast("success", res.data.message, 5000);
            setOpenPayment(false);
            setPaymentMethod({});
            navigate.push('/bookings');
        }).catch((error) => {
            formik.setSubmitting(false);
            MakeToast("error", error);
            setPaymentMethod({});
        });
    }

    const handleExistingCard = () => {
        setOpenPayment(true);
    }

    function handleBack() {
        navigate.go(-1);
    }

    return (
        <>
            <ScreenLoader open={loader} />
            <ServicePayment openPayment={openPayment} setOpenPayment={setOpenPayment} handlePaymentMethod={handlePaymentMethod} />
            <section className="cart-page-section inner-section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <BreadCrumb data={[["Cart"]]} />
                        <Grid item xs={12}>
                            <div className="service-top-header">
                                <div className="left-back-arrow ">
                                    <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        className=" tertiary-text"
                                        color="inherit"
                                        onClick={handleBack}
                                        style={{ 'fontSize': '13px' }}
                                    >
                                        <img src={images.backIcon} alt=""
                                        />
                                        Back
                                    </Link>
                                </div>
                                {/* <div className="mobile-bottom-btn">
                                    <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        className="mobile-link-btn btn-primary"
                                        color="inherit"
                                    >
                                        Checkout
                                    </Link>
                                </div> */}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Box sx={{
                                '@media (max-width:1024px)': {
                                    paddingRight: '0',
                                },
                            }}
                            >
                                <div className="main-heading-block">
                                    <h2 className="main-heading">
                                        Cart
                                        {
                                            cartlist.items?.length
                                                ?
                                                <span className="tertiary-text cart-items">{cartlist.items?.length} item{cartlist.items?.length == 1 ? '' : 's'}</span>
                                                :
                                                <></>
                                        }
                                    </h2>
                                    {
                                        cartlist.items?.length > 1
                                            ?
                                            <Link
                                                component="button"
                                                variant="body2"
                                                underline="none"
                                                className="view-all-btn primary-text"
                                                sx={{ display: 'inline-block !important' }}
                                                onClick={cartDeleteAll}
                                            >
                                                <img src={images.deleteIcon} alt="" />
                                                <span>Delete All</span>
                                            </Link>
                                            :
                                            <></>
                                    }
                                </div>
                                {
                                    cartlist.items?.length
                                        ?
                                        <>
                                            {cartlist.items?.map((v, i) => {
                                                return (
                                                    <Card className="cart-item" sx={{ display: 'flex', width: '100%' }} elevation={0} square>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ flexShrink: '0' }}
                                                            image={v.featureImg?.imgPath}
                                                            alt="Live from space album cover"
                                                            className="cart-item-img border-radius-12"
                                                        />
                                                        <Box
                                                            sx={{
                                                                width: "100% !important",
                                                                '@media (max-width:575px)': {
                                                                    width: 'calc(100% - 85px)',
                                                                    position: 'relative',
                                                                },
                                                            }}
                                                        >
                                                            <CardContent
                                                                sx={{
                                                                    p: 0,
                                                                    width: "100%",
                                                                    '&:last-child': {
                                                                        paddingBottom: 0,
                                                                    }
                                                                }}
                                                            >
                                                                <Box className='cart-section-block' sx={{
                                                                    display: "flex", justifyContent: "space-between",
                                                                    '@media (min-width: 0px) and (max-width: 767.98px)': {
                                                                        flexWrap: "wrap",
                                                                    }
                                                                }}>
                                                                    <Box className="cart-product-name">
                                                                        <RouterLink to={"/service-detail/" + v.product_slug} underline="none" className="cart-item-name secondary-text" sx={{ marginRight: '10px' }}>
                                                                            {v.title}
                                                                        </RouterLink>
                                                                        <Box component="div" className="beachgear-value-input">
                                                                            <QuantityInput
                                                                                decrement={() => {
                                                                                    AppHelper.cartQuantityAction(0, i, v.cart_id, v.quantity, cartlist, getCartList, dispatch, MakeToast)
                                                                                }}
                                                                                quantity={v.quantity}
                                                                                increment={() => {
                                                                                    AppHelper.cartQuantityAction(1, i, v.cart_id, v.quantity, cartlist, getCartList, dispatch, MakeToast)
                                                                                }}
                                                                            />
                                                                        </Box>

                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            flexShrink: '0',
                                                                            ml: 'auto',
                                                                            width: '150px',
                                                                            '@media (max-width:575px)': {
                                                                                width: '100%',
                                                                                borderTop: '1px solid #EEEEEE',
                                                                                paddingTop: '15px',
                                                                                marginTop: '15px',
                                                                            },
                                                                        }}>

                                                                        <CardContent
                                                                            sx={{
                                                                                height: '100%',
                                                                                p: 0,
                                                                                '&:last-child': {
                                                                                    paddingBottom: 0,
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Box className="horizontal-card-right-side"
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'end',
                                                                                    justifyContent: 'space-between',
                                                                                    flexDirection: 'column',
                                                                                    height: 'unset',
                                                                                    '@media (max-width:575px)': {
                                                                                        alignItems: 'center',
                                                                                        flexDirection: 'row',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <RouterLink to={'/host/' + v.host_slug}>
                                                                                    <Box className="secondary-host-img host-img">
                                                                                        <Box className="calender-small-logo">
                                                                                            <img src={v.profile_image} alt="" />
                                                                                        </Box>
                                                                                        <h2 className="host-heading line-clamp-1 teritary-text">
                                                                                            {v.company_name}
                                                                                        </h2>
                                                                                    </Box>
                                                                                </RouterLink>
                                                                                <Box sx={{ textAlign: 'right', marginTop: "5px" }}>
                                                                                    <Typography component="span" className="cart-item-value-each tertiary-text fz-2">{currency}{v.amount} x {v.quantity} item{v.quantity == 1 ? '' : 's'} : {currency}{v.subtotal}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </CardContent>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="cart-date" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', flexWrap: "wrap", }}>
                                                                    <Box className='cart-date-range' sx={{
                                                                        padding: '15px 0', display: "flex",
                                                                        '@media (min-width: 0px) and (max-width: 575.98px)': {
                                                                            padding: '15px 0 0 0',
                                                                        }

                                                                    }}>
                                                                        <Box className="start-date" style={{ paddingRight: '10px' }}>
                                                                            <Typography component="p" className="tertiary-text fz-2">Beginning date</Typography>
                                                                            <Typography component="p" className="trip-date secondary-text fz-2">{AppHelper.convertDateTime(v.start_datetime)}</Typography>
                                                                        </Box>
                                                                        <Box className="start-date end-date" style={{ paddingLeft: '10px' }}>
                                                                            <Typography component="p" className="tertiary-text fz-2">End date</Typography>
                                                                            <Typography component="p" className="trip-date secondary-text fz-2">{AppHelper.convertDateTime(v.end_datetime)}</Typography>
                                                                        </Box>

                                                                    </Box>

                                                                    <Box className="cart-info-block" sx={{ width: 'unset', marginRight: '0' }}>
                                                                        <Button variant="text" className="cart-delete-option" disableRipple onClick={() => { AppHelper.cartDeleteAction(v.cart_id, getCartList, dispatch) }}>
                                                                            <img src={images.deleteIcon} alt="" />
                                                                            <span>Delete</span>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                                <Box>
                                                                    {
                                                                        v.delivery_address != ''
                                                                            ?
                                                                            <Box>
                                                                                <Typography component="p" className="fz-2"><img src={images.locationGreyIcon} alt="location" /><b> Delivery :</b> {v?.delivery_address?.address}</Typography>
                                                                            </Box>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        v.pickup_address != ''
                                                                            ?
                                                                            <Box style={{ marginTop: "10px" }}>
                                                                                <Typography component="p" className="fz-2"><img src={images.locationGreyIcon} alt="location" /><b> Pickup  :</b> {v.pickup_address?.address}</Typography>
                                                                            </Box>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </Box>
                                                                {/* <Box className="cart-info-block" sx={{ marginTop: '15px' }}>
                                                                    <Button variant="text" className="cart-delete-option" disableRipple onClick={() => { AppHelper.cartDeleteAction(v.cart_id, getCartList, dispatch) }}>
                                                                        <img src={images.deleteIcon} alt="" />
                                                                        <span>Delete</span>
                                                                    </Button>
                                                                </Box> */}
                                                            </CardContent>
                                                        </Box>
                                                    </Card>
                                                )
                                            })}
                                        </>
                                        :
                                        <p>No item in the cart</p>
                                }
                            </Box>
                        </Grid>
                        {
                            cartlist.items?.length
                                ?
                                <>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <form onSubmit={formik.handleSubmit}>
                                            <Paper className="border-radius-30 medium-shadowed-sidebar cart-checkout-sidebar">
                                                <Box className="cart-summary">
                                                    <List component="div" disablePadding>
                                                        {
                                                            cartlist.subtotal != '' && cartlist.subtotal != '0'
                                                                ?
                                                                <ListItemText className="summary-price secondary-text fz-2">Subtotal <span>{currency}{cartlist.subtotal}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            cartlist.securitydeposittotal != '' && cartlist.securitydeposittotal != '0'
                                                                ?
                                                                <ListItemText className="summary-price secondary-text fz-2">Security Deposit <span>{currency}{cartlist.securitydeposittotal}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            cartlist.service_charge_total != '' && cartlist.service_charge_total != '0'
                                                                ?
                                                                <ListItemText className="summary-price secondary-text fz-2">Beachify Service Fee  <span>{currency}{cartlist.service_charge_total}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            cartlist.deliverytotal != '' && cartlist.deliverytotal != '0'
                                                                ?
                                                                <ListItemText className="summary-price secondary-text fz-2">Delivery Total <span>{currency}{cartlist.deliverytotal}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            cartlist.pickuptotal != '' && cartlist.pickuptotal != '0'
                                                                ?
                                                                <ListItemText className="summary-price secondary-text fz-2">Pickup Total <span>{currency}{cartlist.pickuptotal}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            cartlist.total != '' && cartlist.total != '0'
                                                                ?
                                                                <ListItemText className="summary-price summary-price-total fz-2"><span style={{ fontSize: "1.9rem" }}>Total</span> <span>{currency}{cartlist.total}</span></ListItemText>
                                                                :
                                                                <></>
                                                        }
                                                    </List>
                                                </Box>
                                                {
                                                    cartlist.payment_method != null
                                                        ?
                                                        <ExistingCard paymentMethod={cartlist.payment_method} handleExistingCard={handleExistingCard} />
                                                        :
                                                        <></>
                                                }
                                                <Button
                                                    type="button"
                                                    fullHeight
                                                    disableElevation
                                                    variant="contained"
                                                    onClick={() => handleSubmitPayment()}
                                                    disabled={formik.isSubmitting}
                                                    className="border-radius-md btn-primary save-btn w-100"
                                                >
                                                    {formik.isSubmitting ? <CircularProgress /> : 'Checkout'}
                                                </Button>
                                            </Paper>
                                        </form>
                                    </Grid>
                                </>
                                :
                                <></>
                        }
                    </Grid>
                </Container>
            </section>
        </>
    )
}
export default Cart;