import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SelectInput from "../../Inputs/SelectInput";
import { useContext } from "react";
import {
  FormContext,
  PayoutMethodContext,
} from "../../../Context/PaymentsContext";

const CardHolderDetails = () => {
  const { formik } = useContext(FormContext);
  const { countries } = useContext(PayoutMethodContext);

  return (
    <>
      <Typography
        sx={{ fontWeight: "500", mt: "16px !important" }}
        component="h4"
      >
        Cardholder details
      </Typography>
      <Typography sx={{ color: "#9b9a9a", mt: 1 }}>First Name</Typography>
      <TextField
        placeholder="First Name"
        name="cardholder_details.first_name"
        value={formik.values.cardholder_details.first_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          borderRadius: "18px !important",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#eee",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #eee",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#eee",
            },
          },
        }}
      />
      {formik.errors.cardholder_details?.first_name &&
        formik.touched.cardholder_details?.first_name && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.cardholder_details?.first_name}
          </FormHelperText>
        )}
      <Typography sx={{ color: "#9b9a9a", mt: 1 }}>Last Name</Typography>
      <TextField
        placeholder="Last Name"
        name="cardholder_details.last_name"
        value={formik.values.cardholder_details.last_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          borderRadius: "18px !important",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#eee",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #eee",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#eee",
            },
          },
        }}
      />
      {formik.errors.cardholder_details?.last_name &&
        formik.touched.cardholder_details?.last_name && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.cardholder_details?.last_name}
          </FormHelperText>
        )}
      <Grid sx={{ mt: 1 }} spacing={2} container>
        <Grid xs={6} item>
          <Typography sx={{ color: "#9b9a9a" }}>Date of birth</Typography>
          <TextField
            placeholder="Date of birth"
            name="cardholder_details.date_of_birth"
            value={formik.values.cardholder_details.date_of_birth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="date"
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              width: "100%",
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
          {formik.errors.cardholder_details?.date_of_birth &&
            formik.touched.cardholder_details?.date_of_birth && (
              <FormHelperText className="field-helper-text error">
                {formik.errors.cardholder_details?.date_of_birth}
              </FormHelperText>
            )}
        </Grid>
        <Grid xs={6} item>
          <Typography sx={{ color: "#9b9a9a" }}>Country</Typography>
          <FormControl
            variant="standard"
            className="input-style outlined-input select-style textfield-rouded-md"
            sx={{
              width: "100%",
              borderRadius: "18px",
              mb: 1,
              pl: 4,
              mr: 4,
            }}
          >
            {countries?.length > 0 ? (
              <SelectInput
                customArr={{ data: countries, name: "name", id: "code" }}
                name="cardholder_details.country"
                value={formik.values.cardholder_details.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                renderValue={(selected) => {
                  if (
                    (selected == undefined || selected == "") &&
                    (formik.values.cardholder_details.country == undefined ||
                      formik.values.cardholder_details.country == "")
                  ) {
                    return <em>Country</em>;
                  }

                  let countryData = countries.find(
                    (o) => o.code == formik.values.cardholder_details.country
                  );
                  return countryData ? countryData.name : "";
                }}
              />
            ) : (
              <SelectInput
                className="opacity-40"
                renderValue={(selected) => {
                  return <em>Country</em>;
                }}
              />
            )}
          </FormControl>
          {formik.errors.cardholder_details?.country &&
            formik.touched.cardholder_details?.country && (
              <FormHelperText className="field-helper-text error">
                {formik.errors.cardholder_details?.country}
              </FormHelperText>
            )}
        </Grid>
      </Grid>
      <Typography sx={{ color: "#9b9a9a" }}>
        If there’s a middle initial or multiple first or last names, enter them
        so they exactly match the government ID.
      </Typography>
    </>
  );
};
export default CardHolderDetails;
