import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    FormControl,
    Input,
    FormControlLabel,
    Switch,
    Button,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';

import images from '../../assets/js/images';
import RangePicker from '../../Components/Inputs/RangePicker';
import ModalPopup from '../../Components/Modal/ModalPopup';
import moment from "../../Helpers/MomentDefaultHelper";
import AppHelper from '../../Helpers/AppHelper';
import MakeToast from "../../Helpers/MakeToast";

import { HostService } from "../../Services/HostService";

const AwayMode = ({ hostData, getHostProfile }) => {
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                        theme.palette.getContrastText(theme.palette.primary.main),
                    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }));

    const [loader, setLoader] = useState(false);
    const [startDate, setStartDate] = React.useState(moment().format("MM-DD-YYYY"));
    const [endDate, setEndDate] = React.useState(moment().format("MM-DD-YYYY"));
    const [openPopup, setOpenPopup] = React.useState(false);
    const [openPopupType, setOpenPopupType] = React.useState(0);

    const validationSchemas = yup.object({

    });

    const formik = useFormik({
        initialValues: {
            is_away: 0,
            away_start_date: '',
            away_end_date: '',
        },
        validationSchema: validationSchemas,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoader(true);
            HostService.checkHostBooking(values).then((response) => {
                if (response.data.data == '1' || response.data.data == '2') {
                    setOpenPopupType(response.data.data);
                    setOpenPopup(true)
                    setLoader(false);
                } else {
                    hostupdate(values)
                }
            })
        }
    });

    const hostupdate = (values) => {
        setLoader(true);
        HostService.hostProfileUpdate(values).then((resp) => {
            getHostProfile();
            MakeToast("success", resp);
            setLoader(false);
            setOpenPopup(false)
        }).catch(error => {
            setLoader(false);
            setOpenPopup(false)
        });
    }

    const setRangePicker = (start, end) => {
        setStartDate(start);
        setEndDate(end);

        formik.setFieldValue('away_start_date', start);
        formik.setFieldValue('away_end_date', end);
    }

    useEffect(() => {
        formik.setFieldValue('away_start_date', startDate);
        formik.setFieldValue('away_end_date', endDate);
    }, [])

    useEffect(() => {
        if (hostData.is_away != null) formik.setFieldValue('is_away', hostData.is_away);
        if (hostData.away_start_date != null) {
            setStartDate(AppHelper.convertDateTime(hostData.away_start_date, 1));
            formik.setFieldValue('away_start_date', AppHelper.convertDateTime(hostData.away_start_date, 1));
        }
        if (hostData.away_start_date != null) {
            setEndDate(AppHelper.convertDateTime(hostData.away_end_date, 1));
            formik.setFieldValue('away_end_date', AppHelper.convertDateTime(hostData.away_end_date, 1));
        }
    }, [hostData]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="h3">
                    Away Mode
                </Typography>
                <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }}
                    checked={formik.values.is_away == 1 ? true : false} />}
                    onChange={(e) => {
                        if (e.target.checked) {
                            formik.setFieldValue('is_away', 1)
                        } else {
                            formik.setFieldValue('is_away', 0)
                        }
                    }}
                />
                {
                    formik.values.is_away == 1
                        ?
                        <>
                            <RangePicker selector="#range-picker-service" startdate={startDate} enddate={endDate} setRangePicker={setRangePicker} mindate={new Date()} />
                            <Box className='dotted-border-2'></Box>
                            <Box className="border-radius-md search-result-box-2 order-date-box"
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    boxShadow: '0px 0px 16px 0px rgba(9, 6, 15, 0.0784313725)',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },
                                    '@media (max-width:769px)': {
                                        width: '100%',
                                    },
                                }}>
                                <Box className="border-right-radius" sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },

                                }}>
                                    <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                        <img src={images.calendarIcon} alt="location" />
                                        Beginning date
                                    </Typography>
                                    <FormControl className="input-style small-radius border-0 border-right-radius"
                                    >
                                        <Input
                                            placeholder="08-12-2023"
                                            underline="none"
                                            disableUnderline={true}
                                            className="outline-input textfield-rouded-md input-text-style small-radius service-picker"
                                            value={startDate}
                                            sx={{
                                                borderRadius: '18px !important',
                                                '.MuiInputBase-input': {
                                                    paddingLeft: '30px',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                },
                                                '.MuiInputBase-root': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    },
                                                    '&.Mui-focused': {
                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #eee',
                                                            border: '0',
                                                        }
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    }
                                                },
                                            }}
                                            onClick={() => { document.getElementById('range-picker-service').click() }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '12px',
                                    width: '50%',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },
                                }}>
                                    <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                        <img src={images.calendarIcon} alt="location" />
                                        End date
                                    </Typography>
                                    <FormControl className="input-style small-radius border-0 border-left-radius"
                                    >
                                        <Input
                                            id="range-picker-service"
                                            placeholder="08-12-2023"
                                            underline="none"
                                            disableUnderline={true}
                                            value={endDate}
                                            name='end_date'
                                            readOnly
                                            className="outline-input textfield-rouded-md input-text-style small-radius service-picker endDate"
                                            sx={{
                                                borderRadius: '18px !important',
                                                '.MuiInputBase-input': {
                                                    paddingLeft: '30px',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                },
                                                '.MuiInputBase-root': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    },
                                                    '&.Mui-focused': {
                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #eee',
                                                            border: '0',
                                                        }
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    }
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </>
                        :
                        <></>
                }
                <Box className="modal-filter-btn">
                    <Button className="filter-button rounded-button"
                        type="submit"
                        sx={{
                            marginTop: '15px !important',
                            color: '#ffffff',
                            textTransform: 'capitalize',
                        }} >
                        {loader ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </Box>
            </form>
            {openPopup &&
                <ModalPopup
                    open={openPopup}
                    onClose={() => { setOpenPopup(false) }}
                    content={<>
                        <Box className="modalheader" sx={{ marginBottom: 0 }}>
                            <Box className="modal-close-btn">
                                <Button size="small" onClick={() => { setOpenPopup(false) }}> <img src={images.closeIcon} alt="" /></Button>
                            </Box>
                        </Box>
                        <Box className="modal-body message-modal">
                            <Typography component="h4">
                                {openPopupType == '1' && "Your specified away dates conflict with currently scheduled reservations. Be sure to reach out to your customers to reschedule or cancel reservations that conflict with these dates complete this action"}
                                {openPopupType == '2' &&
                                    <>
                                        Your specified away dates conflict with one or more reservation requests with customers that are pending your approval. Are you sure you want to keep these away dates? Keeping these away dates will trigger an automatic denial notification to all your customers who have reservations pending approval.
                                        <Box>
                                            <Button className="filter-button rounded-button"
                                                onClick={() => {
                                                    setLoader(true);
                                                    hostupdate(formik.values);
                                                }}
                                                sx={{
                                                    marginTop: '15px !important',
                                                    color: '#ffffff',
                                                    textTransform: 'capitalize',
                                                    width: '90px'
                                                }} >
                                                {loader ? <CircularProgress color="inherit" /> : 'Yes'}
                                            </Button>
                                            <Button className="filter-button rounded-button"
                                                onClick={() => { setOpenPopup(false) }}
                                                sx={{
                                                    marginTop: '15px !important',
                                                    marginLeft: '5px !important',
                                                    color: '#ffffff',
                                                    textTransform: 'capitalize',
                                                    width: '90px'
                                                }} >
                                                {loader ? <CircularProgress color="inherit" /> : 'No'}
                                            </Button>
                                        </Box>
                                    </>
                                }
                            </Typography>
                        </Box>
                    </>}
                />}
        </>
    );
}

export default AwayMode;
