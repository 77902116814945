import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../../../Context/PaymentsContext";

const RadioItem = (props) => {
  const { option } = props;
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == option.id;
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={option.id}
      control={<Radio />}
      label={
        <>
          <Typography
            sx={{ fontSize: 17, color: "black", fontWeight: "light" }}
          >
            {option.label}
          </Typography>
        </>
      }
      sx={{
        fontSize: 19,
        mx: "0px !important",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
};

const CheckingSaving = () => {
  const { formik } = useContext(FormContext);

  const types = [
    { id: "CHECKING", label: "Checking" },
    { id: "SAVING", label: "Savings" },
  ];
  return (
    <>
      <Typography
        sx={{ fontWeight: "500", mt: "16px !important" }}
        component="h4"
      >
        Is this a checking or savings account?
      </Typography>
      <RadioGroup
        name="cheking_savings"
        value={formik.values.cheking_savings}
        onChange={(event) =>
          formik.setFieldValue("cheking_savings", event.target.value)
        }
      >
        {types.map((data, index) => (
          <RadioItem key={index} option={data} />
        ))}
      </RadioGroup>
      {formik.errors.cheking_savings && formik.touched.cheking_savings && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.cheking_savings}
        </FormHelperText>
      )}
    </>
  );
};

export default CheckingSaving;
