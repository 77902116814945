//Get if platform is mobile
let isMobile = false;
if (
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  isMobile = true;
}
//Get os name
let OSName = "Unknown";
if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
  OSName = "Windows 10";
if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
  OSName = "Windows 8.1";
if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
  OSName = "Windows 8";
if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
  OSName = "Windows 7";
if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
  OSName = "Windows Vista";
if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
  OSName = "Windows XP";
if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
  OSName = "Windows 2000";
if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
//Get browser name
var browserName = (function (agent) {
  switch (true) {
    case agent.indexOf("edge") > -1:
      return "MS Edge";
    case agent.indexOf("edg/") > -1:
      return "Edge ( chromium based)";
    case agent.indexOf("opr") > -1 && !!window.opr:
      return "Opera";
    case agent.indexOf("chrome") > -1 && !!window.chrome:
      return "Chrome";
    case agent.indexOf("trident") > -1:
      return "MS IE";
    case agent.indexOf("firefox") > -1:
      return "Mozilla Firefox";
    case agent.indexOf("safari") > -1:
      return "Safari";
    default:
      return "other";
  }
})(window.navigator.userAgent.toLowerCase());

export default {
  browser_name: browserName,
  os_name: OSName,
  is_mobile: isMobile,
};
