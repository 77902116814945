import React from "react";
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { useSelector } from "react-redux";
import {
  Container,
  Box,
  Link,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Card,
  CardMedia,
  CardContent,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";

import images from "../../assets/js/images";
import MakeToast from "../../Helpers/MakeToast";
import AppHelper from "../../Helpers/AppHelper";
import moment from "../../Helpers/MomentDefaultHelper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import CancellationModal from "../../Components/Modal/CancellationModal";
import OnTheWayModal from "../../Components/Modal/OnTheWayModal";
import DenyModal from "../../Components/Modal/DenyModal";
import ContactCustomerModal from "../../Components/Modal/ContactCustomerModal";
import ModalPopup from "../../Components/Modal/ModalPopup";

import { BookingService } from "../../Services/BookingService";
import ScreenLoader from "../../Components/Common/ScreenLoader";

const BookingDetail = () => {
  const params = useParams();
  const navigate = useHistory();
  const currency = AppHelper.currency();
  const statuslabel = AppHelper.lists().statusLabel;

  const { user } = useSelector((state) => state.userReducers.data);

  const [editReservation, setEditReservation] = React.useState("");
  const [bookingDetail, setBookingDetail] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const [onTheWayPickupStatus, setOnTheWayPickupStatus] = React.useState(false);
  const [onTheWayDropStatus, setOnTheWayDropStatus] = React.useState(false);
  const [openOnTheWayPickup, setOpenOnTheWayPickup] = React.useState(false);
  const [openOnTheWayDrop, setOpenOnTheWayDrop] = React.useState(false);
  const [openCancellation, setOpenCancellation] = React.useState(false);
  const [openDeny, setOpenDeny] = React.useState(false);
  const [openPickupSuccess, setOpenPickupSuccess] = React.useState(false);
  const [openContactCustomer, setOpenContactCustomer] = React.useState(false);

  const getBookingDetail = () => {
    setLoader(true);
    BookingService.bookingDetail(params.id)
      .then((res) => {
        var response = res.data.data;

        setBookingDetail(response);
        setLoader(false);

        if (response.is_delivery_pickup != 1) {
          var today = moment().format("YYYY-MM-DD HH:mm:ss");
          var startdatetimesub = moment(response.start_datetime)
            .subtract(30, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
          var enddatetimesub = moment(response.end_datetime)
            .subtract(30, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
          var enddatetimeadd = moment(response.end_datetime)
            .add(30, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
          setOnTheWayPickupStatus(
            startdatetimesub < today &&
            enddatetimesub > today &&
            response.status == 1 &&
            response.pickup_option == null
          );
          setOnTheWayDropStatus(
            enddatetimesub < today &&
            enddatetimeadd > today &&
            response.status == 1 &&
            response.drop_option == null
          );
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response.status == 403) {
          MakeToast("error", "Access Denied");
          navigate.push("/bookings");
        }
      });
  };

  const confirmBooking = () => {
    AppHelper.approveBooking(params.id, MakeToast, renderBooking);
  };

  const renderBooking = () => {
    setBookingDetail([]);
    getBookingDetail();
  };

  const locations = useLocation();
  const typeParams = new URLSearchParams(locations.search);
  const typeparam = typeParams.get("type");
  const searchParam = typeParams.get("search");

  const openActionPopup = () => {
    if (typeparam != null) {
      if (typeparam == "pendingapprovalhost") confirmBooking();
      else if (typeparam == "pendingdenialhost") setOpenDeny(true);
      else if (typeparam == "pickupcustomer") setOnTheWayPickupStatus(true);
      else if (typeparam == "dropcustomer") setOnTheWayDropStatus(true);

      window.history.replaceState(null, null, window.location.pathname);
    }
  };

  React.useEffect(() => {
    getBookingDetail();
    openActionPopup();
  }, [params.id]);

  React.useEffect(() => {
    openActionPopup();
  }, [searchParam]);

  const reservationlist = [
    "Add or remove items/services",
    "Extend beach gear rental",
    "Change reservation dates/times",
    "Cancel reservation",
  ];

  return (
    <>
      <ScreenLoader open={loader} />
      <section className="booking-order inner-section-padding">
        <Container>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
            <BreadCrumb data={[["Bookings", "/bookings"], [`Order #${bookingDetail.id}`]]} />
            </Grid>
            <Grid item xs={12}>
              <Box className="heading-search">
                <Box className="top-heading">
                  <div className="main-heading-block">
                    <h2
                      className="main-heading"
                      sx={{
                        marginBottom: "0 !important",
                        marginTop: "0 !important",
                      }}
                    >
                      Order #{bookingDetail.id}
                    </h2>
                    <span
                      className={"trip-badge " + bookingDetail?.status_label}
                    >
                      {statuslabel[bookingDetail?.status_label]}{" "}
                      {bookingDetail?.status_label == "denied"
                        ? " (" +
                        AppHelper.convertDateTime(
                          bookingDetail?.cancellation_datetime,
                          3
                        ) +
                        ")"
                        : ""}
                    </span>
                  </div>
                  <Box className="pending-badge">
                    <List className="feature-listing mobile-menu-host">
                      {bookingDetail.is_instant == 1 ? (
                        <ListItem>
                          <ListItemIcon className="feature-listing-icon">
                            <img src={images.instantbooking} alt="calender" />
                          </ListItemIcon>
                          <ListItemText
                            className="feature-list-name"
                            primary="Instant booking"
                          />
                        </ListItem>
                      ) : (
                        <></>
                      )}
                      {bookingDetail.is_delivery_pickup == 1 ? (
                        <ListItem>
                          <ListItemIcon className="feature-listing-icon">
                            <img src={images.deliveryIcon} alt="car" />
                          </ListItemIcon>
                          <ListItemText
                            className="feature-list-name"
                            primary="Delivery & Pickup"
                          />
                        </ListItem>
                      ) : (
                        <></>
                      )}
                    </List>
                  </Box>
                </Box>
                <Box className="heading-right-btn">
                  {user.role_id == "2" ? (
                    <>
                    <Button
                        type="button"
                        fullHeight
                        disableElevation
                        variant="contained"
                        className="btn-primary small-btn border-radius-md card-btn"
                        onClick={() => {
                          setOpenContactCustomer(true);
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        Contact Customer
                      </Button>
                      {["pending"].includes(bookingDetail.status_label) &&
                        !bookingDetail.is_instant ? (
                        <Button
                          type="submit"
                          fullHeight
                          disableElevation
                          variant="contained"
                          className="btn-primary small-btn border-radius-md card-btn"
                          onClick={() => {
                            confirmBooking();
                          }}
                        >
                          Approve
                        </Button>
                      ) : (
                        <></>
                      )}
                      {["pending", "upcoming"].includes(
                        bookingDetail.status_label
                      ) ? (
                        <Button
                          type="submit"
                          fullHeight
                          disableElevation
                          variant="contained"
                          className="btn-primary small-btn border-radius-md card-btn"
                          onClick={() => {
                            setOpenDeny(true);
                          }}
                          style={{ marginLeft: "5px" }}
                        >
                          {bookingDetail.status_label == "pending"
                            ? "Deny"
                            : "Cancel"}
                        </Button>
                      ) : (
                        <></>
                      )}
                      {["cancelled"].includes(bookingDetail.status_label) &&
                        bookingDetail.is_refund != "1" &&
                        bookingDetail.is_refund != "2" ? (
                        <Button
                          type="submit"
                          fullHeight
                          disableElevation
                          variant="contained"
                          className="small-btn border-radius-md card-btn outline-btn"
                          onClick={() => {
                            AppHelper.SAToast().then((result) => {
                              if (result.isConfirmed) {
                                BookingService.refundBooking({ id: params.id })
                                  .then((res) => {
                                    renderBooking();
                                    MakeToast(
                                      "success",
                                      "Refund successfully."
                                    );
                                  })
                                  .catch((error) => { });
                              }
                            });
                          }}
                          style={{ marginLeft: "5px" }}
                        >
                          Refund
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {user.role_id == "3" ? (
                    <>
                      {onTheWayPickupStatus ? (
                        <Button
                          size="small"
                          className="btn-primary border-radius-md top-small-btn"
                          onClick={() => {
                            setOpenOnTheWayPickup(true);
                          }}
                        >
                          On the way to pick up
                        </Button>
                      ) : (
                        <></>
                      )}
                      {onTheWayDropStatus ? (
                        <Button
                          size="small"
                          className="btn-primary border-radius-md top-small-btn"
                          onClick={() => {
                            setOpenOnTheWayDrop(true);
                          }}
                        >
                          On the way to return
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/* {
                                                ['pending', 'upcoming'].includes(bookingDetail.status_label)
                                                ?
                                                <>
                                                    <Button
                                                        type="submit"
                                                        fullHeight
                                                        disableElevation
                                                        variant="contained"
                                                        className="small-btn border-radius-md card-btn"
                                                        style={{ color: '#808084', background: 'white', marginLeft: '5px', border: '1px solid #E5E4E4' }}
                                                        onClick={() => {
                                                            var dd = document.getElementById('editdropdown');
                                                            if (dd.style.display === 'block' || dd.style.display === '') dd.style.display = 'none';
                                                            else dd.style.display = 'block'
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Box
                                                        sx={{
                                                            '& > :not(style)': {
                                                                width: 260,
                                                            },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                        className='d-flex gap-3 mb-4'
                                                        id="editdropdown"
                                                        style={{ display: 'none' }}
                                                    >
                                                        <FormControl variant="standard" className="input-style outlined-input select-style" sx={{
                                                            '@media (max-width:575px)': {
                                                                width: '100% !important',
                                                            }
                                                        }}>
                                                            <SelectInput
                                                                customObj={reservationlist}
                                                                value={editReservation}
                                                                onChange={(e) => {
                                                                    setEditReservation(e.target.value)
                                                                }}
                                                                renderValue={(selected) => {
                                                                    if (selected == undefined || selected == '') {
                                                                        return <em>Edit reservation</em>;
                                                                    }
                                                                    return reservationlist[editReservation];
                                                                }}
                                                                startAdornment={
                                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 1.5, pr: 1 }}>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </>
                                                :
                                                <></>
                                            } */}
                      {["pending", "upcoming"].includes(
                        bookingDetail.status_label
                      ) ? (
                        <>
                          <Button
                            type="submit"
                            fullHeight
                            disableElevation
                            variant="contained"
                            className="border-radius-md card-btn outline-btn"
                            onClick={() => {
                              setOpenCancellation(true);
                            }}
                            sx={{ textTransform: "none!important" }}
                          >
                            {bookingDetail.cancellation
                              ? "Cancel"
                              : "Request a Cancellation"}
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Card
                className="card-detail cart-item"
                sx={{
                  display: "flex",
                  gap: "35px",
                  width: "100%",
                  alignItems: "center",
                  "@media (max-width:575px)": {
                    gap: "12px",
                  },
                }}
                elevation={0}
                square
              >
                <CardMedia
                  component="img"
                  sx={{ flexShrink: "0" }}
                  image={
                    bookingDetail?.beachgears?.beachgear_cover_image[0]?.imgPath
                  }
                  alt="Live from space album cover"
                  className="cart-item-img border-radius-12"
                />
                <Box
                  sx={{
                    "@media (max-width:575px)": {
                      width: "calc(100% - 105px)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      p: 0,
                      "&:last-child": {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <RouterLink
                      onClick={() => {
                        if(!bookingDetail?.host.archive){
                          navigate.push("/service-detail/" + bookingDetail?.beachgears?.beachgear_slug);
                        }
                      }}
                      underline="none"
                      className="cart-item-names secondary-text"
                    >
                      {bookingDetail?.beachgears?.name}
                    </RouterLink>
                    <Box component="div" className="total-input tertiary-text">
                      Amount - {bookingDetail?.qty}
                    </Box>
                  </CardContent>
                </Box>
                <Box
                  sx={{
                    flexShrink: "0",
                    ml: "auto",
                    width: "auto",
                    "@media (max-width:575px)": {
                      width: "100%",
                      borderTop: "1px solid #EEEEEE",
                      paddingTop: "15px",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      height: "100%",
                      p: 0,
                      "&:last-child": {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <Box
                      className="horizontal-card-right-side"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "right",
                          "@media (max-width:575px)": {
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          },
                        }}
                      >
                        <Typography
                          component="span"
                          className="cart-item-value-each tertiary-text"
                        >
                          {currency}
                          {bookingDetail?.amount}/{bookingDetail?.price_option}{" "}
                          x {bookingDetail?.qty}{" "}
                          {bookingDetail?.qty > 1 ? " items" : " item"}
                        </Typography>
                        {/* <Typography component="p" className="cart-item-value">
                          {currency}
                          {bookingDetail?.subtotal}
                        </Typography> */}
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
              <Box className="cart-summary">
                <List component="div" disablePadding>
                  {bookingDetail?.subtotal != "" &&
                    bookingDetail?.subtotal != "0" ? (
                    <ListItemText className="summary-price secondary-text">
                      Subtotal{" "}
                      <span>
                        {currency}
                        {bookingDetail?.subtotal ?? 0}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}
                  {bookingDetail?.delivery_charges != "" &&
                    bookingDetail?.delivery_charges != "0" &&
                    bookingDetail?.delivery_charges != "0.00" ? (
                    <ListItemText className="summary-price secondary-text">
                      Delivery Charges{" "}
                      <span>
                        {currency}
                        {bookingDetail?.delivery_charges ?? 0}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}
                  {bookingDetail?.pickup_charges != "" &&
                    bookingDetail?.pickup_charges != "0" &&
                    bookingDetail?.pickup_charges != "0.00" ? (
                    <ListItemText className="summary-price secondary-text">
                      Pickup Charges{" "}
                      <span>
                        {currency}
                        {bookingDetail?.pickup_charges ?? 0}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}
                  {bookingDetail?.security_deposit_amount != "" &&
                    bookingDetail?.security_deposit_amount != "0" &&
                    bookingDetail?.security_deposit_amount != "0.00" ? (
                    <ListItemText className="summary-price secondary-text">
                      Security Deposit{" "}
                      <span>
                        {currency}
                        {bookingDetail?.security_deposit_amount ?? 0}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}

                  {bookingDetail?.service_charge != "" &&
                    bookingDetail?.service_charge != "0" &&
                    bookingDetail?.service_charge != "0.00" ? (
                    <ListItemText className="summary-price secondary-text">
                      Service Fee{" "}
                      <span>
                        {currency}
                        {user.role_id == "2" ? (bookingDetail?.service_charge_host ?? 0) : (bookingDetail?.service_charge ?? 0)}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}
                  {bookingDetail?.total_amount != "" &&
                    bookingDetail.total_amount != "0" ? (
                    <ListItemText className="summary-price summary-price-total">
                      Total{" "}
                      <span>
                        {currency}
                        {user.role_id == "2" ? bookingDetail?.total_amount_host : bookingDetail?.total_amount}
                      </span>
                    </ListItemText>
                  ) : (
                    <></>
                  )}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              {bookingDetail.status_label == "cancelled" ||
                bookingDetail.status_label == "denied" ? (
                <>
                  <Box className="right-side-block">
                    <Typography
                      variant="h6"
                      component="p"
                      className="sub-text tertiary-text label-bottom-space"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={images.circlecloseIcon}
                        className="calendar-icon"
                      />
                      Order{" "}
                      {bookingDetail.status_label == "denied"
                        ? "denied"
                        : "cancelled"}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="label"
                      className="secondary-text label-bottom-space inner-page-medium-heading"
                    >
                      {bookingDetail?.cancellation_option}{" "}
                      {bookingDetail?.cancellation_reason != "" &&
                        bookingDetail?.cancellation_reason != null
                        ? " - " + bookingDetail?.cancellation_reason
                        : ""}
                    </Typography>
                  </Box>
                </>
              ) : (
                <></>
              )}
              {bookingDetail.is_delivery_pickup == 1 ? (
                <>
                  <Box className="right-side-block">
                    <Typography
                      variant="h6"
                      component="p"
                      className="sub-text tertiary-text label-bottom-space"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={images.locationGreyIcon}
                        alt="location"
                        className="calendar-icon"
                      />
                      Delivery
                    </Typography>
                    <Typography
                      variant="h6"
                      component="label"
                      className="secondary-text label-bottom-space inner-page-medium-heading"
                    >
                      {bookingDetail?.delivery_address?.address}
                    </Typography>
                  </Box>
                  <Box className="right-side-block">
                    <Typography
                      variant="h6"
                      component="label"
                      className="sub-text tertiary-text label-bottom-space"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={images.locationGreyIcon}
                        alt="location"
                        className="calendar-icon"
                      />
                      Pickup
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      className="secondary-text label-bottom-space inner-page-medium-heading"
                    >
                      {bookingDetail?.pickup_address?.address}
                    </Typography>
                  </Box>
                </>
              ) : (
                <></>
              )}
              <Box
                className="border-radius-md search-result-box-2"
                sx={{
                  width: "100%",
                  display: "flex",
                  "@media (max-width:566px)": {
                    width: "100% !important",
                  },
                  "@media (max-width:769px)": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  className="border-right-radius"
                  sx={{
                    display: "flex",
                    width: "50%",
                    flexDirection: "column",
                    "@media (max-width:769px)": {
                      width: "100%",
                    },
                    "@media (max-width:566px)": {
                      width: "100% !important",
                    },
                    "@media (max-width:799px)": {
                      // width: '50% !important',
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    component="label"
                    className="sub-text tertiary-text label-bottom-space "
                  >
                    <img src={images.calendarIcon} alt="location" />
                    Beginning date
                  </Typography>
                  <FormControl
                    className="input-style small-radius border-0 border-right-radius 123654"
                    sx={{
                      "@media (max-width:768px)": {
                        width: "100%",
                      },
                      // '@media (min-width:770px) and (max-width: 899px)': {
                      //     width: '50% !important',
                      // },
                      "@media (min-width:770px) and (max-width:799px)": {
                        width: "50% !important",
                      },
                    }}
                  >
                    <TextField
                      InputProps={{ readOnly: true }}
                      value={AppHelper.convertDateTime(
                        bookingDetail.start_datetime,
                        1
                      )}
                      className="outline-input textfield-rouded-md input-text-style small-radius"
                      sx={{
                        borderRadius: "18px !important",
                        ".MuiInputBase-input": {
                          paddingLeft: "30px",
                          fontSize: "18px",
                          fontWeight: "400",
                        },
                        ".MuiInputBase-root": {
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#eee",
                            border: "0",
                          },
                          "&.Mui-focused": {
                            " .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #eee",
                              border: "0",
                            },
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#eee",
                            border: "0",
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "12px",
                    width: "50%",

                    "@media (max-width: 575.98px)": {
                      width: "100%",
                    },
                    "@media (min-width: 576px) and (max-width: 769px)": {
                      width: "100% !important",
                    },
                    //   '@media (min-width:770px) and (max-width: 899px)': {
                    //         width: '50% !important',
                    //     },
                  }}
                >
                  <Typography
                    variant="h6"
                    component="label"
                    className="sub-text tertiary-text label-bottom-space"
                  >
                    <img src={images.calendarIcon} alt="location" />
                    End date
                  </Typography>
                  <FormControl className="input-style small-radius border-0 border-left-radius">
                    <TextField
                      InputProps={{ readOnly: true }}
                      value={AppHelper.convertDateTime(
                        bookingDetail.end_datetime,
                        1
                      )}
                      className="outline-input textfield-rouded-md input-text-style small-radius"
                      sx={{
                        borderRadius: "18px !important",
                        ".MuiInputBase-input": {
                          paddingLeft: "30px",
                          fontSize: "18px",
                          fontWeight: "400",
                        },
                        ".MuiInputBase-root": {
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#eee",
                            border: "0",
                          },
                          "&.Mui-focused": {
                            " .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #eee",
                              border: "0",
                            },
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#eee",
                            border: "0",
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className="time-field">
                <FormControl
                  sx={{
                    width: "50%",
                    "@media (max-width:769px)": {
                      marginBottom: "15px",
                      "@media (max-width:566px)": {
                        marginBottom: "0px",
                        width: "100% !important",
                      },
                    },
                  }}
                  className="input-style"
                >
                  <TextField
                    InputProps={{ readOnly: true }}
                    value={AppHelper.convertDateTime(
                      bookingDetail.start_datetime,
                      4
                    )}
                    className="outline-input textfield-rouded-md  input-text-style"
                    sx={{
                      ".MuiInputBase-input": {
                        paddingLeft: "30px",
                      },

                      ".MuiInputBase-root": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#eee",
                        },
                        "&.Mui-focused": {
                          " .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #eee",
                          },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#eee",
                        },
                      },
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    width: "50%",
                    "@media (max-width:769px)": {
                      marginBottom: "15px",
                    },
                    "@media (max-width:566px)": {
                      width: "100% !important",
                    },
                  }}
                  className="input-style"
                >
                  <TextField
                    InputProps={{ readOnly: true }}
                    value={AppHelper.convertDateTime(
                      bookingDetail.end_datetime,
                      4
                    )}
                    className="outline-input textfield-rouded-md  input-text-style"
                    sx={{
                      ".MuiInputBase-input": {
                        paddingLeft: "30px",
                      },

                      ".MuiInputBase-root": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#eee",
                        },
                        "&.Mui-focused": {
                          " .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #eee",
                          },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#eee",
                        },
                      },
                      "@media (max-width:566px)": {
                        width: "100% !important",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              {user.role_id == "2" ? (
                <Box
                  className="bg-light-fourth border-radius-30"
                  style={{ padding: "30px" }}
                >
                  <RouterLink
                    to={"/customer-profile/" + bookingDetail?.user?.id}
                  >
                    <Box
                      className="service-listing-bg"
                      style={{ marginBottom: "10px", padding: "0" }}
                    >
                      <Box className="service-logo">
                        <img src={bookingDetail?.user?.profile_image} alt="" />
                      </Box>
                      <div className="main-heading-block">
                        <h2
                          className="main-heading"
                          sx={{
                            marginBottom: "0 !important",
                            marginTop: "0 !important",
                          }}
                        >
                          {bookingDetail?.user?.name}
                        </h2>
                      </div>
                    </Box>
                  </RouterLink>
                  <List
                    disablePadding
                    className="host-info-listing"
                    style={{ fontSize: "1.8rem" }}
                  >
                    <ListItemText className="summary-price secondary-text">
                      <b>Reservation No. :</b> RESERVATION - {params.id}
                    </ListItemText>
                  </List>
                </Box>
              ) : (
                bookingDetail?.beachgears && (
                  <Box
                    className="bg-light-fourth border-radius-30"
                    style={{ padding: "30px" }}
                  >
                    <RouterLink
                      onClick={() => {
                        if(!bookingDetail?.host.archive){
                          navigate.push("/host/" + bookingDetail?.beachgears.host_slug);
                        }
                      }}
                    >
                      <Box
                        className="service-listing-bg"
                        style={{ marginBottom: "10px", padding: "0" }}
                      >
                        <Box className="service-logo">
                          <img src={bookingDetail?.host?.profile_image} alt="" />
                        </Box>
                        <div className="main-heading-block">
                          <h2
                            className="main-heading"
                            sx={{
                              marginBottom: "0 !important",
                              marginTop: "0 !important",
                            }}
                          >
                            {bookingDetail?.beachgears.company_name}
                          </h2>
                        </div>
                      </Box>
                    </RouterLink>
                    <List
                      disablePadding
                      className="host-info-listing"
                      style={{ fontSize: "1.8rem" }}
                    >
                      {bookingDetail?.beachgears?.phone != "" ? (
                        <>
                          <ListItem>
                            <ListItemIcon className="feature-listing-icon">
                              <img
                                src={images.phoneIcon}
                                alt="calender"
                                style={{ marginRight: "10px" }}
                              />
                              <ListItemText className="summary-price">
                                {bookingDetail?.beachgears?.phone}
                              </ListItemText>
                            </ListItemIcon>
                          </ListItem>
                        </>
                      ) : (
                        <></>
                      )}
                      {bookingDetail?.beachgears?.email != "" ? (
                        <>
                          <ListItem>
                            <ListItemIcon className="feature-listing-icon">
                              <img
                                src={images.mailIcon}
                                alt="calender"
                                style={{ marginRight: "10px" }}
                              />
                              <ListItemText className="summary-price">
                                {bookingDetail?.beachgears?.email}
                              </ListItemText>
                            </ListItemIcon>
                          </ListItem>
                        </>
                      ) : (
                        <></>
                      )}
                      {bookingDetail?.beachgears?.address != "" ? (
                        <>
                          <ListItem>
                            <ListItemIcon className="feature-listing-icon">
                              <img
                                src={images.locationIcon}
                                alt="calender"
                                style={{ marginRight: "10px" }}
                              />
                              <ListItemText className="summary-price">
                                {bookingDetail?.beachgears?.address}
                              </ListItemText>
                            </ListItemIcon>
                          </ListItem>
                        </>
                      ) : (
                        <></>
                      )}
                    </List>
                  </Box>
                )
              )}
            </Grid>
          </Grid>
        </Container>
      </section>
      {openCancellation ? (
        <CancellationModal
          openCancellation={openCancellation}
          setOpenCancellation={setOpenCancellation}
          id={params.id}
          onEmitValue={renderBooking}
        />
      ) : (
        <></>
      )}
      {openDeny ? (
        <DenyModal
          openDeny={openDeny}
          setOpenDeny={setOpenDeny}
          id={params.id}
          onEmitValue={renderBooking}
        />
      ) : (
        <></>
      )}
      {openOnTheWayPickup ? (
        <OnTheWayModal
          openOnTheWay={openOnTheWayPickup}
          setOpenOnTheWay={setOpenOnTheWayPickup}
          id={params.id}
          onEmitValue={renderBooking}
          pagetype="1"
        />
      ) : (
        <></>
      )}
      {openOnTheWayDrop ? (
        <OnTheWayModal
          openOnTheWay={openOnTheWayDrop}
          setOpenOnTheWay={setOpenOnTheWayDrop}
          id={params.id}
          onEmitValue={renderBooking}
          pagetype="2"
        />
      ) : (
        <></>
      )}
      {openPickupSuccess && (
        <ModalPopup
          open={openPickupSuccess}
          onClose={() => {
            setOpenPickupSuccess(false);
          }}
          content={
            <>
              <Box className="modalheader" sx={{ marginBottom: 0 }}>
                <Box className="modal-close-btn">
                  <Button
                    size="small"
                    disableRipple
                    onClick={() => {
                      setOpenPickupSuccess(false);
                    }}
                  >
                    {" "}
                    <img src={images.closeIcon} alt="" />
                  </Button>
                </Box>
              </Box>
              <Box className="modal-body message-modal">
                <Typography component="h2">
                  You’re all set. Time to enjoy your beach day!
                </Typography>
              </Box>
            </>
          }
        />
      )}
      {openContactCustomer ? (
        <ContactCustomerModal
          openContactCustomer={openContactCustomer}
          setOpenContactCustomer={setOpenContactCustomer}
          id={params.id}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default BookingDetail;
