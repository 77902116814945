import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../Context/PaymentsContext";

const AccountNumber = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Account number{" "}
      </Typography>
      <TextField
        placeholder="Account number"
        name="account_number"
        value={formik.values.account_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Confirm account number
      </Typography>
      <TextField
        placeholder="Confirm account number"
        name="confirm_account_number"
        value={formik.values.confirm_account_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.account_number && formik.touched.account_number && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.account_number}
        </FormHelperText>
      )}
      {formik.errors.confirm_account_number &&
        formik.touched.confirm_account_number && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.confirm_account_number}
          </FormHelperText>
        )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter the account number. This can usually be found within the account
        details.
      </Typography>
    </Box>
  );
};

export default AccountNumber;
