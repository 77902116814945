import React from 'react'
import { Box, Button, CircularProgress, FormControl, IconButton, ListItemIcon, ListItemSecondaryAction, Radio, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        width: '60px',
        height: '60px',
        backgroundColor: "#FF4110",
    },
}));

const ExistingCard = ({ paymentMethod, handleExistingCard }) => {
    const classes = useStyles();
    const { user } = useSelector((state) => state.userReducers.data);

    return (
        <List className={classes.root}>
            <ListItem style={{
                backgroundColor: '#f1f8ff', borderRadius: 10
            }}>
                <ListItemIcon className="radio-check-button">
                    <Radio color="primary" sx={{
                        fontSize: '30px',
                        color: '#FF4110 !important'
                    }} checked={true} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography style={{ fontSize: 20 }}>
                            {paymentMethod?.card_holder ? paymentMethod?.card_holder : user.name}
                        </Typography>
                    }
                    secondary={
                        <Typography style={{ fontSize: 16 }}>
                            **** **** **** {paymentMethod.last_four} - {paymentMethod.card_type}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit" onClick={handleExistingCard}>
                        Edit
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

export default ExistingCard