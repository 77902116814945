import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { FormContext } from "../../../Context/PaymentsContext";
import { useContext } from "react";

const StripeFields = () => {
  const { formik } = useContext(FormContext);

  return (
    <Grid sx={{ mt: 1 }} spacing={2} container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography sx={{ fontWeight: "500" }} component="h4">
          Credit card number
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              type="text"
              className="outline-input textfield-rouded-md input-text-style small-radius"
              InputProps={{
                inputComponent: CardNumberElement,
              }}
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography sx={{ fontWeight: "500" }} component="h4">
          Expiration month and year
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="06/2028"
              InputProps={{ inputComponent: CardExpiryElement }}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography sx={{ fontWeight: "500" }} component="h4">
          CVC
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              type="text"
              InputProps={{ inputComponent: CardCvcElement }}
              value={"***"}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StripeFields;
