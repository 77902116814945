import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { PersonalInformationService } from "../../Services/PersonalInformationService";
import NameForm from "../../Components/EditProfile/Forms/Name";
import Email from "../../Components/EditProfile/Forms/Email";
import Phone from "../../Components/EditProfile/Forms/Phone";
import Address from "../../Components/EditProfile/Forms/Address";
import EmergencyContact from "../../Components/EditProfile/Forms/EmergencyContact";
import InfoRightCard from "../../Components/EditProfile/InfoRightCard";
import { CountryService } from "../../Services/CountryService";
import { useHistory } from "react-router-dom";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import { CommonService } from "../../Services/CommonService";

const PersonalInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [countries, setCountries] = useState({});
  const [languages, setLanguages] = useState({});
  useEffect(() => {
    getInfo();
    getCountries();
    getLanguages();
  }, []);

  const getInfo = () => {
    setIsLoading(true);
    PersonalInformationService.getInfo()
      .then((response) => {
        setInitialData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {});
  };

  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };
  const getLanguages = () => {
    CommonService.getAllLanguagesAsArray()
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <Container sx={{ py: 5 }}>
      <AccountSettingsTitle
        title="Personal Information"
        routeLabel="Personal Information"
      />
      <Grid rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12}  sm={12} md={8} item >
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <NameForm initialValue={initialData.name} />
              <Email initialValue={initialData.email} />
              <Phone initialValue={initialData.phone} />
              <Address
                initialValue={{
                  country_id: initialData.address?.country_id || "",
                  address_number: initialData.address?.address_number || "",
                  street: initialData.address?.street || "",
                  city: initialData.address?.city || "",
                  state: initialData.address?.state || "",
                  zip_code: initialData.address?.zip_code || "",
                  label: initialData.address?.label || "",
                }}
                countries={countries}
              />
              <EmergencyContact
                initialValue={{
                  name: "",
                  relationship: "",
                  country_id: "1",
                  language_id: "",
                  email: "",
                  phone: "",
                  label: initialData.emergency_contacts?.label || "",
                  emergency_contacts:
                    initialData.emergency_contacts?.contacts || [],
                }}
                countries={countries}
                languages={languages}
                hideSaveButton
                noInitialValues
              />
            </>
          )}
        </Grid>
        <Grid xs={12} sm={12} md={4} item className="mobile-none">
          <InfoRightCard />
        </Grid>
      </Grid>
    </Container>
  );
};
export default PersonalInformation;
