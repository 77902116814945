import { Box, Chip, Grid, Skeleton, Typography } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import { PaymentsService } from "../../Services/PaymentsService";
import MakeToast from "../../Helpers/MakeToast";
import { useEffect, useState } from "react";
const examples = [
  {
    booking_id: 1,
    user_id: 11,
    amount: 100.2,
    delivery_charges: null,
    pickup_charges: null,
    security_deposit: 0.0,
    tax: 0.0,
    total_amount: 100.2,
    txn_id: "",
    payment_status: "pending",
  },
  {
    booking_id: 1,
    user_id: 11,
    amount: 100.2,
    delivery_charges: 12.0,
    pickup_charges: 1.0,
    security_deposit: 0.0,
    tax: 0.0,
    total_amount: 100.2,
    txn_id: "",
    payment_status: "pending",
  },
];

const ListItem = ({ payment }) => (
  <Grid
    sx={{
      borderBottom: "1px solid rgb(221, 221, 221) !important",
      py: 2,
    }}
    container
  >
    <Grid xs={10} item>
      <Typography component="h3">
        <strong>Total Amount:</strong> ${payment.total_amount}
      </Typography>
      <Typography>
        <strong>Amount:</strong> ${payment.amount}
      </Typography>
      <Typography>
        <strong>Delivery Charges:</strong> ${payment.delivery_charges ?? 0.0}
      </Typography>
      <Typography>
        <strong>Pickup Charges:</strong> ${payment.pickup_charges ?? 0.0}
      </Typography>
    </Grid>
    <Grid
      sx={{ display: "grid", alignSelf: "normal", justifyItems: "center" }}
      xs={2}
      item
    >
      <PaidIcon sx={{ fontSize: 40 }} />
      <Chip
        sx={{ textTransform: "capitalize" }}
        label={payment.payment_status}
      />
    </Grid>
  </Grid>
);
const List = () => {
  const [methods, setMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPayments = async () => {
    await PaymentsService.get()
      .then((response) => setMethods(response.data.data))
      .catch((error) => MakeToast("error", error));

    setLoading(false);
  };
  useEffect(() => {
    getPayments();
  }, []);
  return (
    <Box sx={{ mt: 2 }}>
      {loading ? (
        <Skeleton height="300" />
      ) : (
        methods.map((payment) => <ListItem payment={payment} />)
      )}
    </Box>
  );
};

export default List;
