import { Box, Typography } from "@mui/material";
import CustomSwitch from "../../Common/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { PrivacyAndSharingSevice } from "../../../Services/PrivacyAndSharingSevice";
import { useState } from "react";
import MakeToast from "../../../Helpers/MakeToast";

const SharingTab = () => {
  const { user } = useSelector((state) => state.userReducers.data);
  const [loading, setLoading] = useState({
    sharing_activity: false,
    sharing_data: false,
  });
  const dispatch = useDispatch();

  const updateSwitch = (field) => {
    setLoading({
      ...loading,
      [field]: true,
    });
    PrivacyAndSharingSevice.updateSwitch({
      field_name: field,
      value: !user[field],
    })
      .then((response) => {
        setLoading({
          ...loading,
          [field]: false,
        });
        dispatch({
          type: "LOGIN_START",
          payload: response?.data,
        });
        MakeToast("success", response);
      })
      .catch((error) => {
        setLoading({
          ...loading,
          [field]: false,
        });
        MakeToast("error", error);
      });
  };
  return (
    <>
      <Box>
        <Typography sx={{ fontWeight: "500" }} component="h3">
          Activity sharing
        </Typography>
        <Typography>
          Decide how your profile and activity are shown to others.
        </Typography>
        <Box
          sx={{
            marginTop: 6,
            borderBottom: "solid 1px #EBEBEB",
            paddingBottom: 3,
          }}
        >
          <Typography component="h4">
            Include my profile and listing in search engines
          </Typography>
          <Typography>
            Turning this on means search engines, like Google, will display your
            profile and listing pages in search results.
          </Typography>
          <CustomSwitch
            sx={{ marginTop: 2 }}
            checked={user.sharing_activity}
            disabled={loading.sharing_activity}
            onChange={() => updateSwitch("sharing_activity")}
          />
        </Box>
      </Box>
      {/* <Box sx={{ marginTop: 5 }}>
        <Typography sx={{ fontWeight: "500" }} component="h3">
          Data sharing
        </Typography>
        <Typography>
          Decide how your data is used for Beachify research.
        </Typography>
        <Box
          sx={{
            marginTop: 6,
            borderBottom: "solid 1px #EBEBEB",
            paddingBottom: 3,
          }}
        >
          <Typography component="h4">
            Use my first name and profile photo to help fight discrimination
          </Typography>
          <Typography>
            Leaving this on means that you’re helping us further studies to help
            identify and prevent discrimination from happening on Beachify.
            Learn more
          </Typography>
          <CustomSwitch
            sx={{ marginTop: 2 }}
            checked={user.sharing_data}
            disabled={loading.sharing_data}
            onChange={() => updateSwitch("sharing_data")}
          />
        </Box>
      </Box> */}
    </>
  );
};

export default SharingTab;
