import { useContext } from "react";
import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";
import SelectInput from "../../../../Inputs/SelectInput";

const TaxClassification = () => {
  const { formik } = useContext(FormContext);

  const options = [
    { id: "ENTITY_TYPE_INDIVIDUAL_SOLE", name: "Individual/sole proprietor" },
    { id: "ENTITY_TYPE_C_CORPORATION", name: "C Corporation" },
    { id: "ENTITY_TYPE_S_CORPORATION", name: "S Corporation" },
    { id: "ENTITY_TYPE_PARTNERSHIP", name: "Partnership" },
    { id: "ENTITY_TYPE_TRUST_ESTATE", name: "Trust/estate" },
    { id: "ENTITY_TYPE_LLC", name: "LLC (Single member)" },
    { id: "ENTITY_TYPE_LLC_C", name: "LLC (C Corporation)" },
    { id: "ENTITY_TYPE_LLC_S", name: "LLC (S Corporation)" },
    { id: "ENTITY_TYPE_LLC_PARTNERSHIP", name: "LLC (Partnership)" },
    { id: "ENTITY_TYPE_EXEMPT_PAYEE", name: "Exempt payee" },
  ];
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Tax classification
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
          mt: 1,
        }}
      >
        <SelectInput
          customArr={{
            data: options,
            name: "name",
            id: "id",
          }}
          name="classification"
          value={formik.values.classification}
          onChange={(e) => {
            let value = e.target.value;
            formik.setFieldValue("classification", value);
          }}
          onBlur={formik.handleBlur}
          renderValue={(selected) => {
            if (
              (selected == undefined || selected == "") &&
              (formik.values.classification == undefined ||
                formik.values.classification == "")
            ) {
              return <em>Tax classification</em>;
            }

            let optionData = options.find(
              (o) => o.id == formik.values.classification
            );
            return optionData ? optionData.name : "";
          }}
        />
      </FormControl>
      {formik.errors.classification && formik.touched.classification && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.classification}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TaxClassification;
