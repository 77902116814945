import { Box, Typography } from "@mui/material";

const Available = () => {
  return (
    <Box>
      <Typography fontWeight="bold" component="h1">
        S/. 0
      </Typography>
      <Typography fontWeight="light" component="h4">
        Available credits
      </Typography>
    </Box>
  );
};

export default Available;
