import { Link, Typography } from "@mui/material";

const Documents = () => {
  return (
    <>
      <Typography variant="h4">
        Tax documents required for filing taxes are available to review and
        download here.
      </Typography>
      <Typography sx={{ mt: 3 }} variant="h4">
        You can also file taxes using detailed earnings info, available in the{" "}
        <Link
          sx={{
            display: "initial !important",
            fontWeight: "500",
            fontSize: 17,
          }}
          className="link-update"
          href="#"
        >
          earnings summary
        </Link>
        .
      </Typography>
    </>
  );
};

export default Documents;
