import { Box, Icon, Typography } from "@mui/material";
const InfoDescription = ({
  noBorder = false,
  title,
  description,
  icon = "add_circle",
}) => {
  return (
    <Box
      sx={{
        borderBottom: !noBorder ? "1px solid #DDDDDD !important;" : "",
        paddingBottom: 3,
        paddingTop: 3,
      }}
    >
      <Icon
        className="text-danger"
        sx={{ fontSize: "50px !important", marginBottom: 2 }}
      >
        {icon}
      </Icon>
      <Typography sx={{ fontWeight: "600", marginBottom: 2 }} variant="h3">
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  );
};
export default InfoDescription;
