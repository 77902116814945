import React from "react";
import { List, ListItem, ListItemButton, ListItemText,} from "@mui/material";
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import { BeachGearService } from "../../Services/BeachGearService";

const ServiceSidebar = ({ images, getServiceList, getActiveServiceCategories, activeServiceCategories, pageType, hostSlug }) => {
    const location = useLocation();
    const [list, setTypeList] = React.useState();
    
    React.useEffect(() => {
        getTypeList();
    }, []);

    const getTypeList = () =>{
        var services;
        if(pageType=='hostprofile') services = BeachGearService.typeListsByHost(hostSlug);
        else services = BeachGearService.typeList();
        
        services.then(res =>{
            setTypeList(res.data.data);
        }).catch(error => {
        });
    }

    return (
        <>
            <Grid xs={12} sm={12} md={3} lg={2} style={{display : (pageType == 'hostprofile' ? 'none' : '') }}>
                <div className="service-list-left-side">    
                    <nav>
                        <List className="left-side-menu-show">
                            <ListItem disablePadding>
                                <ListItemButton component="a" 
                                    onClick={() => {
                                        getServiceList('all'); 
                                        getActiveServiceCategories(['all', 'All Beach Gear'])
                                    }} className={`service-listing-toggle ${activeServiceCategories[0] == 'all' && 'listing-text active'}`}>
                                    <img src={images.sidemenuIcon} alt=""
                                    />
                                      All Services
                                </ListItemButton>
                            </ListItem>
                            {location.pathname.includes("favourites") &&
                            <>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                <img src={images.sidemenuIcon} alt=""
                                    />
                                    <ListItemText className="listing-text active" primary="All Beach Gear" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                    <ListItemText className="listing-text active" primary="Hosts profile (1)" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                    <ListItemText className="listing-text" primary="Kayaking (7)" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                    <ListItemText className="listing-text" primary="Surfing (3)" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                    <ListItemText className="listing-text" primary="Wake surfing (2)" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="javascript:void(0)">
                                    <ListItemText className="listing-text" primary="Surf board (3)" />
                                </ListItemButton>
                            </ListItem>
                            </>
                            }
                            {(location.pathname.includes("services") || location.pathname.includes("host")) && 
                            <>
                            {list && Object.keys(list).map((listData, index) =>{
                                return(
                                <ListItem key={index} disablePadding>
                                    <ListItemButton component="a" href="javascript:void(0)">
                                        <ListItemText 
                                        onClick={() => {
                                            getServiceList(listData)
                                            getActiveServiceCategories([listData, list[listData]['type']+" ("+list[listData]['count']+")"])
                                        }} 
                                        className={`service-listing-toggle ${activeServiceCategories[0] == listData && 'listing-text active'}`} primary={`${list[listData].type} (${list[listData].count})` } />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </>
                            }
                        </List>
                    </nav>
                </div>
            </Grid>
        </>
    );
};

export default ServiceSidebar;
