import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import FormRadioItem from "../../../../Components/Taxes/FormRadionItem";
import { TaxFormContext } from "../../../../Context/TaxContext";

const TaxListItems = [
  "Active US listing(s)",
  "A US payout method on file",
  "US citizen or resident status",
];
const TaxListItem = ({ label }) => {
  return (
    <>
      <ListItem sx={{ pl: 0, py: "0px !important" }}>
        <Typography
          sx={{ pr: 2, fontWeight: "bold", fontSize: 30, lineHeight: 0 }}
        >
          •
        </Typography>
        <ListItemText sx={{ fontSize: 18, lineHeight: 0 }} primary={label} />
      </ListItem>
    </>
  );
};

const UnitedStates = () => {
  const [form, setForm] = useState(null);
  const { setPageIndex } = useContext(TaxFormContext);
  const nextHandler = () => {
    setPageIndex(form);
  };
  const formsOptions = [
    {
      name: "Form W-9",
      description:
        "For US taxpayers (citizens, tax residents, or businesses) filing a US tax return",
      index: 1,
    },
    {
      name: "Form W-8ECI",
      description:
        "For foreign taxpayers (citizens, tax residents or businesses) filing a US tax return",
      index: 2,
    },
    {
      name: "Form W-8BEN",
      description:
        "For foreign citizens or tax residents who don’t file a US tax return",
      index: 3,
    },
  ];
  return (
    <>
      <Container
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
          px: "20% !important",
          my: 9,
          py: 6,
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "500" }} component="h2">
          Tax requirements for the US
        </Typography>
        <Typography component="h4">
          Tax info is required from every individual or entity with one or more
          of the following:
        </Typography>
        <List dense={true}>
          {TaxListItems.map((label, index) => (
            <TaxListItem key={index} label={label} />
          ))}
        </List>
        <Typography sx={{ mt: 3 }} component="h4">
          Tax info you submit will be reported to the IRS. Get answers to
          questions about taxes in our{" "}
          <Link
            sx={{
              display: "initial !important",
              fontWeight: "500",
              fontSize: 17,
            }}
            className="link-update"
            href="#"
          >
            Help Center
          </Link>
          .
        </Typography>
        <Box sx={{ borderTop: "solid 1px #EBEBEB", mt: 4, pt: 4 }}>
          <Typography sx={{ fontWeight: "600" }} component="h4">
            Select one of the following:
          </Typography>
          <RadioGroup
            sx={{ mt: 1 }}
            name="us-radio-buttons-group"
            value={form}
            onChange={(event) => setForm(event.target.value)}
          >
            {formsOptions.map((form, index) => (
              <FormRadioItem key={index} form={form} />
            ))}
          </RadioGroup>
        </Box>
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "white",
          px: 3,
        }}
      >
        <Button
          type="button"
          sx={{
            backgroundColor: "black !important",
            border: "1px solid black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          disabled={!form ? true : false}
          className="btn-primary submit-btn "
          onClick={nextHandler}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default UnitedStates;
