import {
  Box,
  Button,
  Link as MaterialLink,
  Skeleton,
  Typography,
  // Grid
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import EditProfileForm from "../../../Components/Profile/Host/EditProfileForm";
import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch } from "react-redux";
import AppHelper from '../../../Helpers/AppHelper';
import AuthHelper from "../../../Helpers/AuthHelper";
import { ProfileService } from "../../../Services/ProfileService";
import { LoginAndSecurityService } from "../../../Services/LoginAndSecurityService";
import { useSelector } from "react-redux";
import MakeToast from "../../../Helpers/MakeToast";

const ProfileDataSection = (props) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducers.data);

  const [showEditProfile, setShowEditProfile] = useState(false);
  const { profile, languages, loading, setLanguages, isEditable } =
    useContext(ProfileContext);
  const [spokenLanguages, setSpokenLanguages] = useState([]);

  useEffect(() => {
    languagesApi();
  }, [showEditProfile, profile]);

  useEffect(() => {
    languagesApi();
  }, []);

  const languagesApi = () => {
    setLanguages({
      userLanguages: [...languages.availableLanguages],
      availableLanguages: [...languages.availableLanguages],
    });
    setSpokenLanguages([
      ...languages.availableLanguages
        .filter((language) => language.checked)
        .map((l) => l.name),
    ]);
  };

  const deleteAccount = () => {
    AppHelper.SAToast(
        'Are you sure you want to delete your account? This will erase all of your profile settings and data.',
        'notext',
        { title: 'delete-title-swal'}
      ).then((result) => {
        if (result.isConfirmed) {
          ProfileService.hostAccountDelete().then(resp => {
            try {
              LoginAndSecurityService.removeDeviceHistory(AuthHelper.getToken());
            } catch (err) { }
        
            dispatch({
              type: "LOGOUT",
              payload: [],
            });

            navigate.push('/');
            MakeToast("success", "Account deleted successfully.");
          })
        }
    })
  }

  const AboutSection = () => {
    return (
      <Grid
        sx={{ alignItems: "end", marginBottom: 4, ml: 0 }}
        spacing={1}
        container className="operation-padding"
      >
        {loading ? (
          <Skeleton sx={{ ml: 1, mt: 2 }} height={25} width={90} />
        ) : (
          <>
            {profile?.description && (
              // <Grid xs={12} sm={12} md={12} lg={12}
              //   className="confirmed-section"
              //   sx={{ alignItems: "end", marginTop: 4 }}
              //   spacing={1}
               
              // >
              //   <Grid  xs={12} sm={12} md={12} lg={12} 
              //    sx={{ paddingTop: "5px !important" }} item className="operation-padding confirmed-section">
              //     <Typography
              //       sx={{ fontWeight: "bold", textAlign: "start", mt: 2, mb: 3 }}
              //       component="h4"
              //     >
              //       Overview/Biography
              //     </Typography>
              //     <Typography
              //       sx={{ fontWeight: "350" }}
              //       component="h4"
              //     >
              //       {profile?.description}
              //     </Typography>
              //   </Grid>
              // </Grid>
                <Grid  xs={12} sm={12} md={12} lg={12} 
                sx={{ paddingTop: "5px !important" }} item className=" confirmed-section">
                 <Typography
                   sx={{ fontWeight: "bold", textAlign: "start", mt: 2, mb: 3 }}
                   component="h4"
                 >
                   Overview/Biography
                 </Typography>
                 <Typography
                   sx={{ fontWeight: "350" }}
                   component="h4"
                 >
                   {profile?.description}
                 </Typography>
               </Grid>
              
            )}

            <Grid  xs={12} sm={12} md={12} lg={12}
              className="confirmed-section"
              sx={{ alignItems: "end", marginTop: 2, marginBottom: 3 }}
              spacing={1}
             
            >
              <Box sx={{ alignSelf: "start", display:"flex",alignItems:"center",gap:"10px"}} >
                <LocationOnIcon style={{ fontSize: 20 }} />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                  component="h4"
                >
                  General Location
                </Typography>
              </Box>
              <Box
                sx={{ paddingTop: "5px !important" }} >
                
                <Typography
                  sx={{ fontWeight: "400", textAlign: "start" }}
                  component="h4"
                >
                  {profile?.rental_location_address}
                </Typography>
              </Box>
            </Grid>
            {spokenLanguages?.length > 0 && (
              <Grid xs={12} sm={12} md={12} lg={12}
                sx={{ alignItems: "end", marginBottom: 3 }}
                spacing={1}
               
              >
                <Grid xs={12} sm={12} md={12} lg={12} 
                sx={{ alignSelf: "start",display:"flex",alignItems:"center",gap:"10px"}}>
                  <ChatBubbleIcon style={{ fontSize: 20 }} />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "start",
                    }}
                    component="h4"
                  >
                    Languages Spoken
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}
                 sx={{ paddingTop: "5px !important" }} item>
                 
                  <Typography
                    sx={{ fontWeight: "400", textAlign: "start" }}
                    component="h4"
                  >
                    Speaks {spokenLanguages.join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {profile?.hours_of_operation && (
              <>
                <Grid xs={12} sm={12} md={12} lg={12} className="operation-padding"
                  sx={{ alignItems: "end", marginBottom: 3 }}
                  spacing={1}

                >
                <Box className="hours-date-time">
                    <Box sx={{ display: "flex", alignItems:"center", gap:"10px" }} >
                      <AccessTimeFilledIcon style={{ fontSize: 20 }} />
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                        component="h4"
                      >
                        Hours of operation
                      </Typography>
                    </Box>
                    <Box  className="opening-date"
                      sx={{p:4,pb:"0",pt:"2" }}
                      xs={12}
                      md={12}
                    >
                    
                      {profile.hours_of_operation.map((hours, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          key={index}
                        >
                          <Typography
                            sx={{
                              fontWeight: "lighter",
                              textAlign: "start",
                              ...(hours.status != 1 && { color: "lightgray" }),
                            }}
                            component="h4"
                          >
                            {hours.day}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              textAlign: "start",
                              ...(hours.status != 1 && { color: "lightgray" }),
                            }}
                            component="h4"
                          >
                            {hours.status == 1
                              ? ` ${hours.open} - ${hours.close}`
                              : " Closed"}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                </Box>
                </Grid>
              </>
            )}
             <Grid xs={12} sm={12} md={12} lg={12}
              sx={{ alignItems: "end", marginBottom: 0,}}
              spacing={1} className="operation-padding"
              container
            >
              <Box sx={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <Box sx={{ alignSelf: "start" }} >
                    <StarIcon style={{ fontSize: 20 }} />
                  </Box>
                  <Box >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "start",
                        lineHeight:"19px"
                      }}
                      component="h4"
                    >
                      Rating{" "}
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "400", textAlign: "start" }}
                      component="h4"
                    >
                      {profile.reviews_ovr} ({profile.reviews} Reviews)
                    </Typography>
                  </Box>
               </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
  return (
    <>
      {loading ? (
        <>
          <Skeleton height={40} width={200} />
          <Skeleton height={13} width={60} />
        </>
      ) : (
        <>
        <Box className="main-heading-block" sx={{marginBottom:"0",lineHeight:"32px"}}>
          <h1 className="main-heading" sx={{marginBottom:"0"}}>{profile.company_name}</h1>
          </Box>
          <p>Joined in {profile.joined}</p>
        </>
      )}
      {isEditable && (
        <>
          {loading ? (
            <Skeleton sx={{ mt: 1 }} height={15} width={50} />
          ) : (
            <MaterialLink
              onClick={() => {
                setShowEditProfile(true);
              }}
              className={"link-update " + (showEditProfile && "disable-link")}
            >
              Edit profile
            </MaterialLink>
          )}

          {showEditProfile && (
            <EditProfileForm
              showEditProfile={showEditProfile}
              setShowEditProfile={setShowEditProfile}
            />
          )}
        </>
      )}

      {!showEditProfile && <AboutSection />}

      {isEditable && (
        <Link to="/host/reviews" className="link-update reviews-borders">
          See Reviews
        </Link>
      )}

      {user.role_id=='2' && (
        <Button size="small" type="button" sx={{margin:"15px 0"}} className="btn-primary submit-btn rounded-button" onClick={(e) => { deleteAccount() }}>
            Delete Account
        </Button>
      )}
    </>
  );
};
export default ProfileDataSection;
