import React, { useState } from "react";
import { useFormik } from "formik";
import { Box, Typography, FormControl, Button, CircularProgress} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import QuestionMarkPopover from '../Common/QuestionMarkPopover';
import RadioInput from "../Inputs/RadioInput";
import AppHelper from "../../Helpers/AppHelper";
import MakeToast from "../../Helpers/MakeToast";

import { HostService } from "../../Services/HostService";

const InstantBooking = ({hostData, getHostProfile}) => {
    const [loader, setLoader] = useState(false);
    const yesno = AppHelper.lists().yesno;

    const [ibInfo, setIBInfo] = React.useState(null);
    const ibContent = "Hosts who allow customers to book reservations without Host approval<br /> will have the Instant Booking feature turned on. This allows a customer to<br /> book services with a Host, without a booking request, as long as the<br /> desired items/services have not already been reserved for that same time slot.";
  
    const formik = useFormik({
        initialValues: {
            instant_booking_available: hostData.instant_booking_available
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoader(true);
            HostService.hostProfileUpdate(values).then((resp) => {
                getHostProfile();
                MakeToast("success", resp);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            });
        },
    });

    return(
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} className="become-host-step-form">
                        <Box className="small-heading">
                        <Typography variant="h6" component="h2" className="main-heading p-relative">
                        Do you offer instant booking
                        <QuestionMarkPopover 
                            anchorPopOver={ibInfo} 
                            setAnchorPopOver={setIBInfo}
                            content={ibContent}
                            spanClassName='p-absolute'
                        />
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Box className="radio-check-button">
                            <FormControl>
                                <RadioInput 
                                customArr={{data : yesno, name : 'name', id : 'id'}}
                                name="instant_booking_available"
                                value={formik.values.instant_booking_available}
                                onChange={formik.handleChange}
                                defaultValue="0"
                                sx={{
                                    fontSize: '16px',
                                    paddingRight: '30px',
                                }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="modal-filter-btn">
                    <Button className="filter-button rounded-button"
                        type="submit"
                        sx={{
                        marginTop: '15px !important',
                        color: '#ffffff',
                        textTransform: 'capitalize',
                        }} >
                            {loader ? <CircularProgress color="inherit" /> : 'Save' }
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default InstantBooking;
