import CustomTabs from "../../Components/Common/CustomTabs/CustomTabs";
import ReviewsTab from "./ReviewsTab";

const ReviewsTabs = () => {
  const tabsContent = [{ label: "Reviews", content: <ReviewsTab /> }];
  return (
    <CustomTabs
      tabs={tabsContent}
      tabName="reviews-tab"
      panelName="reviews-tabpanel"
      sx={{ marginTop: 5 }}
    />
  );
};

export default ReviewsTabs;
