import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Container,
} from '@mui/material';

import Faq from '../Faq/Faq';
import Tutorials from '../Tutorials/Tutorials'
import { TipService } from '../../Services/TipService';
import Support from '../../Components/Support/Support';
import BreadCrumb from "../../Components/Common/BreadCrumb";

import parse from 'html-react-parser'

const Help = () => {
    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);
    const [openSupport, setOpenSupport] = React.useState(true);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tabparam = searchParams.get("tab");

    const tabChange = () => {
        if (tabparam != null) {
            setValue(parseInt(tabparam));
            window.history.replaceState(null, null, window.location.pathname);
        }
    };

    useEffect(() => {
        if (!openSupport) {
            setValue(2);
            setOpenSupport(true);
        }

        tabChange();
    }, [openSupport, tabparam]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const waitForElement = (name, callback) => {
        const interval = setInterval(function() {
          const elements = document.querySelectorAll(name);
          if (elements.length > 0) {
            clearInterval(interval);
            callback();
          }
        }, 100); // Check every 100 milliseconds
      }

    const [tip, setTip] = React.useState([]);
    const getRenterTip = () => {
        TipService.renters({}).then(response => {
            setTip(response.data.data);

            waitForElement('.tipfaq', function() {
                document.querySelectorAll(".tipfaq")[0].addEventListener("click", (e) => {
                    setValue(0)
                })
            });

            waitForElement('.tiptutorials', function() {
                document.querySelectorAll(".tiptutorials")[0].addEventListener("click", (e) => {
                    setValue(3)
                })
            });
        }).catch(error => {

        });
    }

    const getHostTip = () => {
        TipService.hosts({}).then(response => {
            setTip(response.data.data);
        }).catch(error => {

        });
    }

    React.useEffect(() => {
        if (isLoggedIn && user.role_id == "2") {
            getHostTip();
        }else{
            getRenterTip();
        }

        
    }, []);

    return (
        <>
            <section className='help-block-page section-padding'>
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <BreadCrumb data={[["Help"]]} /> 
                        <Grid xs={12}>
                            <Box className="big-heading" sx={{
                                textAlign: 'center',
                            }}>
                                <Typography variant="h6" component="h2" className='help-big-heading'>Hi, how can we help?</Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    className="horizontal-tab"
                                    aria-label="secondary tabs example scrollable auto"
                                    value={value}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    onChange={handleChange} >
                                    <Tab label="FAQs" {...a11yProps(0)} />
                                    <Tab label="Support" {...a11yProps(1)} />
                                    <Tab label="Tips for Success" {...a11yProps(2)} />
                                    <Tab label="Tutorials" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Faq />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Support pagetype='form' />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ul className="tips-list">
                                    {tip.map((result, index) => (
                                        <li>{parse(result.tip)}</li>
                                    ))}
                                </ul>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Tutorials />
                            </TabPanel>

                        </Grid>

                    </Grid>
                </Container>
            </section>
        </>
    )
}

export default Help;
