import React from "react";
import { Box, Button, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import Form from "../../Components/PaymentsMethod/Form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DataForm from "../../Components/PaymentsMethod/DataForm";
import { useState } from "react";
import { PaymentMethodContext } from "../../Context/PaymentMethodContext";

const PaymentInfo = () => {
  const options = {};
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
  );
  const [showEdit, setShowEdit] = useState(false);

  return (
    <PaymentMethodContext.Provider
      value={{ showEdit: showEdit, setShowEdit: setShowEdit }}
    >
      <Container sx={{ py: 5 }}>
        <AccountSettingsTitle title="Payments" routeLabel="Payments" />
        <Grid spacing={2} container>
          <Grid xs={12} md={12} sx={{ justifyContent:"space-between" ,display:'flex',alignItems:'center'}}>

            <Box>
              <h2 className="main-heading small-heading">Payment method</h2>
            </Box>
            <Button
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              onClick={() => {
                setShowEdit(!showEdit);
              }}
            >
              {showEdit ? "Close" : "Edit"}
            </Button>
          </Grid>
          {/* <Grid sx={{ textAlign: "end" }} xs={12} md={4}>
            <Button
              size="small"
              type="button"
              disableRipple
              disableElevation
              className="btn-primary submit-btn rounded-button"
              sx={{ mt: "15px" }}
              onClick={() => {
                setShowEdit(!showEdit);
              }}
            >
              {showEdit ? "Close" : "Edit"}
            </Button>
          </Grid> */}
          {showEdit ? (
            <Elements stripe={stripePromise} options={options}>
              <Form />
            </Elements>
          ) : (
            <DataForm />
          )}
        </Grid>
      </Container>
    </PaymentMethodContext.Provider>
  );
};

export default PaymentInfo;
