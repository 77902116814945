import { Button } from "@mui/material";
import RateHostModal from "./Modal";
import { useState } from "react";
import { Link } from "react-router-dom";

const RateHostButton = ({ beachGearData }) => {
  const [showModal, setShowModal] = useState(false);
  return beachGearData?.review ? (
    <Link
      style={{ marginLeft: "5px" }}
      fullHeight
      disableElevation
      variant="contained"
      className="btn-primary small-btn border-radius-md card-btn"
      to={`/host/reviews/${beachGearData.host_id}`}
    >
      Rate this Host
    </Link>
  ) : (
    <>
      <Button
        sx={{ ml: 1 }}
        fullHeight
        disableElevation
        variant="contained"
        className="btn-primary small-btn border-radius-md card-btn"
        onClick={() => setShowModal(true)}
      >
        Rate this Host
      </Button>
      <RateHostModal
        open={showModal}
        onClose={() => setShowModal(false)}
        beachGearData={beachGearData}
      />
    </>
  );
};

export default RateHostButton;
