import { APIService } from "./APIService";

export const ContactUsService= {
    contactus,
    verifyCaptcha
};

function contactus(data) {
    return APIService.post('api/contactus', data);
}

function verifyCaptcha(data) {
    return APIService.post('api/verify-captcha', data);
}