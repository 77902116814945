import React from 'react'
import ModalPopup from './ModalPopup';
import { Box, Button, CircularProgress, FormControl, FormHelperText, TextareaAutosize, Typography, Input } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import images from '../../assets/js/images';
import MakeToast from '../../Helpers/MakeToast';
import { ShareService } from '../../Services/ShareService';
import {
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";

function ShareModal(props) {
    let validationSchema = yup.object().shape({
        email: yup.string().email().nullable().typeError("Email must be a valid address"),
        mobile: yup.number().when(['email'], {
            is: (email) => email == '' || email == undefined,
            then: yup.number().nullable().required('Mobile number is required').typeError("Mobile number must be a number"),
            otherwise: yup.number().nullable().typeError("Mobile number must be a number"),
        })
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            mobile: '',
            url: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            ShareService.share(values).then(resp => {
                formik.setSubmitting(false);
                MakeToast("success", "Shared successfully");
                props.setOpenShare(false);
            })
        },
    });

    React.useEffect(() => {
        formik.setFieldValue('url', props.url);
    }, [props])

    return (
        <>
            <ModalPopup
                contentStyle={{ width: '550px', height: '328px' }}
                open={props.openShare}
                onClose={() => { props.setOpenShare(false) }}
                content={<>
                    <Box className="modalheader" sx={{ marginBottom: 0 }}>
                        <Box className="modal-close-btn">
                            <Button size="small" onClick={() => { props.setOpenShare(false) }}> <img src={images.closeIcon} alt="" /></Button>
                        </Box>
                    </Box>
                    <Box className="modal-body message-modal">
                        <form onSubmit={formik.handleSubmit}>
                            <Typography component="h2">
                                Share this page
                            </Typography>
                            <FormControl sx={{ width: '100%' }} className={"input-style"}>
                                <Input 
                                    name="mobile"
                                    onChange={formik.handleChange}
                                    placeholder="305-330-4000" 
                                    className="outline-input"
                                    sx={{
                                        paddingLeft: '30px',
                                        '&:before': {
                                            borderBottom: '0px solid'
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormHelperText className="field-helper-text error">{formik?.errors?.mobile}</FormHelperText>
                            <FormControl sx={{ width: '100%', marginTop: '15px' }} className={"input-style"}>
                                <Input 
                                    name="email"
                                    onChange={formik.handleChange}
                                    placeholder="stephenharvey@mail.com" 
                                    className="outline-input"
                                    sx={{
                                        paddingLeft: '30px',
                                        '&:before': {
                                            borderBottom: '0px solid'
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormHelperText className="field-helper-text error">{formik?.errors?.email}</FormHelperText>
                            {
                                props.image!='' && props.image!=undefined
                                ?
                                <>
                                    <FacebookShareButton title={props.title} url={props.url} imageUrl={props.image} style={{ width: '100%', marginTop: '15px', border: '1px solid #EEEEEE', borderRadius : '50px', padding: '15px 0px 15px 30px', fontSize : '16px', textAlign: 'left'}}>
                                        <img src={images.fb} alt=""  style={{margin:'0 5px 0 0'}}/> Share with facebook
                                    </FacebookShareButton>
                                    <WhatsappShareButton title={props.title} url={props.url} imageUrl={props.image} style={{ width: '100%', marginTop: '15px', border: '1px solid #EEEEEE', borderRadius : '50px', padding: '15px 0px 15px 30px', fontSize : '16px', textAlign: 'left'}}>
                                        <img src={images.whatsapp} alt="" style={{margin:'0 5px 0 0'}}/> Share with whatsapp
                                    </WhatsappShareButton>
                                </>
                                :
                                <></>
                            }
                            <Box sx={{ marginTop: '15px' }}>
                                <Button type="submit" disabled={formik.isSubmitting} className="btn-primary border-radius-md w-100" sx={{ padding: '15px 10px !important' }}>
                                {formik.isSubmitting ? <CircularProgress color="inherit" /> : "Share"}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </>}
            />
        </>
    )
}

export default ShareModal