import React from "react";
import {
    Link,
} from '@mui/material';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Grid from '@mui/material/Unstable_Grid2';

const SectionHeader = ({ title, viewAllLink, imgSource, sectionType }) => {
    const navigate = useHistory();

    return (
        <>
            <Grid xs={12} sx={{ py: 0 }}>
                <div className="main-heading-block">
                    <h2 className="main-heading">{title}</h2>
                    <RouterLink
                        variant="body2"
                        underline="none"
                        className="view-all-btn"
                        color="inherit"
                        onClick={() => {
                            if(sectionType!='popularcategories' && sectionType!='previouslyviewed'){
                                navigate.push("/search-result");
                            }
                        }}
                    >
                        View all
                        <img src={imgSource} alt="arrow" />
                    </RouterLink>
                </div>
            </Grid>
        </>
    )
}

export default SectionHeader;