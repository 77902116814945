import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ButtonBase, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneModal from "./PhoneModal";
import { useContext } from "react";
import { ModalContext } from "./Context/ModalContext";
const VerifyOption = ({ title, type, titleVerified, description }) => {
  const { user } = useSelector((state) => state.userReducers.data);
  const isVerified = user[type] != null;
  const { setOpenPhone } = useContext(ModalContext);

  return (
    <>
      <Grid sx={{ mb: 3 }} container>
        <Grid xs={2} item>
          {isVerified ? (
            <CheckCircleIcon sx={{ fontSize: 40, color: "#007A80" }} />
          ) : (
            <CircleIcon sx={{ color: "gray", fontSize: 40 }} />
          )}
        </Grid>
        <Grid xs={10} item>
          {isVerified ? (
            <Typography
              sx={{ fontWeight: "500", color: "#007A80 !important" }}
              component="h4"
            >
              {title}
            </Typography>
          ) : (
            <ButtonBase
              variant="text"
              onClick={() => {
                setOpenPhone(true);
              }}
            >
              <Typography
                sx={{ fontWeight: "500", color: "#007A80 !important" }}
                component="h4"
              >
                {isVerified ? titleVerified : title}
              </Typography>
            </ButtonBase>
          )}

          {!isVerified && <Typography component="h4">{description}</Typography>}
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyOption;
