import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GppGoodTwoToneIcon from "@mui/icons-material/GppGoodTwoTone";
import VerifyOption from "./VerifyOption";

const optionsData = [
  {
    title: "Add phone number",
    titleVerified: "Phone number Verified",
    type: "mobile_no",
    description:
      "This is the number where you’ll receive security codes so you can always access your account.",
  },
  {
    title: "Add Email address",
    titleVerified: "Email address verified",
    type: "email",
    description: "",
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
    overflow: "auto",
  },
}));

const ImproveModal = (props) => {
  const { onClose } = props;
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton
          onClick={onClose}
          sx={{ padding: 0, position: "absolute", right: 17, top: 20 }}
        >
          <HighlightOffIcon
            sx={{
              color: "#bababa",
              fontSize: 35,
            }}
          />
        </IconButton>
        <Box sx={{ my: 1 }}>
          <GppGoodTwoToneIcon
            sx={{ fontSize: 65, mx: 0, marginLeft: "-10px", color: "#FFB400" }}
          />
        </Box>
        <Typography sx={{ fontWeight: "600" }} component="h2">
          Let’s make your account more secure
        </Typography>
        <Box sx={{ display: "flex", my: 3 }}>
          <Typography sx={{ fontWeight: "500" }} component="h4">
            Your account security:
          </Typography>
          <Typography
            sx={{ ml: 1, color: "red", fontWeight: "500" }}
            component="h4"
          >
            Low
          </Typography>
        </Box>
        <Typography sx={{ mb: 4 }} component="h4">
          We’re always working on ways to increase safety in our community.
          That’s why we look at every account to make sure it’s as secure as
          possible.
        </Typography>
        {optionsData.map((optionData, index) => (
          <VerifyOption key={index} {...optionData} />
        ))}
      </ResponsiveStyles>
    </Modal>
  );
};

export default ImproveModal;
