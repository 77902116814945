import React from 'react'
import { useState, useRef } from "react";
import {Box, Button, FormControl, Typography, TextareaAutosize, TextField, FormHelperText, CircularProgress } from '@mui/material';
import { Formik } from "formik";
import * as yup from 'yup';
import Reaptcha from "reaptcha";
import { Link, useLocation } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2';
import images from "../../assets/js/images";
import SelectInput from "../../Components/Inputs/SelectInput";
import ModalPopup from "../../Components/Modal/ModalPopup";

import { ContactUsService } from "../../Services/ContactUsService";

export default function Support({ setOpen, open, pagetype, hostslug }) {
    const [openChild, setOpenChild] = React.useState(false);

    const location = useLocation();
    const types = [
        "I have a question or problem with my reservation or beach gear",
        "I’d like to report a glitch/problem within the app",
        "I have a general question"
    ];

    const [captchaToken, setCaptchaToken] = useState('');
    const captchaRef = useRef(null);

    const expired = () => {
        captchaRef.current.reset();
        setCaptchaToken(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let contactUsValidationSchema = yup.object().shape({
        type: yup.string().nullable().when([], {
            is: () => pagetype!='forgotemail' && pagetype!='contacthost',
            then: yup.string().nullable().required('Option is required'),
            otherwise: yup.string().nullable(),
        }),
        subject: yup.string().required('Subject is required'),
        message: yup.string().min(50, "Message must be more than 50 character").required('Message is required'),
        recaptcha: yup.string().required('Recaptcha is required')
    });

    const formsection = 
        <>
        <Box className="modalheader">
            <Box id="modal-modal-title" className="modal-heading" component="h2" sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '0' }}>
                Contact us
            </Box>
            <Box className="modal-close-btn" onClick={() => {
                setOpen(false);
            }}>
                <Button size="small" disableRipple onClick={handleClose}> <img src={images.closeIcon} alt="" /></Button>
            </Box>
        </Box>
        <Box className="modal-body" sx={{ p : 0 }}>
            <Formik
                validationSchema={contactUsValidationSchema}
                initialValues={{ type: '', subject: '', message: '', recaptcha: captchaToken }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    if(hostslug!=undefined) values.host_slug = hostslug;
                    ContactUsService.contactus(values).then(response => {
                        if(pagetype!='form') setOpen(false)
                        setOpenChild(true)
                        resetForm();
                        expired();
                        setSubmitting(false);
                    }).catch(error => {
                        setSubmitting(false);
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box component="form" noValidate autoComplete="off">
                            {pagetype!='forgotemail' && pagetype!='contacthost' &&
                                <>
                                    <FormControl variant="standard" className={"input-style outlined-input select-style " + (errors.type && touched.type ? "error" : "")} sx={{ width:'100%', borderRadius : '15px'}}>
                                        <SelectInput
                                            customArr1={types}
                                            name="type"
                                            value={values.type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            sx={{
                                                marginLeft: '20px',
                                                '@media (max-width:769px)': {
                                                    marginLeft: '20px'
                                                }
                                            }}
                                            renderValue={(selected) => {
                                                if (selected == undefined || selected == '') {
                                                    return <em>How can we help?</em>;
                                                }

                                                let typedata = types.indexOf(values.type);
                                                return typedata > -1 ? types[typedata] : '';
                                            }}
                                        />
                                    </FormControl>
                                    <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.type && touched.type && errors.type}</FormHelperText>
                                </>
                            }
                            {values.type === types[1] && 
                                <p>Please write a detailed description of the problem or glitch you encountered, and Beachify’s developers will get to work on this right away!</p>
                            }
                            <FormControl sx={{ width: '100%' }}>
                                <TextField id="standard-basic" variant="filled" placeholder="Type a subject" name="subject" className={"border-radius-md outline-input input-style " + (errors.subject && touched.subject ? "error" : "")}
                                    value={values.subject}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    sx={{
                                        fontSize: '15px',
                                        bgcolor: 'transparent',
                                        '&:before': {
                                            borderBottom: '0'
                                        },
                                        '& .Mui-focused': {
                                            bgcolor: 'transparent',
                                        },
                                        '& .MuiFilledInput-root': {
                                            bgcolor: 'transparent',
                                            padding: '0 20px',
                                            borderRadius: '18px',
                                            '&:hover': {
                                                bgcolor: 'transparent !important',
                                            },
                                            '&:after': {
                                                borderBottom: '0 !important',
                                            },
                                            '&:before': {
                                                borderBottom: '0 !important',
                                            },
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.subject && touched.subject && errors.subject}</FormHelperText>
                            <FormControl sx={{ width: '100%' }}>
                                <TextareaAutosize
                                    name="message"
                                    className={"border-radius-md outline-input input-style " + (errors.message && touched.message ? "error" : "")}
                                    aria-label="minimum height"
                                    minRows={5}
                                    placeholder="Your message"
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormControl>
                            <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.message && touched.message && errors.message}</FormHelperText>
                            <Reaptcha
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={captchaRef}
                                onVerify={() => {
                                    captchaRef.current.getResponse().then((res) => {
                                        setCaptchaToken(res);
                                        setFieldValue('recaptcha', res);
                                    });
                                }}
                                onExpire={expired}
                            />
                            <TextField name="recaptcha" value={values.recaptcha} onChange={handleChange} onBlur={handleBlur} hidden style={{display: 'none' }}/>
                            <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.recaptcha && touched.recaptcha && errors.recaptcha}</FormHelperText>
                        </Box>
                        <Box noValidate autoComplete="off" className="send-btn">
                            <Button type="submit" disabled={isSubmitting} className="btn-primary border-radius-md w-100" sx={{ textTransform: 'capitalize', marginBottom: '0px !important', padding: '15px 10px !important' }} >
                            {isSubmitting ? <CircularProgress color="inherit" /> : "Send"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    </>

    return (
        <>
            {
            pagetype=='form' 
            ? 
            <Grid sx={{
                backgroundColor: '#fff',
                width: '100%',
                borderRadius: '16px !important',
                padding: '30px 15px 15px'
            }}>{ formsection }</Grid>
            : 
            <ModalPopup
                contentStyle={{width:'600px', width1:'550px'}}
                open={open}
                onClose={handleClose}
                content={formsection}
            />
            }
            {openChild && 
            <ModalPopup
                contentStyle={{width:'600px', width1:'550px'}}
                open={openChild}
                onClose={() => { setOpenChild(false) }}
                content={<>
                    <Box className="modalheader" sx={{ marginBottom: 0 }}>
                        <Box className="modal-close-btn" onClick={() => { setOpenChild(false); }}>
                            <Button size="small" disableRipple onClick={() => { setOpenChild(false); }}> <img src={images.closeIcon} alt="" /></Button>
                        </Box>
                    </Box>
                    <Box className="modal-body message-modal">
                        <img src={images.restingIcon} alt="sucessful" />
                        <Typography component="h2">Message sent</Typography>
                        <Typography component="p">
                            {
                            pagetype=='forgotemail' 
                            ? 
                            'Your message has been sent successfully. A beachify team member will respond to your request' 
                            : 
                            (
                                pagetype=='contacthost' 
                                ?
                                'Your message has been sent successfully.'
                                :
                                'Your message has been sent. If you have a question about your reservation, please note that all questions/concerns with making or changing a reservation, refunds, cancellations, security deposits, and delivery/pickup are handled by the Host, and not by Beachify support. You can contact your Host by visiting the “Bookings” tab.'
                            )
                            }
                        </Typography>
                        <Box noValidate autoComplete="off">
                            {location.pathname == "/" || location.pathname == "" ?
                                <Button type="submit" className="btn-primary border-radius-md w-100" sx={{ textTransform: 'capitalize', marginBottom: '0px !important', padding: '15px 10px !important' }} onClick={() => { setOpenChild(false) }}>
                                    Go back to main page
                                </Button> :
                                <Link to="/">
                                    <Button type="submit" className="btn-primary border-radius-md w-100" sx={{ textTransform: 'capitalize', marginBottom: '0px !important', padding: '15px 10px !important' }}>
                                        Go back to main page
                                    </Button>
                                </Link>
                            }
                        </Box>
                    </Box>
                </>}
            />}
        </>
    )
}