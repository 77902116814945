import { Box, Chip, Grid, Typography } from "@mui/material";

const PayoutMethod = ({ method }) => {
  if (method?.length != 0)
    return (
      <Grid
        sx={{
          mt: 2,
          py: 2,
          borderTop: "1px solid rgb(221, 221, 221)!important",
        }}
        container
      >
        <Grid xs={10} item>
          {method?.paid_type == "FASTPAY" ? (
            <Box>
              <Typography component="h3">Fast Pay</Typography>
              <Typography>
                <strong>CardHolder:</strong>{" "}
                {method?.info?.cardholder_details?.first_name}{" "}
                {method?.info?.cardholder_details?.last_name}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography component="h3">Bank Account</Typography>
              <Typography>
                <strong>Holder name:</strong> {method?.info?.holder_name}
              </Typography>
              <Typography>
                <strong>Routing number:</strong> {method?.info?.routing_number}
              </Typography>
              <Typography>
                <strong>Account number:</strong> {method?.info?.account_number}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid xs={2} item>
          <Chip label="Current method" />
        </Grid>
      </Grid>
    );

  return <></>;
};

export default PayoutMethod;
