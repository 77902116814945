import {
  Card,
  CardContent,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const HelpCard = () => {
  const listItems = [
    { label: "How do payment plans work?", link: "#" },
    { label: "How do I pay for my long-term reservation?", link: "#" },
    { label: "Where can I find my payment?", link: "#" },
  ];
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="info-right-card">
        <CardContent sx={{ pt: 2 }}>
          <Typography sx={{ fontWeight: "600", my: 2 }} component="h4">
            Need help?
          </Typography>
          <List sx={{ width: "100%" }}>
            {listItems.map((item) => (
              <ListItem sx={{ p: 0 }}>
                <ListItemButton sx={{ p: 0 }} disableGutters>
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": {
                        fontWeight: "500 !important",
                      },
                      fontSize: 16,
                      textDecoration: "underline",
                    }}
                    primary={item.label}
                  />
                  <ListItemIcon
                    sx={{
                      justifyContent: "right",
                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Hidden>
  );
};
export default HelpCard;
