import { Typography,Box } from "@mui/material";

const TitleDescription = (props) => {
  const { title, description } = props;
  return (
    <>
    <Box className="become-host-step-form">
      <Box className="small-heading">
      <Typography sx={{ fontWeight: "500" }} component="h2" className="main-heading">
        {title}
      </Typography>
      </Box>
    </Box>
      <Typography>{description}</Typography>
    </>
  );
};

export default TitleDescription;
