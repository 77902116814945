import { Typography,Box} from "@mui/material";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const AccountSettingsTitle = (props) => {
  const { title, routeLabel } = props;
  return (
    <>
      {routeLabel && (
        <BreadCrumb data={[["Account", "/account-settings"], [`${routeLabel}`]]} />
      )}
    <Box className="main-heading-block" sx={{marginBottom:"0"}}>      
      <Typography component="h2" className="main-heading">
            {title}
      </Typography>
      </Box>

    </>
  );
};

export default AccountSettingsTitle;
