import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { CountryService } from "../../Services/CountryService";
import { PrivacyAndSharingSevice } from "../../Services/PrivacyAndSharingSevice";
import SelectInput from "../Inputs/SelectInput";
import * as yup from "yup";
import { useFormik } from "formik";
import { PrivacyAndSharingContext } from "../../Context/PrivacyAndSharingContext";
import Otp from "./Otp/Otp";
import MakeToast from "../../Helpers/MakeToast";
import { useSelector } from "react-redux";

const reasonsOptions = [
  "I want to know what Beachify knows about me",
  "I want to file a support ticket",
  "I want to move my data to another service",
  "I plan to delete or deactivate my account soon",
  "I need to access specific data for legal reasons",
  "Other",
];

const DataForm = () => {
  const { user } = useSelector((state) => state.userReducers.data);
  const [openOtp, setOtpOpen] = useState(false);
  const [countries, setCountries] = useState(false);
  const formSubmit = () => {
    PrivacyAndSharingSevice.dataRequestGenerateOtp({
      ...values,
      otp_type: user.email ? "email" : "mobile_no",
    })
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  let dataValidationSchema = yup.object().shape({
    country_id: yup
      .number()
      .required("Country is required")
      .typeError("Country data is invalid"),
    reason: yup.string().nullable().typeError("Reason must be a text"),
  });
  const formik = useFormik({
    validationSchema: dataValidationSchema,
    initialValues: { country_id: "", reason: "" },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <PrivacyAndSharingContext.Provider value={values}>
      <Box sx={{ marginTop: 4 }}>
        {openOtp && (
          <Otp
            userData={user.email ?? user.mobile_no}
            openOtp={openOtp}
            setOtpOpen={setOtpOpen}
            handleClose={() => setOtpOpen(false)}
          />
        )}
        <Typography sx={{ fontWeight: "500", marginBottom: 1 }} component="h4">
          Where do you reside?
        </Typography>
        <FormControl
          variant="standard"
          className="input-style outlined-input select-style textfield-rouded-md"
          sx={{
            width: "100%",
            borderRadius: "18px",
            mb: 1,
            pl: 4,
            mr: 4,
          }}
        >
          {countries?.length > 0 ? (
            <SelectInput
              customArr={{ data: countries, name: "name", id: "id" }}
              name="country_id"
              value={values.country_id}
              onChange={handleChange}
              onBlur={handleBlur}
              renderValue={(selected) => {
                if (
                  (selected == undefined || selected == "") &&
                  (values.country_id == undefined || values.country_id == "")
                ) {
                  return <em>Country/Region</em>;
                }

                let countryData = countries.find(
                  (o) => o.id == values.country_id
                );
                return countryData ? countryData.name : "";
              }}
            />
          ) : (
            <SelectInput
              className="opacity-40 pe-none"
              renderValue={(selected) => {
                return <em>Country/Region</em>;
              }}
            />
          )}
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.country_id && touched.country_id && errors.country_id}
        </FormHelperText>

        <Typography
          sx={{ fontWeight: "500", marginBottom: 1, marginTop: 2 }}
          component="h4"
        >
          Why are you requesting a copy of your data?
        </Typography>
        <FormControl
          variant="standard"
          className="input-style outlined-input select-style textfield-rouded-md"
          sx={{
            width: "100%",
            borderRadius: "18px",
            mb: 1,
            pl: 4,
            mr: 4,
          }}
        >
          <SelectInput
            customArr1={reasonsOptions}
            name="reason"
            value={values.reason}
            onChange={handleChange}
            onBlur={handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (values.reason == undefined || values.reason == "")
              ) {
                return <em>Select reason (Optional)</em>;
              }

              let reasonData = reasonsOptions.find(
                (reason) => reason == values.reason
              );
              return reasonData ? reasonData : "";
            }}
          />
        </FormControl>
        <FormHelperText className="field-helper-text error">
          {errors.reason && touched.reason && errors.reason}
        </FormHelperText>
        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          disabled={formik.isSubmitting}
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Request data"
          )}
        </Button>
      </Box>
    </PrivacyAndSharingContext.Provider>
  );
};

export default DataForm;
