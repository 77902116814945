import { Container, Typography,Box } from "@mui/material";
import DeleteForm from "../../Components/PrivacyAndSharing/DeleteForm";
import DeleteList from "../../Components/PrivacyAndSharing/DeleteList";
// TODO: Get the auth user mail
const userMail = "usermail@gmail.com";
const DeleteAccount = () => {
  return (
    <Container maxWidth="sm" sx={{ marginTop: 3 }}>
      <Box className="main-heading-block" sx={{mb:0}}>
      <Typography sx={{marginBottom: 1 }} component="h1" className="main-heading">
        Delete your account
      </Typography>
      </Box>
      <Typography component="p" className="inner-points">
        Submit a request to delete your personal data. To confirm you're the
        true owner of this account, we may contact you at {userMail}. We will
        only be able to proceed with your request once you follow the steps set
        out in the email.
      </Typography>
        <Box className="become-host-step-form" sx={{paddingBottom:'0 !important'}}>
          <Box className="small-heading">
          <Typography  component="h2" className="main-heading" >
                About account deletion requests:
              </Typography>
        </Box>
        </Box>
      <DeleteList />
      <DeleteForm />
    </Container>
  );
};

export default DeleteAccount;
