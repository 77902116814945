import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Container,
    Link,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    FormControl,
    TextField,
    InputAdornment,
    Input,
    MenuList,
    Skeleton
} from '@mui/material';
import debounce from 'lodash.debounce';
import images from "../../assets/js/images";
import AppHelper from '../../Helpers/AppHelper';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BeachGear from "../../Components/Home/BeachGear";
import { BeachGearSkeleton } from "../../Components/Skeleton/BeachGearSkeleton";
import CartDrawer from "../../Components/Common/CartDrawer";
import RangePicker from "../../Components/Inputs/RangePicker";
import ServiceSidebar from "../../Components/Service/ServiceSidebar";
import ServiceContent from "../../Components/Service/ServiceContent";
import Support from '../../Components/Support/Support';

import { BeachGearService } from "../../Services/BeachGearService";
import { CartService } from "../../Services/CartService";
import { BookingService } from "../../Services/BookingService";
import { useDispatch, useSelector } from "react-redux";

import ServiceOrder from "../Service/ServiceOrder";
import FavoriteButton from "../../Components/BeachGear/FavoriteButton";
import HostProfileCard from "../../Components/Profile/Host/HostProfileCard";

const HostProfile = () => {
    const params = useParams();
    const navigate = useHistory();
    const languages = AppHelper.lists().languages;
    const currency = AppHelper.currency();

    const [hostLoader, setHostLoader] = useState(false);
    const [beachGearLoader, setBeachGearLoader] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const [hostData, setHostData] = React.useState({});
    const getHostData = () => {
        setHostLoader(true);
        BeachGearService.hostBySlug(params.slug).then(res => {
            setHostData(res.data.data);
            setHostLoader(false);
        }).catch(error => {
            if (error.response.status === 404) {
                navigate.push('/');
            }
        });
    };

    const [beachgearlist, setBeachGearList] = React.useState([]);
    const getBeachGearList = (type = 'all', search = '') => {
        setBeachGearLoader(true);
        BeachGearService.beachGearsByHost(params.slug, type, search).then(res => {
            setBeachGearList(res.data.data);
            setBeachGearLoader(false);
        }).catch(error => {
        });
    };

    const [cartlist, setCartList] = React.useState([]);
    const getCartList = () => {
        AppHelper.cartLists(setCartList)
    };

    const [activeServiceCategories, setActiveServiceCategories] = React.useState(['all', 'All Beach Gear']);
    const getActiveServiceCategories = (data) => {
        setActiveServiceCategories(data);
        document.getElementById('searchservice').value = '';
    };

    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);
    useEffect(() => {
        getHostData();
        getBeachGearList();
        if (isLoggedIn) {
            getCartList();
        }
    }, []);

    const debounceApi = debounce((category, value) => {
        getBeachGearList(category, value);
    });


    const dispatch = useDispatch();
    const [openOrder, setOpenOrder] = React.useState(false);
    const [selectedGear, setSelectedGear] = useState({});
    const [priceData1, setPriceData1] = useState([]);
    const [selectedPriceData, setSelectedPriceData] = useState('');
    const [selectedGearCart, setSelectedGearCart] = useState({});
    const [dayOption, setDayOption] = useState(0);

    const openCartPopup = (data) => {
        if (isLoggedIn && user.role_id == "3") {
            if (isLoggedIn) {
                setOpenOrder(true)
                setSelectedGear(data)

                var pricedatas1 = AppHelper.sortedPrice(data.price, 1);
                if (pricedatas1[0]) setSelectedPriceData(pricedatas1[0].id);
                setPriceData1(pricedatas1);

                if (cartlist.items?.length) {
                    var cartitems = cartlist.items;
                    var item = cartitems.find(o => o.id == data.id);
                    if (item != undefined) {
                        setSelectedGearCart(item);
                        setQuantity(item.quantity);
                        setSelectedPriceData(item.price_id);
                    } else {
                        setSelectedGearCart({});
                        setQuantity(1);
                    }
                }
            } else if (!isLoggedIn) {
                dispatch({
                    type: "OPEN_AUTH_MODAL",
                });
            }
        }

    };

    function handleBack() {
        navigate.go(-1);
    }

    const updates = useSelector((state) => state.cart);
    React.useEffect(() => {
        if (isLoggedIn) {
            getCartList();
        }
    }, [updates]);

    const { start_date, end_date } = useSelector((state) => state.filterReducers.data);
    const calculationUpdate = useSelector((state) => state.calculationReducers);
    React.useEffect(() => {
        if (isLoggedIn) {
            (start_date!='' && start_date == end_date) ? setDayOption(1) : setDayOption(0)
            setPriceData1(priceData1);
        }
    }, [calculationUpdate]);

    const [supportOpen, setSupportOpen] = React.useState(false);
    const handleOpenSupport = () => {
        setSupportOpen(true);
    }

    return (<>
        <Support setOpen={setSupportOpen} open={supportOpen} pagetype="contacthost" hostslug={params.slug} />
        <section className="host-profile section-padding inner-section-padding">
            <Container>
                <BreadCrumb data={[[`${hostData.company_name}`]]} />
                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} style={{ marginTop : "15px" }}>
                        <Box className="top-side-heading">
                            <div className="left-back-arrow ">
                                <Link
                                    component="button"
                                    variant="body2"
                                    underline="none"
                                    className=" tertiary-text"
                                    color="inherit"
                                    onClick={handleBack}
                                >
                                    <img src={images.backIcon} alt="" />
                                    Back
                                </Link>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <HostProfileCard hostData={hostData} navigate={navigate} handleOpenSupport={handleOpenSupport} hostLoader={hostLoader} user={user} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className='heading-search'>
                            <Box className="inner-main-heading">
                                <Typography variant="h6" component="h2">Services</Typography>
                            </Box>
                            <Box className="inner-search-bar">
                                <FormControl className="input-style"
                                    sx={{
                                        width: '350px',
                                        '@media (max-width:769px)': {
                                            width: '310px',
                                        },
                                        '@media (max-width:583px)': {
                                            width: '280px',
                                        },
                                        '@media (max-width:575px)': {
                                            width: '100%',
                                        }
                                    }}>
                                    <TextField
                                        onChange={(e) => debounceApi(activeServiceCategories[0], e.target.value)}
                                        placeholder="Search"
                                        id="searchservice"
                                        className="outline-input input-text-style border-radius-60"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={images.searchIcon} alt="" />
                                                </InputAdornment>
                                            )
                                        }}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                },
                                                '&.Mui-focused': {
                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #eee',
                                                    }
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                }
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Grid container className="service-listing">
                            {
                                beachgearlist.length > 0
                                    ?
                                    <>
                                        <ServiceSidebar getServiceList={getBeachGearList} images={images} getActiveServiceCategories={getActiveServiceCategories} activeServiceCategories={activeServiceCategories} pageType="hostprofile" hostSlug={params.slug} />
                                        <ServiceContent serveList={beachgearlist} images={images} loader={beachGearLoader} pageType="hostprofile" openCartPopup={openCartPopup} />
                                    </>
                                    :
                                    <div style={{ margin: '35px auto' }}>
                                        <h4>No record found</h4>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {
                openOrder
                    ?
                    <>
                        <ServiceOrder
                            open={openOrder}
                            setOpen={setOpenOrder}
                            beachGear={selectedGear}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            priceData={priceData1}
                            selectedPriceData={selectedPriceData}
                            setSelectedPriceData={setSelectedPriceData}
                            cartItem={selectedGearCart}
                            dayOption={dayOption}
                            setDayOption={setDayOption}
                            pageType="hostprofile"
                        />
                    </>
                    :
                    <></>
            }
        </section>
    </>)
}

export default HostProfile;
