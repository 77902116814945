import jwt_decode from "jwt-decode";
const sessionKey = 'token';
const sessionUserKey = 'user';

class AuthHelper {
    isAuthenticated() {
        const token = this.getToken();
        // If there is no token - return false
        if (!token) {
            return false;
        }
        return true;
    }

    getToken() {
        return localStorage.getItem(sessionKey) || null;
    }

    getUserData() {
        return this.getToken() ? jwt_decode(this.getToken()) : [];
    }

    setToken(userToken) {
        localStorage.setItem(sessionKey, userToken);
    }

    removeAuthData() {
        this.removeToken();
    }

    removeToken() {
        localStorage.removeItem(sessionKey);
    }
}

export default new AuthHelper();
