import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Link,
  Rating,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { ReviewsService } from "../../../Services/ReviewsService";
import MakeToast from "../../../Helpers/MakeToast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContext } from "react";
import { ReviewsContext } from "../../../Context/ReviewsContext";
import SelectInput from "../../Inputs/SelectInput";

const CreateForm = ({ review, onClose }) => {
  const { getReviews, getBookings, bookings } = useContext(ReviewsContext);

  const handleSubmit = (values) => {
    ReviewsService.store(values)
      .then((response) => {
        getBookings();
        formik.setSubmitting(false);
        onClose();
        getReviews();
        MakeToast("success", response);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  const ValidationSchema = yup.object().shape({
    booking_id: yup
      .string()
      .required("Booking is required")
      .typeError("Booking must be a valid value"),
    review: yup
      .string()
      .required("Review is required")
      .typeError("Review must be a valid value"),
    score: yup
      .number()
      .integer()
      .min(1)
      .required("Score is required")
      .typeError("Score be a valid value"),
  });
  const InitialValues = {
    booking_id: "",
    score: "",
    review: "",
  };
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Container
        sx={{
          maxWidth: "100% !important",
          mt: 3,
          mb: 10,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "500" }}>
          Booking to Review{" "}
        </Typography>
        <FormControl
          variant="standard"
          className="input-style outlined-input select-style textfield-rouded-md"
          sx={{
            width: "100%",
            borderRadius: "18px",
            mb: 1,
            pl: 4,
          }}
        >
          <SelectInput
            customArr={{ data: bookings, name: "label", id: "id" }}
            name="booking_id"
            value={formik.values.booking_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (
                (selected == undefined || selected == "") &&
                (formik.values.booking_id == undefined ||
                  formik.values.booking_id == "")
              ) {
                return <em>Booking</em>;
              }

              let bookingData = bookings.find(
                (o) => o.id == formik.values.booking_id
              );
              return bookingData ? bookingData.label : "";
            }}
          />
        </FormControl>
        {formik.errors.booking_id && formik.touched.booking_id && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.booking_id}
          </FormHelperText>
        )}
        <Typography sx={{ fontSize: 20, fontWeight: "500" }}>
          How was your experience with this host?
        </Typography>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Rating
            sx={{ fontSize: 50, mt: 1 }}
            defaultValue={formik.values.score}
            onChange={(event, newValue) => {
              formik.setFieldValue("score", newValue);
            }}
          />
        </Box>
        {formik.errors.score && formik.touched.score && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.score}
          </FormHelperText>
        )}
        <Typography
          sx={{ fontWeight: "500", mt: "16px !important" }}
          component="h4"
        >
          Review
        </Typography>
        <TextareaAutosize
          id="review"
          value={formik.values.review}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="How was your experience with this host?"
          style={{
            width: "100%",
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: "18px",
            padding: "18px",
            height: "110px",
            resize: "none",
          }}
        />
        {formik.errors.review && formik.touched.review && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.review}
          </FormHelperText>
        )}
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          px: 3,
        }}
      >
        <Link
          sx={{ mt: "0px !important", fontSize: 17, fontWeight: "600" }}
          className="link-update"
          onClick={() => onClose()}
        >
          Cancel
        </Link>
        <Button
          type="button"
          sx={{
            border: "1px solid black !important",
            backgroundColor: "black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          className="btn-primary submit-btn"
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </>
  );
};

export default CreateForm;
