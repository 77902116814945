import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Link,
  Grid,
} from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import FavoriteButton from "../BeachGear/FavoriteButton";
import images from "../../assets/js/images";
const HostContent = (props) => {
  const {
    id = 0,
    logo = images.smallogo,
    name = "No Provided",
    company_name = "No Provided",
    created_at = "No Provided",
    delivery = 0,
    instant_booking_available = 0,
    isFavorite = false,
  } = props;
  const { pathname } = useLocation();
  return (
    <>
      <Grid xs={12} sm={12} md={9} lg={10} style={{'marginBottom' : '15px'}}>
        <Box className="favorite-total-box">
          <Box className="favorite-bg">
            <Box className="favorite-img">
              <img src={props.profile_image==null ? logo : props.profile_image} alt="" />
            </Box>

            <Box className="favorite-heading">
              <Typography component="h2" className="inner-heading">
                <RouterLink to={"/profile/"+props.user_id} style={{color:'black'}}>{company_name}</RouterLink>
              </Typography>
              <Box className="sub-list">
                <List
                  sx={{
                    p: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                  className="feature-listing listing-visible"
                >
                  <Box className="favorite-space">
                  {name}
                  </Box>
                  <ListItem>
                    <ListItemIcon className="feature-listing-icon">
                      <img src={images.timeIcon} alt="time" />
                    </ListItemIcon>
                    <ListItemText
                      primary={created_at}
                      className="feature-list-name"
                    />
                  </ListItem>
                  {delivery == 1 && (
                    <ListItem>
                      <ListItemIcon className="feature-listing-icon">
                        <img src={images.deliveryIcon} alt="delivery" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delivery & Pickup"
                        className="feature-list-name"
                      />
                    </ListItem>
                  )}
                  {instant_booking_available == 1 && (
                    <ListItem>
                      <ListItemIcon className="feature-listing-icon">
                        <img src={images.roundedbookingIcon} alt="booking" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Instant booking"
                        className="feature-list-name"
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Box>
          </Box>
          <Box>
            {!pathname.includes("service-list") && (
              <FavoriteButton
                elementData={props}
                isFavorite={isFavorite}
                isHost
              />
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default HostContent;
