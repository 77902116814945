import CustomTabs from "../Common/CustomTabs/CustomTabs";
import DataTab from "./Tabs/DataTab";
import SharingTab from "./Tabs/SharingTab";

const SettingsTabs = () => {
  const tabsContent = [
    {
      label: "Data",
      content: <DataTab />,
    },
    { label: "Sharing", content: <SharingTab /> },
  ];
  return (
    <CustomTabs
      tabs={tabsContent}
      tabName="privacysharing-tab"
      panelName="privacysharing-tabpanel"
    />
  );
};
export default SettingsTabs;
