import React from "react";

import $ from "jquery";
import moment from "moment";
import "../../Helpers/moment-custom-locale";
import "../../Helpers/daterangepickers";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const RangePicker = ({ selector, startdate, enddate, setRangePicker, mindate, setMinSelection }) => {
    let parentSelector = selector.replace('#', '');
    parentSelector = parentSelector.replace('.', '');

    React.useEffect(() => {
        $(function () {
            var options = {
                parentSelector: parentSelector,
                autoUpdateInput: true,
                autoApply: true,
                showCustomRangeLabel: false,
                opens: "left",
                ranges: {
                    "+1 day": [moment(), moment().add(1, "days")],
                    "+2 days": [moment(), moment().add(2, "days")],
                    "+3 days": [moment(), moment().add(3, "days")],
                    "+4 days": [moment(), moment().add(4, "days")],
                    "+5 days": [moment(), moment().add(5, "days")],
                },
                alwaysShowCalendars: true
            };
            if (mindate != undefined) options.minDate = mindate;

            if (startdate != '' && startdate != null) Object.assign(options, { startDate: moment(new Date(startdate.replace(/-/g, "/"))) })
            if (enddate != '' && enddate != null) Object.assign(options, { endDate: moment(new Date(enddate.replace(/-/g, "/"))) })

            $(selector).daterangepicker(options, function (start, end, label) {
                setRangePicker(start.format("MM-DD-YYYY"), end.format("MM-DD-YYYY"))
            });
        });
    }, [startdate, enddate])
}

//export default React.memo(RangePicker);
export default RangePicker;
