import { APIService } from "./APIService";

export const BeachGearHostService = {
    lists,
    create,
    update,
    show,
    del,
    changeStatus,
    changePublish,
    isFeaturedService,
    serviceDetail,
    suggestedService
};

function lists(data) {
    return APIService.post('api/beachgearhost', data);
}

function create(data) {
    return APIService.post('api/beachgearhost', data);
}

function update(data, id) {
    return APIService.put('api/beachgearhost/'+id, data);
}

function show(id) {
    return APIService.get('api/beachgearhost/'+id);
}

function del(id) {
    return APIService.del('api/beachgearhost/'+id);
}

function changeStatus(data, id) {
    return APIService.post('api/change-status/'+id, data);
}

function changePublish(data, id) {
    return APIService.post('api/change-publish/'+id, data);
}

function isFeaturedService(data, id) {
    return APIService.post('api/make-featured/'+id, data);
}

function serviceDetail(id) {
    return APIService.get('api/beachgearhost/'+id+'/detail');
}

function suggestedService(id) {
    return APIService.get('api/beachgearhost/'+id+'/suggested-service');
}