import React, { useState } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Visibility } from '@mui/icons-material';

import images from "../../assets/js/images";
import FavoriteButton from "../BeachGear/FavoriteButton";
import AppHelper from "../../Helpers/AppHelper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BeachGear = ({beachgear, enableAction, pagetype, openCartPopup}) => {
  const currency = AppHelper.currency();
  const bgId = beachgear?.id;
  const bgSlug = beachgear?.slug;
  const bgTitle = beachgear?.title;
  const bgBeachGearName = beachgear?.beachGearName;
  const bgQty = beachgear?.quantity;
  const bgIsFavorite = beachgear?.isFavorite
  const bgImagesData = beachgear?.imagesData;
  const bgImagesDataLength = beachgear?.imagesData?.length;
  const bgIsPublished = beachgear?.isPublished;
  const bgListingPrice = beachgear?.listing_price;
  
  const theme = useTheme();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.userReducers.data);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const updateNavigation = (e, slug) => {
    var classlist = e.target.classList == "" ? [] : e.target.classList;
    
    if (classlist.contains("fav-image") || classlist.contains("featuring-badge")) {
      return false;
    }

    if(pagetype=='nonavigation'){
      openCartPopup();
      return false;
    } 

    if (
      classlist.contains("prev-btn") ||
      classlist.contains("next-btn") ||
      classlist.contains("edit-bg")
    ) {
      return false;
    }

    navigate.push("/service-detail/" + slug + (pagetype=='searchresult' ? '?searchresult=1' : ''));
  };

  const namesection = () => {
    return (
      <CardActionArea disableRipple>
        <CardContent sx={{ p: 0 }}>
          <Typography
            variant="h5"
            component="div"
            className={(pagetype=='serviceList' ? "serviceListTitle " : "text-truncate ")+"card-title service-list-review"}
            style={{marginTop:(pagetype=='searchresult' ? '10px' : '20px')}}
          >
            {bgTitle!='' ? bgTitle : bgBeachGearName}
          </Typography>
        </CardContent>
      </CardActionArea>
    )
  }
  const pricesection = () => {
    return (
      <CardActionArea disableRipple>
        <CardContent sx={{ p: 0 }}>
          <Typography
            variant="h5"
            component="div"
            className="text-truncate card-title service-list-review"
          >
            From {currency+bgListingPrice}
          </Typography>
        </CardContent>
      </CardActionArea>
    )
  }
  return (
    <>
      <RouterLink onClick={(e) => updateNavigation(e, bgSlug)}>
        <Card
          className="border-0 shadow-none vertical-card card-info no-hover"
          id={"beachlist" + bgSlug}
        >
          <div className="card-media-item bg-white border-radius-12"
         >
            { pagetype=='searchresult' || pagetype=='popularBeach' ? namesection() : '' }
            <div className="card-media-block">
              <Box sx={{ flexGrow: 1 }} className="card-slider">
                <AutoPlaySwipeableViews
                  interval={5000}
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {bgImagesData?.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          sx={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={step.imgPath}
                          alt={step.label}
                          className="border-radius-12 card-image"
                        />
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  steps={bgImagesDataLength}
                  position="static"
                  activeStep={activeStep}
                  className="stepper-dots"
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === bgImagesDataLength - 1}
                      className="prev-btn"
                    >
                      {theme.direction === "rtl" ? (
                        <img src={images.rightChevronIcon} alt="arrow" />
                      ) : (
                        <img src={images.rightChevronIcon} alt="arrow" />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                      className="next-btn"
                    >
                      {theme.direction === "rtl" ? (
                        <img src={images.leftChevronIcon} alt="arrow" />
                      ) : (
                        <img src={images.leftChevronIcon} alt="arrow" />
                      )}
                    </Button>
                  }
                />
              </Box>
              <div className="featuring-edit-block">
                {enableAction && bgId ? (
                  <RouterLink to={"/service/edit/" + bgId}>
                    <Badge className="featuring-badge">
                      <img src={images.editIcon} className="edit-bg" alt="" />
                    </Badge>
                  </RouterLink>
                ) : (
                  <></>
                )}
              </div>
              <div className="featuring-action-block sds">
                {enableAction && bgId ? (
                  <>
                    { bgIsPublished == 0 ? 
                      <Badge
                        style={{
                          background: "rgba(9, 6, 15, 0.5)",
                          borderRadius: "6px",
                          padding: "5px",
                          color: "white",
                          fontWeight: "600",
                          marginLeft: "5px",
                          minWidth: "35px",
                          textAlign: "center",
                          display: "inline-flex",
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '14px',
                          marginLeft: '8px',
                          height: '28px',
                        }}
                      >
                        <Visibility />
                    </Badge>
                    : '' }
                    {bgQty > 0 ? (
                      <Badge
                        style={{
                          background: "#FF4110",
                          borderRadius: "6px",
                          padding: "4px",
                          color: "white",
                          fontWeight: "600",
                          marginLeft: "5px",
                          minWidth: "35px",
                          textAlign: "center",
                          display: "inline-block",
                        }}
                      >
                        ({bgQty})
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {!pathname.includes("services") && user.role_id == "3" ? (
                  <FavoriteButton
                    elementData={beachgear}
                    isFavorite={bgIsFavorite}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            { pagetype=='searchresult' || pagetype=='popularBeach' ? pricesection() : namesection() }
          </div>
        </Card>
      </RouterLink>
    </>
  );
};

export default BeachGear;
