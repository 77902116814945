import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Support from "../Components/Support/Support";

// React routes
import Home from "../Pages/Home/Home";
import Faq from "../Pages/Faq/Faq";
import BecomeHost from "../Pages/Host/BecomeaHostForm";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ServiceForm from "../Pages/Service/ServiceForm";
import PersonalInformation from "../Pages/EditProfile/PersonalInformation";
import PaymentInfo from "../Pages/PaymentInfo/PaymentInfo";
import BeachDetail from "../Pages/BeachDetail/BeachDetail";
import ServiceList from "../Pages/Service/ServiceList";
import ServiceDetail from "../Pages/Service/ServiceDetail";
import Favourites from "../Pages/Favourites/Favourites";
import Favorites from "../Pages/Favorites/Favorites";
import AccountSettings from "../Pages/AccountSettings/AccountSettings";
import Notifications from "../Pages/Notifications/Notifications";
import BookingPreferences from "../Pages/BookingPreferences/BookingPreferences";

import Help from "../Pages/Help/Help";
import SearchResult from "../Pages/SearchResult/SearchResult";
import BookingList from "../Pages/Booking/BookingList";
import BookingDetail from "../Pages/Booking/BookingDetail";
import HostProfile from "../Pages/Host/HostProfile";
import FullCalendarPage from "../Pages/FullCalendar/FullCalendar";
import Cart from "../Pages/Cart/Cart";
import Notification from "../Pages/Notification/Notification";
import { useSelector } from "react-redux";
import UsersReviews from "../Pages/UsersReviews/UsersReviews";
import Referral from "../Pages/Referral/Referral";
import PrivacyAndSharing from "../Pages/PrivacyAndSharing/PrivacyAndSharing";
import PrivacyAndSharingData from "../Pages/PrivacyAndSharing/Data";
import DeleteAccount from "../Pages/PrivacyAndSharing/DeleteAccount";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import GlobalPreferences from "../Pages/GlobalPreferences/GlobalPreferences";
import LoginAndSecurity from "../Pages/LoginAndSecurity/LoginAndSecurity";
import BusinessInfo from "../Pages/BusinessInfo/BusinessInfo";
import Profile from "../Pages/Profile/Profile";
import RenterProfile from "../Pages/Profile/Renter/UsersShow";
import HostReviews from "../Pages/HostReviews/HostReviews";
import Taxes from "../Pages/Taxes/Taxes";
import TaxInfo from "../Pages/Taxes/TaxInfo";
import PaymentsAndPayouts from "../Pages/PaymentsAndPayouts/PaymentsAndPayouts";
import YourPayments from "../Pages/YourPayments/YourPayments";
import AddPayoutMethod from "../Pages/PaymentsAndPayouts/AddPayoutMethod";
import HostPublicReviews from "../Pages/HostPublicProfile/Reviews";
const routes = [
  {
    path: "/",
    element: <Home />,
    permission: [],
  },
  {
    path: "/verified",
    element: <Home />,
    permission: [],
  },
  {
    path: "/faq",
    element: <Faq />,
    permission: [],
  },
  {
    path: "/profile/:id?",
    element: <Profile />,
    permission: [],
  },
  {
    path: "/customer-profile/:id?",
    element: <RenterProfile />,
    permission: [],
  },
  {
    path: "/users/reviews",
    element: <UsersReviews />,
    permission: [],
  },
  {
    path: "/host/reviews",
    element: <HostReviews />,
    permission: [2],
  },
  {
    path: "/host/reviews/:id",
    element: <HostPublicReviews />,
    permission: [2, 3],
  },
  {
    path: "/account-settings",
    element: <AccountSettings />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/personal-info",
    element: <PersonalInformation />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/booking-preferences",
    element: <BookingPreferences />,
    permission: [2],
  },
  {
    path: "/account-settings/login-and-security",
    element: <LoginAndSecurity />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/payment-info",
    element: <PaymentInfo />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/payments-payouts",
    element: <PaymentsAndPayouts />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/payments-payouts/create",
    element: <AddPayoutMethod />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/your-payments",
    element: <YourPayments />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/preferences",
    element: <GlobalPreferences />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/notifications",
    element: <Notifications />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/referrals",
    element: <Referral />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/privacy-and-sharing",
    element: <PrivacyAndSharing />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/privacy-and-sharing/data",
    element: <PrivacyAndSharingData />,
    permission: [3],
  },
  {
    path: "/account-settings/manage-your-data/deletion",
    element: <DeleteAccount />,
    permission: [2, 3],
  },
  {
    path: "/account-settings/business-info",
    element: <BusinessInfo />,
    permission: [2],
  },
  {
    path: "/account-settings/taxes",
    element: <Taxes />,
    permission: [2],
  },
  {
    path: "/account-settings/taxes/create/us",
    element: <TaxInfo />,
    permission: [2],
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    permission: [],
  },
  {
    path: "/service/add",
    element: <ServiceForm />,
    permission: [2],
  },
  {
    path: "/service/edit/:id",
    element: <ServiceForm />,
    permission: [2],
  },
  {
    path: "/beach-detail",
    element: <BeachDetail />,
    permission: [],
  },
  {
    path: "/support",
    element: <Support />,
    permission: [],
  },
  {
    path: "/becomehost",
    element: <Home />,
    permission: [],
  },
  {
    path: "/login",
    element: <Home />,
    permission: [],
  },
  {
    path: "/signup",
    element: <Home />,
    permission: [],
  },
  {
    path: "/services",
    element: <ServiceList />,
    permission: [2],
  },
  {
    path: "/favorites",
    element: <Favorites />,
    permission: [3],
  },
  {
    path: "/service-detail/:slug",
    element: <ServiceDetail />,
    permission: [],
  },
  {
    path: "/help",
    element: <Help />,
    permission: [],
  },
  {
    path: "/search-result",
    element: <SearchResult />,
    permission: [],
  },
  {
    path: "/bookings",
    element: <BookingList />,
    permission: [2, 3],
  },
  {
    path: "/booking-detail/:id",
    element: <BookingDetail />,
    permission: [2, 3],
  },
  {
    path: "/host/:slug",
    element: <HostProfile />,
    permission: [],
  },
  {
    path: "/full-calendar",
    element: <FullCalendarPage />,
    permission: [],
  },
  {
    path: "/cart",
    element: <Cart />,
    permission: [],
  },

  {
    path: "/notification",
    element: <Notification />,
    permission: [],
  },
];

const AppRouter = () => {
  const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

  return (
    <Router>
      <Switch>
        {routes.map((data) => (
          <Route key={data.path} exact path={data.path}>
            {data?.permission?.length == 0 ||
              (isLoggedIn &&
                data?.permission?.length > 0 &&
                data.permission.includes(user.role_id)) ? (
              <>
                <Header />
                {data.element}
                <Footer />
              </>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
        ))}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default AppRouter;
