import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export default function ScreenLoader({ open }) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 9999999 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
