const Reminders = (data) => {
  const titleData = {
    title: "Reminders",
    description:
      "Get important reminders about your reservations, listings, and account activity.",
  };

  const optionsData = [
    {
      label: "Reminders",
      description:
        "Never miss important reminders about your reservations and account activity.",
      field: "reminders",
      options: data["reminders"],
    },
  ];

  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};

export default Reminders;
