import { Box, Card, CardContent,Icon, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BaseModal from "../Modal/BaseModal";
import images from "../../assets/js/images";
import { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';

const MenuCard = ({
  title,
  description,
  icon = "add_circle",
  link = "#", //Set as # to show the coming soon modal
  linkProps = {},
  comingSoonTitle,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      {link == "#" && (
        <BaseModal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={{ textAlign: "center" }}>
            <img src={images.logo} />
            <Typography sx={{ pt: 1 }} component="h3">
              {comingSoonTitle ?? title + " coming soon!"}
            </Typography>
          </Box>
        </BaseModal>
      )}
      <Grid
        sx={{ margin: "8px 0px !important;", paddingTop: "0px !important" }}
        xs={12}
        sm={6}
        md={4}
        item
      >
        <RouterLink
          {...linkProps}
          onClick={() => link == "#" && setOpenModal(true)}
          to={link}
        >
          <Card
            className="account-card"
            sx={{
              height: "100% !important",
            }}
          >
            <CardContent>
              <Icon sx={{ fontSize: "32px !important", marginBottom: 2 }}>
                {icon}
              </Icon>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                {title}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "rgb(113, 113, 113) !important;",
                  marginTop: 1,
                  fontWeight: "light",
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </Card>
        </RouterLink>
      </Grid>
    </>
  );
};

export default MenuCard;
