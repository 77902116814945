import { APIService } from "./APIService";

export const FavoriteService = {
  allBeachGears,
  categories,
  categoryListings,
  hosts,
  addBeachGear,
  removeBeachGear,
  addHost,
  removeHost,
};

function allBeachGears(data) {
  return APIService.get("api/favorites/beach_gears/all", data);
}

function categories() {
  return APIService.get("api/categories");
}

function categoryListings(id, data) {
  return APIService.get("api/favorites/category/" + id, data);
}

function hosts(data) {
  return APIService.get("api/favorites/hosts", data);
}

function addBeachGear(id) {
  return APIService.post("api/favorites/add_beach_gear/" + id);
}

function removeBeachGear(id) {
  return APIService.post("api/favorites/remove_beach_gear/" + id);
}

function addHost(id) {
  return APIService.post("api/favorites/add_host/" + id);
}

function removeHost(id) {
  return APIService.post("api/favorites/remove_host/" + id);
}
