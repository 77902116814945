import { Box, Typography } from "@mui/material";
import CustomTabs from "../Common/CustomTabs/CustomTabs";
import Pending from "./Tabs/Pending";
import Available from "./Tabs/Available";
import BaseModal from "../Modal/BaseModal";
import { styled } from '@mui/material/styles';

const tabsContent = [
  {
    label: "Pending",
    content: <Pending />,
  },
  { label: "Available", content: <Available /> },
];

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
  },
}));

const DetailModal = (props) => {
  return (
    <BaseModal {...props}>
      <Typography variant="h6" component="h2">
        People you’ve invited
      </Typography>
      <Typography component="h4">
        The travel credit you earn can be used to book your next home or
        experience on Beachify.
      </Typography>
      <CustomTabs
        tabs={tabsContent}
        tabName="details-tab"
        panelName="details-tabpanel"
        sx={{ marginTop: 5 }}
      />
    </BaseModal>
  );
};

export default DetailModal;
