import { APIService } from "./APIService";

export const PaymentMethodService = {
  paymentMethods,
  updatePaymentMethod,
  hostPaymentMethods,
  hostCreatePaymentMethod,
  hostDefaultPaymentMethod,
  hostRemovePaymentMethod,
};

function paymentMethods() {
  return APIService.get("api/account_settings/payment_method");
}
function updatePaymentMethod(data) {
  return APIService.post("api/account_settings/payment_method", data);
}
function hostPaymentMethods() {
  return APIService.get("api/account_settings/host/payment_method");
}
function hostCreatePaymentMethod(data) {
  return APIService.post("api/account_settings/host/payment_method", data);
}
function hostDefaultPaymentMethod(id) {
  return APIService.post(
    `api/account_settings/host/payment_method/default/${id}`
  );
}
function hostRemovePaymentMethod(id) {
  return APIService.post(
    `api/account_settings/host/payment_method/remove/${id}`
  );
}
