import { useState } from "react";
import { useEffect } from "react";
import MakeToast from "../../Helpers/MakeToast";
import { NotificationSettingsService } from "../../Services/NotificationSettingsService";
import CustomTabs from "../Common/CustomTabs/CustomTabs";
import Account from "./Tabs/Account";
import OffersAndUpdates from "./Tabs/OffersAndUpdates";
import { NotificationSettingsContext } from "../../Context/NotificationSettingsContext";

const NotificationsTabs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const getNotificationsData = () => {
    NotificationSettingsService.getNotificationsSettings()
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    setLoading(true);
    getNotificationsData();
  }, []);
  const tabsContent = [
    {
      label: "Offers and updates",
      content: <OffersAndUpdates />,
    },
    { label: "Account", content: <Account /> },
  ];
  return (
    <NotificationSettingsContext.Provider
      value={{
        loading: loading,
        data: data,
        getNotificationsData: getNotificationsData,
      }}
    >
      <CustomTabs
        tabs={tabsContent}
        tabName="notification-tab"
        panelName="notification-tabpanel"
      />
    </NotificationSettingsContext.Provider>
  );
};
export default NotificationsTabs;
