import { Container } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import CompanyName from "../../Components/BusinessInfo/Form/CompanyName";
import InfoRightCard from "../../Components/BusinessInfo/InfoRightCard";
import PhoneNumber from "../../Components/BusinessInfo/Form/PhoneNumber";
import Email from "../../Components/BusinessInfo/Form/Email";
import StoreLocationAddress from "../../Components/BusinessInfo/Form/StoreLocationAddress";
import BillingAddress from "../../Components/BusinessInfo/Form/BillingAddress";
import { BusinessInfoContext } from "../../Context/BusinessInfoContext";
import { useEffect, useState } from "react";
import { BusinessInfoService } from "../../Services/BusinessInfoService";
import MakeToast from "../../Helpers/MakeToast";
import FormLoading from "../../Components/BusinessInfo/FormLoading";
import EmergencyContact from "../../Components/BusinessInfo/Form/EmergencyContact";
import { CountryService } from "../../Services/CountryService";
import { CommonService } from "../../Services/CommonService";
import Grid from '@mui/material/Unstable_Grid2';

const BusinessInfo = () => {
  const [businessInfo, setBusinessInfo] = useState({});
  const [countries, setCountries] = useState({});
  const [languages, setLanguages] = useState({});
  const [loading, setLoading] = useState(true);
  const getBusinessInfo = async () => {
    setLoading(true);
    await BusinessInfoService.getInfo()
      .then((response) => setBusinessInfo(response.data.data))
      .catch((error) => MakeToast(error));
    setLoading(false);
  };
  useEffect(() => {
    getBusinessInfo();
    getCountries();
    getLanguages();
  }, []);
  const getCountries = () => {
    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };
  const getLanguages = () => {
    CommonService.getAllLanguagesAsArray()
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <BusinessInfoContext.Provider
      value={{
        business: businessInfo,
        setBusinessInfo: setBusinessInfo,
        countries: countries,
        languages: languages,
      }}
    >
      <Container sx={{ py: 5, }}>
        <AccountSettingsTitle
          title="Business Info"
          routeLabel="Business Info"
        />
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} >
          <Grid xs={12} md={8} item>
            {loading ? (
              <FormLoading />
            ) : (
              <>
                <CompanyName />
                <PhoneNumber />
                <Email />
                <StoreLocationAddress />
                <BillingAddress />
                <EmergencyContact />
              </>
            )}
          </Grid>
          <Grid xs={4} item  className="mobile-none">
            <InfoRightCard />
          </Grid>
        </Grid>
      </Container>
    </BusinessInfoContext.Provider>
  );
};

export default BusinessInfo;
