import { useContext } from "react";
import { FormContext } from "../../../Context/PaymentsContext";
import { Box, Typography } from "@mui/material";
import CheckingSaving from "./CheckingSaving";
import Name from "./Name";
import RoutingNumber from "./RoutingNumber";
import AccountNumber from "./AccountNumber";

const Form = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 2 }}>
      <CheckingSaving />
      <Name />
      <RoutingNumber />
      <AccountNumber />
    </Box>
  );
};

export default Form;
