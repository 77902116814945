import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";
import MakeToast from "../../../Helpers/MakeToast";
import { PaymentMethodService } from "../../../Services/PaymentMethodService";
import { useContext } from "react";
import { AddPaymentContext } from "../../../Context/PaymentsContext";
import AppHelper from "../../../Helpers/AppHelper";

const Form = ({ closeModal, onEmitValue, isCheckout }) => {
  const cardTypesData = AppHelper.lists().cardTypes;
  const cardTypesIcons = [];
  Object.entries(cardTypesData).forEach(([key, value]) => {
    cardTypesIcons.push(
      <Grid item>
        <img src={value.icon} />
      </Grid>
    );
  });
  const { setMethods } = useContext(AddPaymentContext);
  const [loading, setLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const { user } = useSelector((state) => state.userReducers.data);
  const elements = useElements();
  const stripe = useStripe();
  const formSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardHolderName,
        email: user?.email ?? null,
        phone: user?.phone ?? null,
        address: {
          city : billingAddress,
        },
      },
    });

    if (result.error) {
      setLoading(false);
      MakeToast("error", result.error?.message);
      return;
    }
    if (isCheckout) {
      MakeToast("success", "Your card Info will be stored securely");
      onEmitValue(result.paymentMethod);
      setLoading(false);
      return;
    }
    PaymentMethodService.hostCreatePaymentMethod({
      last_four: result.paymentMethod.card.last4,
      card_holder: cardHolderName,
      billing_address: billingAddress,
      expiration_date:
        result.paymentMethod.card.exp_month +
        "/" +
        result.paymentMethod.card.exp_year,
      card_type: result.paymentMethod.card.brand,
      stripe_payment_method_id: result.paymentMethod.id,
    })
      .then((response) => {
        setLoading(false);
        setMethods(response.data.data);
        closeModal();
        MakeToast("success", response);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  return (
    <>
      <Grid sx={{ mb: 3 }} spacing={1} container>
        {cardTypesIcons}
      </Grid>
      <Grid sx={{ mb: 3 }} spacing={2} container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{ lineHeight: 1 }}
            variant="h6"
            component="h2"
            className="sub-text tertiary-text label-bottom-space"
          >
            Card holder
          </Typography>
          <Box className="small-service-heading ">
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="James Jackson"
                className="outline-input textfield-rouded-md input-text-style small-radius placeholder-gray"
                InputProps={{
                  sx: {
                    "input::placeholder": {
                      color: "#757575",
                    },
                  },
                }}
                sx={{
                  borderRadius: "18px !important",
                  "::placeholder": {
                    color: "red",
                    opacity: 1,
                  },
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
                onChange={(e) => setCardHolderName(e.target.value)}
                value={cardHolderName}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{ lineHeight: 1 }}
            variant="h6"
            component="h2"
            className="sub-text tertiary-text label-bottom-space"
          >
            Credit card number
          </Typography>
          <Box className="small-service-heading ">
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                type="text"
                className="outline-input textfield-rouded-md input-text-style small-radius"
                InputProps={{
                  inputComponent: CardNumberElement,
                }}
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h6"
            component="h2"
            className="sub-text tertiary-text label-bottom-space"
          >
            Expiration month and year
          </Typography>
          <Box className="small-service-heading ">
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="06/2028"
                InputProps={{ inputComponent: CardExpiryElement }}
                className="outline-input textfield-rouded-md input-text-style small-radius"
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h6"
            component="h2"
            className="sub-text tertiary-text label-bottom-space"
          >
            CVC
          </Typography>
          <Box className="small-service-heading ">
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                type="text"
                InputProps={{ inputComponent: CardCvcElement }}
                value={"***"}
                className="outline-input textfield-rouded-md input-text-style small-radius"
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            variant="h6"
            component="h2"
            className="sub-text tertiary-text label-bottom-space"
          >
            Billing Address
          </Typography>
          <Box className="small-service-heading ">
            <FormControl
              sx={{ width: "100%" }}
              className="input-style small-radius"
            >
              <TextField
                placeholder="Enter your billing address"
                className="outline-input textfield-rouded-md input-text-style small-radius"
                InputProps={{
                  sx: {
                    "input::placeholder": {
                      color: "#757575",
                    },
                  },
                }}
                sx={{
                  borderRadius: "18px !important",
                  ".MuiInputBase-input": {
                    paddingLeft: "30px",
                  },

                  ".MuiInputBase-root": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                    "&.Mui-focused": {
                      " .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #eee",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#eee",
                    },
                  },
                }}
                onChange={(e) => setBillingAddress(e.target.value)}
                value={billingAddress}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {stripe && (
        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          sx={{
            backgroundColor: "black !important",
            border: "solid 1px black !important",
          }}
          disabled={loading}
          onClick={formSubmit}
        >
          {loading ? <CircularProgress color="inherit" size={25} /> : "Save"}
        </Button>
      )}
    </>
  );
};
export default Form;
