import {Skeleton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

const LoadingReview = () => {
  return (
    <>
      <Skeleton width={200} height={40} />
      <Grid
        sx={{
          borderBottom: "solid 1px #EBEBEB !important",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}
        container
      >
        <Grid xs={12} md={1}>
          <Skeleton variant="circular" width={60} height={60} />
        </Grid>
        <Grid xs={12} md={11}>
          <Skeleton width={200} height={40} />
          <Skeleton width={300} height={20} />
        </Grid>
      </Grid>
    </>
  );
};

export default LoadingReview;
