import { Skeleton } from '@mui/material';
import React from 'react'

export const BeachSkeleton = ({count}) => {
    if(count === undefined){
        count = 3;
    }
    const skeletonElements = Array.from({ length: count }).map((_, index) => (
        <div key={index}>
            <Skeleton variant="rounded" animation="wave" sx={{ width:'100%', height:'320px' }} />
            <Skeleton animation="wave" sx={{ width:'100%', height:'80px' }} />
        </div>
    ));
  return (
    <>{skeletonElements}</>
  )
}
