import { Box, Card, CardContent, Hidden, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

const InfoRightCard = () => {
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="info-right-card">
        <CardContent sx={{ paddingTop: 3 }}>
          <TuneIcon
            className="text-danger"
            sx={{ fontSize: "50px !important", marginBottom: 2 }}
          />
          <Typography sx={{ fontWeight: "600", marginBottom: 2 }} variant="h3">
            Your global preferences
          </Typography>
          <Typography>
            Changing your currency updates how you see prices. You can change
            how you get payments in your payments & payouts preferences.
          </Typography>
        </CardContent>
      </Card>
    </Hidden>
  );
};
export default InfoRightCard;
