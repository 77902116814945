import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Link,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import SelectInput from "../Inputs/SelectInput";
import * as yup from "yup";
import { PreferencesService } from "../../Services/PreferencesService";
import MakeToast from "../../Helpers/MakeToast";
import { useDispatch } from "react-redux";
import { PreferencesContext } from "../../Context/PreferencesContext";
function selectedLabel(options, value) {
  const optionsArray = Object.entries(options);

  const option = optionsArray.filter(([key]) => key === value);
  return option.length != 0 ? option[0][1] : "No data";
}

const EditForm = (props) => {
  const dispatch = useDispatch();
  const { setSelectedLanguage } = useContext(PreferencesContext);
  const { title, description, options, field, selectedValue } = props;
  const [showEdit, setShowEdit] = useState(false);
  const submitChanges = (values) => {
    PreferencesService.update({
      field_name: field,
      value: values.preference,
    })
      .then((response) => {
        formik.setSubmitting(false);
        dispatch({
          type: "LOGIN_START",
          payload: response?.data,
        });
        if(field=='language') setSelectedLanguage(values.preference);
        MakeToast("success", response);
        setShowEdit(false);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  let dataValidationSchema = yup.object().shape({
    preference: props.multiple
      ? yup
          .array()
          .of(yup.string().typeError("Each option must be a text"))
          .min(1, "At least one option is required")
      : yup
          .string()
          .required("An option is required")
          .typeError("The option must be a text"),
  });

  const formik = useFormik({
    validationSchema: dataValidationSchema,
    initialValues: { preference: selectedValue ?? [] },
    enableReinitialize: true,
    onSubmit: submitChanges,
  });
  useEffect(() => {
    if (!showEdit) formik.resetForm();
  }, [showEdit]);
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <Grid sx={{ mt: 3 }} className="gray-border-bottom" container>
      <Grid xs={8} item>
      <Box className="become-host-step-form" sx={{paddingTop:'0 !important'}}>
              <Box className="small-heading">
        <Typography sx={{  mb: 1 }} component="h2" className="main-heading">
          {title}
        </Typography>
        </Box>
        </Box>

        {!showEdit && Object.keys(options).length > 0 && (
            <Typography component="h4">
            {Array.isArray(selectedValue) && props.multiple && selectedValue?.length > 0
              ? selectedValue.map((langCode) => options[langCode]).join(", ")
              : selectedLabel(options, selectedValue)}
          </Typography>
        )}
      </Grid>
      <Grid xs={4} sx={{ textAlign: "right" }} item>
        <Link
          onClick={() => {
            showEdit ? setShowEdit(false) : setShowEdit(true);
          }}
          sx={{ fontWeight: "500" }}
          className="link-update"
        >
          {showEdit ? "Cancel" : "Edit"}
        </Link>
      </Grid>
      {showEdit && (
        <Grid xs={12} item>
          {description && <Typography component="h4">{description}</Typography>}
          <FormControl
            variant="standard"
            className="input-style outlined-input select-style textfield-rouded-md"
            sx={{
              width: "100%",
              borderRadius: "18px",
              my: 3,
              pl: 4,
            }}
          >
            <SelectInput
              customObj={options}
              name="preference"
              multiple={props.multiple}
              value={values.preference}
              onChange={(e) => {
                let finalVal = e.target.value;
                if(props.multiple && values?.preference?.length > 0) finalVal.sort();
                formik.setFieldValue("preference", finalVal);
              }}
              onBlur={handleBlur}
              renderValue={(selected) => {
                if (props.multiple && values?.preference?.length > 0) {
                  return values.preference
                    .map((langCode) => options[langCode])
                    .join(", ");
                }
                if (
                  (selected == undefined || selected == "") &&
                  (values.preference == undefined || values.preference == "")
                ) {
                  return <em>Choose a {title}</em>;
                }
                const optionsArray = Object.entries(options);

                const option = optionsArray.filter(
                  ([key]) => key === values.preference
                );
                return option.length != 0 ? option[0][1] : "No data";
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.preference && touched.preference && errors.preference}
          </FormHelperText>
          <Button
            size="small"
            type="button"
            disableRipple
            disableElevation
            className="btn-primary submit-btn rounded-button"
            disabled={formik.isSubmitting}
            onClick={(e) => formik.handleSubmit()}
          >
            {formik.isSubmitting ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EditForm;
