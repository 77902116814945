var filterparameter = {
    data: {
        search: '',
        activity_search: '',
        location: {},
        start_date: '',
        end_date: '',
        services: [],
        price_sort: 'asc',
        host_proximity: 30,
        is_instant_booking: 0,
        is_delivery_pickup: 0
    }
}

let defaultState = sessionStorage.getItem('searchItems') != null ? JSON.parse(sessionStorage.getItem('searchItems')) : filterparameter

let filterReducers = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_SEARCH': {
            state.data.search = action.payload;
            break;
        }
        case 'SET_ACTIVITY_SEARCH': {
            state.data.activity_search = action.payload;
            break;
        }
        case 'SET_LOCATION': {
            state.data.location = action.payload;
            break;
        }
        case 'SET_DATE': {
            state.data.start_date = action.payload.start_date;
            state.data.end_date = action.payload.end_date;
            break;
        }
        case 'SET_SERVICES': {
            if (action.payload == '') {
                var servicesdata = [];
            } else {
                var servicesdata = state.data.services;

                if (servicesdata.length) {
                    var index = servicesdata.findIndex(o => o.id == action.payload.id);
                    if (index > -1) {
                        if (action.payload.count > 0) {
                            servicesdata[index].count = action.payload.count;
                        } else {
                            servicesdata.splice(index, 1);
                        }
                    } else {
                        servicesdata.push(action.payload)
                    }
                } else if (action.payload.count > 0) {
                    servicesdata.push(action.payload)
                }
            }

            state.data.services = servicesdata;
            break;
        }
        case 'SET_PRICE_FILTER': {
            state.data.price_sort = action.payload;
            break;
        }
        case 'SET_HOST_PROXIMITY': {
            state.data.host_proximity = action.payload;
            break;
        }
        case 'SET_INSTANT_BOOKING': {
            state.data.is_instant_booking = action.payload;
            break;
        }
        case 'SET_DELIVERY_PICKUP': {
            state.data.is_delivery_pickup = action.payload;
            break;
        }
        case 'SET_FILTER_INITIAL': {
            state = filterparameter;
            break;
        }
    }
    sessionStorage.setItem('searchItems', JSON.stringify(state));
    return state;
}

export default filterReducers