import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem, 
  Button, 
  CircularProgress
} from '@mui/material';
import { useFormik } from "formik";
import * as yup from 'yup';

import RadioInput from "../Inputs/RadioInput";
import AppHelper from '../../Helpers/AppHelper';
import MakeToast from "../../Helpers/MakeToast";

import { HostService } from "../../Services/HostService";

const CancellationPolicy = ({hostData, getHostProfile}) => {
    const [loader, setLoader] = useState(false);
    const totaldays = AppHelper.lists().days;
    const totalhours = AppHelper.lists().hours;
    const yesno = AppHelper.lists().yesno;

    const validationSchemas = yup.object({
        cancellation_period_day: yup.string().when('is_cancellation', {
            is: (is_cancellation) => is_cancellation=='1', 
            then: yup.string().required('Cancellation period is required'),
            otherwise: yup.string().nullable(true)
        }),
        cancellation_period_hour: yup.string().when('is_cancellation', {
            is: (is_cancellation) => is_cancellation=='1', 
            then: yup.string().required('Cancellation period is required'),
            otherwise: yup.string().nullable(true)
        })    
    });

    const formik = useFormik({
        initialValues: {
            is_cancellation: hostData.is_cancellation,
            cancellation_period_day: hostData.cancellation_period ? (hostData.cancellation_period.day!=null ? hostData.cancellation_period.day : '') : '',
            cancellation_period_hour: hostData.cancellation_period ? (hostData.cancellation_period.hour!=null ? hostData.cancellation_period.hour : '') : '',
        },
        validationSchema: validationSchemas,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoader(true);

            var extras = {
                'cancellation_period': {
                    'day': values.cancellation_period_day,
                    'hour': values.cancellation_period_hour
                }
            }
        
            Object.assign(values, extras);

	        delete values.cancellation_period_day;
	        delete values.cancellation_period_hour;

            HostService.hostProfileUpdate(values).then((resp) => {
                getHostProfile();
                MakeToast("success", resp);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            });
        },
    });

    return(
        <>  
            <form onSubmit={formik.handleSubmit} className="become-host-step-form">
                <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                    <Typography variant="h6" component="h2" className="main-heading">
                    Do your company have a cancellation policy that enforces penalties for customers canceling outside of your allowed specified cancellation period?
                    </Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Box className="radio-check-button">
                        <FormControl>
                            <RadioInput 
                            customArr={{data : yesno, name : 'name', id : 'id'}}
                            name="is_cancellation"
                            value={formik.values.is_cancellation}
                            onChange={formik.handleChange}
                            defaultValue="0"
                            sx={{
                                fontSize: '16px',
                                paddingRight: '30px',
                            }}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                {formik.values.is_cancellation=='1' && 
                <>
                    <Grid xs={12} sm={12} md={12} lg={12} className="small-heading" sx={{marginTop : "15px"}}>
                        <Typography variant="h6" component="h2" className="main-heading">
                            How much notice do you require from your customers for cancellation of your services, where you will still issue a full or partial refund? 
                        </Typography>
                    </Grid>
                    <Box className="total-hours">
                        <Box className="opening-hours">
                            <Box className="hours-show"
                            style={{
                                border: `1px solid #${formik.touched.cancellation_period_day && Boolean(formik.errors.cancellation_period_day) ||
                                formik.touched.cancellation_period_hour && Boolean(formik.errors.cancellation_period_hour) ? 'd83e3f' : 'EEEEEE'}`,
                                justifyContent: 'space-evenly'
                            }}
                            >
                            <div>
                                <FormControl sx={{
                                m: 1,
                                borderRight: 'none',
                                width : '95px'
                                }} >
                                <Select
                                    sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    color: '#09060F'
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    name="cancellation_period_day"
                                    value={formik.values.cancellation_period_day}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem disabled value="">
                                    <em>Days</em>
                                    </MenuItem>
                                    {totaldays.map((name) => {
                                    return (
                                        <MenuItem value={name} key={name} >{name}</MenuItem>);
                                    })}
                                </Select>
                                </FormControl>
                            </div>
                            <div className="border-none-class">
                                <FormControl sx={{
                                m: 1,
                                borderRight: 'none'
                                }} >
                                <Select
                                    sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    color: '#09060F',
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    name="cancellation_period_hour"
                                    value={formik.values.cancellation_period_hour}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem disabled value="">
                                    <em>Hours</em>
                                    </MenuItem>
                                    {totalhours.map((name) => {
                                    return (
                                        <MenuItem value={name} key={name} >{name}</MenuItem>);
                                    })}
                                </Select>
                                </FormControl>
                            </div>
                            </Box>
                            {formik.touched.cancellation_period_day && Boolean(formik.errors.cancellation_period_day) ?
                            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                style={{
                                paddingLeft: 15,
                                color: 'red',
                                }}
                                id="input-with-icon-textfield-helper-text"
                            >
                                {formik.errors.cancellation_period_day}
                            </p>
                            : formik.touched.cancellation_period_hour && Boolean(formik.errors.cancellation_period_hour) ?
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                style={{
                                    paddingLeft: 15,
                                    color: 'red',
                                }}
                                id="input-with-icon-textfield-helper-text"
                                >
                                {formik.errors.cancellation_period_hour}
                                </p>
                                :
                                ''
                            }
                        </Box>
                    </Box>
                </>
                }
                <Box className="modal-filter-btn">
                    <Button className="filter-button rounded-button"
                        type="submit"
                        sx={{
                        marginTop: '15px !important',
                        color: '#ffffff',
                        textTransform: 'capitalize',
                        }} >
                            {loader ? <CircularProgress color="inherit" /> : 'Save' }
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default CancellationPolicy;
