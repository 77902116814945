import { Box, Button, CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material'
import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom'
import parse from "html-react-parser";

import MakeToast from '../../Helpers/MakeToast';
import { BookingService } from '../../Services/BookingService';
import { NotificationService } from '../../Services/NotificationService';
import AppHelper from '../../Helpers/AppHelper';
import DenyModal from "../../Components/Modal/DenyModal";

function Notifications(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [openDeny, setOpenDeny] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [notifications, setNotifications] = useState({});
    const [loader, setLoader] = useState(false);
    const { isLoggedIn } = useSelector((state) => state.userReducers.data);

    const getNotifications = () => {
        if (isLoggedIn) {
            AppHelper.notificationLists(setNotifications, history, dispatch, props.setNotifOpen, {'limit' : '1'});
        }
    }

    React.useEffect(() => {
        if (props.notifOpen === true) {
            getNotifications();
        }
    }, [props.notifOpen]);

    const renderNotification = () => {
        setLoader(true);
        getNotifications()
        setLoader(false);
    }

    const notificationContent = (notfication) => {
        if (notfication?.type == 'REGULAR_BOOKING_REQUEST') {
            return (<>
                <Box
                onClick={() => {
                    history.push("/notification");
                    props.setNotifOpen(false);
                }}
                >
                    <Box className={"notification-message"+(notfication?.is_read ? " notification-read" : "")}>
                        <Typography component="span">
                            {notfication?.human_time}
                        </Typography>
                        <Typography component="h6">
                            {notfication?.sender?.name} Send Request for Booking.{" "}
                        </Typography>
                    </Box>
                    <Box className="notification-menu-btn" style={{ marginTop: '5px' }}>
                        {!loader ?
                            <>
                                <Button
                                    variant="contained"
                                    className="rounded-button btn-primary menu-btn regularbookingpendingapprovalhost"
                                >
                                    Approve
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="rounded-button btn-secondary-outline menu-btn regularbookingpendingdenialhost"
                                >
                                    Deny
                                </Button>
                            </>
                            :
                            <CircularProgress />
                        }
                    </Box>
                </Box>
            </>);
        } else {
            return (<>
                <Box className={"notification-message"+(notfication?.is_read ? " notification-read" : "")}
                onClick={() => {
                    history.push("/notification");
                    props.setNotifOpen(false);
                }}
                >
                    <Typography component="span">
                        {notfication?.human_time}
                    </Typography>
                    <Typography component="h6">
                        {parse(notfication?.payload?.message)}
                    </Typography>
                </Box >
            </>);
        }
    }

    return (
        <>
            <Popper
                open={props.notifOpen}
                anchorEl={props.notifRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal={false}
                className="menu-popper"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                        }}
                    >
                        <Paper className="menu-dropdown">
                            <ClickAwayListener
                                onClickAway={props.handleCloseNotification}
                            >
                                <div>
                                    <Box className="notification-list">
                                        <Typography
                                            component="h6"
                                            className="menu-heading"
                                            sx={{ paddingBottom: '5px' }}
                                        >
                                            Notifications
                                        </Typography>
                                        <Scrollbars className="menu-scrollbar" autoHeight autoHeightMin={20} autoHeightMax={350}>
                                            <Box className="header-menu-list">
                                                <MenuList
                                                    autoFocusItem={props.notifOpen}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    // onKeyDown={props.handleListKeyDown}
                                                    className="notification-menu"
                                                    sx={{ p: 0 }}
                                                >
                                                    {notifications?.length > 0 ? notifications.map(notfication =>
                                                        <MenuItem
                                                            // onClick={props.handleCloseNotification}
                                                            sx={{ p: 0, display: 'block' }}
                                                            disableRipple
                                                            data-notificationid={notfication.id}
                                                        >
                                                            {notificationContent(notfication)}
                                                        </MenuItem>
                                                    ) :
                                                        <MenuItem
                                                            onClick={props.handleCloseNotification}
                                                            sx={{ p: 0, display: 'block' }}
                                                            disableRipple
                                                        >
                                                            <Box className="notification-message">
                                                                <Typography component="h6">
                                                                    No Notifications
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    }
                                                </MenuList>

                                            </Box>
                                        </Scrollbars>
                                    </Box>

                                    <Box className="notification-menu-footer">
                                        <Link
                                            to="/notification"
                                            className="link-secondary clear-btn"
                                        >
                                            View all
                                        </Link>
                                    </Box>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {
                openDeny
                    ?
                    <DenyModal openDeny={openDeny} setOpenDeny={setOpenDeny} id={selectedId} onEmitValue={renderNotification} />
                    :
                    <></>
            }
        </>
    )
}

export default React.memo(Notifications);