import { Box, IconButton, Modal, Divider, Typography, styled } from "@mui/material";
import { loadStripe } from '@stripe/stripe-js';
import ModalPopup from "../Modal/ModalPopup";
import CloseIcon from "@mui/icons-material/Close";
import images from "../../assets/js/images";
import PaymentForm from "./PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import Form from "../PaymentsAndPayouts/PaymentMethod/Form";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    outline: "none",
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        height: "100% !important",
        width: "100% !important",
        maxWidth: "100% !important",
        borderRadius: 0,
        "& .listResponsive": {
            maxHeight: "100% !important",
        },
    },
}));

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
const ServicePayment = (props) => {
    const options = {};
    return (
        <Modal
            open={props.openPayment}
            onClose={() => props.setOpenPayment(false)}>
            <ResponsiveStyles sx={style}>
                <IconButton
                    sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
                    onClick={() => props.setOpenPayment(false)}
                >
                    <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
                </IconButton>
                <Box
                    sx={{
                        textAlign: "center",
                        height: "10%",
                        borderBottom: "solid 1px #ebebeb",
                        p: 2.5,
                    }}
                >
                    <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                        Add card details
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: 3,
                        overflowX: "auto",
                    }}
                >
                    <Elements stripe={stripePromise} options={options}>
                        <Form closeModal={() => props.setOpenPayment(false)} onEmitValue={props.handlePaymentMethod} isCheckout={true} />
                    </Elements>
                </Box>
            </ResponsiveStyles>
        </Modal>
    );
};

export default ServicePayment;
