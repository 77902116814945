import { useContext } from "react";
import { Box } from "@mui/material";
import TabOption from "../TabOption";
import TabLoading from "../TabLoading";
// Data from Host options
import HostActivityAndPolicies from "../Account/Hosts/ActivityAndPolicies";
import HostReminders from "../Account/Hosts/Reminders";
import HostMessages from "../Account/Hosts/Messages";
// Data from Renter options
import RenterActivityAndPolicies from "../Account/Renters/ActivityAndPolicies";
import RenterReminders from "../Account/Renters/Reminders";
import RenterMessages from "../Account/Renters/Messages";
import { NotificationSettingsContext } from "../../../Context/NotificationSettingsContext";
import { useSelector } from "react-redux";

const Account = () => {
  const { user } = useSelector((state) => state.userReducers.data);
  const { data, loading } = useContext(NotificationSettingsContext);
  let ActivityAndPoliciesData = [];
  let RemindersData = [];
  let MessagesData = [];
  if (user.role_id == 2) {
    ActivityAndPoliciesData = HostActivityAndPolicies(data);
    RemindersData = HostReminders(data);
    MessagesData = HostMessages(data);
  } else {
    ActivityAndPoliciesData = RenterActivityAndPolicies(data);
    RemindersData = RenterReminders(data);
    MessagesData = RenterMessages(data);
  }
  return (
    <>
      {loading ? (
        <TabLoading />
      ) : (
        <>
          <Box className="gray-border-bottom">
            <TabOption {...ActivityAndPoliciesData} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...RemindersData} />
          </Box>
          <Box sx={{ mt: 3 }} className="gray-border-bottom">
            <TabOption {...MessagesData} />
          </Box>
        </>
      )}
    </>
  );
};

export default Account;
