const ActivityAndPolicies = (data) => {
  const titleData = {
    title: "Account activity and policies",
    description:
      "Confirm your booking and account activity, and learn about important Beachify policies.",
  };

  const optionsData = [
    {
      label: "Account activity",
      description:
        "Stay on top of your reservations, account activity, and legal info, like our Terms of Service.",
      field: "activity",
      options: data["activity"],
    },
  ];
  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};

export default ActivityAndPolicies;
