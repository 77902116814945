import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import MakeToast from "../../../Helpers/MakeToast";
import { LoginAndSecurityService } from "../../../Services/LoginAndSecurityService";
import OtpPhone from "../Otp/Phone";

const Phone = ({ setShowPhone }) => {
  const [openOtp, setOtpOpen] = useState(false);
  const [phone, setPhone] = useState("");

  const formSubmit = (values, actions) => {
    setPhone(values.phone);
    LoginAndSecurityService.phoneGenerateOtp(values)
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
        actions.resetForm({ phone: "", confirm_phone: "" });
      })
      .catch((error) => {
        formik.setSubmitting(false);
        actions.resetForm({ phone: "", confirm_phone: "" });
        MakeToast("error", error);
      });
  };

  let informationValidationSchema = yup.object().shape({
    mobile_no: yup
      .string()
      .required("Phone is required")
      .typeError("Phone must be a valid phone"),
    confirm_mobile_no: yup
      .string()
      .required("Confirm Phone is required")
      .typeError("Confirm Phone must be a valid phone"),
  });
  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { mobile_no: "", confirm_mobile_no: "" },
    enableReinitialize: true,
    onSubmit: formSubmit,
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      {openOtp && (
        <OtpPhone
          setShowPhone={setShowPhone}
          openOtp={openOtp}
          setOtpOpen={setOtpOpen}
          handleClose={() => setOtpOpen(false)}
          mobileNo={phone}
        />
      )}
      <Box sx={{ mb: 2 }}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Phone"
            name="mobile_no"
            value={values.mobile_no}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.mobile_no && touched.mobile_no && errors.mobile_no && (
          <FormHelperText className="field-helper-text error">
            {errors.mobile_no && touched.mobile_no && errors.mobile_no}
          </FormHelperText>
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Confirm phone"
            name="confirm_mobile_no"
            value={values.confirm_mobile_no}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.confirm_mobile_no &&
          touched.confirm_mobile_no &&
          errors.confirm_mobile_no && (
            <FormHelperText className="field-helper-text error">
              {errors.confirm_mobile_no &&
                touched.confirm_mobile_no &&
                errors.confirm_mobile_no}
            </FormHelperText>
          )}
        <Button
          sx={{ my: 1.5 }}
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          disabled={formik.isSubmitting}
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default Phone;
