import { Box, IconButton, Modal, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import SwitchOption from "./SwitchOption";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
  },
}));

function generateOptionsSwitch(options, field) {
  const optionsSwitch = [];
  for (const [keyName, value] of Object.entries(options)) {
    optionsSwitch.push(
      <SwitchOption
        key={keyName}
        keyName={keyName}
        value={value}
        field={field}
      />
    );
  }
  return optionsSwitch;
}
const EditModal = (props) => {
  const { onClose, label, description, options, field } = props;
  const optionsSwitch = generateOptionsSwitch(options, field);
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon sx={{ fontSize: 25, mb: 4 }} />
        </IconButton>
        <Typography sx={{ fontWeight: "600" }} component="h3">
          {label}
        </Typography>
        <Typography component="h4">{description}</Typography>
        {optionsSwitch.map((OptionSwitch) => OptionSwitch)}
      </ResponsiveStyles>
    </Modal>
  );
};

export default EditModal;
