import { Box, ButtonBase, Typography, Grid } from "@mui/material";
import { useState } from "react";
import Email from "./Forms/Email";
import Phone from "./Forms/Phone";
const Login = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  return (
    <Box>
       <Box className="become-host-step-form">
        <Box classname="small-heading">
            <Typography sx={{mb: 3}} component="h2">
               Login
          </Typography>
        </Box>
      </Box>
      
      <Grid sx={{ my: 1 }} container>
        <Grid xs={10} item>
          <Typography sx={{ fontWeight: "400" }} component="h4">
            Email
          </Typography>
        </Grid>
        <Grid xs={2} sx={{ textAlign: "end" }} item>
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1 }}
            className="black-underline-link"
            variant="text"
            onClick={() => {
              showEmail ? setShowEmail(false) : setShowEmail(true);
            }}
          >
            {showEmail ? "Cancel" : "Update"}
          </ButtonBase>
        </Grid>
      </Grid>
      {showEmail && <Email setShowEmail={setShowEmail} />}
      <Grid className="gray-border-bottom" sx={{ my: 1 }} container>
        <Grid xs={10} item>
          <Typography sx={{ fontWeight: "400" }} component="h4">
            Phone Number
          </Typography>
        </Grid>
        <Grid xs={2} sx={{ textAlign: "end" }} item>
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1 }}
            className="black-underline-link"
            variant="text"
            onClick={() => {
              showPhone ? setShowPhone(false) : setShowPhone(true);
            }}
          >
            {showPhone ? "Cancel" : "Update"}
          </ButtonBase>
        </Grid>
        {showPhone && <Phone setShowPhone={setShowPhone} />}
      </Grid>
    </Box>
  );
};

export default Login;
