import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import FormField from "../FormField";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  BusinessEmailContext,
  BusinessInfoContext,
} from "../../../Context/BusinessInfoContext";
import { useContext, useEffect, useState } from "react";
import OtpEmail from "../Otp/Email";
import { BusinessInfoService } from "../../../Services/BusinessInfoService";
import MakeToast from "../../../Helpers/MakeToast";

let informationValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid address")
    .required("Email is required")
    .typeError("Email must be a valid address"),
});

const Email = () => {
  const [openOtp, setOtpOpen] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const { business } = useContext(BusinessInfoContext);

  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { email: business?.email ?? "" },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  const formSubmit = (values) => {
    BusinessInfoService.generateOtp({
      value: values.email,
      field_name: "email",
    })
      .then((response) => {
        formik.setSubmitting(false);
        setOtpOpen(true);
        MakeToast("success", response);
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    editEnable == false && formik.setFieldValue("email", business?.email ?? "");
  }, [editEnable]);
  return (
    <BusinessEmailContext.Provider value={values.email}>
      {openOtp && (
        <OtpEmail
          setShow={setEditEnable}
          openOtp={openOtp}
          setOtpOpen={setOtpOpen}
          handleClose={() => setOtpOpen(false)}
          email={values.email}
        />
      )}
      <FormField
        label="Email"
        description="Please enter your company email address below."
        text={business?.email}
        editEnable={editEnable}
        setEditEnable={setEditEnable}
      >
        <FormControl
          sx={{ width: "100%", mt: 2 }}
          className="input-style small-radius"
        >
          <TextField
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {errors.email && touched.email && errors.email && (
          <FormHelperText className="field-helper-text error">
            {errors.email && touched.email && errors.email}
          </FormHelperText>
        )}

        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          disabled={formik.isSubmitting}
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Save"
          )}
        </Button>
      </FormField>
    </BusinessEmailContext.Provider>
  );
};

export default Email;
