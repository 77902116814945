import { Container, Typography,Box } from "@mui/material";
import { useSelector } from "react-redux";
import MenuCard from "../../Components/AccountSettings/MenuCard";
import { Link } from "react-router-dom";
import RenterOptions from "./Options/Renter";
import HostOptions from "./Options/Host";
import Grid from '@mui/material/Unstable_Grid2';
import BreadCrumb from "../../Components/Common/BreadCrumb";
const getOptions = (role) => {
  if (role == 2) return HostOptions;
  if (role == 3) return RenterOptions;

  //Default Options
  return RenterOptions;
};
const AccountSettings = () => {
  const { user } = useSelector((state) => state.userReducers.data);
  const options = getOptions(user.role_id);
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <BreadCrumb data={[["Account"]]} />
      <Box className="main-heading-block" sx={{mb:0}}>
      <Typography variant="h1" sx={{ marginBottom: 1 }} className="main-heading">
        Account
      </Typography>
      </Box>
    
      <Typography variant="h4">
        <strong>{user.name},</strong> {user.email} ·{" "}
        <Link className="black-underline-link" to={user.role_id == 2 ? `/profile` : `customer-profile`}>
          Go to profile
        </Link>
      </Typography>

      <Grid spacing={2} sx={{ marginTop: 6 }} container>
        {options.map((option, index) => (
          <MenuCard key={index} {...option} />
        ))}
      </Grid>
    </Container>
  );
};

export default AccountSettings;
