import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../../../../../Context/TaxContext";
const RadioItem = (props) => {
  const { option } = props;

  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == option.id;
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={option.id}
      control={<Radio />}
      label={
        <>
          <Typography sx={{ fontSize: 16, color: "black", fontWeight: "600" }}>
            {option.name}
          </Typography>
          <Typography
            sx={{ fontSize: 14, color: "black", fontWeight: "light" }}
          >
            {option.description}
          </Typography>
        </>
      }
      sx={{
        fontSize: 19,
        mx: "0px !important",
        px: "3px !important",
        py: 2,
        my: 0.7,
        border: !checked ? "solid 2px #ebebeb" : "solid 2px #000",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          pl: 1,
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
};
const Delivery = () => {
  const { formik } = useContext(FormContext);
  const options = [
    {
      id: "MAIL",
      name: "Deliver by mail",
      description:
        "Your form will be mailed to the address above. You’ll also get an email when your form is available for download on Beachify.com.",
    },
    {
      id: "ELECTRONICALLY",
      name: "Deliver electronically only",
      description:
        "We’ll notify you by email when an electronic copy of your form is available for download on Beachify.com. Please read important disclosure information.",
    },
  ];
  return (
    <Box sx={{ mt: 3, borderBottom: "1px solid #ebebeb", pb: 6 }}>
      <Typography sx={{ fontWeight: "500", mb: 1 }} component="h4">
        Form 1099 delivery
      </Typography>
      <RadioGroup
        name="delivery_type"
        value={formik.values.delivery_type}
        onChange={(event) =>
          formik.setFieldValue("delivery_type", event.target.value)
        }
      >
        {options.map((option, index) => (
          <RadioItem key={index} option={option} />
        ))}
      </RadioGroup>
      {formik.errors.delivery_type && formik.touched.delivery_type && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.delivery_type}
        </FormHelperText>
      )}
    </Box>
  );
};

export default Delivery;
