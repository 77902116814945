import React from 'react'
import { Box, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { TutorialService } from "../../Services/TutorialService";

const Tutorials = () => {
    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

    const [tutorials, setTutorials] = React.useState([]);

    const getTutorials = (value) => {
        if (isLoggedIn && user.role_id == "2") {
            getHostTutorials(value);
        }else{
            getRenterTutorials(value);
        }
    }
    
    const getRenterTutorials = () => {
        setTutorials([]);

        TutorialService.renters().then(response => {
            setTutorials(response.data.data);
        }).catch(error => {

        });
    }

    const getHostTutorials = () => {
        setTutorials([]);

        TutorialService.hosts().then(response => {
            setTutorials(response.data.data);
        }).catch(error => {

        });
    }

    React.useEffect(() => {
        getTutorials();
    }, []);

    return (
        <>
            <section className="tutorials-section">
                {
                    tutorials.map((v) => {
                        return (
                            <Box sx={{marginBottom:'25px',background:'white',borderRadius:'5px',padding:'15px',textAlign:'center'}}>
                                <iframe src={v.links} width="100%" height="520"></iframe>
                                <Typography component="h4">{v.description}</Typography>
                            </Box>
                        )
                    })
                }
            </section>
        </>
    )
}

export default Tutorials;
