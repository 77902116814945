import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: "top-start",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const MakeToast = (type, response, timer = 3000) => {
    if (type === "success") {
        Toast.fire({
            icon: type,
            title: response?.data?.message ? response?.data?.message : response,
        });
    }

    if (type === "error") {
        Toast.fire({
            icon: type,
            title: response?.response?.data?.message ? response.response.data.message : response,
            html: response?.response?.data?.errors,
        });
    }

};

export default MakeToast;