import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Taxpayer or business name is required")
    .typeError("Taxpayer or business name must be a valid value"),
  disregarded_entity_name: yup
    .string()
    .typeError("Taxpayer or business name must be a valid value"),
  entity_type: yup
    .string()
    .required("Entity type is required")
    .typeError("Entity type must be a valid value"),
  country_region: yup
    .string()
    .required("Country/region of incorporation or residence type is required")
    .typeError(
      "Country/region of incorporation or residence type must be a valid value"
    ),
  type_id: yup
    .string()
    .required("Tax ID type is required")
    .typeError("Tax ID type must be a valid value"),
  id_number: yup
    .string()
    .required("ID is required")
    .typeError("ID must be a valid value"),
  income: yup
    .string()
    .required("Income is required")
    .typeError("Income be a valid value"),
  foreign_id_number: yup
    .string()
    .typeError("Foreign tax ID must be a valid value"),
  permanent_residence_address: yup.object().shape({
    country: yup
      .string()
      .required("Country is required")
      .typeError("Country must be a valid value"),
    line_1: yup
      .string()
      .required("Line 1 is required")
      .typeError("Line 1 must be a valid value"),
    line_2: yup.string().typeError("Line 2 must be a valid value"),
    line_3: yup.string().typeError("Line 3 must be a valid value"),
    line_4: yup.string().typeError("Line 4 must be a valid value"),
    city: yup.string().typeError("City/village must be a valid value"),
    state: yup.string().typeError("State must be a valid value"),
    zip: yup.string().typeError("ZIP code must be a valid value"),
  }),
  us_business_address: yup.object().shape({
    street: yup
      .string()
      .required("Street is required")
      .typeError("Street must be a valid value"),
    apt_suite: yup.string().typeError("Apt, suite, etc. must be a valid value"),
    city: yup
      .string()
      .required("City is required")
      .typeError("City must be a valid value"),
    state: yup
      .string()
      .required("State is required")
      .typeError("State must be a valid value"),
    zip: yup
      .string()
      .required("ZIP code is required")
      .typeError("ZIP code must be a valid value"),
  }),
  signature: yup
    .string()
    .required("Signature is required")
    .typeError("Signature must be a valid value"),
});

export default ValidationSchema;
