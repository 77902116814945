const geoData = 'geo_data';

class GeoHelper {
    getGeolocationCoordinates() {
        let geo = sessionStorage.getItem(geoData);
        return geo?.length > 0 ? JSON.parse(geo) : null;
    }

    setGeolocationCoordinates() {
        navigator.geolocation.getCurrentPosition(function(position) {
            sessionStorage.setItem(geoData, JSON.stringify(
                {latitude : position.coords.latitude,longitude : position.coords.longitude},
            ));
        });
    }
}

export default new GeoHelper();