import { Box, Card, Hidden, Typography } from "@mui/material";
import { useState } from "react";
import MakeToast from "../../Helpers/MakeToast";
import { NotificationSettingsService } from "../../Services/NotificationSettingsService";
import CheckboxInput from "../Inputs/CheckboxInput";

const PhoneCard = () => {
  const [unsubscribe, setUnsubcribe] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChangeMarketingSms = (e) => {
    NotificationSettingsService.updateMarketingSms({
      value: !unsubscribe,
    })
      .then((response) => {
        setUnsubcribe(!unsubscribe);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  return (
    <Hidden only={["sm", "xs"]}>
      <Card className="privacy-info-card">
        <Typography sx={{ fontWeight: "500" }} component="h4">
          Where would you like to get text messages?
        </Typography>
        <Typography sx={{ marginTop: 2 }}>
          By checking an SMS box, you agree to receive autodialed promotional
          texts from Beachify and Beachify's partners at:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <CheckboxInput
            disabled={loading}
            inputtype="checkboxonly"
            checked={unsubscribe}
            onChange={onChangeMarketingSms}
          />
          <Typography
            sx={{ ml: 1, opacity: loading ? "50%" : "100%" }}
            component="h4"
          >
            Unsubcribe from sms notifications
          </Typography>
        </Box>
        <Typography sx={{ marginTop: 2 }}>
          For more info, text HELP to 247262. To cancel mobile notifications,
          reply STOP to 247262. Message and data rates may apply.
        </Typography>
      </Card>
    </Hidden>
  );
};

export default PhoneCard;
