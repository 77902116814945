import { APIService } from "./APIService";

export const CommonService = {
  removeMedia,
  getAllLanguages,
  getAllLanguagesAsArray,
};

function removeMedia(data) {
  return APIService.post("api/remove-media", data);
}

function getAllLanguages() {
  return APIService.get("api/languages");
}

function getAllLanguagesAsArray() {
  return APIService.get("api/languages_array");
}
