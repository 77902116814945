import { Container,Link,Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ProfileDataSection from "./ProfileDataSection";
import CompanyProfileCard from "./CompanyProfileCard";
import { useEffect } from "react";
import { ProfileService } from "../../../Services/ProfileService";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MakeToast from "../../../Helpers/MakeToast";
import { ProfileContext } from "../../../Context/ProfileContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import images from "../../../assets/js/images";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

const CompanyProfile = ({ params }) => {
  const { user, isLoggedIn } = useSelector((state) => state.userReducers.data);
  const [profile, setProfile] = useState([]);
  const isEditable = params.id ? user.id == params.id : true;
  const navigate = useHistory();

  const [languages, setLanguages] = useState({
    userLanguages: [],
    availableLanguages: [],
  });

  const [profileId, setProfileId] = useState(params.id ? params.id : user.id);

  const [loading, setLoading] = useState(true);
  const getProfile = () => {
    ProfileService.hostProfile(profileId)
      .then((response) => {
        setProfile(response.data.data.profile);
        setLanguages({
          userLanguages: [...response.data.data.languages],
          availableLanguages: [...response.data.data.languages],
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate.push("/");
        MakeToast("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setProfileId(params.id ? params.id : user.id);
    if (profileId) {
      getProfile();
    }
  }, [params]);
  return (
    <ProfileContext.Provider
      value={{
        profile: profile,
        setProfile: setProfile,
        languages: languages,
        setLanguages: setLanguages,
        loading: loading,
        getProfile: getProfile,
        isEditable: isEditable,
      }}
    >
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <BreadCrumb data={[["Profile"]]} />
        <Grid row Spacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
          <Grid item xs={12} style={{ margin : "15px 0" }}>
              <Box className="top-side-heading">
                  <div className="left-back-arrow ">
                      <Link
                          component="button"
                          variant="body2"
                          underline="none"
                          className=" tertiary-text"
                          color="inherit"
                          onClick={() => { navigate.go(-1); }}
                      >
                          <img src={images.backIcon} alt="" />
                          Back
                      </Link>
                  </div>
              </Box>
          </Grid>
          <Grid xs={12} md={4} item>
            <CompanyProfileCard />
          </Grid>
          <Grid xs={12} md={8} item>
            <ProfileDataSection />
          </Grid>
        </Grid>
      </Container>
    </ProfileContext.Provider>
  );
};
export default CompanyProfile;
