import { Skeleton, Grid } from "@mui/material";
const FieldLoading = () => {
  return (
    <Grid container className="modal-header">
      <Grid item xs={10}>
        <Skeleton width={300} />
        <Skeleton width={100} />
      </Grid>
      <Grid item xs={2} align="right">
        <Skeleton width={50} />
      </Grid>
    </Grid>
  );
};
const FormLoading = ({ fieldQty = 6 }) => {
  const fields = Array.from({ length: fieldQty }, () => {
    return <FieldLoading />;
  });
  return fields;
};

export default FormLoading;
