const InitialValues = {
  name: "",
  country_citizenship: "",
  id_number: "",
  foreign_id_number: "",
  permanent_residence_address: {
    country: "",
    line_1: "",
    line_2: "",
    line_3: "",
    line_4: "",
    city: "",
    state: "",
    zip: "",
  },
  mail_address: {
    country: "",
    street: "",
    apt_suite: "",
    city: "",
    state: "",
    zip: "",
  },
  signature: "",
  same_mail_address: true,
};
export default InitialValues;
