import React from 'react'
import preloader from '../../../src/assets/loaders.gif';

function ApiLoader() {
    return (
        <div style={{
            display: "flex",
            width: "100%",
            justifyContent: "center"
        }}>
            <img src={preloader} alt="Site preloader" style={{ width: "250px" }} />
        </div>
    )
}

export default ApiLoader