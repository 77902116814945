import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Typography,
  FormControl,
  Input,
  InputAdornment,
  Link,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import { initFacebookSdk } from "../../assets/js/initFbSdk";
import images from "../../assets/js/images";
import MakeToast from "../../Helpers/MakeToast";
import ScreenLoader from "../../Components/Common/ScreenLoader";
import ModalPopup from "../../Components/Modal/ModalPopup";

import { AuthenticationService } from "../../Services/AuthenticationService";

const Login = ({
  isOpen,
  handleClose,
  setLoginOpen,
  setOtpOpen,
  setEmail,
  handleOpenSignUp,
  handleOpenSupport,
  setType,
  type,
  setMobileNo,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = React.useState(false);

  const loginValidationSchema = Yup.object().shape({
    email:
      type == "email"
        ? Yup.string().email('Email must be a valid email').required("Email field is required")
        : Yup.string(),
    mobile_no:
      type == "mobile_no"
        ? Yup.number().typeError('Mobile number must be a valid number').required("Mobile No is required")
        : Yup.number().typeError('Mobile number must be a valid number'),
  });

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  React.useEffect(() => {
    initFacebookSdk();
    setTimeout(() => {
      try {
        gapi.load("client:auth2", () => {
          gapi.client.init({
            clientId: clientId,
            scope: "",
          });
        });
      } catch (error) { }
    }, 1000);
  });

  const responseGoogle = (response) => {
    if (response?.profileObj) {
      setLoader(true);
      AuthenticationService.loginWithSocial(response?.profileObj)
        .then((res) => {
          setLoader(false);
          MakeToast("success", res);
          dispatch({
            type: "LOGIN_START",
            payload: res?.data,
          });
          setLoginOpen(false);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  return (
    <>
      <ScreenLoader open={loader} />
      <ModalPopup
        className="modallogin"
        open={isOpen}
        onClose={handleClose}
        content={
          <>
            <Box className="modalheader" sx={{
              marginBottom: '25px',
              '@media (max-width:768px)': {
                marginBottom: '15px',
              }
            }}>
              <Box
                id="modal-modal-title"
                className="modal-heading text-center"
                component="h2"
              >
                Login to start booking!
              </Box>
              <Box
                className="modal-close-btn"
                onClick={() => {
                  setLoginOpen(false);
                  if (window.location.pathname == '/login') {
                    window.history.replaceState(null, null, '/');
                  }
                }}
              >
                <Button size="small" disableRipple>
                  {" "}
                  <img src={images.closeIcon} alt="" />
                </Button>
              </Box>
            </Box>
            <Box className="modal-body login-form">
              <Formik
                validationSchema={loginValidationSchema}
                initialValues={{ email: "", mobile_no: "" }}
                onSubmit={(values, { setSubmitting }) => {
                  values["type"] = type;
                  AuthenticationService.login(values)
                    .then((response) => {
                      setEmail(values.email);
                      setMobileNo(values.mobile_no);
                      setType(type);
                      setSubmitting(false);
                      setOtpOpen(true);
                      setLoginOpen(false);
                      MakeToast("success", response);
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setErrors,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box component="form" noValidate autoComplete="off">
                      {type == "email" ? (
                        <>
                          <FormControl
                            sx={{ width: "100%" }}
                            className={
                              "input-style " +
                              (errors.email && touched.email ? "error" : "")
                            }
                          >
                            <Input
                              placeholder="E-mail"
                              className="outline-input"
                              name="email"
                              error
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPress={e => {
                                if (e.key == 'Enter') {
                                  e.preventDefault();
                                  document.getElementById('nextbutton').click();
                                }
                              }}
                              sx={{
                                "&:before": {
                                  borderBottom: "0px solid",
                                },
                              }}
                              startAdornment={
                                <InputAdornment
                                  position="start"
                                  className="input-image"
                                  underline="none"
                                  sx={{ pl: 3, pr: 1 }}
                                >
                                  <img src={images.mailIcon} alt="search" />
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  underline="none"
                                  sx={{
                                    pl: 3,
                                    pr: 1,
                                    "@media (max-width:577px)": {
                                      paddingLeft: "0",
                                    },
                                  }}
                                >
                                  <Link
                                    href="javascript:void(0);"
                                    onClick={function () {
                                      setErrors({});
                                      setType("mobile_no");
                                    }}
                                    underline="none"
                                    sx={{
                                      paddingRight: "30px",
                                      color: "#B1B1B1",
                                      "&:hover": {
                                        color: "#B1B1B1",
                                      },
                                      "@media (max-width:577px)": {
                                        paddingRight: "0",
                                      },
                                      "@media (max-width:767.98px)": {
                                        paddingRight: "10px",
                                      },
                                      "@media (max-width:1023.98px)": {
                                        paddingRight: "10px",
                                      },
                                    }}
                                  >
                                    Login by phone
                                  </Link>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <FormHelperText
                            id="standard-weight-helper-text"
                            className="field-helper-text error"
                          >
                            {errors.email && touched.email && errors.email}
                          </FormHelperText>
                        </>
                      ) : (
                        <>
                          <FormControl
                            sx={{ width: "100%" }}
                            className={
                              "input-style " +
                              (errors.mobile_no && touched.mobile_no
                                ? "error"
                                : "")
                            }
                          >
                            <Input
                              placeholder="Mobile No"
                              className="outline-input"
                              name="mobile_no"
                              error
                              type="number"
                              value={values.mobile_no}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPress={e => {
                                if (e.key == 'Enter') {
                                  e.preventDefault();
                                  document.getElementById('nextbutton').click();
                                }
                              }}
                              sx={{
                                "&:before": {
                                  borderBottom: "0px solid",
                                },
                              }}
                              startAdornment={
                                <InputAdornment
                                  position="start"
                                  className="input-image"
                                  underline="none"
                                  sx={{ pl: 3, pr: 1 }}
                                >
                                  <img src={images.contactIcon} alt="" />
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  underline="none"
                                  sx={{
                                    pl: 3,
                                    pr: 1,
                                    "@media (max-width:577px)": {
                                      paddingLeft: "0",
                                    },
                                  }}
                                >
                                  <Link
                                    href="#"
                                    underline="none"
                                    onClick={function () {
                                      setErrors({});
                                      setType("email");
                                    }}
                                    sx={{
                                      paddingRight: "30px",
                                      color: "#B1B1B1",
                                      "&:hover": {
                                        color: "#B1B1B1",
                                      },
                                      "@media (max-width:577px)": {
                                        paddingRight: "0",
                                      },
                                      "@media (max-width:767.98px)": {
                                        paddingRight: "10px",
                                      },
                                      "@media (max-width:1023.98px)": {
                                        paddingRight: "10px",
                                      },
                                    }}
                                  >
                                    Login by email
                                  </Link>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <FormHelperText
                            id="standard-weight-helper-text"
                            className="field-helper-text error"
                          >
                            {errors.mobile_no &&
                              touched.mobile_no &&
                              errors.mobile_no}
                          </FormHelperText>
                        </>
                      )}
                    </Box>
                    <Box noValidate autoComplete="off">
                      <Button
                        size="small"
                        className="header-submit-btn"
                        type="submit"
                        id="nextbutton"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress color="inherit" sx={{ height: '20px !important', width: '20px !important' }}  />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
            <Box className="modalfooter">
              <div className="title title--center-line">Or</div>
              <Box className="social-media-btn mt-1">
                <Button
                  size="small"
                  onClick={async () => {
                    const { authResponse } = await new Promise(window.FB.login);
                    if (authResponse) {
                      window.FB.api(
                        "/me",
                        { fields: "id,email,name,birthday" },
                        function (response) {
                          if (response) {
                            const userRes = {
                              ...authResponse,
                              ...response,
                            };
                            setLoader(true);
                            AuthenticationService.loginWithSocial(userRes)
                              .then((response) => {
                                setLoader(false);
                                MakeToast("success", response);
                                dispatch({
                                  type: "LOGIN_START",
                                  payload: response?.data,
                                });
                                setLoginOpen(false);
                              })
                              .catch((error) => {
                                window.FB.logout();
                                setLoader(false);
                              });
                          }
                        }
                      );
                    }
                    if (!authResponse) return;
                  }}
                >
                  <img src={images.facebookIconone} alt="" />
                  Login with Facebook
                </Button>
                <GoogleLogin
                  clientId={clientId}
                  render={(renderProps) => (
                    <Button onClick={renderProps.onClick} size="small">
                      {" "}
                      <img src={images.googleIcon} alt="" />
                      Login with Google
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                />
              </Box>
              <Typography component="p" className="sub-text" style={{marginBottom: "5px"}}>
                        <Link
                          href="javascript:void(0);"
                          className="primary-text"
                          onClick={handleOpenSupport}
                        >
                          Forgot Email?
                        </Link>
                      </Typography>
              <Typography component="p" className="sub-text mt-5">
                Don't have an account? 
                <Link
                  href="#"
                  className="primary-text"
                  onClick={handleOpenSignUp}
                  sx={{marginLeft:'4px'}}
                >
                  Sign up
                </Link>
              </Typography>
            </Box>
          </>
        }
      />
    </>
  );
};

export default Login;
