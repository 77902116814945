import { APIService } from "./APIService";

export const FaqService = {
    hosts,
    renters
};

function hosts(data) {
    return APIService.post('api/faq/hosts', data);
}

function renters(data) {
    return APIService.post('api/faq/renters', data);
}