import Swal from "sweetalert2";
import moment from "./MomentDefaultHelper";
import TimeZones from "./TimeZones";

import { CartService } from "../Services/CartService";
import { NotificationService } from "../Services/NotificationService";
import { BookingService } from "../Services/BookingService";
import images from "../assets/js/images";

let qtyInterval;
class AppHelper {
  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  SAToast(title = "", text = "", customclass={}) {
    var options = {
      title: title == "" ? "Are you sure?" : title,
      text: text == "" ? "You want to proceed" : text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }

    if(title=="notitle") delete options.title;
    if(text=="notext") delete options.text;

    if(Object.keys(customclass).length) Object.assign(options, {customClass : customclass})
    return Swal.fire(options);
  }

  convertDateTime(date='', type = "") {
    if (type == 1) return moment(date).format("MM-DD-YYYY");
    else if (type == 2) return moment(date).format("hh:mm A");
    else if (type == 3) return moment(date).format("MM-DD-YYYY h:mm A");
    else if (type == 4) return moment(date).format("h:mm A");
    else if (type == 5) return moment();
    else return moment(date).format("MM-DD-YYYY, hh:mm A");
  }

  convertAMPM(timedata) {
    var split = timedata.split(":");
    var time = new Date().setHours(split[0], split[1], 0);
    return time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  convertTime(timedata) {
    const [time, modifier] = timedata.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  //TODO: Need to use loadash debouce instead of timeout
  debounce(func) {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  }

  lists() {
    return {
      timezones: TimeZones,
      languages: {
        en: "English",
        fr: "French",
        ger: "Germany",
        ja: "Japan",
        it: "Italy",
        sp: "Spain",
      },
      yesno: [
        { id: "1", name: "Yes" },
        { id: "0", name: "No" },
      ],
      typeofcharges: {
        1: "Flat rate charge",
        2: "By the minute, hour, day, etc.",
      },
      pricetypes: ["Minutes", "Hours", "Days"],
      pricetypesorder: { Minutes: "1", Hours: "2", Days: "3" },
      minutes: ["15", "30", "45"],
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      days: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      hours1: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      days1: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      weeks: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      tollserviceareas: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      cancellationlist: [
        "My plans have changed",
        "I found a better deal",
        "Another reason",
      ],
      denylist: [
        "My availability changed",
        "Beach gear is in need of repair or maintenance",
        "Other",
      ],
      statusLabel: {
        pending: "Pending Approval",
        inprogress: "In Progress",
        upcoming: "Upcoming",
        completed: "Completed",
        cancelled: "Cancelled",
        denied: "Denied",
      },
      statusList: {
        all: "All status",
        pending: "Pending Approval",
        inprogress: "In Progress",
        upcoming: "Upcoming",
        completed: "Completed",
        cancelled: "Cancelled",
      },
      cardTypes: {
        visa: {
          icon: images.visa,
          name: "Visa",
        },
        mastercard: {
          icon: images.mastercardicon,
          name: "Master Card",
        },
        amex: {
          icon: images.amexicon,
          name: "American Express",
        },
        discover: {
          icon: images.discover,
          name: "Discover",
        },
        diners: {
          icon: images.diners,
          name: "Diners Club",
        },
        jcb: {
          icon: images.jcb,
          name: "JCB",
        },
        unionpay: {
          icon: images.unionpay,
          name: "UnionPay",
        },
      },
    };
  }

  checkboxList(e, list) {
    var value = e.target.value;

    if (e.target.checked) {
      list.push(value);
    } else {
      var index = list.findIndex((o) => o == value);
      list.splice(index, 1);
    }

    return list.join(",").split(",");
  }

  searchRandomNumber() {
    var max = 99999999;
    var min = 11111111;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  dateTimeFormat(time, date = "") {
    let inputDateTime = moment(`${date} ${time}`, "MM-DD-YYYY hh:mm A");
    let output = inputDateTime.format("YYYY-MM-DD HH:mm:ss");
    return output;
  }

  validateStartEnd(opentime, closetime, startdate = "", enddate = "") {
    var dopen = this.dateTimeFormat(opentime, startdate);
    var dclose = this.dateTimeFormat(closetime, enddate);
    var startDate = moment(dopen, "YYYY-MM-DD HH:mm:ss");
    var endDate = moment(dclose, "YYYY-MM-DD HH:mm:ss");

    return endDate.isAfter(startDate) ? true : false;
  }

  currency() {
    return "$";
  }

  sortedPrice(price, type = "") {
    var pricedatas = [];

    price.map((v, i) => {
      let typeText = v.type
      if(v.option == 1){
        if(v.type == "Days") typeText = 'Day';
        if(v.type == "Hours") typeText = 'Hour';
        if(v.type == "Week") typeText = 'Week';
      }
      var stringDays = v.type == "Days" ? (v.from+ ' - '+ v.to) : v.option;
      if (type == 1) {
        pricedatas.push({
          id: v.id,
          price: v.deal_price,
          pricetype: v.type,
          from: v.from ? v.from : null,
          priceoption: v.option,
          type: this.lists().pricetypesorder[v.type],
          name: "For " +
          stringDays +
          " " +
          typeText +
          " - " +
          this.currency() +
          v.deal_price + (v.type == "Days" ? "/day" : ""),
        });
      } else {
        pricedatas.push({
          id: v.id,
          price: v.deal_price,
          pricetype: v.type,
          from: v.from ? v.from : null,
          priceoption: v.option,
          type: this.lists().pricetypesorder[v.type],
          name:
            "For " +
            stringDays +
            " " +
            typeText +
            ' <div class="priceset">' +
            (v.type != "Days" ? '<span class="listprice">'+this.currency() + v.list_price+'</span>/' : '')+
            '<span class="dealprice">' +
            this.currency() +
            v.deal_price +  (v.type == "Days" ? "/day" : "")+
            "</span></div>",
        });
      }
    });
    
    if (pricedatas.length) {
      pricedatas.sort((a, b) => a.type - b.type);
      pricedatas.sort((a, b) => a.price - b.price);
      pricedatas.sort((a, b) => a.from - b.from);
    }

    return pricedatas;
  }

  cartMultipleHostWarning(popup, cartlist, dispatch) {
    this.SAToast(
      "notitle",
      "Beachify does not currently support multi-Host booking. Please only book with one Host at a time"
    ).then((result) => {
      if (result.isConfirmed) {
        CartService.deleteByUser()
          .then((res) => {
            popup(true);
            cartlist({});
            dispatch({
              type: "CART_UPDATE",
              payload: []
            });
          })
          .catch((error) => { });
      }
    });
  }

  cartQuantityAction(
    type,
    index,
    id,
    quantity,
    cartlist,
    getCartList,
    dispatch,
    MakeToast
  ) {
    if (type == 0 && quantity - 1 <= 0) return;

    if (type == 0) quantity = quantity - 1;
    else if (type == 1) quantity = quantity + 1;

    if (qtyInterval) clearTimeout(qtyInterval);
    qtyInterval = setTimeout(() => {
      CartService.update({ quantity: quantity }, id).then((resp) => {
        cartlist.items[index].quantity = quantity;
        getCartList();
        dispatch({
          type: "CART_UPDATE",
          payload: true,
        });
      }).catch(error => {
        if (error == 'Out of stock') MakeToast("error", "Out of Stock for selected quantity");
        else MakeToast("error", error);
      });
    }, 1000);
  }

  cartDeleteAction(id, getCartList, dispatch) {
    CartService.del(id)
      .then((res) => {
        getCartList();
        dispatch({
          type: "CART_UPDATE",
          payload: true,
        });
      })
      .catch((error) => { });
  }

  cartDeleteByUserAction(getCartList, dispatch) {
    CartService.deleteByUser()
      .then((res) => {
        getCartList();
        dispatch({
          type: "CART_UPDATE",
          payload: true,
        });
      })
      .catch((error) => { });
  }

  cartLists(setCartList) {
    CartService.list()
      .then((res) => {
        setCartList(res.data.data);
      })
      .catch((error) => { });
  }

  confirmBookingNotifcation(history, id) {
    this.notificationReadUpdate(id, "regularbookingrequest")
    history.push(
      "/booking-detail/" +
      id +
      "?type=pendingapprovalhost&search=" +
      this.searchRandomNumber()
    );
  }

  denialBookingNotifcation(history, id) {
    this.notificationReadUpdate(id, "regularbookingrequest")
    history.push(
      "/booking-detail/" +
      id +
      "?type=pendingdenialhost&search=" +
      this.searchRandomNumber()
    );
  }

  notificationLists(setList, history, dispatch, popupclose = '', data={}) {
    NotificationService.list(data).then((resp) => {
      var response = resp.data.data;

      setList(response);
      
      setTimeout(() => {
        var selectors = [
          ".instantbookingapprovedcustomer",
          ".instantbookingapprovedhost",
          ".regularbookingapprovedcustomer",
          ".bookingcancellation",
          ".bookingrequestcancellation",
          ".refundissuedsuccesscustomer",
          ".pickupconfirmationcustomer",
          ".pickuphost",
          ".drophost",
          ".addresschangehost",
        ];

        for (var l = 0; l < selectors.length; l++) {
          document.querySelectorAll(selectors[l]).forEach((el) =>
            el.addEventListener("click", (e) => {
              var id = e.target.getAttribute("data-id");
              this.notificationReadUpdate(id, e.target.className) 
              history.push("/booking-detail/" + id);
              if (popupclose != '') popupclose(false)
            })
          );
        }

        document
          .querySelectorAll(".paymentunsuccessfulcustomer")
          .forEach((el) =>
            el.addEventListener("click", (e) => {
              var id = e.target.getAttribute("data-id");
              this.notificationReadUpdate(id, "paymentunsuccessfulcustomer")
              history.push("/account-settings/payment-info");
              if (popupclose != '') popupclose(false)
            })
          );

        document.querySelectorAll(".bookingdenial").forEach((el) =>
          el.addEventListener("click", (e) => {
            var search = e.target.getAttribute("data-search") != '' ? JSON.parse(e.target.getAttribute("data-search")) : {};
            if (Object.keys(search).length) {
              sessionStorage.setItem('searchItems', JSON.stringify({ 'data': search }))
            } else {
              dispatch({ type: "SET_FILTER_INITIAL" });
            }
            history.push("/search-result");
            if (popupclose != '') popupclose(false)
          })
        );

        document.querySelectorAll(".pickupcustomer").forEach((el) =>
          el.addEventListener("click", (e) => {
            var id = e.target.getAttribute("data-id");
            this.notificationReadUpdate(id, "pickupcustomer")
            history.push(
              "/booking-detail/" +
              id +
              "?type=pickupcustomer&search=" +
              this.searchRandomNumber()
            );
            if (popupclose != '') popupclose(false)
          })
        );

        document.querySelectorAll(".dropcustomer").forEach((el) =>
          el.addEventListener("click", (e) => {
            var id = e.target.getAttribute("data-id");
            this.notificationReadUpdate(id, "dropcustomer")
            history.push(
              "/booking-detail/" +
              id +
              "?type=dropcustomer&search=" +
              this.searchRandomNumber()
            );
            if (popupclose != '') popupclose(false)
          })
        );

        document.querySelectorAll(".reviewcustomer").forEach((el) =>
          el.addEventListener("click", (e) => {
            history.push("/users/reviews");
            if (popupclose != '') popupclose(false)
          })
        );

        document.querySelectorAll(".reviewhost").forEach((el) =>
          el.addEventListener("click", (e) => {
            history.push("/host/reviews");
            if (popupclose != '') popupclose(false)
          })
        );

        document.querySelectorAll(".hourschangehost").forEach((el) =>
          el.addEventListener("click", (e) => {
            var id = e.target.getAttribute("data-id");
            this.notificationReadUpdate(id, "hourschangehost")
            history.push("/profile/" + id);
            if (popupclose != '') popupclose(false)
          })
        );
      }, 1000);
    });
  }

  notificationRead(setRead) {
    NotificationService.read().then((resp) => {
      setRead(resp.data.data)
    })
  }

  notificationReadUpdate(id, type){
    NotificationService.readUpdate({'model_id' : id, 'type' : type}).then((resp) => {})
  }

  approveBooking(id, toast, render, loader = "") {
    this.SAToast().then((result) => {
      if (result.isConfirmed) {
        BookingService.request(id).then((res) => {
          toast("success", "Booking is approved.");
          render();
          if (loader != "") loader(false);
        });
      } else {
        if (loader != "") loader(false);
      }
    });
  }

  checkFileFormat(filetype, type = '') {
    return ['image/jpg', 'image/jpeg', 'image/png'].includes(filetype);
  }
  
  async checkFileDimensions(file, width, height) {
    let img = new Image()
    img.src = window.URL.createObjectURL(file)
    await img.decode();
    if(img.width == width && img.height == height){
      return true;
    }else{
      return false;
    }  
  }
}

export default new AppHelper();
