import { Box } from "@mui/material";
import Documents from "../Documents/Documents";
import Help from "../Help/Help";

const TaxDocuments = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Documents />
      <Help />
    </Box>
  );
};

export default TaxDocuments;
