import React, { useState, useEffect } from "react";

import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormControl,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BeachGear from "../../Components/Home/BeachGear";
import images from "../../assets/js/images";
import { FavoriteService } from "../../Services/FavoriteService";
import HostContent from "../../Components/Favorites/HostContent";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { FavoritesContext } from "../../Context/FavoritesContext";
import Grid from '@mui/material/Unstable_Grid2';

const Favorites = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]);
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getData();
  }, [selectedOption]);

  const getData = () => {
    setIsLoading(true);
    if (selectedOption) {
      if (selectedOption.key == "hosts") {
        getHosts(search);
        return;
      }
      if (selectedOption.key == "beach_gear") {
        getListings(search, true);
        return;
      }

      if (selectedOption) getListings(search);
    }
  };
  //Update the cateogry quantity when an item is removed from favorites
  const updateCategoryQty = (elementData) => {
    let newValues = [...categories];
    newValues[selectedIndex].count--;
    //Reduce one to category counter in case is removing from beach gear option
    if (selectedOption.key == "beach_gear") {
      let categoryIndex = categories.findIndex(
        (p) => p.id == elementData.categoryId
      );
      newValues[categoryIndex].count--;
    }
    //In case we select a category the beach gear total count substracts one
    if (selectedOption.key.startsWith("category-")) {
      let beachGearIndex = categories.findIndex((p) => p.key == "beach_gear");
      newValues[beachGearIndex].count--;
    }
    setCategories(newValues);
  };
  const searchHandle = (event) => {
    setSearch(event.target.value);
    getData();
  };

  const getCategories = () =>
    FavoriteService.categories()
      .then((response) => {
        setCategories(response.data.data);
        if (response.data.data.length > 0)
          setSelectedOption(response.data.data[0]);
      })
      .catch((error) => {});

  const handleOptionChange = (value, index) => (event, newExpanded) => {
    setIsLoading(true);
    setSelectedOption(value);
    setSelectedIndex(index);
  };

  const getListings = (search = "", getAll = false) => {
    const serviceFunction = getAll
      ? FavoriteService.allBeachGears({ search: search })
      : FavoriteService.categoryListings(selectedOption.id, {
          search: search,
        });
    serviceFunction
      .then((response) => {
        setFavoriteItems(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };

  const getHosts = (search = "") => {
    FavoriteService.hosts({ search: search })
      .then((response) => {
        setFavoriteItems(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };

  const ItemsList = () =>
    selectedOption?.key == "hosts" ? (
      <>
        {favoriteItems.length &&
          favoriteItems.map((data) => <HostContent {...data} />)}
      </>
    ) : (
      <div className="grid-4">
        {favoriteItems.length &&
          favoriteItems.map((data) => <BeachGear beachgear={data} />)}
      </div>
    );

  return (
    <>
      <section className="service-listing section-padding ">
        <FavoritesContext.Provider
          value={{ getData: getData, updateCategoryQty: updateCategoryQty }}
        >
          <Container>
            <Grid container>
              <BreadCrumb data={[["Favorites"]]} />
              <Grid item xs={12}>
                <div className="service-top-header">
                  <div className="left-back-arrow ">
                    <RouterLink className="tertiary-text" to="/">
                      <img src={images.backIcon} alt="" />
                      Back
                    </RouterLink>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="web-view-all">
                {selectedOption && (
                  <div className="main-heading-block">
                    <Box className="service-heading">
                      <h2 className="main-heading">Favorites</h2>
                      <Box component="span" className="filter-sub-value">
                        {selectedOption.name} ({selectedOption.count})
                      </Box>
                    </Box>
                    <FormControl
                      sx={{ width: "324px" }}
                      className="input-style reduce-bottom-space search-listing-space"
                    >
                      <TextField
                        placeholder="Search"
                        className="outline-input input-text-style"
                        onKeyUp={searchHandle}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={images.searchIcon} alt="" />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          ".MuiInputBase-root": {
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#eee",
                            },
                            "&.Mui-focused": {
                              " .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #eee",
                              },
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#eee",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={3} lg={2}>
                <div className="service-list-left-side">
                  <nav>
                    <List className="left-side-menu-show">
                      {categories.map((result, index) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            component="a"
                            onClick={handleOptionChange(result, index)}
                          >
                            {result.key == "beach_gear" && (
                              <img
                                style={{ marginRight: 5 }}
                                src={images.sidemenuIcon}
                                alt=""
                              />
                            )}
                            <ListItemText
                              className={
                                selectedOption.key == result.key
                                  ? "listing-text active"
                                  : ""
                              }
                              primary={
                                result.name +
                                (result.key != "beach_gear"
                                  ? " (" + result.count + ")"
                                  : "")
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </nav>
                </div>
              </Grid>
              {!isLoading ? (
                <Grid xs={12} sm={12} md={9} lg={10}>
                  {favoriteItems.length == 0 ? (
                    <p>No Data found</p>
                  ) : (
                    <ItemsList />
                  )}
                </Grid>
              ) : (
                <p>Loading...</p>
              )}
            </Grid>
          </Container>
        </FavoritesContext.Provider>
      </section>
    </>
  );
};

export default Favorites;
