import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh"
    },
    textContainer: {
        textAlign: "center"
    },
    icon: {
        fill: "#8f8f8f"
    },
    text: {
        fontSize: "18px",
        fontWeight: 500,
        color: "#8f8f8f"
    }
});

export default function NoResultsFound() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <Typography className={classes.text}>No Results Found</Typography>
            </Box>
        </Box>
    );
}