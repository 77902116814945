import { Skeleton, Grid } from "@mui/material";
const LoadingDeviceHistory = () => {
  return (
    <Grid sx={{ mt: 4 }} className="gray-border-bottom" container>
      <Grid xs={12} md={1} item>
        <Skeleton animation="wave" variant="rounded" width={45} height={45} />
      </Grid>
      <Grid xs={12} md={11} item>
        <Skeleton animation="wave" height={17} width="100%" />
        <Skeleton animation="wave" sx={{ mt: 1 }} height={13} width="70%" />
      </Grid>
    </Grid>
  );
};

export default LoadingDeviceHistory;
