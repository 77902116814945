import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .typeError("Name must be a valid value"),
  duration_type: yup
    .string()
    .required("Duration type is required")
    .typeError("Duration Type must be a valid value"),
  duration_in_months: yup
    .number()
    .integer()
    .typeError("Duration Type must be a valid value")
    .when("duration_type", {
      is: "repeating",
      then: yup.number().integer().required("Duration type is required"),
    }),
  amount_type: yup
    .string()
    .required("Amount type is required")
    .typeError("Amount type must be a valid value"),
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a valid value"),
});

export default ValidationSchema;
