const Messages = (data) => {
  const titleData = {
    title: "Guest and Host messages",
    description:
      "Keep in touch with your Host or guests before and during your trip.",
  };

  const optionsData = [
    {
      label: "Messages",
      description: "Never miss important messages from your Hosts or guests.",
      field: "messages",
      options: data["messages"],
    },
  ];

  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};
export default Messages;
