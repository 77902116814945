import { useContext } from "react";
import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";
import SelectInput from "../../../../Inputs/SelectInput";

const EntityType = () => {
  const { formik } = useContext(FormContext);

  const options = [
    { id: "ENTITY_TYPE_INDIVIDUAL", name: "Individual" },
    { id: "ENTITY_TYPE_PARTNERSHIP", name: "Partnership" },
    { id: "ENTITY_TYPE_GOVERNMENT", name: "Government" },
    { id: "ENTITY_TYPE_PRIVATE_FOUNDATION", name: "Private foundation" },
    { id: "ENTITY_TYPE_SIMPLE_TRUST", name: "Simple Trust" },
    { id: "ENTITY_TYPE_GRANTOR_TRUST", name: "Grantor Trust" },
    {
      id: "ENTITY_TYPE_INTERNATIONAL_ORGANIZATION",
      name: "International organization",
    },
    { id: "ENTITY_TYPE_COMPLEX_TRUST", name: "Complex trust" },
    { id: "ENTITY_TYPE_CENTRAL_BANK_OF_ISSUE", name: "Central Bank of Issue" },
    { id: "ENTITY_TYPE_CORPORTATION", name: "Corporation" },
    { id: "ENTITY_TYPE_ESTATE", name: "Estate" },
    {
      id: "ENTITY_TYPE_TAX_EXEMPT_ORGANIZATION",
      name: "Tax-exempt organization",
    },
  ];
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Entity type
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
          mt: 1,
        }}
      >
        <SelectInput
          customArr={{
            data: options,
            name: "name",
            id: "id",
          }}
          name="entity_type"
          value={formik.values.entity_type}
          onChange={(e) => {
            let value = e.target.value;
            formik.setFieldValue("entity_type", value);
          }}
          onBlur={formik.handleBlur}
          renderValue={(selected) => {
            if (
              (selected == undefined || selected == "") &&
              (formik.values.entity_type == undefined ||
                formik.values.entity_type == "")
            ) {
              return <em>Entity type</em>;
            }

            let optionData = options.find(
              (o) => o.id == formik.values.entity_type
            );
            return optionData ? optionData.name : "";
          }}
        />
      </FormControl>
      {formik.errors.entity_type && formik.touched.entity_type && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.entity_type}
        </FormHelperText>
      )}
    </Box>
  );
};

export default EntityType;
