import {
  FormControlLabel,
  Radio,
  Typography,
  useRadioGroup,
} from "@mui/material";

function FormRadioItem(props) {
  const { form } = props;
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) checked = radioGroup.value == form.index;

  return (
    <FormControlLabel
      {...props}
      checked={checked}
      value={form.index}
      control={<Radio />}
      label={
        <>
          <Typography sx={{ fontSize: 16, color: "black", fontWeight: "600" }}>
            {form.name}
          </Typography>
          <Typography
            sx={{ fontSize: 14, color: "black", fontWeight: "light" }}
          >
            {form.description}
          </Typography>
        </>
      }
      sx={{
        fontSize: 19,
        mx: "0px !important",
        px: "3px !important",
        py: 2,
        my: 0.7,
        border: !checked ? "solid 2px #ebebeb" : "solid 2px #000",
        borderRadius: 3,
        "& .MuiSvgIcon-root": {
          fontSize: 26,
          color: "black",
        },
        "& .MuiFormControlLabel-label": {
          pl: 1,
          lineHeight: "initial",
          fontWeight: "100",
        },
      }}
    />
  );
}

export default FormRadioItem;
