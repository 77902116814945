import { Box, Switch, Typography,  } from "@mui/material";
import AppHelper from "../../../../Helpers/AppHelper";
import TimeInput from "../../../Inputs/TimeInput";
import Grid from '@mui/material/Unstable_Grid2';
const OperationHours = (props) => {
  const { formik } = props;
  const totalWeeks = AppHelper.lists().weeks;
  return (
    <Box>
      {totalWeeks.map((name, index) => {
        return (
         
          <Grid key={index} container rowSpacing={{ xs: 1, sm: 1, md: 3 }} columnSpacing={{ xs: 1, sm: 1, md: 3 }} className="operation-padding">
            <Grid xs={6} sm={2} md={3} lg={3} className="day-start">
              <Box>
                <Typography>{name}</Typography>
              </Box>
            </Grid>
            <Grid xs={6} sm={2} md={1} lg={1}>
              <Box className="swith-end">            
                      <Switch
                         sx={{ 
                                '& .MuiSwitch-track':{
                                  opacity: '1',
                                },
                                '& .MuiSwitch-switchBase':{
                                  '&:hover':{
                                    background: 'transparent',
                                  },
                                  '&.Mui-checked':{
                                    color: '#ff4110',
                                    '&:hover' :{
                                      background: 'rgb(255 65 16 / 12%)',
                                    },
                                    '&+.MuiSwitch-track':{
                                      opacity: '0.2',
                                    }
                                  }
                                }
                              }}
                    checked={
                      formik.values.hours_of_operation[index].status == 1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue(`hours_of_operation.${index}.status`, 1)
                        formik.setFieldValue(`hours_of_operation.${index}.open`, '__:__ AM')
                        formik.setFieldValue(`hours_of_operation.${index}.close`, '__:__ PM')
                      } else {
                        formik.setFieldValue(`hours_of_operation.${index}.status`, 0)
                        formik.setFieldValue(`hours_of_operation.${index}.open`, '')
                        formik.setFieldValue(`hours_of_operation.${index}.close`, '')
                      }
                    }}
                  />
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4} lg={4} >
              <TimeInput
                id={"opentext" + index}
                placeholder="Opens At"
                name={`hours_of_operation.${index}.open`}
                value={formik.values.hours_of_operation[index].open}
                onChange={(e) => {
                  var opentext = e.target.value;
                  var opensplit = opentext.split(" ");

                  formik.setFieldValue(
                    `hours_of_operation.${index}.open`,
                    opensplit[0] +
                      " " +
                      (opensplit[1] == "PM" || opensplit[1] == "pM"
                        ? "PM"
                        : "AM")
                  );
                  formik.setFieldValue(
                    `open_close_checkbox.${index}.open`,
                    opensplit[1] == "PM" || opensplit[1] == "pM" ? true : false
                  );
                }}
                onBlur={(e) => {
                  formik.handleChange(e);
                  document.getElementById("openampmbtn" + index).style.display =
                    "none";
                }}
                onClick={(e) => {
                  if (e.target.value == "__:__ AM")
                    e.target.setSelectionRange(0, 0);
                  document.getElementById("openampmbtn" + index).style.display =
                    "";
                }}
                style={{
                  width: "100%",
                  height: "45px",
                  padding: "10px",
                  borderRadius: "50px",
                  border: `1px solid #${
                    Object.keys(formik.errors).length > 0 &&
                    formik.errors?.hours_of_operation &&
                    typeof formik.errors?.hours_of_operation[index] !==
                      "undefined" &&
                    formik.errors.hours_of_operation[index].open
                      ? "d83e3f"
                      : "EEEEEE"
                  }`,
                }}
                disabled={
                  formik.values.hours_of_operation[index].status == 0
                    ? true
                    : false
                }
                idSwitch={"openampmbtn" + index}
                checkedSwitch={formik.values.open_close_checkbox[index].open}
                onChangeSwitch={(e) => {
                  var opentext = formik.values.hours_of_operation[index].open;
                  var opensplit = opentext.split(" ");

                  formik.setFieldValue(
                    `hours_of_operation.${index}.open`,
                    opensplit[0] + " " + (e.target.checked ? "PM" : "AM")
                  );
                  formik.setFieldValue(
                    `open_close_checkbox.${index}.open`,
                    e.target.checked ? true : false
                  );
                }}
              />
              {Object.keys(formik.errors).length > 0 &&
                formik.errors?.hours_of_operation &&
                typeof formik.errors?.hours_of_operation[index] !==
                  "undefined" && (
                  <p
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                    style={{
                      paddingLeft: 15,
                      color: "red",
                    }}
                  >
                    {formik.errors.hours_of_operation[index].open}
                  </p>
                )}
            </Grid>
            <Grid xs={12} sm={4} md={4} lg={4}>
              <TimeInput
                id={"closetext" + index}
                placeholder="Closes At"
                name={`hours_of_operation.${index}.close`}
                value={formik.values.hours_of_operation[index].close}
                onChange={(e) => {
                  var closetext = e.target.value;
                  var closesplit = closetext.split(" ");

                  formik.setFieldValue(
                    `hours_of_operation.${index}.close`,
                    closesplit[0] +
                      " " +
                      (closesplit[1] == "PM" || closesplit[1] == "pM"
                        ? "PM"
                        : "AM")
                  );
                  formik.setFieldValue(
                    `open_close_checkbox.${index}.close`,
                    closesplit[1] == "PM" || closesplit[1] == "pM"
                      ? true
                      : false
                  );
                }}
                onBlur={(e) => {
                  formik.handleChange(e);
                  document.getElementById(
                    "closeampmbtn" + index
                  ).style.display = "none";
                }}
                onClick={(e) => {
                  if (e.target.value == "__:__ PM")
                    e.target.setSelectionRange(0, 0);
                  document.getElementById(
                    "closeampmbtn" + index
                  ).style.display = "";
                }}
                style={{
                  width: "100%",
                  height: "45px",
                  padding: "10px",
                  borderRadius: "50px",
                  border: `1px solid #${
                    Object.keys(formik.errors).length > 0 &&
                    formik.errors?.hours_of_operation &&
                    typeof formik.errors?.hours_of_operation[index] !==
                      "undefined" &&
                    formik.errors.hours_of_operation[index].close
                      ? "d83e3f"
                      : "EEEEEE"
                  }`,
                }}
                disabled={
                  formik.values.hours_of_operation[index].status == 0
                    ? true
                    : false
                }
                idSwitch={"closeampmbtn" + index}
                checkedSwitch={formik.values.open_close_checkbox[index].close}
                onChangeSwitch={(e) => {
                  var closetext = formik.values.hours_of_operation[index].close;
                  var closesplit = closetext.split(" ");

                  formik.setFieldValue(
                    `hours_of_operation.${index}.close`,
                    closesplit[0] + " " + (e.target.checked ? "PM" : "AM")
                  );
                  formik.setFieldValue(
                    `open_close_checkbox.${index}.close`,
                    e.target.checked ? true : false
                  );
                }}
              />
              {Object.keys(formik.errors).length > 0 &&
                formik.errors?.hours_of_operation &&
                typeof formik.errors?.hours_of_operation[index] !==
                  "undefined" && (
                  <p
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                    style={{
                      paddingLeft: 15,
                      color: "red",
                    }}
                  >
                    {formik.errors.hours_of_operation[index].close}
                  </p>
                )}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default OperationHours;
