import { ButtonBase, Typography, Grid } from "@mui/material";
import { useState } from "react";
import MakeToast from "../../Helpers/MakeToast";
import { GoogleLogin } from "react-google-login";
import { LoginAndSecurityService } from "../../Services/LoginAndSecurityService";
import { useDispatch, useSelector } from "react-redux";
const ConnectOption = ({ title, name }) => {
  const [loader, setLoader] = useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducers.data);
  let connected = user.auth_type == name;
  const responseGoogle = (response) => {
    if (response?.profileObj) {
      setLoader(true);
      LoginAndSecurityService.connectSocialAccount(response?.profileObj)
        .then((res) => {
          setLoader(false);
          MakeToast("success", res);
          dispatch({
            type: "LOGIN_START",
            payload: res?.data,
          });
          connected = user.auth_type == name;
        })
        .catch((error) => {
          setLoader(false);
          MakeToast("error", error);
        });
    }
  };

  const facebookLogIn = async () => {
    const { authResponse } = await new Promise(window.FB.login);
    if (authResponse) {
      window.FB.api(
        "/me",
        { fields: "id,email,name,birthday" },
        function (response) {
          if (response) {
            const userRes = {
              ...authResponse,
              ...response,
            };
            setLoader(true);
            LoginAndSecurityService.connectSocialAccount(userRes)
              .then((response) => {
                setLoader(false);
                MakeToast("success", response);
                dispatch({
                  type: "LOGIN_START",
                  payload: response?.data,
                });
                connected = user.auth_type == name;
              })
              .catch((error) => {
                window.FB.logout();
                setLoader(false);
              });
          }
        }
      );
    }
    if (!authResponse) return;
  };

  const disconnect = () => {
    if (
      window.confirm("Do you really want to disconnect this social account?")
    ) {
      setLoader(true);
      LoginAndSecurityService.disconnectSocialAccount()
        .then((response) => {
          setLoader(false);
          //Set user auth_type to personal
          dispatch({
            type: "LOGIN_START",
            payload: response?.data,
          });
          connected = user.auth_type == name;
          MakeToast("success", response);
        })
        .catch((error) => {
          setLoader(false);
          MakeToast("error", error);
        });
    }
  };
  return (
    <Grid sx={{ mt: 4 }} className="gray-border-bottom" container>
      <Grid xs={10} item>
        <Typography sx={{ fontWeight: "500" }} component="h4">
          {title}
        </Typography>
        <Typography sx={{ fontWeight: "400" }} component="h4">
          {connected ? "Connected" : "Not connected"}
        </Typography>
      </Grid>
      <Grid xs={2} sx={{ textAlign: "end" }} item>
        {connected ? (
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1 }}
            className="black-underline-link"
            variant="text"
            onClick={disconnect}
            disabled={loader}
          >
            Disconnect
          </ButtonBase>
        ) : name == "google" ? (
          <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
              <ButtonBase
                sx={{ fontSize: 16, marginTop: 1 }}
                className="black-underline-link"
                variant="text"
                onClick={renderProps.onClick}
                disabled={loader}
              >
                Connect
              </ButtonBase>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
            isSignedIn={false}
          />
        ) : (
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1 }}
            className="black-underline-link"
            variant="text"
            onClick={facebookLogIn}
            disabled={loader}
          >
            Connect
          </ButtonBase>
        )}
      </Grid>
    </Grid>
  );
};

export default ConnectOption;
