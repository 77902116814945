import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';
import parse from 'html-react-parser'

const RadioInput = ({ customObj, customArr, name, value, onChange, onBlur, defaultValue, sx, radiohidden, dayOption, dayOptionStatus }) => {
  const BpradiocheckIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 24,
    height: 24,

    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedradioIcon = styled(BpradiocheckIcon)({
    backgroundColor: '#fff',
    color: '#fff',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#FF4110,#FF4110 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  });

  function BpRadio(props) {
    return (
      !props.hideRadio
      ?
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedradioIcon />}
        icon={<BpradiocheckIcon />}
        {...props}
      />
      :
      ''
    );
  }

  return (
    <>
      <RadioGroup
        row
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
      >
        {
          (customObj != '' && customObj != undefined) &&
          Object.keys(customObj).map((i) => (
            <FormControlLabel
              control={<BpRadio />}
              label={customObj[i]}
              value={i}
              checked={value == i}
              sx={sx}
              style={{ display: (radiohidden && value != i ? 'none' : '') }}
            />
          ))
        }

        {
          (customArr != '' && customArr != undefined) &&
          customArr.data.map((result, index) => {
            
            var hideRadio, disableRadio = false;
            if(dayOption!=undefined){
              hideRadio = true;
              disableRadio = false;

              if(dayOptionStatus==undefined || dayOptionStatus){
                if(dayOption==0){
                  hideRadio = true;
                }else if(result && dayOption==1 && (result.from==null || result.from==1)){
                  hideRadio = false;
                }else{
                  disableRadio = true;
                }
              }
            }

            return (
              <FormControlLabel checked={value == result[customArr.id]} disabled={disableRadio} value={result[customArr.id]} control={<BpRadio hideRadio={hideRadio}/>} label={parse(result[customArr.name])} sx={sx} />
            )
          }
          )
        }
      </RadioGroup>
    </>
  )
}

export default RadioInput;