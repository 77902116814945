import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../Context/PaymentsContext";

const Name = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Account holder name
      </Typography>
      <TextField
        placeholder="Account holder name"
        name="holder_name"
        value={formik.values.holder_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.holder_name && formik.touched.holder_name && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.holder_name}
        </FormHelperText>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter the account holder's name exactly as it appears on bank
        statements.
      </Typography>
    </Box>
  );
};

export default Name;
