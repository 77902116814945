import { APIService } from "./APIService";

export const CartService = {
    list,
    add,
    update,
    del,
    deleteByUser
};

function list() {
    return APIService.get('api/cart');
}

function add(data) {
    return APIService.post('api/cart', data);
}

function update(data, id) {
    return APIService.put('api/cart/'+id, data);
}

function del(id) {
    return APIService.del('api/cart/'+id);
}

function deleteByUser() {
    return APIService.del('api/cart/delete/user');
}
