import { APIService } from "./APIService";

export const HostService = {
    hostProfile,
    hostProfileUpdate,
    checkHostBooking
  };

  function hostProfile() {
    return APIService.get("api/host-profile");
  }
  function hostProfileUpdate(data) {
    return APIService.post("api/host-profile-update", data);
  }
  function checkHostBooking(data) {
    return APIService.post("api/check-host-booking", data);
  }