import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { Link as RouterLink } from "react-router-dom";
import {
    Box,
    Badge,
    Typography,
} from '@mui/material';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import BeachGear from "../Home/BeachGear";
import ApiLoader from "../Common/ApiLoader";

const imagesArr = [
    {
        url: '',
        title: 'Add info',
        width: '206px',
        height: '168px',
        backgroundPosition: 'cover',
    }
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: 100,
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ServiceContent = ({ images, serveList, loader, pageType, openCartPopup }) => {
    const [beachGears, setBeachgears] = useState([]);
    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

    React.useEffect(() => {
        updateBGData();
    }, [serveList]);

    const updateBGData = () => {
        setBeachgears(serveList);
    }

    return (
        <>
            <Grid xs={12} sm={12} md={12} lg={12}>
                {loader ?
                    <ApiLoader /> :
                    <div className="grid-5">
                        {beachGears?.length > 0 && beachGears.map(gData =>
                            gData.title
                                ?
                                <>
                                    {
                                        pageType == 'hostprofile'
                                            ?
                                            <BeachGear beachgear={gData} pagetype={(isLoggedIn && user.role_id == "3") ? "nonavigation" : ""} openCartPopup={() => { openCartPopup(gData) }} />
                                            :
                                            <BeachGear beachgear={gData} pagetype={pageType} enableAction={true} />
                                    }
                                </>
                                :
                                <div className="grid-1">
                                    <Box className="add-info-card mt-3 " sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', minWidth: 206, }}>
                                        {imagesArr.map((image) => (
                                            <RouterLink to={"/service/edit/" + gData.id}>
                                                <ImageButton
                                                    className="image-bg-add-info btn-primary"
                                                    disableRipple
                                                    key={image.title}
                                                    style={{
                                                        width: image.width,
                                                    }}
                                                >
                                                    <div className="featuring-action-block">
                                                        <Badge className="featuring-badge">
                                                            <img src={images.editIcon} className="edit-bg" alt="" />
                                                        </Badge>
                                                    </div>
                                                    <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
                                                    <Image>
                                                        <Typography
                                                            className="add-info-btn"
                                                            component="span"
                                                            variant="subtitle1"
                                                            color="inherit"
                                                            sx={{
                                                                position: 'relative',

                                                            }}
                                                        >
                                                            {image.title}
                                                        </Typography>
                                                    </Image>
                                                </ImageButton>
                                            </RouterLink>
                                        ))}
                                        <Typography component="h2" className="add-info-card-title">
                                            {gData?.title != '' ? gData?.title : gData?.beachGearName}
                                        </Typography>
                                    </Box>
                                </div>
                        )}
                    </div>}
            </Grid>
        </>
    );
};

export default ServiceContent;
