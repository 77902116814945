import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import {
    Container,
    Box,  
    Popover,
    Typography,
    Button,
    Popper,
    Fade,
    TextField,
    FormControl,
    ListItem,
    ListItemIcon,
    List,
    ListItemText,
    ListItemButton,

}from '@mui/material';
import dayjs from 'dayjs';
import images from "../../assets/js/images";

// ---- full calendar start----//
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'


const events = [
    { title: 'Meeting', start: new Date() }
  ]
  
  // ---- full calendar end----//
const FullCalendarPage = () => {
    function renderEventContent(eventInfo) {
        return (
          <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
          </>
        )
      }
    const [value, setValue] = React.useState(dayjs('2022-04-07'));

// ---popover -----//
// const [anchorEl, setAnchorEl] = React.useState(null);

// const handleClick = (event) => {
//   setAnchorEl(event.currentTarget);
// };

// const handleClose = () => {
//   setAnchorEl(null);
// };

// const open = Boolean(anchorEl);
// const id = open ? 'simple-popover' : undefined;
const [open, setOpen] = React.useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  setOpen((previousOpen) => !previousOpen);
};

const canBeOpen = open && Boolean(anchorEl);
const id = canBeOpen ? 'transition-popper' : undefined;
    return (
        <>
           <section className="full-calendar inner-section-padding">
            <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Box className='top-heading'>
                                <div className="main-heading-block">
                                    <h2 className="main-heading" sx={{
                                        marginBottom: '0 !important',
                                        marginTop: '0 !important',
                                    }}>Calendar
                                    </h2>
                                </div>
                            </Box>
                            <div className="service-list-left-side ">
                <nav>
                    <List className="left-side-menu-show edit-profile-menu"
                        sx={{
                            padding: '0',
                        }}>
                             <ListItem disablePadding sx={{ 
                                fontSize:'16px'
                              }}>
                                <ListItemButton component="a" 
                                   className='service-listing-toggle ServiceCategories listing-text'>
                                    <img src={images.sidemenuIcon} alt="" sx={{ marginRight:'10px !important' }}
                                    />
                                     All Categories
                                </ListItemButton>
                            </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText className="listing-text active" primary="Kayaking (4)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText className="listing-text" primary="Surfing (4)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText className="listing-text" primary="Wake surfing (2)" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </div>
                            </Grid>      
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                  <Box >
                                        <div className='full-calender'>
                                            {/* <h1>Demo App</h1> */}
                                            <FullCalendar
                                            plugins={[dayGridPlugin]}
                                            initialView='dayGridMonth'
                                            weekends={true}
                                            events={events}
                                            eventContent={renderEventContent}
                                            />
                                        </div>
                                        
                                  </Box>
                            </Grid>    
                           <button aria-describedby={id} type="button" onClick={handleClick}>
                                Toggle Popper
                            </button>
                            <Box>
                            <Popper className='date-info-popover' id={id} open={open} anchorEl={anchorEl} transition>
                                    {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Box className='full-calendar-detail'>
                                            <Box sx={{ 
                                                display: 'flex',
                                                justifyContent:'space-between',
                                                alignItems: 'center',
                                                marginBottom:'12px'
                                            }}>
                                                <Typography variant="h6" component="h6">
                                                    Kayak <span className="tertiary-text">#18652</span>
                                                </Typography>  
                                                <Box className="close-btn-common">
                                                <img src={images.closeIcon} alt="" />
                                               </Box>    
                                             </Box>
                                             <List sx={{ p: 0, display: 'flex', flexWrap: 'wrap', width: '100%' }} className="feature-listing listing-visible">
                                            <ListItem>
                                                <ListItemIcon className="feature-listing-icon">
                                                    <img src={images.ColortimeIcon} alt="time" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Pending" className="feature-list-name "
                                                />
                                            </ListItem>
                                                <ListItem>
                                                <ListItemIcon className="feature-listing-icon">
                                                    <img src={images.deliveryIcon} alt="delivery"  />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Delivery" className="feature-list-name "
                                                />
                                            </ListItem>
                                    <p className="pricing tertiary-text">
                                    Paid 
                                         <span className='secondary-text'> $20</span>
                                     </p>
                                          
                                    </List>

                                        <Box className="border-radius-md search-result-box-2"
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        '@media (max-width:566px)': {
                                                            width: '100% !important',
                                                        },
                                                        '@media (max-width:769px)': {
                                                            width: '100%',
                                                        },
                                                    }}>
                                                    <Box className="border-right-radius" sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                                            Beginning date
                                                        </Typography>
                                                        <FormControl className="input-style small-radius border-0 border-right-radius"
                                                        >
                                                            <TextField placeholder="08-23-2023" className="outline-input textfield-rouded-md input-text-style small-radius"
                                                                sx={{
                                                                    borderRadius: '18px !important',
                                                                    '.MuiInputBase-input': {
                                                                        fontSize: '18px',
                                                                        fontWeight: '400',
                                                                    },
                                                                    '.MuiInputBase-root': {
                                                                        '.MuiOutlinedInput-notchedOutline': {
                                                                            borderColor: '#eee',
                                                                            border: '0',
                                                                        },
                                                                        '&.Mui-focused': {
                                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                                border: '1px solid #eee',
                                                                                border: '0',
                                                                            }
                                                                        },
                                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                            borderColor: '#eee',
                                                                            border: '0',
                                                                        }
                                                                    },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginLeft: '12px',
                                                    }}>
                                                        <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                                            End date
                                                        </Typography>
                                                        <FormControl className="input-style border-0 border-left-radius"
                                                        >
                                                            <TextField placeholder="08-23-2023" className="outline-input textfield-rouded-md input-text-style small-radius"
                                                                sx={{
                                                                    borderRadius: '18px !important',
                                                                    '.MuiInputBase-input': {
                                                                        fontSize: '18px',
                                                                        fontWeight: '400',
                                                                    },
                                                                    '.MuiInputBase-root': {
                                                                        '.MuiOutlinedInput-notchedOutline': {
                                                                            borderColor: '#eee',
                                                                            border: '0',
                                                                        },
                                                                        '&.Mui-focused': {
                                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                                border: '1px solid #eee',
                                                                                border: '0',
                                                                            }
                                                                        },
                                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                            borderColor: '#eee',
                                                                            border: '0',
                                                                        }
                                                                    },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                        </Box>  
                                        <Box className='time-field'>
                                                <FormControl sx={{ width: '50%', 
                                            marginBottom: '10px',
                                            '@media (max-width:769px)': { 
                                                marginBottom: '15px',
                                                '@media (max-width:566px)': {
                                                    marginBottom: '10px',  
                                                }
                                            },
                                            '@media (max-width:566px)': {
                                                width: '100% !important',
                                            },
                                            }} className="input-style">
                                                        <TextField placeholder="Start Time" className="outline-input textfield-rouded-md  input-text-style"
                                                            name="name"
                                                            sx={{

                                                                '.MuiInputBase-input': {
                                                                    paddingLeft: '20px',
                                                                    height: '40px',
                                                                },

                                                                '.MuiInputBase-root': {
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#eee',
                                                                    },
                                                                    '&.Mui-focused': {
                                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                                            border: '1px solid #eee',
                                                                        }
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#eee',
                                                                    }
                                                                },
                                                            }}
                                                            />
                                                </FormControl>
                                            
                                                <FormControl sx={{ width: '50%',
                                                marginBottom: '10px',

                                                    '@media (max-width:769px)': { 
                                                        marginBottom: '15px',
                                                    },
                                                    '@media (max-width:566px)': {
                                                        width: '100% !important',
                                                    },
                                                    
                                            }} className="input-style">
                                                        <TextField placeholder="End Time" className="outline-input textfield-rouded-md  input-text-style"
                                                            name="name"
                                                            sx={{

                                                                '.MuiInputBase-input': {
                                                                    paddingLeft: '20px',
                                                                    height: '40px',
                                                                },

                                                                '.MuiInputBase-root': {
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#eee',
                                                                    },
                                                                    '&.Mui-focused': {
                                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                                            border: '1px solid #eee',
                                                                        }
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#eee',
                                                                    }
                                                                },
                                                                '@media (max-width:566px)': {
                                                                    width: '100% !important',
                                                                },
                                                            }}
                                                            />
                                                </FormControl>
                                        </Box>
                                        <Typography variant="h6" component="h6" className='secondary-text bottom-space'>
                                        <img src={images.profileIcon} alt="" className='right-space' />
                                            James Jackson 
                                        </Typography> 

                                        <Box className='calender-btn'>
                                        <Button
                                            size="small"
                                            className="btn-primary border-radius-md rentor-btn"
                                        >
                                          Contact rentors

                                        </Button>  
                                        <Button
                                            size="small"
                                            className=" border-radius-md outline-btn view-more-btn"
                                        >
                                        View more

                                        </Button>  
                                        </Box>

                                         </Box> 
                  
                                    </Fade>
                                    )}
                            </Popper>
                             </Box>
                     </Grid>
            </Container>
           </section>
        </>
    )
    

}
// a custom render function
function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

export default FullCalendarPage;
