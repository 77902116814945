import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Button, Link, CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { PersonalInformationService } from "../../../Services/PersonalInformationService";
import MakeToast from "../../../Helpers/MakeToast";
import parse from "html-react-parser";
const FormControlEdit = (
  OriginalComponent,
  { informationValidationSchema, fieldName, label, description }
) => {
  const HocComponent = (props) => {
    const { hideSaveButton = false } = props;
    const [editEnable, setEditEnable] = useState(false);
    const [text, setText] = useState(
      typeof props.initialValue == "object"
        ? props.initialValue.label || "No Provided"
        : props.initialValue || "No Provided"
    );
    const [formInitialValues, setFormInitialValues] = useState(
      typeof props.initialValue == "object"
        ? props.initialValue
        : { [fieldName]: text == "No Provided" ? "" : text }
    );
    const formik = useFormik({
      validationSchema: informationValidationSchema,
      initialValues: formInitialValues,
      enableReinitialize: true,
      onSubmit: (values) => formSubmit(values),
    });
    const dispatch = useDispatch();
    const updateUserData = (response) => {
      dispatch({
        type: "UPDATE_USER_DATA",
        payload: response?.data,
      });
      if (typeof response.data.data[fieldName] == "object") {
        setText(response.data.data[fieldName].label);
        setFormInitialValues(response.data.data[fieldName]);
      } else {
        setText(response.data.data[fieldName]);
        setFormInitialValues({
          [fieldName]: response.data.data[fieldName],
        });
      }

      setEditEnable(false);
    };
    const formSubmit = (values) => {
      PersonalInformationService.updateInfo({
        ...values,
        field_name: fieldName,
      })
        .then((response) => {
          formik.setSubmitting(false);
          updateUserData(response);
          MakeToast("success", "Personal Information updated successfully.");
        })
        .catch((error) => {
          formik.setSubmitting(false);
          MakeToast("error", error);
        });
    };

    return (
      <Grid container className="modal-header">
        <Grid item xs={10}>
          <h3 className="main-heading small-heading">{label}</h3>
          <h5 className="text-muted" style={{ marginBottom: "0px" }}>
            {editEnable ? description : parse(text)}
          </h5>
        </Grid>
        <Grid item xs={2} align="right">
          <Link
            style={{ cursor: "pointer", color: "black", fontWeight: 500 }}
            className="text-underline"
            onClick={() => {
              setEditEnable(!editEnable);
              formik.setValues(formInitialValues);
            }}
          >
            {editEnable ? "Cancel" : "Edit"}
          </Link>
        </Grid>
        {editEnable && (
          <Grid item xs={12}>
            <form
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
              style={{ marginTop: "15px" }}
            >
              <OriginalComponent
                formInitialValues={formInitialValues}
                updateUserData={updateUserData}
                formik={formik}
                {...props}
              />
              {!hideSaveButton && (
                <Button
                  size="small"
                  type="button"
                  disableRipple
                  disableElevation
                  className="btn-primary submit-btn rounded-button"
                  style={{ marginTop: "15px" }}
                  disabled={formik.isSubmitting}
                  onClick={(e) => formik.handleSubmit()}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    "Save"
                  )}
                </Button>
              )}
            </form>
          </Grid>
        )}
      </Grid>
    );
  };
  return HocComponent;
};

export default FormControlEdit;
