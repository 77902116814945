import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import { FormContext, TaxFormContext } from "../../../../../Context/TaxContext";
import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import ValidationSchema from "./ValidationSchema";
import InitialValues from "./InitialValues";
import FormTitle from "../../../FormTitle";
import Name from "./Name";
import CountryCitizenship from "./CountryCitizenship";
import IDNumber from "./IDNumber";
import ForeignIDNumber from "./ForeignIDNumber";
import PermanentResidenceAddress from "./PermanentResidenceAddress";
import MailAddress from "./MailAddress";
import SignDate from "./SignDate";
import { TaxesService } from "../../../../../Services/TaxesServices";
import MakeToast from "../../../../../Helpers/MakeToast";
import { useHistory } from "react-router-dom";

const W8BEN = () => {
  const { setPageIndex } = useContext(TaxFormContext);
  const navigate = useHistory();

  const handleSubmit = async (values) => {
    await TaxesService.storeForm({
      form_name: "w8ben",
      ...values,
    })
      .then((response) => {
        MakeToast("success", response);
        navigate.push("/account-settings/taxes");
      })
      .catch((error) => MakeToast("error", error));
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    (() => formik.validateForm())();
  }, []);
  return (
    <FormContext.Provider
      value={{
        formik: formik,
      }}
    >
      <Container
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
          px: "20% !important",
          my: 9,
          py: 6,
          overflowY: "auto",
        }}
      >
        <FormTitle />
        <Typography sx={{ fontWeight: "500", mt: 5, fontSize: 20 }}>
          Substitute Form W-8BEN
        </Typography>
        <Name />
        <CountryCitizenship />
        <IDNumber />
        <ForeignIDNumber />
        <Box sx={{ borderBottom: "1px solid #ebebeb", pb: 6 }}>
          <PermanentResidenceAddress />
          <FormControlLabel
            required
            sx={{ fontSize: 20, fontWeight: "lighter", mt: 2 }}
            control={
              <Checkbox
                name="same_mail_address"
                checked={formik.values.same_mail_address}
                onChange={formik.handleChange}
                sx={{
                  "&.Mui-checked": {
                    color: "black",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                }}
              />
            }
            label="My mailing address is the same as my permanent residence"
          />
          {!formik.values.same_mail_address && <MailAddress />}
        </Box>
        <SignDate />
      </Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "75px",
          borderTop: "solid 1px #ebebeb",
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "white",
          px: 3,
        }}
      >
        <Link
          sx={{ mt: "0px !important", fontSize: 17, fontWeight: "600" }}
          className="link-update"
          onClick={() => setPageIndex(0)}
        >
          Back
        </Link>
        <Button
          type="button"
          sx={{
            border: "1px solid black !important",
            backgroundColor: "black !important",
            borderRadius: 3,
            my: 1.5,
            fontSize: 18,
            fontWeight: "500",
          }}
          className="btn-primary submit-btn "
          onClick={(e) => formik.handleSubmit()}
        >
          {formik.isSubmitting ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </FormContext.Provider>
  );
};

export default W8BEN;
