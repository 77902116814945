import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../Context/PaymentsContext";

const RoutingNumber = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500" }} component="h4">
        Routing number
      </Typography>
      <TextField
        placeholder="Routing number"
        name="routing_number"
        value={formik.values.routing_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          my: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.routing_number && formik.touched.routing_number && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.routing_number}
        </FormHelperText>
      )}
      <Typography sx={{ color: "#9b9a9a" }}>
        Enter the routing number. It is located on the bottom left corner of a
        check or within account details from the bank.
      </Typography>
    </Box>
  );
};

export default RoutingNumber;
