import { Button } from "@mui/material";
import CreateModal from "./Modal";
import { ReviewsContext } from "../../../Context/ReviewsContext";
import { useEffect, useState, useContext } from "react";
import { ReviewsService } from "../../../Services/ReviewsService";

const CreateButton = () => {
  const [createModal, setCreateModal] = useState(false);
  const { bookings, getBookings } = useContext(ReviewsContext);
  useEffect(() => {
    getBookings();
  }, []);
  return bookings.length == 0 ? (
    <Button sx={{ p: 1.3 }} className="btn-secondary rounded-button" disabled>
      Add a review
    </Button>
  ) : (
    <>
      <Button
        onClick={() => setCreateModal(true)}
        sx={{ p: 1.3 }}
        className="btn-secondary rounded-button"
      >
        Add a review
      </Button>
      <CreateModal open={createModal} onClose={() => setCreateModal(false)} />
    </>
  );
};

export default CreateButton;
