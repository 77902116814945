import { ButtonBase, Chip, Typography, Grid } from "@mui/material";
import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AuthHelper from "../../Helpers/AuthHelper";
import { useDispatch } from "react-redux";
import MakeToast from "../../Helpers/MakeToast";
import { AuthenticationService } from "../../Services/AuthenticationService";
import { useState } from "react";
import { LoginAndSecurityService } from "../../Services/LoginAndSecurityService";
const DeviceItem = ({
  token,
  os,
  browser,
  location,
  date,
  is_mobile: isMobile,
}) => {
  const dispatch = useDispatch();
  const [isLogOut, setIsLogOut] = useState(false);
  const isCurrent = AuthHelper.getToken() == token;
  const handleClick = () => {
    if (isCurrent) {
      handleLogout();
      return;
    }
    LoginAndSecurityService.removeDeviceHistory({ token: token })
      .then((response) => {
        setIsLogOut(true);
        MakeToast("success", "Session removed successfully");
      })
      .catch((error) => {
        MakeToast("error", error);
      });
  };
  const handleLogout = async () => {
    try {
      await AuthenticationService.removeDeviceHistory({
        token: AuthHelper.getToken(),
      });
    } catch (err) {}
    dispatch({
      type: "LOGOUT",
      payload: [],
    });
    MakeToast("success", "Logged out successfully");
  };
  return (
    <Grid sx={{ mt: 4 }} className="gray-border-bottom" container>
      <Grid xs={12} md={1} item>
        {isMobile ? (
          <PhoneIphoneIcon sx={{ fontSize: 45 }} />
        ) : (
          <LaptopIcon sx={{ fontSize: 45 }} />
        )}
      </Grid>
      <Grid xs={12} md={9} item>
        <Typography sx={{ display: "flex" }} component="h4">
          <Typography sx={{ mr: 1 }} fontWeight="500">
            {os},
          </Typography>
          {browser}
        </Typography>
        {isCurrent && (
          <Chip
            sx={{ fontSize: 10, height: 25, fontWeight: "500", mb: 1 }}
            label="CURRENT SESSION"
          />
        )}
        <Typography sx={{ fontWeight: "400" }}>
          {location} · {date}
        </Typography>
      </Grid>
      <Grid xs={12} md={2} sx={{ textAlign: "end" }} item>
        {isLogOut ? (
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1, opacity: 0.7 }}
            variant="text"
            disabled
          >
            Logged out
          </ButtonBase>
        ) : (
          <ButtonBase
            sx={{ fontSize: 16, marginTop: 1 }}
            className="black-underline-link"
            variant="text"
            onClick={handleClick}
          >
            Log out device
          </ButtonBase>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceItem;
