import React from "react";
import {
    Box,
    Button,
    Typography,
    FormControl,
    Input,
    InputAdornment,
    FormGroup,
    FormHelperText,
    CircularProgress,
    TextField,
    Link,
    FormControlLabel
} from '@mui/material';
import { Formik, useFormikContext } from "formik";
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser'
import images from "../../assets/js/images";
import ModalPopup from "../../Components/Modal/ModalPopup";
import CheckboxInput from "../../Components/Inputs/CheckboxInput"
import { AuthenticationService } from "../../Services/AuthenticationService";
import { SettingsService } from "../../Services/SettingsService";
import SingleDatePicker from "../../Components/Inputs/SingleDatePicker";

const Register = ({ isOpen, handleClose, setOpenRegister, setOtpOpen, setMobileNo, setType, type }) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [isForcePickerOpen, setIsOpen] = React.useState(false);
    const [openChild, setOpenChild] = React.useState(false);
    var today = new Date();
    var maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    var formattedMaxDate = (maxDate.getMonth() + 1) + '/' + maxDate.getDate() + '/' + maxDate.getFullYear();

    const [date, setDate] = React.useState(formattedMaxDate);
    const DobUpdates = () => {
        const { setFieldValue, values } = useFormikContext();
        React.useEffect(() => {
            setFieldValue('dob', date);
        }, [date]);
        return null;
    };

    var pickerinterval;
    const dpStyle = () => {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiDialogActions-root').length) document.getElementsByClassName('MuiDialogActions-root')[0].style.display = "none";
            if (document.getElementsByClassName('MuiPickersToolbar-root').length) document.getElementsByClassName('MuiPickersToolbar-root')[0].style.display = "none";
            if (document.getElementsByClassName('MuiPickersArrowSwitcher-spacer').length) document.getElementsByClassName('MuiPickersArrowSwitcher-spacer')[0].style.width = "0";
            if (document.getElementsByClassName('MuiPickersPopper-root').length) document.getElementsByClassName('MuiPickersPopper-root')[0].classList.add("datepicker-wrapper");

            var switchicon = document.getElementsByClassName('MuiPickersCalendarHeader-switchViewIcon')[0];
            switchicon.style.fontSize = "20px";
            switchicon.style.fontWeight = "bold";

            var arrbtn = document.getElementsByClassName('MuiPickersArrowSwitcher-button');
            for (var i = 0; i < arrbtn.length; i++) {
                arrbtn[i].style.fontSize = "20px";
                arrbtn[i].style.fontWeight = "bold";
            }

            pickerinterval = setInterval(function () {
                var monthyear = document.getElementsByClassName('MuiPickersCalendarHeader-label')[0].textContent;
                if (monthyear != '') {
                    var monthyearsplit = monthyear.split(' ');
                    if (monthyearsplit[0] != '' && monthyearsplit[1] != '' && monthyearsplit[0] != undefined && monthyearsplit[1] != undefined) {
                        if (document.getElementsByClassName('monthsection').length) document.getElementsByClassName('monthsection')[0].remove();
                        document.getElementsByClassName('MuiPickersArrowSwitcher-spacer')[0].insertAdjacentHTML('afterend', '<div class="monthsection" style="padding:8px;margin:0 5px">' + monthyearsplit[0] + '</div>');
                        document.getElementsByClassName('MuiPickersCalendarHeader-label')[0].textContent = monthyearsplit[1];
                        clearInterval(pickerinterval);
                    }
                }
            }, 50);
        }, 50);
    }

    let registerValidationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().required('Email is required').email('Email must be a valid email'),
        mobile_no: yup.string().required('Mobile number is required').matches(phoneRegExp, 'Mobile number is not valid'),
        dob: yup.date()
            .nullable()
            .required('Date of Birth is required').typeError('Invalid date format')
            .test('age', 'You must be at least 18 years old to register', (value) => {
                const now = new Date();
                const birthDate = new Date(value);
                const diff = now.getTime() - birthDate.getTime();
                const age = diff / (1000 * 60 * 60 * 24 * 365.25);
                return age >= 18;
            }),
        privacy: yup.boolean().oneOf([true], 'Please check privacy policy')
    });

    const useFormik = React.useRef();

    const [openTC, setOpenTC] = React.useState(false);
    const [termsAndConditions, setTermsAndConditions] = React.useState('');
    const getTermsAndConditions = () => {
        SettingsService.termsandconditions('renter').then(res => {
            setTermsAndConditions(res.data.data.result);
        }).catch(error => {
        });
    };

    React.useEffect(() => {
        getTermsAndConditions();
    }, []);

    return (
        <>
            <SingleDatePicker
                selector="#date-picker-dob"
                date={date}
                setDate={setDate}
            />
            {!openChild &&
                <ModalPopup
                    className="modalregister"
                    open={isOpen}
                    onClose={handleClose}
                    content={<>
                        <Box className="modalheader">
                            <Box id="modal-modal-title" className="modal-heading" component="h2">
                                Fill in the details
                            </Box>
                            <Box className="modal-close-btn" onClick={() => {
                                setOpenRegister(false);
                                if (window.location.pathname == '/signup') {
                                    window.history.replaceState(null, null, '/');
                                }
                            }}>
                                <Button size="small" disableRipple > <img src={images.closeIcon} alt="" /></Button>
                            </Box>
                        </Box>
                        <Box className="modal-body" sx={{ padding: '0', }}>
                            <Formik
                                ref={useFormik}
                                validationSchema={registerValidationSchema}
                                enableReinitialize={true}
                                initialValues={{ name: '', mobile_no: '', dob: '', email: '', privacy: true }}
                                onSubmit={(values, { setSubmitting }) => {
                                    AuthenticationService.register(values).then(response => {
                                        if (window.location.pathname == '/signup') {
                                            window.history.replaceState(null, null, '/');
                                        }
                                        setType(values.type);
                                        setMobileNo(values.mobile_no);
                                        setOpenChild(true);
                                        setSubmitting(false);
                                    }).catch(error => {
                                        setSubmitting(false);
                                    });
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                        <FormControl sx={{ width: '100%' }} className={"input-style " + (errors.name && touched.name ? "error" : "")}>
                                            <Input placeholder="Name" name="name" className="outline-input"
                                                sx={{
                                                    '&:before': {
                                                        borderBottom: '0px solid'
                                                    }
                                                }}
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                startAdornment={
                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                        <img src={images.profileIcon} alt="search" />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.name && touched.name && errors.name}</FormHelperText>
                                        <FormControl sx={{ width: '100%' }} className={"input-style " + (errors.email && touched.email ? "error" : "")}>
                                            <Input placeholder="Email" name="email" className="outline-input"
                                                sx={{
                                                    '&:before': {
                                                        borderBottom: '0px solid'
                                                    }
                                                }}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                startAdornment={
                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                        <img src={images.mailIcon} alt="search" />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.email && touched.email && errors.email}</FormHelperText>
                                        <FormControl sx={{ width: '100%' }} className={"input-style " + (errors.mobile_no && touched.mobile_no ? "error" : "")}>
                                            <Input placeholder="Phone number" name="mobile_no" className="outline-input"
                                                sx={{
                                                    '&:before': {
                                                        borderBottom: '0px solid'
                                                    }
                                                }}
                                                type="number"
                                                value={values.mobile_no}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                startAdornment={
                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                        <img src={images.contactIcon} alt="search" />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <p style={{ margin: '5px 0px 0px 15px' }}>By providing your number you agree to receive SMS from us</p>
                                        <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.mobile_no && touched.mobile_no && errors.mobile_no}</FormHelperText>
                                        <FormControl sx={{ width: '100%' }} className={"input-style " + (errors.dob && touched.dob ? "error" : "")}>
                                            <Input
                                                placeholder="Date of Birth"
                                                name="dob"
                                                className="outline-input"
                                                sx={{
                                                    '&:before': {
                                                        borderBottom: '0px solid'
                                                    }
                                                }}
                                                id="date-picker-dob"
                                                value={values.dob}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                startAdornment={
                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                        <img src={images.calendarIcon} alt="search" />
                                                    </InputAdornment>
                                                }
                                                readOnly={true}
                                            />
                                        </FormControl>
                                        <DobUpdates />
                                        <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.dob && touched.dob && errors.dob}</FormHelperText>
                                        {/* <FormControl sx={{ width: '100%' }} className={"input-style " + (errors.dob && touched.dob ? "error" : "")}>
                                            <Input placeholder="Date of Birth" name="dob" className="outline-input"
                                                sx={{
                                                    '&:before': {
                                                        borderBottom: '0px solid'
                                                    }
                                                }}
                                                type="number"
                                                id="date-picker-dob"
                                                value={values.dob}
                                                onChange={(e) => {
                                                    console.log('element',e);
                                                }}
                                                onBlur={handleBlur}
                                                startAdornment={
                                                    <InputAdornment position="start" className="input-image" underline="none" sx={{ pl: 3, pr: 1 }}>
                                                        <img src={images.calendarIcon} alt="search" />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <DobUpdates/> */}
                                        <FormGroup sx={{ textAlign: 'left' }}>
                                            <FormControlLabel control={
                                                <CheckboxInput
                                                    inputtype='checkboxonly'
                                                    className="register-check-box"
                                                    name="privacy"
                                                    checked={values.privacy}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFieldValue('privacy', true);
                                                        } else {
                                                            setFieldValue('privacy', false);
                                                        }
                                                    }}
                                                />
                                            } label={
                                                <Box
                                                    sx={{
                                                        fontSize: '16px',
                                                        textAlign: 'start'
                                                    }}>
                                                    <span>I agree to </span>
                                                    <Link href="javascript:void(0)" className="primary-text"
                                                        sx={{
                                                            textDecoration: 'underline !important',
                                                        }}
                                                        onClick={() => { setOpenTC(true) }}>
                                                        Beachify’s Terms & Conditions</Link>
                                                </Box>
                                            }
                                            />
                                        </FormGroup>
                                        <FormHelperText id="standard-weight-helper-text" className="field-helper-text error">{errors.privacy && touched.privacy && errors.privacy}</FormHelperText>
                                        <Button size="small" type="submit" className="header-submit-btn" disabled={isSubmitting} sx={{ marginBottom: '0 !important', }}>
                                            {isSubmitting ? <CircularProgress color="inherit" /> : "Register"}
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </>}
                />}
            {openChild &&
                <ModalPopup
                    open={openChild}
                    onClose={() => { setOpenChild(false), setOpenRegister(false) }}
                    content={<>
                        <Box className="modalheader" sx={{ marginBottom: 0 }}>
                            <Box className="modal-close-btn">
                                <Button size="small" disableRipple onClick={() => { setOpenChild(false), setOpenRegister(false) }}> <img src={images.closeIcon} alt="" /></Button>
                            </Box>
                        </Box>
                        <Box className="modal-body message-modal">
                            <img src={images.restingIcon} alt="sucessful" />
                            <Typography component="h2">Verification Link Sent</Typography>
                            <Typography component="p">Sign Up Process succesfully done , Please check mail for Verification Link, Mail sent to your mail kindly check it</Typography>
                        </Box>
                    </>}
                />}
            {openTC &&
                <ModalPopup
                    contentStyle={{ width: '1130px', width1: '100%' }}
                    open={openTC}
                    onClose={() => { setOpenTC(false) }}
                    content={<>
                        <Box className="modalheader" sx={{ marginBottom: 0 }}>
                            <Box className="modal-close-btn">
                                <Button size="small" onClick={() => { setOpenTC(false) }}> <img src={images.closeIcon} alt="" /></Button>
                            </Box>
                        </Box>
                        <Box className="modal-body message-modal">
                            {parse(termsAndConditions)}
                        </Box>
                    </>}
                />}
        </>
    );
}

export default Register;
