import { Box, IconButton, Modal, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  outline: "none",
};

const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
    borderRadius: 0,
    "& .listResponsive": {
      maxHeight: "100% !important",
    },
  },
}));

const MoreInfoModal = (props) => {
  const { onClose } = props;
  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton
          sx={{ position: "absolute", top: 25, left: 20, padding: 0 }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: 25, mb: 1 }} />
        </IconButton>

        <Box
          sx={{
            p: 3,
            mt: 5,
            overflowX: "auto",
          }}
        >
          <Typography sx={{ fontWeight: "600" }} component="h3">
            Which location do I choose?
          </Typography>
          <Typography sx={{ fontWeight: "light", my: 2 }} component="h4">
            To send your money, we need to know where you opened your financial
            account.
          </Typography>
          <Typography sx={{ fontWeight: "600" }} component="h3">
            What if I don't have a financial account?
          </Typography>
          <Typography sx={{ fontWeight: "light", my: 2 }} component="h4">
            If you don’t have a financial account yet, or if you’re not sure how
            you’d like to get paid, choose your location to find out what
            options are available.
          </Typography>
        </Box>
      </ResponsiveStyles>
    </Modal>
  );
};

export default MoreInfoModal;
