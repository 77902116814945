import React, { useState } from "react";
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import images from "../../assets/js/images";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Beach = (props) => {
    const { imagesData, title, freePlaces, distance } = props;
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = imagesData.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <>
            <Card className="border-0 shadow-none info-square-card card-info">
                <div className="card-media-block">
                    <Box sx={{ flexGrow: 1 }} className="card-slider">
                        <AutoPlaySwipeableViews
                            interval={5000}
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {imagesData.map((step, index) => (
                                <div key={step.label}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                display: 'block',
                                                overflow: 'hidden',
                                                width: '100%',
                                                objectFit: 'cover',
                                            }}
                                            src={step.imgPath}
                                            alt={step.label}
                                            className="border-radius-12 card-image"
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            className="stepper-dots"
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                    className="prev-btn"
                                >
                                    {theme.direction === 'rtl' ? (
                                        <img src={images.rightChevronIcon} alt="arrow" />
                                    ) : (
                                        <img src={images.rightChevronIcon} alt="arrow" />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0} className="next-btn">
                                    {theme.direction === 'rtl' ? (
                                        <img src={images.leftChevronIcon} alt="arrow" />
                                    ) : (
                                        <img src={images.leftChevronIcon} alt="arrow" />
                                    )}
                                </Button>
                            }
                        />
                    </Box>
                </div>
                <CardActionArea>
                    <CardContent sx={{ p: 0 }}>
                        <Typography variant="h5" component="div" className="line-clamp-1 card-title service-list-review">
                            {title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}

export default Beach;
