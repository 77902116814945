import { Container, Grid } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import TaxesTabs from "../../Components/Taxes/Tabs";

const Taxes = () => {
  return (
    <Container sx={{ py: 5, }}>
      <AccountSettingsTitle title="Taxes" routeLabel="Taxes" />
      <Grid sx={{ paddingTop: 5 }} rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12} md={8} item>
          <TaxesTabs />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Taxes;
