const Updates = (data) => {
  const titleData = {
    title: "Beachify updates",
    description:
      "Stay up to date on the latest news from Beachify, and let us know how we can improve.",
  };

  const optionsData = [
    {
      label: "News and programs",
      description:
        "Stay in the know about brand new programs and announcements.",
      options: data["news"],
      field: "news",
    },
    {
      label: "Feedback",
      description: "Let us know how we're doing and how we can improve.",
      options: data["feedback"],
      field: "feedback",
    },
  ];
  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};

export default Updates;
