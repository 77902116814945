import { Link, Grid } from "@mui/material";
const FormField = (props) => {
  const {
    children,
    label,
    description,
    text,
    editEnable,
    setEditEnable,
    renderChildren = false,
    sx = { pl: 0, pr: 0 },
  } = props;
  return (
    <Grid container className="modal-header" sx={sx}>
      <Grid item xs={10}>
        <h3 className="main-heading small-heading">{label}</h3>
        {!editEnable && (
          <h5 className="text-muted" style={{ marginBottom: "0px" }}>
            {text || "No data"}
          </h5>
        )}
        {editEnable && (
          <h5 className="text-muted" style={{ marginBottom: "0px" }}>
            {description}
          </h5>
        )}
      </Grid>
      <Grid item xs={2} align="right">
        <Link
          style={{ cursor: "pointer", color: "black", fontWeight: 500 }}
          className="text-underline"
          onClick={() => setEditEnable(!editEnable)}
        >
          {editEnable ? "Cancel" : "Edit"}
        </Link>
      </Grid>

      {renderChildren ? (
        <Grid item xs={12} style={{ display: editEnable ? "block" : "none" }}>
          {children}
        </Grid>
      ) : (
        editEnable && (
          <Grid item xs={12}>
            {children}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default FormField;
