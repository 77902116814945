import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import AuthCode from "react-auth-code-input";
import { useDispatch } from "react-redux";

import images from "../../../assets/js/images";
import MakeToast from "../../../Helpers/MakeToast";
import ScreenLoader from "../../Common/ScreenLoader";
import ModalPopup from "../../Modal/ModalPopup";
import { PrivacyAndSharingSevice } from "../../../Services/PrivacyAndSharingSevice";
import { PrivacyAndSharingContext } from "../../../Context/PrivacyAndSharingContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

const Otp = ({ openOtp, setOtpOpen, handleClose, userData }) => {
  const history = useHistory();
  const [result, setResult] = useState(null);
  const [seconds, setSeconds] = useState(60);
  const [loader, setLoader] = useState(false);
  const formValues = useContext(PrivacyAndSharingContext);
  const handleOnChange = (res) => {
    setResult(res);
  };

  useEffect(() => {
    let interval = null;
    if (openOtp) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevTime) => prevTime - 1);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [openOtp, seconds]);

  const handleResent = () => {
    setLoader(true);
    PrivacyAndSharingSevice.dataRequestVerifyOtp({ ...formValues, otp: result })
      .then((response) => {
        MakeToast("success", response);
        setSeconds(60);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleGoBack = () => {
    setOtpOpen(false);
  };

  useEffect(() => {
    if (result?.length === 6) {
      setLoader(true);
      PrivacyAndSharingSevice.dataRequestVerifyOtp({
        ...formValues,
        otp: result,
      })
        .then((response) => {
          history.push("/account-settings/privacy-and-sharing");
          MakeToast("success", response);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  }, [result]);

  return (
    <>
      <ScreenLoader open={loader} />
      <ModalPopup
        className="modalotp"
        open={openOtp}
        onClose={handleClose}
        content={
          <>
            <Box className="modalheader">
              <Box className="modal-heading-back" onClick={handleGoBack}>
                <Button
                  className="modal-back-btn border-radius-12"
                  sx={{ p: 0 }}
                >
                  <img src={images.backIcon} alt="" />
                </Button>
                <Box
                  id="modal-modal-title"
                  className="modal-heading"
                  component="h2"
                >
                  Enter your verification code
                </Box>
              </Box>
            </Box>
            <Box className="modal-body">
              <Typography component="p" className="sub-text secondary-text">
                The code was sent to
                <Link href="#" className="secondary-text">
                  {" " + userData}
                </Link>
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <Box component="div" className="otp-input">
                  <AuthCode onChange={handleOnChange} />
                </Box>
              </Box>
              {seconds > 0 ? (
                <Typography
                  component="p"
                  sx={{ mb: 0 }}
                  className="sub-text otp-code-msg"
                >
                  Resend code after
                  <Link href="#" className="primary-text">
                    {seconds} seconds
                  </Link>
                </Typography>
              ) : (
                <Link href="#" className="primary-text" onClick={handleResent}>
                  Resend Code
                </Link>
              )}
            </Box>
          </>
        }
      />
    </>
  );
};

export default Otp;
