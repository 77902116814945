import AppHelper from "../../../../Helpers/AppHelper";

const InitialValues = (profile = {}) => {
  const totalWeeks = AppHelper.lists().weeks;

  const defaultHoursOfOperation = [];
  const openCloseCheckbox = [];

  totalWeeks.map((name) => {
    defaultHoursOfOperation.push({ day: name, status: 0, open: "", close: "" });
    openCloseCheckbox.push({ open: false, close: true });
  });
  return {
    company_name: profile?.company_name ?? "",
    description: profile?.description ?? "",
    hours_of_operation: profile?.hours_of_operation ?? defaultHoursOfOperation,
    open_close_checkbox: openCloseCheckbox,
    rental_location_address: profile?.rental_location_address ?? "",
    lat: profile?.lat ?? "",
    long: profile?.long ?? "",
  };
};

export default InitialValues;
