import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import FormField from "../FormField";
import { useFormik } from "formik";
import * as yup from "yup";
import { BusinessInfoContext } from "../../../Context/BusinessInfoContext";
import { useContext, useEffect, useState } from "react";
import { BusinessInfoService } from "../../../Services/BusinessInfoService";
import MakeToast from "../../../Helpers/MakeToast";
import { useDispatch } from "react-redux";

let informationValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .typeError("Name must be a text"),
});

const CompanyName = () => {
  const dispatch = useDispatch();
  const [editEnable, setEditEnable] = useState(false);
  const { business, setBusinessInfo } = useContext(BusinessInfoContext);
  const formSubmit = (values) => {
    BusinessInfoService.updateField({
      field_name: "company_name",
      value: values.name,
    })
      .then((response) => {
        formik.setSubmitting(false);
        dispatch({
          type: "UPDATE_USER_DATA",
          payload: {data : {name:response.data.data.company_name}},
        });
        setBusinessInfo(response.data.data);
        setEditEnable(false);
        MakeToast("success", "Business Info updated successfully.");
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: { name: business?.company_name ?? "" },
    enableReinitialize: true,
    onSubmit: formSubmit,
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  useEffect(() => {
    editEnable == false &&
      formik.setFieldValue("name", business?.company_name ?? "");
  }, [editEnable]);
  return (
    <FormField
      label="Company Name"
      description="Please enter your company name below."
      text={business?.company_name}
      editEnable={editEnable}
      setEditEnable={setEditEnable}
    >
      <FormControl
        sx={{ width: "100%", mt: 2 }}
        className="input-style small-radius"
      >
        <TextField
          placeholder="Company Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className="outline-input textfield-rouded-md input-text-style small-radius"
          sx={{
            borderRadius: "18px !important",
            ".MuiInputBase-input": {
              paddingLeft: "30px",
            },

            ".MuiInputBase-root": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
              "&.Mui-focused": {
                " .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
            },
          }}
        />
      </FormControl>
      {errors.name && touched.name && errors.name && (
        <FormHelperText className="field-helper-text error">
          {errors.name && touched.name && errors.name}
        </FormHelperText>
      )}

      <Button
        size="small"
        type="button"
        disableRipple
        disableElevation
        className="btn-primary submit-btn rounded-button"
        style={{ marginTop: "15px" }}
        disabled={formik.isSubmitting}
        onClick={(e) => formik.handleSubmit()}
      >
        {formik.isSubmitting ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          "Save"
        )}
      </Button>
    </FormField>
  );
};

export default CompanyName;
