import { APIService } from "./APIService";

export const ReviewsService = {
  bookings,
  store,
  edit,
  aboutYou,
  byYou,
  hostReviews,
  answer,
  updateAnswer,
};

function bookings() {
  return APIService.get("api/reviews/bookings");
}

function store(data) {
  return APIService.post("api/reviews/store", data);
}

function edit(data, id) {
  return APIService.post(`api/reviews/edit/${id}`, data);
}

function aboutYou() {
  return APIService.get("api/reviews/about_you");
}

function byYou() {
  return APIService.get("api/reviews/by_you");
}

function hostReviews(id = null) {
  return APIService.get("api/host_reviews/" + (id || ""));
}

function answer(data) {
  return APIService.post("api/host_reviews/store", data);
}

function updateAnswer(data) {
  return APIService.post("api/host_reviews/update", data);
}
