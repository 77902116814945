const HostOptions = [
  {
    title: "Bussiness info",
    description: "Show and Edit your Bussiness information",
    icon: "storefront",
    link: "/account-settings/business-info",
  },
  {
    title: "Bookings",
    description: "Show and Edit all the booking preferences",
    icon: "auto_stories",
    link: "/account-settings/booking-preferences",
  },
  {
    title: "Payouts",
    description: "Review Payouts from your host account",
    icon: "payments",
    link: "/account-settings/payments-payouts",
  },
  {
    title: "Taxes",
    description: "Manage taxpayer information and tax documents",
    icon: "description-icon",
    link: "/account-settings/taxes",
  },
  {
    title: "Notifications",
    description:
      "Choose notification preferences and how you want to be contacted",
    icon: "campaign",
    link: "/account-settings/notifications",
  },
  {
    title: "Login & security",
    description: "Update your password and secure your account",
    icon: "security",
    link: "/account-settings/login-and-security",
  },
  {
    title: "Privacy & sharing",
    description:
      "Manage your personal data, connected services, and data sharing settings",
    icon: "visibility",
    link: "/account-settings/privacy-and-sharing",
  },
  {
    title: "Global preferences",
    description: "Set your default language, currency, and timezone",
    icon: "tune",
    link: "/account-settings/preferences",
  },
  {
    title: "Referral credit & coupon",
    description: "You have $0 referral credits and coupon. Learn more",
    icon: "redeem",
    link: "#",
    comingSoonTitle: "Referral programs coming soon!",
  },
];

export default HostOptions;
