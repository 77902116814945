import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ConfirmationModal = (props) => {
  const {
    title,
    open,
    setOpen,
    description,
    agreeText = "Continue",
    disagreeText = "Cancel",
    confirmAction = () => {},
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} onClick={handleClose}>
            {disagreeText}
          </Button>
          <Button
            sx={{ padding: 1 }}
            className="btn rounded-button btn-primary"
            onClick={confirmAction}
          >
            {agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationModal;
