import { APIService } from "./APIService";

export const ValidationService = {
    useremailvalidation,
    usermobilevalidation,
    checkUserExists
};

function useremailvalidation(data) {
    return APIService.post('api/useremailvalidation', data);
}

function usermobilevalidation(data) {
    return APIService.post('api/usermobilevalidation', data);
}

function checkUserExists(data) {
    return APIService.post('api/check-exists', data);
}