import { Skeleton } from '@mui/material';
import React from 'react'

export const HoursofOperationSkeleton = ({count}) => {
    if(count === undefined){
        count = 5;
    }
    const skeletonElements = Array.from({ length: count }).map((_, index) => (
        <div key={index}>
          <Skeleton variant="rounded" sx={{ width: '50%', height: '25px', marginBottom: '15px', '@media (min-width: 0) and (max-width: 575.98px)': { width: '100%'} }} />
        </div>
    ));
  return (
    <>{skeletonElements}</>
  )
}
