import React, { useState } from 'react';
import {
    Container,
    Link,
    Box,
    Button,
    Typography,
    CircularProgress
}from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import parse from "html-react-parser";
import Grid from '@mui/material/Unstable_Grid2';
import moment from "../../Helpers/MomentDefaultHelper";
import MakeToast from '../../Helpers/MakeToast';
import images from "../../assets/js/images";
import AppHelper from '../../Helpers/AppHelper';
import DenyModal from "../../Components/Modal/DenyModal";

const Notification = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [openDeny, setOpenDeny] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [notifications, setNotifications] = useState({});
    const [loader, setLoader] = useState(false);
    const { isLoggedIn } = useSelector((state) => state.userReducers.data);

    const getNotifications = () => {
        if (isLoggedIn) {
            AppHelper.notificationLists(setNotifications, history, dispatch);
        }
    }

    React.useEffect(() => {
        getNotifications();
    }, []);

    const renderNotification = () => {
        setLoader(true);
        getNotifications()
        setLoader(false);
    }

    return (
        <>
        <section className="notification-section inner-section-padding bg-light-fourth">
           <Container>
                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ 
                    justifyContent:'center',
                }}>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Box className='top-heading' sx={{ 
                            display: 'flex',
                        }}>
                            <div className="main-heading-block">
                                <h2 className="main-heading" sx={{
                                    marginBottom: '0 !important',
                                    marginTop: '0 !important',
                                    display:'flex'
                                }}>
                                    <Box className="left-back-arrow">
                                        <Link
                                            component="button"
                                            variant="body2"
                                            underline="none"
                                            className="secondary-text"
                                            color="inherit"
                                            onClick={() => { history.go(-1) }}
                                        >
                                            <img src={images.backIcon} alt="" className='bg-white'/>
                                        </Link>
                                    </Box>
                                    Notifications
                                </h2>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10} sx={{ position:'relative' }}>
                        {
                        notifications?.length > 0 
                        ? 
                        notifications.map(notfication =>
                            <Box className='notification-card bg-white notification-position'>
                                <Box className='notification-header'>
                                        <Typography  variant="h6" component="span" className='sub-text tertiary-text'>
                                            {/* { moment(notfication?.created_at).fromNow() } */}
                                            { notfication?.human_time }
                                        </Typography>
                                        <Typography  variant="h6" component="span" className='sub-text tertiary-text'>
                                            { moment(notfication?.created_at).format('DD MMMM YYYY') }
                                        </Typography>
                                </Box>
                                <Box className="inner-main-heading">
                                    <Typography  variant="h6" component="h3" className='secondary-text'>
                                        { 
                                            notfication?.type=='REGULAR_BOOKING_REQUEST' 
                                            ? 
                                            notfication?.sender?.name+' Send Request for Booking.' 
                                            : 
                                            parse(notfication?.payload?.message) 
                                        } 
                                    </Typography>
                                </Box>
                                {
                                notfication?.type=='REGULAR_BOOKING_REQUEST'
                                ?
                                <>
                                    {!loader ?
                                        <>
                                            <Button 
                                                className='btn-primary border-radius-md host-btn regularbookingpendingapprovalhost' 
                                                data-id={notfication?.model_id}
                                                onClick={() => {
                                                    AppHelper.confirmBookingNotifcation(history, notfication?.model_id)
                                                }}
                                            >
                                                Approve
                                            </Button>
                                            <Button 
                                                className='rounded-button btn-secondary-outline menu-btn regularbookingpendingdenialhost' 
                                                style={{border:'1px solid rgba(25, 118, 210, 0.5)', marginLeft : '5px', padding : '6px 30px !important'}}
                                                data-id={notfication?.model_id}
                                                onClick={() => {
                                                    AppHelper.confirmBookingNotifcation(history, notfication?.model_id)
                                                }}
                                            >
                                                Deny
                                            </Button>
                                        </>
                                        :
                                        <CircularProgress />
                                    }
                                </>
                                :
                                <></>
                                }
                            </Box>
                        ) 
                        :
                        <Box className='notification-card bg-white notification-position'>
                            No Notifications
                        </Box>
                        }
                    </Grid>
                </Grid>
           </Container>
        </section>
        {
            openDeny
            ?
            <DenyModal openDeny={openDeny} setOpenDeny={setOpenDeny} id={selectedId} onEmitValue={renderNotification} />
            :
            <></>
        }
        </>
    )
}

export default Notification;
