import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Login from '../../Pages/Auth/Login';
import OtpSection from '../../Pages/Auth/OtpSection';
import Register from '../../Pages/Auth/Register';
import BecomeHost from '../../Pages/Host/BecomeaHostForm';
import Support from '../../Components/Support/Support';
import SignupListModal from '../Modal/SignupListModal';
import { useDispatch, useSelector } from 'react-redux';

const AuthModal = forwardRef((props, _ref) => {
    const [email, setEmail] = useState(null);
    const [mobileNo, setMobileNo] = useState(null);
    const [type, setType] = useState("email");
    const [loginOpen, setLoginOpen] = useState(false);
    const [openOtp, setOtpOpen] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [hostOpen, setOpenHost] = useState(false);
    const [signUpOpen, setSignUpOpen] = useState(false);
    const [supportOpen, setSupportOpen] = useState(false);

    const handleOpenRegister = () => {
        setOpenRegister(true);
        setLoginOpen(false);
        setSignUpOpen(false);
    }

    const handleOpenHostRegister = () => {
        setOpenHost(true);
        setLoginOpen(false);
        setSignUpOpen(false);
    }

    const handleOpenSignUp = () => {
        setOpenHost(false);
        setLoginOpen(false);
        setSignUpOpen(true);
    }

    const handleOpenSupport = () => {
        setLoginOpen(false);
        setSupportOpen(true);
    }

    useImperativeHandle(_ref, () => ({
        openLogin: () => {
            return setLoginOpen(true);
        },
        openSignUp: () => {
            return setSignUpOpen(true);
        },
        openSignUpForm: () => {
            return setOpenRegister(true);
        },
        openBecomeHost: () => {
            return setOpenHost(true);
        }
    }));
    const dispatch = useDispatch();
    const { openAuthModal } = useSelector((state) => state.commonReducers);

    React.useEffect(() => {
        if (openAuthModal === true) {
            setLoginOpen(true);
            dispatch({
                type: 'REVERT_DATA'
            });
        }
    }, [openAuthModal]);

    return (
        <>
            <Support setOpen={setSupportOpen} open={supportOpen} pagetype="forgotemail" />
            <BecomeHost isOpen={hostOpen} handleClose={() => {
                setOpenHost(false);
                if (window.location.pathname == '/becomehost') {
                    window.history.replaceState(null, null, '/');
                }
            }} />
            {loginOpen ? <>
                <Login
                    isOpen={loginOpen}
                    type={type}
                    setMobileNo={setMobileNo}
                    setType={setType}
                    handleOpenSignUp={handleOpenSignUp}
                    handleOpenSupport={handleOpenSupport}
                    setLoginOpen={setLoginOpen}
                    setOtpOpen={setOtpOpen}
                    handleClose={() => {
                        setLoginOpen(false);
                        if (!openOtp && window.location.pathname == '/login') window.history.replaceState(null, null, '/');
                    }}
                    setEmail={setEmail}
                />
            </> : null}
            {openOtp ? <>
                <OtpSection
                    openOtp={openOtp}
                    type={type}
                    setLoginOpen={setLoginOpen}
                    setOtpOpen={setOtpOpen}
                    handleClose={() => {
                        setOtpOpen(false);
                        if (window.location.pathname == '/login') {
                            window.history.replaceState(null, null, '/');
                        }
                    }}
                    email={email}
                    mobileNo={mobileNo}
                />
            </> : null}
            {openRegister ?
                <Register
                    isOpen={openRegister}
                    type={type}
                    setType={setType}
                    setMobileNo={setMobileNo}
                    setOpenRegister={setOpenRegister}
                    setOtpOpen={setOtpOpen}
                    handleClose={() => {
                        setOpenRegister(false);
                        if (window.location.pathname == '/signup') {
                            window.history.replaceState(null, null, '/');
                        }
                    }}
                /> : null}
            {signUpOpen ?
                <SignupListModal
                    isOpen={signUpOpen}
                    handleClose={() => {
                        setSignUpOpen(false);
                        if (window.location.pathname == '/becomehost' || window.location.pathname == '/signup') {
                            window.history.replaceState(null, null, '/');
                        }
                    }}
                    handleOpenRegister={handleOpenRegister}
                    handleOpenHostRegister={handleOpenHostRegister}
                /> : null}
        </>
    )
});

export default React.memo(AuthModal);