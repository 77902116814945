import { Container, Grid } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import Tabs from "../../Components/PaymentsAndPayouts/Tabs";
import { useState } from "react";
import { TabsContext } from "../../Context/PaymentsContext";
import Payments from "../../Components/PaymentsAndPayouts/Tabs/Payments";
import Payouts from "../../Components/PaymentsAndPayouts/Tabs/Payouts";
import PaymentsCard from "../../Components/PaymentsAndPayouts/Tabs/PaymentsCard";
import PayoutsCard from "../../Components/PaymentsAndPayouts/Tabs/PayoutsCard";
const PaymentsAndPayouts = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabsContent = [
    // { label: "Payments", content: <Payments />, card: <PaymentsCard /> },
    { label: "Payouts", content: <Payouts />, card: <PaymentsCard /> },
  ];
  return (
    <TabsContext.Provider
      value={{
        tabIndex: tabIndex,
        setTabIndex: setTabIndex,
        tabsContent: tabsContent,
      }}
    >
      <Container sx={{ py: 5 }}>
        <AccountSettingsTitle title="Payouts" routeLabel="Payouts" />
        <Grid sx={{ paddingTop: 5 }} rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
          <Grid xs={12} md={8} item>
            <Tabs />
          </Grid>
          <Grid xs={4} item className="mobile-none">
            {tabsContent[tabIndex].card}
          </Grid>
        </Grid>
      </Container>
    </TabsContext.Provider>
  );
};

export default PaymentsAndPayouts;
