import { APIService } from "./APIService";

export const PayoutMethodService = {
  store,
  get,
};

function get() {
  return APIService.get("api/account_settings/host/payout_method");
}
function store(data) {
  return APIService.post("api/account_settings/host/payout_method", data);
}
