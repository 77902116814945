import { APIService } from "./APIService";

export const BookingService = {
    calculation,
    confirm,
    request,
    bookingList,
    bookingCalender,
    bookingDetail,
    cancelBooking,
    denyBooking,
    refundBooking,
    pickupHostNotify,
    dropHostNotify,
    bookedCustomer,
    contactCustomer
};

function calculation(data, id) {
    return APIService.post('api/calculation/' + id, data);
}

function confirm(data, id) {
    return APIService.post('api/booking-confirm', data);
}

function request(id) {
    return APIService.put('api/request-booking/' + id, {});
}

function bookingList(param) {
    return APIService.get('api/bookings?' + new URLSearchParams(param).toString());
}

function bookingCalender(date) {
    return APIService.get('api/booking-calender?date=' + date);
}

function bookingDetail(id) {
    return APIService.get('api/booking-detail/' + id);
}

function cancelBooking(data) {
    return APIService.post('api/cancel-booking', data);
}

function denyBooking(data) {
    return APIService.post('api/deny-booking', data);
}

function refundBooking(data) {
    return APIService.post('api/refund-booking', data);
}

function pickupHostNotify(data) {
    return APIService.post('api/pickup-host-notify', data);
}

function dropHostNotify(data) {
    return APIService.post('api/drop-host-notify', data);
}

function bookedCustomer(id) {
    return APIService.get('api/booked-customer/' + id);
}

function contactCustomer(data) {
    return APIService.post('api/contact-customer', data);
}