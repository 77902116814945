import { Box } from "@mui/material";
import { useState } from "react";
import { StyledTab, StyledTabs } from "./StyledTabs";
import TabPanel from "./TabPanel";

function tabProps(props) {
  const { index, panelName, tabName } = props;

  return {
    id: `${tabName}-${index}`,
    "aria-controls": `${panelName}-${index}`,
  };
}

const CustomTabs = (props) => {
  const {
    sx = {},
    tabs = [],
    tabName = "simple-tab",
    panelName = "simple-tabpanel",
  } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
        <StyledTabs value={value} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <StyledTab
              key={index}
              label={tab.label}
              {...tabProps(index, panelName, tabName)}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          panelName={panelName}
          tabName={tabName}
          value={value}
          index={index}
        >
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};
export default CustomTabs;
