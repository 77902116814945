import { Breadcrumbs, Link, Typography,Box} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const BreadCrumb = (props) => {
    const navigate = useHistory();
    return (
    <>
        <Breadcrumbs
          className="custom_breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={props.sx}
        >
            <RouterLink to="/">
                <Typography>Home</Typography>
            </RouterLink>

            {props.data.map((v, i) => {
            return (
                v[0]!='undefined'
                ?
                (
                    i!=props.data.length-1
                    ?
                    (
                        v[1]=='goback' 
                        ?
                        <RouterLink onClick={() => { navigate.goBack() }}>
                            <Typography>{v[0]}</Typography>
                        </RouterLink>
                        : 
                        <RouterLink to={v[1]}>
                            <Typography>{v[0]}</Typography>
                        </RouterLink>
                    )
                    :
                    <Link>{v[0]}</Link>   
                ) 
                :
                <></>
            );
            })}
        </Breadcrumbs>
    </>
    );
};

export default BreadCrumb;
