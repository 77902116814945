import { APIService } from "./APIService";

export const BusinessInfoService = {
  getInfo,
  updateField,
  generateOtp,
  verifyOtp,
  saveEmergencyContact,
};

function getInfo() {
  return APIService.get("api/business_info");
}

function updateField(data) {
  return APIService.post("api/business_info/update", data);
}

function generateOtp(data) {
  return APIService.post("api/business_info/generate_otp", data);
}

function verifyOtp(data) {
  return APIService.post("api/business_info/verify_otp", data);
}

function saveEmergencyContact(data) {
  return APIService.post("api/business_info/emergency_contact", data);
}
