import React from "react";
import images from "../../assets/js/images";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Container,
  FormControl,
  TextField,
  Link,
  InputAdornment,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Stack,
  IconButton,
  CircularProgress,
  Alert,
  Switch,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import parse from 'html-react-parser'

import moment from "../../Helpers/MomentDefaultHelper";
import AppHelper from '../../Helpers/AppHelper';
import MakeToast from "../../Helpers/MakeToast";
import QuestionMarkPopover from '../../Components/Common/QuestionMarkPopover';
import SelectInput from '../../Components/Inputs/SelectInput';
import CheckboxInput from '../../Components/Inputs/CheckboxInput';
import RadioInput from '../../Components/Inputs/RadioInput';
import TimeInput from '../../Components/Inputs/TimeInput';
import GoogleAutoCompleteInput from '../../Components/Inputs/GoogleAutoCompleteInput';
import TextEditor from "../../Components/Inputs/TextEditor";
import ModalPopup from '../../Components/Modal/ModalPopup';

import { AuthenticationService } from "../../Services/AuthenticationService";
import { BeachGearService } from "../../Services/BeachGearService";
import { ValidationService } from "../../Services/ValidationService";
import { SettingsService } from "../../Services/SettingsService";
import { CommonService } from "../../Services/CommonService";

const steps = ['Personal info', 'Business hours', 'Services', 'Pricing', 'Booking amenities', 'Terms & conditions'];

const BecomeHost = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = React.useState(false);

  // --stepper start---//
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    formik.setSubmitting(false);
    if (activeStep == 0) {
      let errorStatus = false;
      ValidationService.checkUserExists({ email: formik.values.email, mobile_no: formik.values.mobile_no }).then(response => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }).catch(error => {
        MakeToast('error', error);
        document.getElementsByClassName('MuiModal-root')[0].scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        return
      });

      if (errorStatus) {
        return;
      }
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // --stepper end---//

  // ---stepper color change--//
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
      '@media (min-width: 0) and (max-width: 575.98px)': { 
        top: '10px',
      },
      '@media (min-width: 576px) and (max-width: 767.98px)': {
        top: '13px',
      },
      '@media (min-width: 768px) and (max-width: 991.98px)': {
        top: '19px',
      }
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          '#FF4110',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          '#FF4110',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EAEAF0',
      borderRadius: 1,

    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#09060F',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    fontSize: '25px',
    alignItems: 'center',
    backgroundColor: '#F8F8FA',
    ...(ownerState.active && {
      backgroundColor: '#FF4110',
      boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.25)',
      color: '#FFF',

    }),
    ...(ownerState.completed && {
      backgroundColor:
        '#FF4110',
      color: '#fff',


    }),
    '@media (max-width: 575.98px)': { 
      width: 25,
      height: 25,
      fontSize: '13px',
    },
    '@media (min-width: 576px) and (max-width: 767.98px)': {
      width: 30,
      height: 30,
      fontSize: '20px',
    },
    '@media (min-width: 768px) and (max-width: 991.98px)': {
      width: 40,
      height: 40,
      fontSize: '20px',
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  //-----stepper color end--//

  const toll_service_total_area = AppHelper.lists().tollserviceareas;
  const yesno = AppHelper.lists().yesno;
  const totalhours = AppHelper.lists().hours;
  const totaldays = AppHelper.lists().days;
  const totalweeks = AppHelper.lists().weeks;

  const [languages, setLanguages] = React.useState({});
  React.useEffect(() => {
    CommonService.getAllLanguages().then((res) => {
      setLanguages(res?.data?.data?.all);
    });
  }, []);

  const formSubmit = (event) => {
    var formvalues = formik.values;

    var extras = {
      //'languages': formvalues.languages.length ? formvalues.languages.join(',') : '',
      'cancellation_period': {
        'day': formvalues.cancellation_period_day,
        'hour': formvalues.cancellation_period_hour
      },
      'scheduled_booking': {
        'day': formvalues.scheduled_booking_day,
        'hour': formvalues.scheduled_booking_hour
      }
    }

    Object.assign(formvalues, extras);

    delete formvalues.open_close_checkbox;

    AuthenticationService.hostsRegister(formvalues).then(response => {
      dispatch({
        type: 'LOGIN_START',
        payload: response?.data
      })
      formik.setSubmitting(false);
      handleClose(event);
      setOpenSuccess(true);
      if (window.location.pathname == '/becomehost') {
        window.history.replaceState(null, null, '/');
      }
    }).catch(error => {
      formik.setSubmitting(false);
    });

  };

  const [googleAutocompleteData, setGoogleAutocompleteData] = React.useState({})
  React.useEffect(() => {
    if (googleAutocompleteData.address && googleAutocompleteData.address != '') formik.setFieldValue('rental_location_address', googleAutocompleteData.address)
    if (googleAutocompleteData.lat && googleAutocompleteData.lat != '') formik.setFieldValue('lat', googleAutocompleteData.lat)
    if (googleAutocompleteData.long && googleAutocompleteData.long != '') formik.setFieldValue('long', googleAutocompleteData.long)
  }, [googleAutocompleteData]);

  const [beachgearlist, setBeachGearList] = React.useState([]);
  const getBeachGearList = () => {
    BeachGearService.adminBeachGears().then(res => {
      setBeachGearList(res.data.data);
    }).catch(error => {
    });
  };

  const [openTC, setOpenTC] = React.useState(false);
  const [termsAndConditions, setTermsAndConditions] = React.useState('');
  const getTermsAndConditions = () => {
    SettingsService.termsandconditions('host').then(res => {
      setTermsAndConditions(res.data.data.result);
    }).catch(error => {
    });
  };

  React.useEffect(() => {
    getBeachGearList();
    getTermsAndConditions();
    tollServices(formik.values.toll_service_total_area)
  }, []);

  const [tcfilepreview, setTCFilePreview] = React.useState('')

  const [ibInfo, setIBInfo] = React.useState(null);
  const ibContent = "Hosts who allow customers to book reservations without Host approval<br /> will have the Instant Booking feature turned on. This allows a customer to<br /> book services with a Host, without a booking request, as long as the<br /> desired items/services have not already been reserved for that same time slot.";

  const validationSchemas = [
    // Step 1
    yup.object({
      company_name: yup
        .string('Enter your company name')
        .required('Company name is required'),
      email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      mobile_no: yup
        .number()
        .required('Mobile number is required')
        .typeError("Mobile number must be a number"),
      billing_address: yup
        .string('Enter your billing address')
        .required('Billing address is required'),
      rental_location_address: yup
        .string('Enter your rental location')
        .required('Rental location is required'),
      description: yup
        .string()
        .required('Description is required'),
    }),
    // Step 2
    yup.object({
      hours_of_operation: yup.array().of(
        yup.object().shape({
          status: yup.number(),
          open: yup.string().nullable().when('status', {
            is: value => value == 1,
            then: yup.string()
              .test("starttime", "Opens at is required", (val) => {
                return val != '' && val.replace(/:| |_/g, "").length === 6 ? true : false;
              })
              .test("startendtime", "", (val, data) => {
                return startEndValidation(data.parent)
              }),
            otherwise: ''
          }),
          close: yup.string().nullable().when('status', {
            is: value => value == 1,
            then: yup.string()
              .test("endtime", "Closes at is required", (val) => {
                return val != "" && val.replace(/:| |_/g, "").length === 6 ? true : false;
              })
              .test("startendtime", "End time must be greater than start time", (val, data) => {
                return startEndValidation(data.parent)
              }),
            otherwise: ''
          })
        })
      ),
      languages: yup
        .array().min(1, "Check atleast one languages")
        .required('Languages is required'),
    }),
    // Step 3
    yup.object({
      service: yup
        .array().min(1, "Check atleast one day")
        .required('Service is required'),
    }),
    // Step 4
    yup.object({
      miles_allowed: yup
        .number()
        .required('Miles allowed is required')
        .typeError("Miles allowed must be a number"),
      per_mile_pricing: yup
        .number()
        .required('Charge per mile is required')
        .typeError("Charge per mile must be a number"),
      scheduled_booking_day: yup.string().when('is_scheduled_booking', {
        is: (is_scheduled_booking) => is_scheduled_booking == '1',
        then: yup.string().required('Scheduled booking is required'),
        otherwise: yup.string().nullable(true)
      }),
      scheduled_booking_hour: yup.string().when('is_scheduled_booking', {
        is: (is_scheduled_booking) => is_scheduled_booking == '1',
        then: yup.string().required('Scheduled booking is required'),
        otherwise: yup.string().nullable(true)
      }),
      toll_service_total_area: yup.string().when('toll_included_services', {
        is: (toll_included_services) => toll_included_services == '1',
        then: yup.string().required('Areas is required'),
        otherwise: yup.string().nullable(true)
      }),
      toll_service_pricing: yup.array().when('toll_included_services', {
        is: (toll_included_services) => toll_included_services == '1',
        then: yup.array().of(
          yup.object().shape({
            zipcode: yup.string().required("Zipcode is required"),
            price: yup.number().required('Price is required').typeError("Price must be a number"),
          })
        ),
        otherwise: ''
      })
    }),
    // Step 5
    yup.object({
      cancellation_period_day: yup.string().when('is_cancellation', {
        is: (is_cancellation) => is_cancellation == '1',
        then: yup.string().required('Cancellation period is required'),
        otherwise: yup.string().nullable(true)
      }),
      cancellation_period_hour: yup.string().when('is_cancellation', {
        is: (is_cancellation) => is_cancellation == '1',
        then: yup.string().required('Cancellation period is required'),
        otherwise: yup.string().nullable(true)
      })
    }),
    // Step 6
    yup.object({
      //terms_conditions_content: yup.string().required('Terms & conditions is required'),
      agreement: yup.bool().oneOf([true], 'Please agree the terms and conditions'),
    })
  ]

  const startEndValidation = (datas) => {
    const todaydate = moment().format("MM-DD-YYYY");
    return AppHelper.validateStartEnd(datas.open, datas.close, todaydate, todaydate);
  }

  const hoursofoperation = [];
  const openclosecheckbox = [];

  totalweeks.map((name) => {
    hoursofoperation.push({ 'day': name, 'status': 0, 'open': '', 'close': '' });
    openclosecheckbox.push({ 'open': false, 'close': true })
  })

  const formik = useFormik({
    initialValues: {
      company_name: '',
      mobile_no: '',
      email: '',
      billing_address: '',
      rental_location_address: '',
      lat: '',
      long: '',
      description: '',
      hours_of_operation: hoursofoperation,
      open_close_checkbox: openclosecheckbox,
      languages: [],
      service: [],
      instant_booking_available: 0,
      delivery_pickup_service: 0,
      is_scheduled_booking: 0,
      scheduled_booking_day: '',
      scheduled_booking_hour: '',
      miles_allowed: '',
      per_mile_pricing: '',
      toll_included_services: 0,
      toll_service_total_area: '',
      toll_service_pricing: [],
      reserve_specific_time: 0,
      is_cancellation: 0,
      cancellation_period_day: '',
      cancellation_period_hour: '',
      terms_conditions_file: '',
      terms_conditions_content: '',
      agreement: false,
    },
    validationSchema: validationSchemas[activeStep],
    onSubmit: (values) => {
      activeStep === steps.length - 1 ? formSubmit() : handleNext();
    },
  });

  const formCheck = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (e.target.checked) {
      formik.setFieldValue(name, [...formik.values[name], value]);
    } else {
      formik.setFieldValue(
        name,
        formik.values[name].filter((v) => v !== value)
      );
    }
  };

  const formEditor = (name, value) => {
    formik.setFieldValue(name, value);
  }

  const tollServices = (value) => {
    if (value != '') {
      const rows = [];
      for (let i = 0; i < value; i++) {
        rows.push({ 'zipcode': '', 'price': '' });
      }

      formik.setFieldValue(
        'toll_service_pricing',
        rows
      );
    }
  }

  const renderStepForm = () => {
    let formContent = null;
    switch (activeStep) {
      case 0:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading">
                  Personal info
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} >
                <Box>
                  <FormControl sx={{ width: '100%' }} className="input-style"
                    style={{
                      border: `1px solid #${formik.touched.company_name && Boolean(formik.errors.company_name) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <TextField className="outline-input" placeholder="Company name"
                      id="input-with-icon-textfield"
                      name="company_name"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px',
                        },
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.company_name && Boolean(formik.errors.company_name) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.company_name}
                    </p>
                  }
                </Box>
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6} >
                <Box>
                  <FormControl sx={{ width: '100%' }} className={"input-style"}
                    style={{
                      border: `1px solid #${formik.touched.email && Boolean(formik.errors.email) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <TextField className="outline-input" placeholder="stephenharvey@mail.com"
                      id="input-with-icon-textfield"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"
                            sx={{
                              paddingRight: '30px',
                              '& a':{
                                cursor: 'default',
                              }, 
                              '@media (max-width:1023.98px)': {
                                paddingRight: '0px',
                              },
                            }} >
                            <Link href="#" underline="none"
                              sx={{
                                fontSize: '14px',
                                color: '#B1B1B1',
                                '&:hover': {
                                  color: '#B1B1B1',
                                },
                                '@media (max-width:577px)': {
                                  paddingRight: '10px',
                                },
                                '@media (max-width:767.98px)': {
                                  paddingRight: '10px',
                                },
                                '@media (max-width:1023.98px)': {
                                  paddingRight: '10px',
                                },
                              }}>
                              Add company email
                            </Link>

                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.email && Boolean(formik.errors.email) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.email}
                    </p>
                  }
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} >
                <Box>
                  <FormControl sx={{ width: '100%' }} className="input-style "
                    style={{
                      border: `1px solid #${formik.touched.mobile_no && Boolean(formik.errors.mobile_no) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <TextField className="outline-input" placeholder="305-330-4000"
                      id="input-with-icon-textfield"
                      name="mobile_no"
                      value={formik.values.mobile_no}
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px',
                        },
                      }}
                      inputProps={{ maxLength: 15 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"
                            sx={{
                              paddingRight: '30px',
                              '& a':{
                                cursor: 'default',
                              },
                              '@media (max-width:1023.98px)': {
                                paddingRight: '0px',
                              },

                            }} >
                            <Link href="#" underline="none"
                              sx={{
                                fontSize: '14px',
                                color: '#B1B1B1',
                                '&:hover': {
                                  color: '#B1B1B1',
                                },
                                '@media (max-width:577px)': {
                                  paddingRight: '10px',
                                },
                                '@media (max-width:767.98px)': {
                                  paddingRight: '10px',
                                },
                                '@media (max-width:1023.98px)': {
                                  paddingRight: '10px',
                                },
                              }}>
                              Add company number

                            </Link>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.mobile_no && Boolean(formik.errors.mobile_no) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.mobile_no}
                    </p>
                  }
                </Box>
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6} >
                <Box>
                  <FormControl sx={{ width: '100%' }} className="input-style"
                    style={{
                      border: `1px solid #${formik.touched.billing_address && Boolean(formik.errors.billing_address) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <TextField className="outline-input" placeholder="Billing address"
                      id="input-with-icon-textfield"
                      name="billing_address"
                      value={formik.values.billing_address}
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px',
                        },
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.billing_address && Boolean(formik.errors.billing_address) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.billing_address}
                    </p>
                  }
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block" sx={{
                marginBottom: '0',
              }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                  sx={{
                    marginBottom: '0',
                  }}>
                  Where do customers meet you for rentals or services?
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} >
                <Box>
                  <FormControl sx={{ width: '100%' }} className="input-style small-radius"
                    style={{
                      border: `1px solid #${formik.touched.rental_location_address && Boolean(formik.errors.rental_location_address) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <GoogleAutoCompleteInput
                      placeholder="Location"
                      name="rental_location_address"
                      value={formik.values.rental_location_address}
                      onChange={formik.handleChange}
                      setGoogleAutocompleteData={setGoogleAutocompleteData}
                      className="outline-input"
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className="input-image"
                            sx={{
                              paddingRight: '5px',
                              '@media (max-width:577px)': {
                                paddingRight: '10px',
                              },
                            }} >
                            <img src={images.locationIcon} alt="location" />

                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end"
                            sx={{
                              // paddingRight: '30px',
                              '@media (max-width:1023.98px)': {
                                paddingRight: '0px',
                              },

                            }} >
                            <Link href="#" underline="none"
                              sx={{
                                fontSize: '14px',
                                paddingRight: '30px',
                                color: '#B1B1B1',
                                '&:hover': {
                                  color: '#B1B1B1',
                                },
                                '@media (max-width:577px)': {
                                  paddingRight: '15px',
                                },
                                '@media (max-width:767.98px)': {
                                  paddingRight: '10px',
                                },
                                '@media (max-width:1023.98px)': {
                                  paddingRight: '10px',
                                },
                              }}>
                              Open map

                            </Link>
                          </InputAdornment>
                        )
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.rental_location_address && Boolean(formik.errors.rental_location_address) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.rental_location_address}
                    </p>
                  }
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block" sx={{
                marginBottom: '0',
              }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                  sx={{
                    marginBottom: '0',
                  }}>
                  Add a description of your company
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} >
                <Box>
                  <TextareaAutosize className="textfield-area input-style"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    Inputaria-label="minimum height"
                    minRows={3}
                    placeholder="Description"
                    style={{
                      width: '100%',
                      border: `1px solid #${formik.touched.description && Boolean(formik.errors.description) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  />
                  {formik.touched.description && Boolean(formik.errors.description) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.description}
                    </p>
                  }
                </Box>
              </Grid>
            </Grid>
          </>
        );
        break;
      case 1:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block" >
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                    sx={{
                      marginBottom: '30px !important',
                      '@media (max-width:1024px)': {
                        marginBottom: '30px !important',
                      },
                      '@media (max-width:769px)': {
                        marginBottom: '20px !important',
                      },
                      '@media (max-width:567px)': {
                        marginBottom: '20px !important',
                      },

                    }}>
                    Add your company hours
                  </Typography>
                  <Box>
                    {totalweeks.map((name, index) => {
                      return (
                        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid xs={6} sm={2} md={3} lg={3} className="company-hours-days"><Typography sx={{ fontSize: '17px' }}>{name}</Typography></Grid>
                          <Grid xs={6} sm={2} md={1} lg={1} className="company-hours-switch">
                            <Switch
                              sx={{ 
                                '& .MuiSwitch-track':{
                                  opacity: '1',
                                },
                                '& .MuiSwitch-switchBase':{
                                  '&:hover':{
                                    background: 'transparent',
                                  },
                                  '&.Mui-checked':{
                                    color: '#ff4110',
                                    '&:hover' :{
                                      background: 'rgb(255 65 16 / 12%)',
                                    },
                                    '&+.MuiSwitch-track':{
                                      opacity: '0.2',
                                    }
                                  }
                                }
                              }}
                              checked={formik.values.hours_of_operation[index].status == 1 ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  formik.setFieldValue(`hours_of_operation.${index}.status`, 1)
                                  formik.setFieldValue(`hours_of_operation.${index}.open`, '__:__ AM')
                                  formik.setFieldValue(`hours_of_operation.${index}.close`, '__:__ PM')
                                } else {
                                  formik.setFieldValue(`hours_of_operation.${index}.status`, 0)
                                  formik.setFieldValue(`hours_of_operation.${index}.open`, '')
                                  formik.setFieldValue(`hours_of_operation.${index}.close`, '')
                                }
                              }} />
                          </Grid>
                          <Grid xs={12} sm={4} md={4} lg={4}>
                            <TimeInput
                              className="time-switch"
                              id={'opentext' + index}
                              placeholder="Opens At"
                              name={`hours_of_operation.${index}.open`}
                              value={formik.values.hours_of_operation[index].open}
                              onChange={(e) => {
                                var opentext = e.target.value;
                                var opensplit = opentext.split(' ');

                                formik.setFieldValue(`hours_of_operation.${index}.open`, opensplit[0] + ' ' + (opensplit[1] == 'PM' || opensplit[1] == 'pM' ? 'PM' : 'AM'));
                                formik.setFieldValue(`open_close_checkbox.${index}.open`, (opensplit[1] == 'PM' || opensplit[1] == 'pM') ? true : false);
                              }}
                              onBlur={(e) => {
                                formik.handleChange(e);
                                document.getElementById('openampmbtn' + index).style.display = "none";
                              }}
                              onClick={(e) => {
                                if (e.target.value == '__:__ AM') e.target.setSelectionRange(0, 0);
                                document.getElementById('openampmbtn' + index).style.display = "";
                              }}
                              style={{
                                width: '100%',
                                height: '45px',
                                padding: '10px',
                                borderRadius: '50px',
                                border: `1px solid #${(Object.keys(formik.errors).length > 0 && formik.errors?.hours_of_operation && typeof formik.errors?.hours_of_operation[index] !== 'undefined' && formik.errors.hours_of_operation[index].open) ? 'd83e3f' : 'EEEEEE'}`,
                              }}
                              disabled={formik.values.hours_of_operation[index].status == 0 ? true : false}
                              idSwitch={"openampmbtn" + index}
                              checkedSwitch={formik.values.open_close_checkbox[index].open}
                              onChangeSwitch={(e) => {
                                var opentext = formik.values.hours_of_operation[index].open;
                                var opensplit = opentext.split(' ');

                                formik.setFieldValue(`hours_of_operation.${index}.open`, opensplit[0] + ' ' + (e.target.checked ? 'PM' : 'AM'));
                                formik.setFieldValue(`open_close_checkbox.${index}.open`, e.target.checked ? true : false);
                              }}
                            />
                            {(Object.keys(formik.errors).length > 0 && formik.errors?.hours_of_operation && typeof formik.errors?.hours_of_operation[index] !== 'undefined') &&
                              <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                style={{
                                  paddingLeft: 15,
                                  color: 'red',
                                }}
                              >
                                {formik.errors.hours_of_operation[index].open}
                              </p>
                            }
                          </Grid>
                          <Grid xs={12} sm={4} md={4} lg={4}>
                            <TimeInput
                              className="time-switch"
                              id={'closetext' + index}
                              placeholder="Closes At"
                              name={`hours_of_operation.${index}.close`}
                              value={formik.values.hours_of_operation[index].close}
                              onChange={(e) => {
                                var closetext = e.target.value;
                                var closesplit = closetext.split(' ');

                                formik.setFieldValue(`hours_of_operation.${index}.close`, closesplit[0] + ' ' + (closesplit[1] == 'PM' || closesplit[1] == 'pM' ? 'PM' : 'AM'));
                                formik.setFieldValue(`open_close_checkbox.${index}.close`, (closesplit[1] == 'PM' || closesplit[1] == 'pM') ? true : false);
                              }}
                              onBlur={(e) => {
                                formik.handleChange(e);
                                document.getElementById('closeampmbtn' + index).style.display = "none";
                              }}
                              onClick={(e) => {
                                if (e.target.value == '__:__ PM') e.target.setSelectionRange(0, 0);
                                document.getElementById('closeampmbtn' + index).style.display = "";
                              }}
                              style={{
                                width: '100%',
                                height: '45px',
                                padding: '10px',
                                borderRadius: '50px',
                                border: `1px solid #${(Object.keys(formik.errors).length > 0 && formik.errors?.hours_of_operation && typeof formik.errors?.hours_of_operation[index] !== 'undefined' && formik.errors.hours_of_operation[index].close) ? 'd83e3f' : 'EEEEEE'}`,
                              }}
                              disabled={formik.values.hours_of_operation[index].status == 0 ? true : false}
                              idSwitch={"closeampmbtn" + index}
                              checkedSwitch={formik.values.open_close_checkbox[index].close}
                              onChangeSwitch={(e) => {
                                var closetext = formik.values.hours_of_operation[index].close;
                                var closesplit = closetext.split(' ');

                                formik.setFieldValue(`hours_of_operation.${index}.close`, closesplit[0] + ' ' + (e.target.checked ? 'PM' : 'AM'));
                                formik.setFieldValue(`open_close_checkbox.${index}.close`, e.target.checked ? true : false);
                              }}
                            />
                            {(Object.keys(formik.errors).length > 0 && formik.errors?.hours_of_operation && typeof formik.errors?.hours_of_operation[index] !== 'undefined') &&
                              <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                style={{
                                  paddingLeft: 15,
                                  color: 'red',
                                }}
                              >
                                {formik.errors.hours_of_operation[index].close}
                              </p>
                            }
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block languages" >
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                    sx={{
                      marginBottom: '30px !important',
                      '@media (max-width:1024px)': {
                        marginBottom: '30px !important',
                      },
                      '@media (max-width:567px)': {
                        marginBottom: '20px !important',
                      },
                    }}>
                    Add available languages
                  </Typography>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <FormControl variant="standard" className="input-style outlined-input select-style border-radius-md" sx={{
                      width: '100%',
                      paddingLeft: '30px',
                    }}>
                      <SelectInput
                        customObj={languages}
                        multiple={true}
                        name="languages"
                        value={formik.values.languages}
                        onChange={(e) => {
                          formik.setFieldValue("languages", e.target.value);
                        }}
                        renderValue={(selected) => {
                          if (selected == undefined || selected == '') {
                            return <em>Add languages</em>;
                          }

                          let selectedlanguage = [];
                          if (formik.values.languages.length > 0) {
                            formik.values.languages.forEach((v, i) => {
                              if (languages[v]) selectedlanguage.push(languages[v])
                            })
                          }
                          return selectedlanguage.join(', ');
                        }}
                      />
                    </FormControl>
                    {formik.touched.languages && Boolean(formik.errors.languages) &&
                      <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                        style={{
                          paddingLeft: 15,
                          color: 'red',
                        }}
                        id="input-with-icon-textfield-helper-text"
                      >
                        {formik.errors.languages}
                      </p>
                    }
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        );
        break;
      case 2:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block" style={{ marginBottom: '30px' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading">
                  What rentals, lessons, or experiences do you offer?
                </Typography>
              </Grid>
              {Object.keys(beachgearlist).map((beachgearcategory, beachgearcategoryindex) => (
                <>
                  <Grid md={4}>
                    <Typography
                      component="h4"
                      style={{
                        color: 'red',
                        fontSize: '19px',
                        fontWeight: '500',
                        textAlign: 'left',
                      }}>{beachgearcategory}</Typography>
                    <div style={{ width: '100%' }}>
                      <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '8px',
                        gap: "5px",
                        borderRadius: 1,
                      }}>
                        {beachgearlist[beachgearcategory].map((result, index) => (
                          <Box className="support-service-check-box">
                            <FormGroup sx={{ width: '280px', '@media (min-width: 576px) and (max-width: 767.98px)':{ width: '230px',}, '@media (min-width: 0) and (max-width: 576.98px':{ width: '250px'} }}>
                              <CheckboxInput label={result.name} name="service" value={result.id} checked={formik.values.service.includes(result['id'].toString())} onChange={(e) => { formCheck(e) }} className={"service-check-box sub-text services-" + beachgearcategoryindex} style={{ color: '#808084' }} checkboxborder="#E5E4E4" />
                            </FormGroup>
                          </Box>
                        ))}
                      </Box>
                    </div>
                  </Grid>
                </>
              ))}
              {formik.touched.service && Boolean(formik.errors.service) &&
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                  style={{
                    paddingLeft: 10,
                    color: 'red',
                  }}
                  id="input-with-icon-textfield-helper-text"
                >
                  Service is required
                </p>
              }
            </Grid>
          </>
        );
        break;
      case 3:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography variant="h6" component="h2" className="main-heading p-relative">
                  Do you offer instant booking
                  <QuestionMarkPopover
                    anchorPopOver={ibInfo}
                    setAnchorPopOver={setIBInfo}
                    content={ibContent}
                    spanClassName='p-absolute'
                  />
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput
                      customArr={{ data: yesno, name: 'name', id: 'id' }}
                      name="instant_booking_available"
                      value={formik.values.instant_booking_available}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading">
                  Do you offer delivery & pickup services for items rented, such as beach gear?
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput
                      customArr={{ data: yesno, name: 'name', id: 'id' }}
                      name="delivery_pickup_service"
                      value={formik.values.delivery_pickup_service}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography variant="h6" component="h2" className="main-heading">
                  Do you require a minimum amount of notice from customers who want to book with delivery & pickup services added?
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput
                      customArr={{ data: yesno, name: 'name', id: 'id' }}
                      name="is_scheduled_booking"
                      value={formik.values.is_scheduled_booking}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              {formik.values.is_scheduled_booking == '1' &&
                <>
                  <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                    <Typography variant="h6" component="h2" className="main-heading">
                      How much notice do you require for bookings scheduled with delivery/pickup services?
                    </Typography>
                  </Grid>
                  <Box className="total-hours">
                    <Box className="opening-hours">
                      <Box className="hours-show"
                        style={{
                          border: `1px solid #${formik.touched.scheduled_booking_day && Boolean(formik.errors.scheduled_booking_day) ||
                            formik.touched.scheduled_booking_hour && Boolean(formik.errors.scheduled_booking_hour) ? 'd83e3f' : 'EEEEEE'}`,
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <div>
                          <FormControl sx={{
                            m: 1,
                            borderRight: 'none',
                            width: '95px'
                          }} >
                            <Select
                              sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                color: '#09060F'
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              name="scheduled_booking_day"
                              value={formik.values.scheduled_booking_day}
                              onChange={formik.handleChange}
                            >
                              <MenuItem disabled value="">
                                <em>Days</em>
                              </MenuItem>
                              {totaldays.map((name) => {
                                return (
                                  <MenuItem value={name} key={name} >{name}</MenuItem>);
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="border-none-class">
                          <FormControl sx={{
                            m: 1,
                            borderRight: 'none'
                          }} >
                            <Select
                              sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                color: '#09060F',
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              name="scheduled_booking_hour"
                              value={formik.values.scheduled_booking_hour}
                              onChange={formik.handleChange}
                            >
                              <MenuItem disabled value="">
                                <em>Hours</em>
                              </MenuItem>
                              {totalhours.map((name) => {
                                return (
                                  <MenuItem value={name} key={name} >{name}</MenuItem>);
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </Box>
                      {formik.touched.scheduled_booking_day && Boolean(formik.errors.scheduled_booking_day) ?
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                          style={{
                            paddingLeft: 15,
                            color: 'red',
                          }}
                          id="input-with-icon-textfield-helper-text"
                        >
                          {formik.errors.scheduled_booking_day}
                        </p>
                        : formik.touched.scheduled_booking_hour && Boolean(formik.errors.scheduled_booking_hour) ?
                          <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                            style={{
                              paddingLeft: 15,
                              color: 'red',
                            }}
                            id="input-with-icon-textfield-helper-text"
                          >
                            {formik.errors.scheduled_booking_hour}
                          </p>
                          :
                          ''
                      }
                    </Box>
                  </Box>
                </>
              }
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                  sx={{
                    '@media (max-width:769px)': {
                      marginBottom: '0 !important',
                    },
                    '@media (max-width:577px)': {

                    },
                  }}>
                  Pricing setup
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="small-heading">
                  How many miles will your company travel for delivery & pickup services? beachify may not allow customers that require these services to find hosts, or book delivery/pickup services with hosts for locations that are outside of the hosts’ designated service area.
                </Typography>
              </Grid>
              <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12}>
                <FormControl sx={{ width: '100%' }} className="input-style "
                  style={{
                    border: `1px solid #${formik.touched.miles_allowed && Boolean(formik.errors.miles_allowed) ? 'd83e3f' : 'EEEEEE'}`
                  }}
                >
                  <TextField className="outline-input" placeholder="Miles allowed"
                    id="input-with-icon-textfield"
                    name="miles_allowed"
                    value={formik.values.miles_allowed}
                    onChange={formik.handleChange}
                    sx={{
                      paddingLeft: '30px',
                      '.MuiInputBase-root': {
                        '&:before': {
                          borderBottom: '0px solid',
                        },
                        '&:after': {
                          borderBottom: '0px solid'
                        },
                        '&:hover': {
                          '&:before': {
                            borderBottom: '0px !important',
                          },
                        },
                      },
                      '@media (max-width:577px)': {
                        paddingLeft: '15px'
                      },
                    }}
                    variant="standard"
                  />
                </FormControl>
                {formik.touched.miles_allowed && Boolean(formik.errors.miles_allowed) &&
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                    style={{
                      paddingLeft: 15,
                      color: 'red',
                    }}
                    id="input-with-icon-textfield-helper-text"
                  >
                    {formik.errors.miles_allowed}
                  </p>
                }
              </Grid>
              <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="sub-heading">
                  How much do you charge per mile for delivery & pickup services?
                </Typography>
              </Grid>
              <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12}>
                <Box>
                  <FormControl sx={{ width: '100%' }} className="input-style"
                    style={{
                      border: `1px solid #${formik.touched.per_mile_pricing && Boolean(formik.errors.per_mile_pricing) ? 'd83e3f' : 'EEEEEE'}`
                    }}
                  >
                    <TextField className="outline-input" placeholder="Charge per mile"
                      id="input-with-icon-textfield"
                      name="per_mile_pricing"
                      value={formik.values.per_mile_pricing}
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: '30px',
                        '.MuiInputBase-root': {
                          '&:before': {
                            borderBottom: '0px solid',
                          },
                          '&:after': {
                            borderBottom: '0px solid'
                          },
                          '&:hover': {
                            '&:before': {
                              borderBottom: '0px !important',
                            },
                          },
                        },
                        '@media (max-width:577px)': {
                          paddingLeft: '15px'
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className="input-image"
                            sx={{
                              paddingRight: '5px',

                            }} >
                            <img src={images.priceIcon} alt="price" />

                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  {formik.touched.per_mile_pricing && Boolean(formik.errors.per_mile_pricing) &&
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                      style={{
                        paddingLeft: 15,
                        color: 'red',
                      }}
                      id="input-with-icon-textfield-helper-text"
                    >
                      {formik.errors.per_mile_pricing}
                    </p>
                  }
                </Box>
              </Grid>
              <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="main-heading-block" style={{ display: 'none' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading">
                  Toll fee setup
                </Typography>
              </Grid>
              <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="small-heading" style={{ display: 'none' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="small-heading">
                  Does your company provide these delivery & pickup services on routes where tolls are incurred? beachify allows Hosts to set toll pricing, as well as custom price per mile charges for these services.
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} style={{ display: 'none' }}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput
                      customArr={{ data: yesno, name: 'name', id: 'id' }}
                      name="toll_included_services"
                      value={formik.values.toll_included_services}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              {formik.values.toll_included_services == "1" &&
                <div>
                  <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="small-heading">
                    <Typography id="modal-modal-title" variant="h6" component="h2" className="small-heading">
                      How many areas do you service where different toll prices need to be charged?
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12}>
                    <Box>
                      <FormControl variant="standard" className="input-style select-style "
                        style={{
                          width: '100%',
                          border: `1px solid #${formik.touched.toll_service_total_area && Boolean(formik.errors.toll_service_total_area) ? 'd83e3f' : 'EEEEEE'}`
                        }}
                      >
                        <SelectInput
                          customArr1={toll_service_total_area}
                          name="toll_service_total_area"
                          value={formik.values.toll_service_total_area}
                          onChange={(e) => {
                            formik.handleChange(e);
                            tollServices(e.target.value)
                          }}
                          sx={{ marginLeft: '30px' }}
                        />
                      </FormControl>
                      {formik.touched.toll_service_total_area && Boolean(formik.errors.toll_service_total_area) &&
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                          style={{
                            paddingLeft: 15,
                            color: 'red',
                          }}
                        >
                          {formik.errors.toll_service_total_area}
                        </p>
                      }
                    </Box>
                  </Grid>
                  <Grid rowSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12} sm={12} md={12} lg={12} className="small-heading mt-5 mb-5">
                    <Typography id="modal-modal-title" variant="h6" component="h2" className="small-heading mt-3 mb-3">
                      Enter the toll price as well as the delivery/pickup zip codes for each area where toll fees will be charged to your customers
                    </Typography>
                  </Grid>
                  {formik.values.toll_service_pricing.map((result, index) => {
                    return (
                      <div key={index}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid xs={6} sm={6} md={6} lg={6}>
                            <Box>
                              <FormControl sx={{ width: '100%' }} className="input-style" >
                                <TextField className="outline-input" placeholder="Zipcode"
                                  name={`toll_service_pricing.${index}.zipcode`}
                                  value={formik.values.toll_service_pricing[index].zipcode}
                                  onChange={formik.handleChange}
                                  id="input-with-icon-textfield"
                                  style={{
                                    border: `1px solid #${(Object.keys(formik.errors).length > 0 && formik.errors?.toll_service_pricing && typeof formik.errors?.toll_service_pricing[index] !== 'undefined' && formik.errors.toll_service_pricing[index].zipcode) ? 'd83e3f' : 'EEEEEE'}`,
                                    borderRadius: '50px'
                                  }}
                                  sx={{
                                    paddingLeft: '30px',
                                    '.MuiInputBase-root': {
                                      '&:before': {
                                        borderBottom: '0px solid',
                                      },
                                      '&:after': {
                                        borderBottom: '0px solid'
                                      },
                                      '&:hover': {
                                        '&:before': {
                                          borderBottom: '0px !important',
                                        },
                                      },
                                    },
                                    '@media (max-width:577px)': {
                                      paddingLeft: '15px'
                                    },
                                  }}
                                  variant="standard"
                                />
                              </FormControl>
                              {(Object.keys(formik.errors).length > 0 && formik.errors?.toll_service_pricing && typeof formik.errors?.toll_service_pricing[index] !== 'undefined') &&
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                  style={{
                                    paddingLeft: 15,
                                    color: 'red',
                                  }}
                                >
                                  {formik.errors.toll_service_pricing[index].zipcode}
                                </p>
                              }
                            </Box>
                          </Grid>
                          <Grid xs={6} sm={6} md={6} lg={6}>
                            <Box>
                              <FormControl sx={{ width: '100%' }} className="input-style" >
                                <TextField className="outline-input" placeholder="Price"
                                  name={`toll_service_pricing.${index}.price`}
                                  value={formik.values.toll_service_pricing[index].price}
                                  onChange={formik.handleChange}
                                  id="input-with-icon-textfield"
                                  style={{
                                    border: `1px solid #${(Object.keys(formik.errors).length > 0 && formik.errors?.toll_service_pricing && typeof formik.errors?.toll_service_pricing[index] !== 'undefined' && formik.errors.toll_service_pricing[index].price) ? 'd83e3f' : 'EEEEEE'}`,
                                    borderRadius: '50px'
                                  }}
                                  sx={{
                                    paddingLeft: '30px',
                                    '.MuiInputBase-root': {
                                      '&:before': {
                                        borderBottom: '0px solid',
                                      },
                                      '&:after': {
                                        borderBottom: '0px solid'
                                      },
                                      '&:hover': {
                                        '&:before': {
                                          borderBottom: '0px !important',
                                        },
                                      },
                                    },
                                    '@media (max-width:577px)': {
                                      paddingLeft: '15px'
                                    },
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start" className="input-image"
                                        sx={{
                                          paddingRight: '5px',
                                        }} >
                                        <img src={images.priceIcon} alt="price" />

                                      </InputAdornment>
                                    ),
                                  }}
                                  variant="standard"
                                />
                              </FormControl>
                              {(Object.keys(formik.errors).length > 0 && formik.errors?.toll_service_pricing && typeof formik.errors?.toll_service_pricing[index] !== 'undefined') &&
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                                  style={{
                                    paddingLeft: 15,
                                    color: 'red',
                                  }}
                                >
                                  {formik.errors.toll_service_pricing[index].price}
                                </p>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              }
            </Grid>
          </>
        );
        break;
      case 4:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {/* <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography variant="h6" component="h2" className="main-heading">
                  Does your store provide services that require your customers to reserve specific start & end reservation time slots before booking?
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput 
                      customArr={{data : yesno, name : 'name', id : 'id'}}
                      name="reserve_specific_time"
                      value={formik.values.reserve_specific_time}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid> */}
              <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                <Typography variant="h6" component="h2" className="main-heading">
                  Does your company have a cancellation policy that enforces penalties for customers canceling outside of your allowed specified cancellation period?
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Box className="radio-check-button">
                  <FormControl>
                    <RadioInput
                      customArr={{ data: yesno, name: 'name', id: 'id' }}
                      name="is_cancellation"
                      value={formik.values.is_cancellation}
                      onChange={formik.handleChange}
                      defaultValue="0"
                      sx={{
                        fontSize: '16px',
                        paddingRight: '30px',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              {formik.values.is_cancellation == '1' &&
                <>
                  <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
                    <Typography variant="h6" component="h2" className="main-heading">
                      How much notice do you require from your customers for cancellation of your services, where you will still issue a full or partial refund?
                    </Typography>
                  </Grid>
                  <Box className="total-hours">
                    <Box className="opening-hours">
                      <Box className="hours-show"
                        style={{
                          border: `1px solid #${formik.touched.cancellation_period_day && Boolean(formik.errors.cancellation_period_day) ||
                            formik.touched.cancellation_period_hour && Boolean(formik.errors.cancellation_period_hour) ? 'd83e3f' : 'EEEEEE'}`,
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <div>
                          <FormControl sx={{
                            m: 1,
                            borderRight: 'none',
                            width: '95px'
                          }} >
                            <Select
                              sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                color: '#09060F'
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              name="cancellation_period_day"
                              value={formik.values.cancellation_period_day}
                              onChange={formik.handleChange}
                            >
                              <MenuItem disabled value="">
                                <em>Days</em>
                              </MenuItem>
                              {totaldays.map((name) => {
                                return (
                                  <MenuItem value={name} key={name} >{name}</MenuItem>);
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="border-none-class">
                          <FormControl sx={{
                            m: 1,
                            borderRight: 'none'
                          }} >
                            <Select
                              sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                color: '#09060F',
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              name="cancellation_period_hour"
                              value={formik.values.cancellation_period_hour}
                              onChange={formik.handleChange}
                            >
                              <MenuItem disabled value="">
                                <em>Hours</em>
                              </MenuItem>
                              {totalhours.map((name) => {
                                return (
                                  <MenuItem value={name} key={name} >{name}</MenuItem>);
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </Box>
                      {formik.touched.cancellation_period_day && Boolean(formik.errors.cancellation_period_day) ?
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                          style={{
                            paddingLeft: 15,
                            color: 'red',
                          }}
                          id="input-with-icon-textfield-helper-text"
                        >
                          {formik.errors.cancellation_period_day}
                        </p>
                        : formik.touched.cancellation_period_hour && Boolean(formik.errors.cancellation_period_hour) ?
                          <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                            style={{
                              paddingLeft: 15,
                              color: 'red',
                            }}
                            id="input-with-icon-textfield-helper-text"
                          >
                            {formik.errors.cancellation_period_hour}
                          </p>
                          :
                          ''
                      }
                    </Box>
                  </Box>
                </>
              }
            </Grid>
          </>
        );
        break;
      case 5:
        formContent = (
          <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12} sm={12} md={12} lg={12} className="main-heading-block">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                  sx={{
                    marginBottom: '30px',
                    '@media (max-width:769px)': {
                      marginBottom: '10px !important',
                    },
                    '@media (max-width:1025px)': {
                      marginBottom: '20px !important',
                    },
                  }}>
                  Terms & conditions
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} className="small-heading">
              <Typography id="modal-modal-title" variant="h6" component="h2" className="main-heading"
                sx={{
                  marginBottom: '30px',
                }}>
                Enter or upload a full and complete copy of your store's rental policy agreement, cancellation policy, refund policy, and any other
                terms & conditions presented to your renters when they do buisness at your location
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} className='bottom-btn'>
              {tcfilepreview == '' ? <Stack
                className={'upload-image-icon ' + (tcfilepreview != '' ? 'imagebgpreview' : '')}
                onClick={() => {
                  document.getElementById("tcfile").click()
                }}
                direction="row" alignItems="center" flexdirection='column' spacing={2}>
                <IconButton color="primary" aria-label="upload picture" component="label" className="upload-icon"
                  sx={{
                    padding: '0',
                  }}>
                  <img src={images.uploadPdf} alt="upload"
                    sx={{
                      marginBottom: '10px',
                    }} />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="upload-text">
                  Upload a pdf
                </Typography>
              </Stack> :
                <Alert severity="info" onClose={() => { setTCFilePreview('') }}>Terms & Condition File - Uploaded </Alert>
              }

              <input accept=".xlsx,.xls,.doc,.docx,.ppt, .pptx,.txt,.pdf" type="file" name="terms_conditions_file" id="tcfile" style={{ display: "none" }}
                onChange={async (event) => {
                  const file = event.currentTarget.files[0];
                  const base64 = await AppHelper.convertToBase64(file);
                  formik.setFieldValue("terms_conditions_file", base64);
                  setTCFilePreview(base64)
                }}
              />
              <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <div style={{ flex: 1, backgroundColor: "#d7d7d7", height: "1px" }} />

                <p style={{ margin: "0 10px" }}>Or</p>

                <div style={{ flex: 1, backgroundColor: "#d7d7d7", height: "1px" }} />
              </div>
              <TextEditor
                formEditor={formEditor}
                name="terms_conditions_content"
                value={formik.values.terms_conditions_content}
              />
              {formik.touched.terms_conditions_content && Boolean(formik.errors.terms_conditions_content) &&
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                  style={{
                    paddingLeft: 15,
                    color: 'red',
                  }}
                >
                  {formik.errors.terms_conditions_content}
                </p>
              }
              <FormGroup sx={{ marginTop: '10px' }}>
                <FormControlLabel control={
                  <CheckboxInput
                    inputtype='checkboxonly'
                    name="agreement"
                    checked={formik.values.agreement}
                    value={formik.values.agreement}
                    onChange={formik.handleChange}
                    checkboxborder="#c5c5c5"
                  />
                } label={
                  <Box
                    sx={{
                      fontSize: '16px',
                      textAlign: 'start'
                    }}>
                    <span>I agree to </span>
                    <Link href="javascript:void(0)" className="primary-text"
                      sx={{
                        textDecoration: 'underline !important',
                      }}
                      onClick={() => { setOpenTC(true) }}>
                      Beachify’s Terms & Conditions</Link>
                  </Box>
                }
                />
                {formik.touched.agreement && Boolean(formik.errors.agreement) &&
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-wl3lqr-MuiFormHelperText-root"
                    style={{
                      color: 'red',
                    }}
                    id="input-with-icon-textfield-helper-text"
                  >
                    {formik.errors.agreement}
                  </p>
                }
              </FormGroup>
            </Grid>
          </>
        );
        break;
    }

    return formContent;
  }

  return (
    <>
      <div>
        <ModalPopup
          contentStyle={{ width: '1130px', width1: '100%' }}
          open={isOpen}
          onClose={handleClose}
          content={<>
            <Box className="modalheader become-host-form">
              <Typography id="modal-modal-title" variant="h6" component="h2">
              </Typography>
              <Box className="modal-close-btn">
                <Button size="small" disableRipple onClick={handleClose}> <img src={images.closeIcon} alt="" /></Button>
              </Box>
            </Box>
            <Box className="modal-body become-host-step-form" sx={{ paddingLeft: '0',  paddingRight: '0' }}>
              <Box sx={{ width: '100%' }}>
                <Stepper className="stepper-bottom" activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <form onSubmit={formik.handleSubmit}>
                  <Container className='grid-space'>
                    {renderStepForm()}
                  </Container>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography style={{ textAlign: 'right', color: 'red', fontSize: '15px' }}>Information can be updated on the accounts page later</Typography>
                      <Box className="modal-filter-btn" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          className="filter-btn"
                          color="inherit"
                          disabled={activeStep === 0 || formik.isSubmitting}
                          onClick={handleBack}
                          sx={{
                            mr: 3,
                            border: '1px solid #E5E4E4',
                            textTransform: 'capitalize',
                            borderRadius: '138px',
                            width: '130px',
                            fontSize: '16px',
                            '@media (max-width:577px)': {
                              fontSize: '16px',
                              textAlign: 'start',
                            },
                          }}
                        >
                          prev
                        </Button>
                        {/* onClick={activeStep === steps.length - 1 ? formSubmit : handleNext} */}
                        <Box className="modal-filter-btn">
                          <Button className="filter-button rounded-button"
                            type="submit"
                            sx={{
                              marginTop: '0 !important',
                              color: '#ffffff',
                              textTransform: 'capitalize',
                            }} >
                            {activeStep === steps.length - 1 ? (formik.isSubmitting ? <CircularProgress color="inherit" /> : "Finish") : 'Next'}
                          </Button>
                        </Box>
                      </Box>
                    </React.Fragment>
                  )}
                </form>
              </Box>
            </Box>
          </>}
        />
      </div>
      {openSuccess &&
        <ModalPopup
          contentStyle={{ width: '1130px', width1: '100%' }}
          open={openSuccess}
          onClose={() => { setOpenSuccess(false) }}
          content={<>
            <Box className="modalheader" sx={{ marginBottom: 0 }}>
              <Box className="modal-close-btn">
                <Button size="small" onClick={() => { setOpenSuccess(false) }}> <img src={images.closeIcon} alt="" /></Button>
              </Box>
            </Box>
            <Box className="modal-body message-modal">
              <img src={images.restingIcon} alt="sucessful" />
              <Typography component="h2">
                You’ve completed registration! It’s time to add details about the services you selected
              </Typography>
              <RouterLink to="/services" style={{ width: '20%', display: 'inline-block', marginTop: '20px' }}>
                <Button className="btn-primary border-radius-md w-100" sx={{ textTransform: 'capitalize', marginBottom: '0px !important', padding: '15px 10px !important' }}>
                  Go to Services
                </Button>
              </RouterLink>
            </Box>
          </>}
        />}
      {openTC &&
        <ModalPopup
          contentStyle={{ width: '1130px', width1: '100%' }}
          open={openTC}
          onClose={() => { setOpenTC(false) }}
          content={<>
            <Box className="modalheader" sx={{ marginBottom: 0 }}>
              <Box className="modal-close-btn">
                <Button size="small" onClick={() => { setOpenTC(false) }}> <img src={images.closeIcon} alt="" /></Button>
              </Box>
            </Box>
            <Box className="modal-body message-modal">
              {parse(termsAndConditions)}
            </Box>
          </>}
        />}
    </>
  )
}
export default BecomeHost;