import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import FormField from "../FormField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { BusinessInfoContext } from "../../../Context/BusinessInfoContext";
import { useContext } from "react";
import { BusinessInfoService } from "../../../Services/BusinessInfoService";
import MakeToast from "../../../Helpers/MakeToast";
import { usePlacesWidget } from "react-google-autocomplete";

const StoreLocationAddress = () => {
  const [editEnable, setEditEnable] = useState(false);
  const { business, setBusinessInfo } = useContext(BusinessInfoContext);
  let informationValidationSchema = yup.object().shape({
    address: yup
      .string()
      .required("Address is required")
      .typeError("Address must be a valid value"),
    lat: yup.number(),
    long: yup.number(),
  });
  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: {
      address: business?.rental_location_address ?? "",
      lat: business?.lat ?? "",
      long: business?.long ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values) => formSubmit(values),
  });

  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;

  const formSubmit = (values) => {
    BusinessInfoService.updateField({
      field_name: "rental_location_address",
      value: values,
    })
      .then((response) => {
        formik.setSubmitting(false);
        setBusinessInfo(response.data.data);
        setEditEnable(false);
        MakeToast("success", "Business Info updated successfully.");
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      formik.setFieldValue("address", place.formatted_address);
      formik.setFieldValue("lat", place.geometry.location.lat());
      formik.setFieldValue("long", place.geometry.location.lng());
    },
  });

  useEffect(() => {
    editEnable == false &&
      formik.setFieldValue("address", business?.rental_location_address ?? "");
  }, [editEnable]);

  return (
    <FormField
      label="Store Location Address"
      description="Please enter your company store location address below."
      text={business?.rental_location_address}
      editEnable={editEnable}
      setEditEnable={setEditEnable}
      renderChildren
    >
      <FormControl
        sx={{ width: "100%", mt: 2 }}
        className="input-style small-radius"
      >
        <TextField
          placeholder="Address"
          inputRef={ref}
          name="address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          className="outline-input textfield-rouded-md input-text-style small-radius"
          sx={{
            borderRadius: "18px !important",
            ".MuiInputBase-input": {
              paddingLeft: "30px",
            },

            ".MuiInputBase-root": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
              "&.Mui-focused": {
                " .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
            },
          }}
        />
      </FormControl>
      {errors.address && touched.address && errors.address && (
        <FormHelperText className="field-helper-text error">
          {errors.address && touched.address && errors.address}
        </FormHelperText>
      )}

      <Button
        size="small"
        type="button"
        disableRipple
        disableElevation
        className="btn-primary submit-btn rounded-button"
        style={{ marginTop: "15px" }}
        disabled={formik.isSubmitting}
        onClick={(e) => formik.handleSubmit()}
      >
        {formik.isSubmitting ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          "Save"
        )}
      </Button>
    </FormField>
  );
};

export default StoreLocationAddress;
