import { Container } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import Account from "../../Components/LoginAndSecurity/Account";
import DeviceHistory from "../../Components/LoginAndSecurity/DeviceHistory";
import Login from "../../Components/LoginAndSecurity/Login";
import SecureAccountCard from "../../Components/LoginAndSecurity/SecureAccountCard";
import SocialAccounts from "../../Components/LoginAndSecurity/SocialAccounts";
import Grid from '@mui/material/Unstable_Grid2';

const LoginAndSecurity = () => {
  return (
    <Container sx={{ py: 5 }}>
      <AccountSettingsTitle
        title="Login & security"
        routeLabel="Login & security"
      />
      <Grid rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12} md={8} item>
          <Login />
          <SocialAccounts />
          <DeviceHistory />
        </Grid>
        <Grid md={4} item>
          <SecureAccountCard />
        </Grid>
      </Grid>
      <Account />
    </Container>
  );
};

export default LoginAndSecurity;
