import { APIService } from "./APIService";

export const NotificationService = {
    list,
    viewed,
    read,
    viewedUpdate,
    readUpdate
};

function list(data) {
    return APIService.get('api/notifications/list', data);
}

function viewed() {
    return APIService.get('api/notifications/viewed');
}

function read() {
    return APIService.get('api/notifications/read');
}

function viewedUpdate() {
    return APIService.get('api/notifications/viewed/update');
}

function readUpdate(data) {
    return APIService.post('api/notifications/read/update', data);
}