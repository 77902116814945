import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
const DeleteConfirmModal = (props) => {
  const { open, setOpen, loading, confirmAction = () => {} } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: 5,
        }}
      >
        <AnnouncementTwoToneIcon
          sx={{
            fontSize: 60,
            marginBottom: 2,
          }}
        />
        <DialogTitle
          sx={{
            padding: 0,
            fontSize: 33,
            fontWeight: "600",
          }}
        >
          Delete account?
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <DialogContentText
            sx={{
              color: "black",
              fontSize: 17,
              fontWeight: "light",
            }}
          >
            Requesting deletion of your account means that you will no longer be
            able to use your Beachify account, and your account will be
            permanently closed.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "solid 1px #DDDDDD",
            marginTop: 4,
          }}
        >
          <Button sx={{ color: "black", marginTop: 1 }} onClick={handleClose}>
            Back
          </Button>
          <Button
            sx={{
              padding: 1.3,
              marginTop: 1,
              backgroundColor: "black !important",
            }}
            className="btn rounded-button btn-primary"
            disabled={loading}
            onClick={confirmAction}
          >
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              "Delete account"
            )}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmModal;
