import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
} from "@mui/material";
import FormField from "../FormField";
import { useFormik } from "formik";
import * as yup from "yup";
import { BusinessInfoContext } from "../../../Context/BusinessInfoContext";
import { useContext, useEffect, useState } from "react";
import { BusinessInfoService } from "../../../Services/BusinessInfoService";
import MakeToast from "../../../Helpers/MakeToast";
import SelectInput from "../../Inputs/SelectInput";
let informationValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .typeError("Name must be a text"),
  relationship: yup
    .string()
    .required("Relationship is required")
    .typeError("Relationship must be a text"),
  // country_id: yup
  //   .number()
  //   .required("Country is required")
  //   .typeError("Country must be a integer"),
  language_id: yup.number().nullable().typeError("Language must be a number"),
  email: yup
    .string()
    .email()
    .nullable()
    .typeError("Email must be a valid email address"),
  phone: yup
    .number()
    //.required("Phone Number is required")
    .typeError("Phone Number  must be a text"),
});

const EmergencyContact = () => {
  const [editEnable, setEditEnable] = useState(false);
  const { business, setBusinessInfo, countries, languages } =
    useContext(BusinessInfoContext);
  const formSubmit = (values) => {
    BusinessInfoService.saveEmergencyContact(values)
      .then((response) => {
        formik.setSubmitting(false);
        setBusinessInfo(response.data.data);
        setEditEnable(false);
        MakeToast("success", "Business Info updated successfully.");
      })
      .catch((error) => {
        formik.setSubmitting(false);
        MakeToast("error", error);
      });
  };
  const formik = useFormik({
    validationSchema: informationValidationSchema,
    initialValues: {
      name: business?.emergency_contact?.name ?? "",
      relationship: business?.emergency_contact?.relationship ?? "",
      country_id: "1",//business?.emergency_contact?.country_id ?? "",
      language_id: business?.emergency_contact?.language_id ?? "",
      email: business?.emergency_contact?.email ?? "",
      phone: business?.emergency_contact?.phone ?? "",
    },
    enableReinitialize: true,
    onSubmit: formSubmit,
  });
  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  useEffect(() => {
    if (editEnable == false) formik.resetForm();
  }, [editEnable]);
  return (
    <FormField
      label="Emergency Contact"
      description="Please enter your emergency contact data below."
      text={business?.emergency_contact_label}
      editEnable={editEnable}
      setEditEnable={setEditEnable}
    >
      <Grid sx={{ mt: 2 }} container>
        <Grid item xs={12}>
          <FormControl
            sx={{ width: "100%", mb: 1 }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.name && touched.name && errors.name}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{ width: "100%", mb: 1 }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="Relationship"
              name="relationship"
              value={values.relationship}
              onChange={handleChange}
              onBlur={handleBlur}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.relationship && touched.relationship && errors.relationship}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            className="input-style outlined-input select-style textfield-rouded-md"
            sx={{
              width: "100%",
              borderRadius: "18px",
              mb: 1,
              pl: 4,
            }}
          >
            <SelectInput
              className={!languages && "pe-none opacity-40"}
              customArr={{
                data: languages,
                name: "name",
                id: "id",
              }}
              name="language_id"
              onChange={handleChange}
              value={values.language_id}
              onBlur={handleBlur}
              renderValue={(selected) => {
                if (
                  (selected == undefined || selected == "") &&
                  (values.language_id == undefined || values.language_id == "")
                ) {
                  return <em>Prefered Language(Optional)</em>;
                }

                let languageData = languages.find(
                  (o) => o.id == values.language_id
                );
                return languageData?.name || "";
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.language_id && touched.language_id && errors.language_id}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{ width: "100%", mb: 1 }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="Email (optional)"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.email && touched.email && errors.email}
          </FormHelperText>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={4} lg={4}>
          <FormControl
            variant="standard"
            className="input-style outlined-input select-style textfield-rouded-md"
            sx={{
              width: "100%",
              borderRadius: "18px",
              mb: 1,
              pl: 4,
            }}
          >
            {countries?.length > 0 ? (
              <SelectInput
                customArr={{
                  data: countries,
                  name: "phone_code",
                  id: "id",
                }}
                name="country_id"
                value={values.country_id}
                onChange={handleChange}
                onBlur={handleBlur}
                renderValue={(selected) => {
                  if (
                    (selected == undefined || selected == "") &&
                    (values.country_id == undefined || values.country_id == "")
                  ) {
                    return <em>Country Code</em>;
                  }

                  let countryData = countries.find(
                    (o) => o.id == values.country_id
                  );
                  return countryData ? countryData.phone_code : "";
                }}
              />
            ) : (
              <SelectInput
                className="opacity-40"
                renderValue={(selected) => {
                  return <em>Country</em>;
                }}
              />
            )}
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.country_id && touched.country_id && errors.country_id}
          </FormHelperText>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl
            sx={{ width: "100%", mb: 1, pl: 1 }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="Phone Number"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
          <FormHelperText className="field-helper-text error">
            {errors.phone && touched.phone && errors.phone}
          </FormHelperText>
        </Grid>
      </Grid>

      <Button
        size="small"
        type="button"
        disableRipple
        disableElevation
        className="btn-primary submit-btn rounded-button"
        style={{ marginTop: "15px" }}
        disabled={formik.isSubmitting}
        onClick={(e) => formik.handleSubmit()}
      >
        {formik.isSubmitting ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          "Save"
        )}
      </Button>
    </FormField>
  );
};

export default EmergencyContact;
