import React from 'react'

const FullLayout = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

export default FullLayout;
