import { Container, Grid, Typography } from "@mui/material";
import AccountSettingsTitle from "../../Components/Common/AccountSettingsTitle";
import HelpCard from "../../Components/YourPayments/HelpCard";
import List from "../../Components/YourPayments/List";

const YourPayments = () => {
  return (
    <Container sx={{ py: 5, }}>
      <AccountSettingsTitle title="Your payments" />
      <Grid sx={{ paddingTop: 5 }} rowSpacing={{ xs: 1, sm: 2, md: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 5 }} container>
        <Grid xs={12} md={8} item>
          <Typography component="h4">
            Once you have a reservation, this is where you can come to track
            your payments and refunds.
          </Typography>
          <List />
        </Grid>
        <Grid xs={4} item>
          <HelpCard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default YourPayments;
