const HostingUpdates = (data) => {
  const titleData = {
    title: "Hosting updates",
    description: "Get updates about programs, features, and regulations.",
  };

  const optionsData = [
    {
      label: "News and updates",
      description:
        "Be first to know about new tools and changes to the app and our service.",
      options: data["news"],
      field: "news",
    },
    {
      label: "Local laws and regulations",
      description: "Get updates on short-term rental laws in your area.",
      field: "locallaws",
      options: data["locallaws"],
    },
  ];

  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};
export default HostingUpdates;
