import React, { useCallback } from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "../../Helpers/MomentDefaultHelper";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import debounce from 'lodash.debounce';
import {
  Box,
  Container,
  FormControl,
  Typography,
  Button,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  TextField,
  Link
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import images from "../../assets/js/images";
import AppHelper from '../../Helpers/AppHelper';
import MakeToast from "../../Helpers/MakeToast";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ModalPopup from "../../Components/Modal/ModalPopup";
import SelectInput from "../../Components/Inputs/SelectInput";

import { BookingService } from "../../Services/BookingService";
import DenyModal from "../../Components/Modal/DenyModal";
import CancellationModal from "../../Components/Modal/CancellationModal";
import SiteLoader from "../../Components/Common/SiteLoader";
import ApiLoader from "../../Components/Common/ApiLoader";
import Support from '../../Components/Support/Support';
import RateHostButton from "../../Components/Booking/RateHost/Button";
import { BookingContext } from "../../Context/BookingContext";

let searchInterval;
const BookingList = () => {
  const navigate = useHistory();
  const { user } = useSelector((state) => state.userReducers.data);

  const currency = AppHelper.currency();

  const convertReadableDate = (dateString = '') =>{
    const date = dateString ? new Date(dateString) : new Date();
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const [bookingDate, setBookingDate] = React.useState(dayjs().utc());
  const [displayDate, setDisplayDate] = React.useState(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate());
  const [openCancellation, setOpenCancellation] = React.useState(false);
  const [openDeny, setOpenDeny] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(false);
  const [selectedHost, setSelectedHost] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [bookingStatus, setBookingStatus] = React.useState('');
  const [bookingSearch, setBookingSearch] = React.useState('');
  const [bookingList, setBookingList] = React.useState([]);
  const [bookingCalenderData, setBookingCalenderData] = React.useState([]);

  React.useEffect(() => {
    setBookingList([]);
    getBookingList('')
  }, [displayDate, bookingStatus]);

  const renderBooking = () => {
    setBookingList([]);
    getBookingList('')
  }

  React.useEffect(() => {
    getBookingCalenderData(bookingDate)
  }, [bookingDate]);

  const getBookingCalenderData = (date) => {
    const dateVal = new Date(date);
    const utcDate = dateVal.toISOString().split('T')[0];
    setDisplayDate(utcDate);
    BookingService.bookingCalender(utcDate).then(res => {
      setBookingCalenderData(res.data.data);
    });
  }

  React.useEffect(() => {
    const buttons = document.querySelectorAll('button[data-timestamp]');
    buttons.forEach((button) => {
      let timestamp = button.getAttribute('data-timestamp');
      if (bookingCalenderData.includes(timestamp / 1000)) {
        button.classList.add('available-day');
      }
    });
  }, [bookingCalenderData]);

  const getBookingList = (searchTerm) => {
    setLoader(true);
    BookingService.bookingList({ date: displayDate, status: bookingStatus, search: searchTerm }).then(res => {
      setBookingList(res.data.data);
      setLoader(false);
    })
  }

  const confirmBooking = (id) => {
    AppHelper.approveBooking(id, MakeToast, renderBooking);
  }

  const statuslabel = AppHelper.lists().statusLabel;
  const statuslist = AppHelper.lists().statusList;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSearch = (searchTerm) => {
    if (searchInterval) clearTimeout(searchInterval);
    searchInterval = setTimeout(() => {
      getBookingList(searchTerm);
    }, 1000)
  };

  const [supportOpen, setSupportOpen] = React.useState(false);
  const handleOpenSupport = () => {
    setSupportOpen(true);
  }

  const updateReviewsData = () => {
    setBookingList([]);
    getBookingList("");
    getBookingCalenderData(bookingDate);
  };

  const locations = useLocation();
  const searchParams = new URLSearchParams(locations.search);
  const statusparam = searchParams.get("status");
  React.useEffect(() => {
    window.history.replaceState(null, null, window.location.pathname);
    setBookingStatus(statusparam);
  }, [statusparam]);

  return (
    <BookingContext.Provider
      value={{
        updateReviewsData: updateReviewsData,
      }}
    >
      <Support setOpen={setSupportOpen} open={supportOpen} pagetype="contacthost" hostslug={selectedHost} />
      <section className='booking-calender section-padding'>
        <Container>
          <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <BreadCrumb data={[["Bookings"]]} />
            <Box className="calender-view-show">
                <Box className='top-heading'>
                  <div className="main-heading-block">
                    <h2 className="main-heading" sx={{ marginTop: '0 !important' }}>
                      Bookings
                    </h2>
                  </div>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
                  <StaticDatePicker
                    timeZone="America/New_York"
                    displayStaticWrapperAs="desktop"
                    value={bookingDate}
                    sx={{
                      ".MuiPickersCalendarHeader-root": {
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center"
                      },
                      ".MuiPickersCalendarHeader-root:first-child": {
                        order: 0,
                        paddingRight: "25px",
                        paddingLeft: "25px"
                      },
                      ".MuiPickersArrowSwitcher-root": {
                        display: "inline-flex"
                        // visibility: "hidden"
                      },
                      ".MuiPickersCalendarHeader-label": {
                        textAlign: "center"
                      },
                      ".MuiPickersCalendarHeader-switchViewButton": {
                        textAlign: "center"
                      },
                      ".MuiPickersArrowSwitcher-spacer": {
                        width: "220px"
                      },
                      ".MuiPickersCalendarHeader-labelContainer .MuiPickersFadeTransitionGroup-root": {
                        display: "flex",
                        position: "absolute",
                        paddingLeft: "100px"
                      },
                      ".css-9reuh9-MuiPickersArrowSwitcher-root": {
                        marginLeft: "-2px"
                      },
                      ".MuiPickersArrowSwitcher-button": {
                        paddingRight: "7px"
                      }
                    }}
                    onViewChange={(e) => {
                      setTimeout(() => {
                        if (e == 'year' && document.querySelectorAll('.MuiYearCalendar-root .MuiPickersYear-root').length) {
                          document.querySelectorAll('.MuiYearCalendar-root .MuiPickersYear-root').forEach(data => {
                            if (data.querySelector('button:disabled')) {
                              data.style.display = 'none'
                            }
                          });
                        }
                      }, 100);
                    }}
                    onMonthChange={(date) => {
                      if (document.getElementsByClassName('MuiYearCalendar-root').length > 0) {
                        document.getElementsByClassName('MuiPickersCalendarHeader-label')[0].click();
                        if (document.getElementsByClassName('MuiYearCalendar-root').length > 0) {
                          document.getElementsByClassName('MuiPickersCalendarHeader-label')[0].click();
                        }
                      }
                      setBookingDate(date);
                    }}
                    onYearChange={(date) => {
                      setBookingDate(date);
                    }}
                    onChange={(newValue) => {
                      setBookingDate(newValue);
                    }}
                    shouldDisableYear={(e) => { return (e.$y) >= '2023' && (e.$y) <= '2030' ? false : true; }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box className="group-img">
                <Box className="booking-top-heading-section">
                  <FormControl
                    variant="standard"
                    className="input-style select-style border-radius-md inner-select"
                    sx={{ width: '180px' }}
                  >
                    <SelectInput
                      customObj={statuslist}
                      value={bookingStatus}
                      onChange={(e) => {
                        setBookingStatus(e.target.value)
                      }}
                      renderValue={(selected) => {
                        if (selected == undefined || selected == '') {
                          return <em>Booking type</em>;
                        }
                        return statuslist[bookingStatus];
                      }}
                    />
                  </FormControl>
                  <Box className="calender-mobile-show-btn">
                    <Button className="btn-primary border-radius-md date-select-btn" onClick={handleOpen}>Filter by Date</Button>
                    <ModalPopup

                      contentStyle={{ width: '600px', width1: '350px', width2: '100%', padding: '0', padding1: '0', padding2: '0', minHeight: '300px', }} className='mobile-popup-calendar'
                      open={open}
                      onClose={handleClose}

                      content={
                        <>
                          <Box className='modalbody mobile-calendar' >
                            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
                              <StaticDatePicker
                                timeZone="America/New_York"
                                displayStaticWrapperAs="desktop"
                                value={bookingDate}
                                onChange={(newValue) => {
                                  setBookingDate(newValue);
                                }}
                                shouldDisableYear={(e) => { console.log(e) }}
                                className='mobile-calender-bg'
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box className='modalfooter'></Box>
                        </>
                      }
                    />
                  </Box>
                  <FormControl sx={{
                    width: '380px',
                    '@media (max-width:769px)': {
                      width: '310px',
                    },
                    '@media (max-width:576px)': {
                      width: '200px !important',
                      marginBottom: '20px',
                    },
                    '@media (max-width:575px)': {
                      width: '100% !important',
                      marginBottom: '20px',
                    },
                    '@media (min-width:577px) and (max-width: 637px)': {
                      width: '250px'
                    }
                  }} className="input-style reduce-bottom-space search-listing-space">
                    <TextField placeholder="Search" className="outline-input input-text-style"
                      value={bookingSearch}
                      onChange={(e) => { setBookingSearch(e.target.value); handleSearch(event.target.value); }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={images.searchIcon} alt="" />
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '.MuiInputBase-root': {
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#eee',
                          },
                          '&.Mui-focused': {
                            ' .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #eee',
                            }
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#eee',
                          }
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box className="inner-calender-heading">
                  <Typography variant="h6">
                    {convertReadableDate(displayDate)}
                    <span className="sub-text">{bookingList.length > 0 ? '(' + bookingList.length + ' services)' : ''} </span>
                  </Typography>
                  {/* <Typography variant="h6" className="archive-heading">
                    <img src={images.archive} alt="archive" />
                    <span className="sub-text">Archive</span>
                  </Typography> */}
                </Box>
                {
                  openCancellation
                    ?
                    <CancellationModal openCancellation={openCancellation} setOpenCancellation={setOpenCancellation} id={selectedId} onEmitValue={renderBooking} />
                    :
                    <></>
                }
                {
                  openDeny
                    ?
                    <DenyModal openDeny={openDeny} setOpenDeny={setOpenDeny} id={selectedId} onEmitValue={renderBooking} />
                    :
                    <></>
                }
                {loader && <ApiLoader />}
                {
                  (bookingList.length > 0)
                    ?
                    bookingList.map((v) => {
                      return (
                        <Box className="inner-page-small-heading">
                          <Box className="total-card">
                            <Box className="trip-block">
                              <Box className="trip-img">
                                <img src={v.beachgears?.beachgear_cover_image[0]?.imgPath} alt="trip" />
                              </Box>
                              <Box className="services-horizontal-card">
                                <Typography variant="h6" className="trip-block-small-heading">
                                  {v.beachgears?.name} ({v.beachgears?.category})<span className="sub-text">(#{v.id})</span> <span className={"trip-badge " + v.status_label}>{statuslabel[v.status_label]} {v.status_label == 'denied' ? ' (' + AppHelper.convertDateTime(v.cancellation_datetime, 3) + ')' : ''}</span>
                                </Typography>
                                <Box className="trip-time-block">
                                  <Box className="start-date">
                                    <Typography component="p" className="tertiary-text">Beginning date</Typography>
                                    <Typography component="p" className="trip-date secondary-text">{AppHelper.convertDateTime(v.start_datetime, 3)}</Typography>
                                  </Box>
                                  <Box className="start-date end-date" style={{ marginLeft: 5 }}>
                                    <Typography component="p" className="tertiary-text">End date</Typography>
                                    <Typography component="p" className="trip-date secondary-text">{AppHelper.convertDateTime(v.end_datetime, 3)}</Typography>
                                  </Box>
                                </Box>
                                {
                                  user.role_id == '2'
                                    ? (
                                      <>
                                        {
                                          ['pending'].includes(v.status_label) && !v.is_instant
                                            ?
                                            <>
                                              <Button
                                                type="submit"
                                                fullHeight
                                                disableElevation
                                                variant="contained"
                                                className="btn-primary small-btn border-radius-md card-btn"
                                                onClick={() => { confirmBooking(v.id) }}
                                              >
                                                Approve
                                              </Button>
                                              <Button
                                                type="submit"
                                                fullHeight
                                                disableElevation
                                                variant="contained"
                                                className="small-btn border-radius-md card-btn outline-btn"
                                                onClick={() => {
                                                  setSelectedId(v.id);
                                                  setTimeout(() => { setOpenDeny(true); }, 500);
                                                }}
                                                style={{ marginLeft: '5px' }}
                                              >
                                                Deny
                                              </Button>
                                            </>
                                            :
                                            <></>
                                        }
                                      </>
                                    ) : (
                                      <>
                                        {
                                        !v.host.archive
                                        ?
                                        <>
                                          <Button
                                            type="submit"
                                            fullHeight
                                            disableElevation
                                            variant="contained"
                                            className="btn-primary small-btn border-radius-md card-btn"
                                            onClick={() => {
                                              setSelectedHost(v.beachgears.host_slug);
                                              setTimeout(() => { handleOpenSupport(); }, 500);
                                            }}
                                          >
                                            Contact Host
                                          </Button>
                                          {[2].includes(v.status) && (
                                            <RateHostButton beachGearData={v} />
                                          )}
                                        </>
                                        :
                                        <></>
                                        }
                                      </>
                                    )}
                              </Box>
                            </Box>
                            <Box className="card-divider"></Box>
                            <Box className='horizontal-card-right-side' sx={{ alignItems: 'end' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: "20px"
                                }}
                              >
                                {
                                  user.role_id == '2'
                                    ?
                                    <RouterLink onClick={() => {
                                        if(!v.user.archive){
                                          navigate.push("/customer-profile/" + v.user.id);
                                        }
                                      }} style={{ display: 'flex' }}>
                                      <Box className="host-img">
                                        <Box className="small-logo">
                                          <img src={v.user.profile_image} alt="" />
                                        </Box>
                                        <Typography component="div" className="host-heading">
                                          {v.user.name}
                                        </Typography>
                                      </Box>
                                    </RouterLink>
                                    :
                                    <RouterLink onClick={() => {
                                        if(!v.host.archive){
                                          navigate.push("/host/" + v.beachgears.host_slug);
                                        }
                                      }} style={{ display: 'flex' }}>
                                      <Box className="host-img">
                                        <Box className="small-logo">
                                          <img src={v.host.profile_image} alt="" />
                                        </Box>
                                        <Typography component="div" className="host-heading">
                                          {v.beachgears.company_name}
                                        </Typography>
                                      </Box>
                                    </RouterLink>
                                }
                              </Box>
                              <Box className="mobile-booking">
                                <List className="booking-list">
                                  <ListItem sx={{ justifyContent: 'flex-end' }}>
                                    <span className="sub-text">Paid <span className="small-block-tex">{currency}{user.role_id == '2' ? v.total_amount_host : v.total_amount}</span></span>
                                    {
                                      v.is_delivery_pickup
                                        ?
                                        <>
                                          <ListItemIcon className="feature-listing-icon">
                                            <img src={images.deliveryIcon} alt="time" />
                                          </ListItemIcon>
                                          <ListItemText primary="Delivery" className="feature-list-name sub-text" />
                                        </>
                                        :
                                        <></>
                                    }
                                  </ListItem>
                                </List>
                                <Box className="calender-button-block">
                                  {
                                    user.role_id == '3'
                                      ?
                                      <>
                                        {
                                          ['pending', 'upcoming'].includes(v.status_label)
                                            ?
                                            <Button
                                              type="submit"
                                              fullHeight
                                              disableElevation
                                              variant="contained"
                                              className="border-radius-md card-btn outline-btn"
                                              onClick={() => {
                                                setSelectedId(v.id);
                                                setTimeout(() => { setOpenCancellation(true); }, 500);
                                              }}
                                              sx={{ textTransform: "none!important" }}
                                            >
                                              {v.cancellation ? 'Cancel' : 'Request a Cancellation'}
                                            </Button>
                                            :
                                            <></>
                                        }
                                      </>
                                      :
                                      <></>
                                  }
                                  <RouterLink to={"/booking-detail/" + v.id}>
                                    <Button
                                      type="submit"
                                      fullHeight
                                      disableElevation
                                      variant="contained"
                                      className="outline-btn border-radius-md"
                                    >
                                      View Details
                                    </Button>
                                  </RouterLink>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="dotted-border-2 dotted-border-3"></Box>
                        </Box>
                      )
                    })
                    :
                    <></>
                }
                {bookingList.length == 0 && !loader ?
                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}>
                    <Typography sx={{
                      textAlign: 'center',
                      fontSize: "20px"
                    }}>No Bookings Found</Typography>
                  </div>
                  :
                  <></>
                }
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </BookingContext.Provider >
  )
}

export default BookingList;
