import { useContext } from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FormContext } from "../../../../../Context/TaxContext";

const SignDate = () => {
  const { formik } = useContext(FormContext);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ fontWeight: "500", mb: 2 }} component="h4">
        Sign and date Form W-8ECI
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        Under penalties of perjury, I declare that I have examined the
        information on this form and to the best of my knowledge and believe it
        is true, correct, and complete. I further certify under penalties of
        perjury that:
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • I am the beneficial owner (or I am authorized to sign for the
        beneficial owner) of all the payments to which this form relates,
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The amounts for which this certification is provided are effectively
        connected with the conduct of a trade or business in the United States,
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The income for which this form was provided is includible in my gross
        income (or the beneficial owner's gross income) for the taxable year,
        and
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        • The beneficial owner is not a US person.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        Furthermore, I authorize this form to be provided to any withholding
        agent that has control, receipt, or custody of the payments of which I
        am the beneficial owner or any withholding agent that can disburse or
        make payments of the amounts of which I am the beneficial owner.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        I agree that I will submit a new form within 30 days if any
        certification made on this form becomes incorrect.
      </Typography>
      <Typography sx={{ fontWeight: "500", mb: 2, fontSize: 15 }}>
        The Internal Revenue Service does not require your consent to any
        provisions of this document other than the certifications required to
        establish your status as a non-US person and that the income for which
        this form is provided is effectively connected with the conduct of a
        trade or business within the United States.
      </Typography>

      <TextField
        placeholder="Your signature"
        name="signature"
        value={formik.values.signature}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      {formik.errors.signature && formik.touched.signature && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.signature}
        </FormHelperText>
      )}
      <TextField
        label="Date"
        value={new Date().toLocaleDateString("en-US")}
        disabled={true}
        className="outline-input textfield-rouded-md input-text-style small-radius"
        sx={{
          width: "100%",
          mt: 1,
          ".MuiInputBase-input": {
            paddingLeft: "30px",
          },

          ".MuiInputBase-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
            "&.Mui-focused": {
              " .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #9b9a9a",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9b9a9a",
            },
          },
        }}
      />
      <Typography sx={{ color: "#9b9a9a" }}>
        Typing your name acts as your signature and certifies that you have
        capacity to sign for the taxpayer identified on this form.
      </Typography>
    </Box>
  );
};

export default SignDate;
