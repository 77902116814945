import { APIService } from "./APIService";

export const PersonalInformationService = {
  getLanguages,
  getInfo,
  updateInfo,
  removeEmergencyContact,
  getPaymentMethod,
  generateOtp,
  verifyOtp,
};

function getLanguages() {
  return APIService.get("api/languages_array");
}

function getInfo() {
  return APIService.get("api/personal_information");
}
function generateOtp(data) {
  return APIService.post("api/personal_information/generate_otp", data);
}
function verifyOtp(data) {
  return APIService.post("api/personal_information/verify_otp", data);
}

function updateInfo(data) {
  return APIService.post(`api/personal_information/update`, data);
}

function removeEmergencyContact(id) {
  return APIService.post(`api/user_emergency_contacts/${id}`);
}

function getPaymentMethod() {
  return APIService.get(`api/get_payment_method`);
}
