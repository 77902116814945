import { APIService } from "./APIService";

export const HomePageService = {
    searchBeach,
    searchServiceFilter,
    serviceListRandom,
    beachesListRandom,
    filterBeachgears,
};

function searchBeach(search, id) {
    var param = '';
    if(search!='') param = '?search='+search;
    if(id!='') param = '?id='+id;

    return APIService.get('api/search-beaches'+param);
}

function searchServiceFilter(data,category_id) {
    return APIService.get('api/beachgearselect-list?search='+data,{'category_id' : category_id});
}

function serviceListRandom() {
    return APIService.get('api/beachgears-list-random');
}

function beachesListRandom() {
    return APIService.get('api/beaches-list-random');
}

function filterBeachgears(data) {
    return APIService.post('api/filter-beach-gears', data);
}
