const TravelTips = (data) => {
  const titleData = {
    title: "Travel tips and offers",
    description:
      "Inspire your next trip with personalized recommendations and special offers.",
  };

  const optionsData = [
    {
      label: "Inspiration and offers",
      description: "Inspiring stays, experiences, and deals.",
      field: "inspiration",
      options: data["inspiration"],
    },
    {
      label: "Trip planning",
      description: "Personalized recommendations for your trip.",
      field: "trip",
      options: data["trip"],
      comingSoon: true,
    },
  ];

  return {
    titleData: titleData,
    optionsData: optionsData,
  };
};
export default TravelTips;
