/**
 * This function separates the country, postal code, state and city from google places autocomplete
 * @param {array} addressComponents - Address components from google autocomplete
 * @returns {object} - Separated data from components
 */

function getAddressComponentData(addressComponents) {
  const separatedData = {
    street_address: null,
    postal_code: null,
    country: null,
    state: null,
    city: null,
  };

  // Get each component of the address from the place details,
  // that are on google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  let streetAddress = "";
  for (const component of addressComponents) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number":
        streetAddress = `${component.long_name} ${streetAddress}`;
        break;
      case "route":
        streetAddress += component.short_name;
        break;
      case "postal_code":
        separatedData.postal_code = component.long_name;
        break;
      case "locality":
        separatedData.city = component.long_name;
        break;
      case "administrative_area_level_1":
        separatedData.state = component.long_name;
        break;
      case "country":
        separatedData.country = component.short_name;
        break;
    }
  }
  separatedData.street_address = streetAddress;
  return separatedData;
}

export default getAddressComponentData;
