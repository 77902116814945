import React from 'react'
import preloader from '../../../src/assets/loaders.gif';

function SiteLoader() {
    return (
        <div className="site-preloader" style={{ width: "400px" }}>
            <img src={preloader} alt="Site preloader" />
        </div>
    )
}

export default SiteLoader