import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { PaymentMethodService } from "../../Services/PaymentMethodService";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import MakeToast from "../../Helpers/MakeToast";
import { PaymentMethodContext } from "../../Context/PaymentMethodContext";
import { useContext } from "react";

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const { setShowEdit } = useContext(PaymentMethodContext);

  const { user } = useSelector((state) => state.userReducers.data);
  const stripe = useStripe();
  const elements = useElements();
  const formSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardHolderName,
        email: user?.email ?? null,
        phone: user?.phone ?? null,
        address: {
          city : billingAddress,
        },
      },
    });

    if (result.error) {
      setLoading(false);
      MakeToast("error", result.error?.message);
      return;
    }
    PaymentMethodService.updatePaymentMethod({
      last_four: result.paymentMethod.card.last4,
      card_holder: cardHolderName,
      billing_address: billingAddress,
      expiration_date:
        result.paymentMethod.card.exp_month +
        "/" +
        result.paymentMethod.card.exp_year,
      card_type: result.paymentMethod.card.brand,
      stripe_payment_method_id: result.paymentMethod.id,
    })
      .then((response) => {
        setLoading(false);
        setShowEdit(false);
        MakeToast("success", response);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Card holder
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="James Jackson"
              className="outline-input textfield-rouded-md input-text-style small-radius placeholder-gray"
              InputProps={{
                sx: {
                  "input::placeholder": {
                    color: "#757575",
                  },
                },
              }}
              sx={{
                borderRadius: "18px !important",
                "::placeholder": {
                  color: "red",
                  opacity: 1,
                },
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
              onChange={(e) => setCardHolderName(e.target.value)}
              value={cardHolderName}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Credit card number
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              type="text"
              className="outline-input textfield-rouded-md input-text-style small-radius"
              InputProps={{
                inputComponent: CardNumberElement,
              }}
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Expiration month and year
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="06/2028"
              InputProps={{ inputComponent: CardExpiryElement }}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          CVC
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              type="text"
              InputProps={{ inputComponent: CardCvcElement }}
              value={"***"}
              className="outline-input textfield-rouded-md input-text-style small-radius"
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Billing Address
        </Typography>
        <Box className="small-service-heading ">
          <FormControl
            sx={{ width: "100%" }}
            className="input-style small-radius"
          >
            <TextField
              placeholder="Enter your billing address"
              className="outline-input textfield-rouded-md input-text-style small-radius"
              InputProps={{
                sx: {
                  "input::placeholder": {
                    color: "#757575",
                  },
                },
              }}
              sx={{
                borderRadius: "18px !important",
                ".MuiInputBase-input": {
                  paddingLeft: "30px",
                },

                ".MuiInputBase-root": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                  "&.Mui-focused": {
                    " .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #eee",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#eee",
                  },
                },
              }}
              onChange={(e) => setBillingAddress(e.target.value)}
              value={billingAddress}
            />
          </FormControl>
        </Box>
      </Grid>
      {stripe && (
        <Button
          size="small"
          type="button"
          disableRipple
          disableElevation
          className="btn-primary submit-btn rounded-button"
          style={{ marginTop: "15px" }}
          disabled={loading}
          onClick={formSubmit}
        >
          {loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Save"
          )}
        </Button>
      )}
    </>
  );
};

export default Form;
