import { useSelector } from "react-redux";
import AppHelper from "../../Helpers/AppHelper";
import EditForm from "./EditForm";
import { CommonService } from "../../Services/CommonService";
import React from "react";
import { PreferencesContext } from "../../Context/PreferencesContext";

const EditOptions = () => {
  const helperLists = AppHelper.lists();
  const { user } = useSelector((state) => state.userReducers.data);

  const [languages, setLanguages] = React.useState({});
  const [selectedLanguage, setSelectedLanguage] = React.useState([]);

  React.useEffect(() => {
    CommonService.getAllLanguages().then((res) => {
      setLanguages(res?.data?.data?.all);
      setSelectedLanguage(res?.data?.data?.selected);
    });
  }, []);

  const optionsData = [
    {
      title: "Preferred language",
      description:
        "This updates what you read on Beachify, and how we communicate with you.",
      options: languages,
      selectedValue: selectedLanguage ?? [],
      field: "language",
      multiple: true,
    },
    {
      title: "Time zone",
      options: helperLists.timezones,
      selectedValue: user?.timezone,
      field: "timezone",
      multiple: false,
    },
  ];
  return (
    <PreferencesContext.Provider
      value={{
        setSelectedLanguage: setSelectedLanguage,
      }}
    >
      {optionsData.map((optionData) => (
        <EditForm {...optionData} />
      ))}
    </PreferencesContext.Provider>
  );
};

export default EditOptions;
