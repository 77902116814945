import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Phone from "./Forms/Phone";
import { useContext } from "react";
import { ModalContext } from "./Context/ModalContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
};
const ResponsiveStyles = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
  },
}));

const PhoneModal = (props) => {
  const { onClose } = props;
  const { setOpenPhone } = useContext(ModalContext);

  return (
    <Modal {...props}>
      <ResponsiveStyles sx={style}>
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon sx={{ fontSize: 25, mb: 4 }} />
        </IconButton>
        <Typography sx={{ fontWeight: "600" }} component="h2">
          What’s your phone number?
        </Typography>
        <Typography sx={{ fontWeight: "500", my: 3 }} component="h4">
          You should have access to this phone so you can receive account
          security codes from us.
        </Typography>
        <Phone setShowPhone={setOpenPhone} />
      </ResponsiveStyles>
    </Modal>
  );
};

export default PhoneModal;
