import * as yup from "yup";
import AppHelper from "../../../../Helpers/AppHelper";
import moment from "../../../../Helpers/MomentDefaultHelper";

const startEndValidation = (data) => {
  const todayDate = moment().format("MM-DD-YYYY");
  return AppHelper.validateStartEnd(
    data.open,
    data.close,
    todayDate,
    todayDate
  );
};

const ValidationSchema = yup.object().shape({
  description: yup
    .string("Enter your company description")
    .required("Description is required"),
  rental_location_address: yup
    .string("Enter your rental location")
    .required("Rental location is required"),
  company_name: yup
    .string("Enter your company name")
    .required("Company name is required"),
  hours_of_operation: yup.array().of(
    yup.object().shape({
      status: yup.number(),
      open: yup
        .string()
        .nullable()
        .when("status", {
          is: (value) => value == 1,
          then: yup
            .string()
            .test(
              "starttime",
              "Opens at is required",
              (val) => val != "" && val.replace(/:| |_/g, "").length === 6
            )
            .test("startendtime", "", (val, data) => {
              return startEndValidation(data.parent);
            }),
          otherwise: "",
        }),
      close: yup
        .string()
        .nullable()
        .when("status", {
          is: (value) => value == 1,
          then: yup
            .string()
            .test(
              "endtime",
              "Closes at is required",
              (val) => val != "" && val.replace(/:| |_/g, "").length === 6
            )
            .test(
              "startendtime",
              "End time must be greater than start time",
              (val, data) => {
                return startEndValidation(data.parent);
              }
            ),
          otherwise: "",
        }),
    })
  ),
});

export default ValidationSchema;
